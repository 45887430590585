import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import gql from 'graphql-tag'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import pluralize from 'pluralize'
import Typography from '@material-ui/core/Typography'
import { format, parseISO } from 'date-fns'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useMutation } from '@apollo/react-hooks'

import ApolloErrorButton from '../ApolloErrorButton'

import styles from './PlannedTourCopyingModal.module.css'

const PlannedTourCopyingModal = ({ onClose, onCopy, plannedTourIds, initialDate }) => {
  const [targetDate, setTargetDate] = useState(initialDate)
  const [mutationResult, setMutationResult] = useState(null)
  const [copyPlannedTours, { error, loading }] = useMutation(
    COPY_PLANNED_TOURS,
    {
      onCompleted: data => {setMutationResult(data); onCopy(targetDate)},
      variables: isNaN(targetDate)
        ? {}
        : { ids: plannedTourIds, targetDate: format(targetDate, 'yyyy-MM-dd') },
    }
  )
  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>{pluralize("Tour", plannedTourIds.length, true)} kopieren</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Hier kannst du die selektierten Touren an einen auswählbaren Zieltag kopieren. Dazu vergleichen
          wir die zu kopierenden Einsätze mit den vorhanden Einsätzen am Zieltag. Passende Einsätze, die am
          Zieltag existieren, verschieben wir in die neu erstellten Touren. Einsätze, die am Zieltag nicht
          als passend identifiziert werden können, kopieren wir zwar nicht, sie werden dir aber am Ende des
          Kopiervorgangs angezeigt.
        </DialogContentText>
        <KeyboardDatePicker
          autoFocus
          autoOk
          className={styles.DatePicker}
          error={isNaN(targetDate)}
          format="dd.MM.yyyy"
          helperText={isNaN(targetDate) ? "Ungültige Eingabe" : null}
          label="Zieldatum"
          margin="normal"
          onChange={setTargetDate}
          value={targetDate}
          variant="inline"
        />
        {error && <ApolloErrorButton error={error} />}
        {mutationResult && <Result result={mutationResult.copyPlannedTours} />}
      </DialogContent>
      {loading && <LinearProgress />}
      <DialogActions>
        <Button
          color="primary"
          disabled={isNaN(targetDate) || loading || !!mutationResult}
          onClick={copyPlannedTours}
        >
          {pluralize("Tour", plannedTourIds.length, true)} kopieren
        </Button>
        <Button color="secondary" disabled={loading} onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  )
}

const Result = ({ result }) => (
  <Paper className={styles.Result}>
    <DialogTitle>Ergebnis</DialogTitle>
    <DialogContent>
      <Typography component="p">
        {((result.matchedPlannedVisits.length > 0) && (result.unmatchedPlannedVisits.length === 0))
          ? "Alle " : ""
        }
        {pluralize("Einsatz", result.matchedPlannedVisits.length, true)} wurden am Zieltag in die kopierten
        Tour(en) verschoben.
      </Typography>
      {result.unmatchedPlannedVisits.length > 0 && <Typography component="p">
        Für die folgenden {pluralize("Einsatz", result.unmatchedPlannedVisits.length, true)} konnte kein
        passender Einsatz am Zieltag identifiziert werden:
        <List dense>{result.unmatchedPlannedVisits.map(v => (
          <ListItem dense><ListItemText>{[
            v.client.lastName,
            v.client.firstName,
            (d => !!d ? format(parseISO(d), 'hh:mm') : "k.A.")(v.estimatedStartDatetime || v.startDatetime),
            `(${v.plannedTour.name || "Tour ohne Namen"})`,
          ].join(", ")}</ListItemText></ListItem>
        ))}</List>
      </Typography>}
    </DialogContent>
  </Paper>
)

const COPY_PLANNED_TOURS = gql`
  mutation copyPlannedTours($ids: [String]!, $targetDate: Date!) {
    copyPlannedTours(ids: $ids, targetDate: $targetDate) {
      matchedPlannedVisits { id }
      unmatchedPlannedVisits {
        client { firstName id lastName }
        estimatedStartDatetime
        id
        plannedTour { id name }
        startDatetime
      }
    }
  }
`

export default PlannedTourCopyingModal
