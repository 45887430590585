import React from 'react'

import moment from 'moment'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import { Button, Form, Image, Loader } from "semantic-ui-react"

import ClientLink from '../ClientLink'
import Avatar from '../../svgs/avatar.svg'

import { getCategoryLabel } from '../../../utils/helpers'
import RelativeTimestamp from "../RelativeTimestamp"

class Header extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      assigneeUserId: (this.props.message.task && this.props.message.task.assigneeUser &&
        this.props.message.task.assigneeUser.id) || null,
      taskDueDate: (this.props.message.task && moment(this.props.message.task.dueDatetime).isValid())
        ? new Date(this.props.message.task.dueDatetime)
        : null,
      isImportant: this.props.message.tags.includes('important')
    }
  }

  render = () => (
    <React.Fragment>
      <div className="Metadata">
        <div className="Title"><h3>{getCategoryLabel(this.props.message.tags)}</h3></div>
        <div className="ImportantTag">
          <Button
            basic
            className={this.state.isImportant ? 'important' : ''}
            onClick={() => this.updateMessageImportance()}
          >Wichtig</Button>
        </div>
        <Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>Bearbeiter:</label>
              {this.props.isWaitingForCreateOrUpdateMessageResponse && <Loader size='small' />}
              <Form.Dropdown
                options={_.sortBy(
                  Object.keys(this.props.userIdToUser)
                    .filter(userId => this.props.userIdToUser[userId].isActive)
                    .map(userId => ({
                      value: userId,
                      text: (user => `${user.firstName} ${user.lastName}`)(this.props.userIdToUser[userId])
                    })),
                  ['text']
                )}
                value={this.state.assigneeUserId}
                onChange={(e, { value }) => this.updateAssigneeUserId(value)}
                placeholder='Bitte auswählen'
                icon={'search'}
                search
                selection
                selectOnBlur={false}
              />
            </Form.Field>
            <Form.Field>
              <label>Fällig am</label>
              <DatePicker
                selected={this.state.taskDueDate}
                onChange={date => this.updateTaskDueDate(date)}
                openToDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <div className="Client">
        <Image size='mini' src={Avatar} avatar />
        {this.props.clientIdToClient &&
          <ClientLink client={this.props.clientIdToClient[this.props.message.clientId]} />
        }
      </div>
      <div className="TaskDescription">
        <div className="Content">{this.props.message.text}</div>
        <div className="Metadata">
          {this.props.userIdToUser &&
            ((user, timestamp) =>
              <p>
                {`Erstellt von ${user} `}
                <RelativeTimestamp timestamp={timestamp} />
              </p>
            )((user => user ? `${user.firstName} ${user.lastName}` : "Unbekannt")(
              this.props.userIdToUser[this.props.message.userId]),
            this.props.message.timestamp
            )}
        </div>
      </div>
    </React.Fragment>
  )

  updateMessageImportance = () => {
    this.setState({
      isImportant: !this.state.isImportant
    }, () => {
      this.props.updateMessage({
        id: this.props.message.id,
        tags: this.state.isImportant
          ? this.props.message.tags.concat('important')
          : this.props.message.tags.filter(tag => tag !== 'important')
      })
    }
    )
  };

  updateAssigneeUserId = assigneeUserId => {
    this.setState({ assigneeUserId })
    this.props.updateMessage({
      id: this.props.message.id,
      taskAssigneeUserId: assigneeUserId,
      taskStatus: (assigneeUserId === this.props.loggedInUserId)
        ? 'read'
        : 'opened'
    })
  }

  updateTaskDueDate = taskDueDate => {
    this.setState({ taskDueDate })

    this.props.updateMessage({
      id: this.props.message.id,
      taskDueDatetime: moment(taskDueDate).format()
    })
  }
}

Header.propTypes = {
  loggedInUserId: PropTypes.string,
  clientIdToClient: PropTypes.object.isRequired,
  userIdToUser: PropTypes.object.isRequired,
  message: PropTypes.shape({
    clientId: PropTypes.string,
    userId: PropTypes.string.isRequired,
    stationId: PropTypes.number.isRequired,
    submessageIdToSubmessage: PropTypes.object,
    tag: PropTypes.string,
    task: PropTypes.object,
    text: PropTypes.string,
    twilioSid: PropTypes.string,
  }),
  updateMessage: PropTypes.func,
}

export default Header
