// General action types
export const FETCH_REQUEST = 'FETCH_REQUEST'
export const FETCH_REQUEST_SUCCESS = 'FETCH_REQUEST_SUCCESS'

// Authentication with LocalStroage
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

// Authentication with Auth0
export const FETCH_AUTH0_LOGIN_REQUEST = 'FETCH_AUTH0_LOGIN_REQUEST'
export const REQUEST_AUTH0_LOGIN_SUCCESS = 'REQUEST_AUTH0_LOGIN_SUCCESS'

// Authentication with Twilio
export const FETCH_TWILIO_LOGIN_REQUEST = 'FETCH_TWILIO_LOGIN_REQUEST'
export const REQUEST_TWILIO_LOGIN_SUCCESS = 'REQUEST_TWILIO_LOGIN_SUCCESS'
export const REQUEST_TWILIO_LOGIN_FAILURE = 'REQUEST_TWILIO_LOGIN_FAILURE'

export const TWILIO_CLIENT_CONNECTION_FAILURE = 'TWILIO_CLIENT_CONNECTION_FAILURE'
export const TWILIO_CLIENT_IS_CONNECTING = 'TWILIO_CLIENT_IS_CONNECTING'
export const TWILIO_CLIENT_IS_CONNECTED = 'TWILIO_CLIENT_IS_CONNECTED'

export const FETCH_GRAPHQL_USERS_SUCCESS = 'FETCH_GRAPHQL_USERS_SUCCESS'
export const FETCH_GRAPHQL_FAILURE = 'FETCH_GRAPHQL_FAILURE'
export const FETCH_GRAPHQL_CLIENTS_SUCCESS = 'FETCH_GRAPHQL_CLIENTS_SUCCESS'

export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS'

export const SET_CURRENT_MESSAGE_ID = 'SET_CURRENT_MESSAGE_ID'
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS'
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS'
export const ADD_SUBMESSAGE_SUCCESS = 'ADD_SUBMESSAGE_SUCCESS'
export const UPDATE_SUBMESSAGE_SUCCESS = 'UPDATE_SUBMESSAGE_SUCCESS'

export const MESSAGE_DECRYPTION_FAILURE = 'MESSAGE_DECRYPTION_FAILURE'

export const SEND_PUSH_NOTIFICATION = 'SEND_PUSH_NOTIFICATION'

// Shiftplanning
export const SET_STATION_ID = 'SET_STATION_ID'

// Other const
export const messageTagLabel = {
  'drug-order': 'Medikamentenbestellung',
  'health': 'Gesundheitszustand',
  'visit-planning': 'Einsatzplanung',
  'general': 'Sonstiges',
}

export const messageTags = ['drug-order', 'health', 'visit-planning']

export const sortOptions = {
  dueDate: { label: 'Nach Fälligkeitsdatum', objectKey: 'task.dueDatetime' },
  date: { label: 'Nach Erstellungsdatum', objectKey: 'timestamp' },
}

export const taskStatusToLabel = {
  'new': "Neu",
  'opened': "Geöffnet",
  'read': "Geöffnet",
  'in-progress': "In Bearbeitung",
  'closed': "Geschlossen",
}

export const alphabetToHexColorCode = {
  'A': '#0D6FBA',
  'B': '#4399db',
  'C': '#063456',
  'D': '#406987',
  'E': '#406987',
  'F': '#a2a8a1',
  'G': '#4a8740',
  'H': '#77e53b',
  'I': '#e5973d',
  'J': '#2c83ba',
  'K': '#14cebf',
  'L': '#13bbce',
  'M': '#0c8f9e',
  'N': '#14cebf',
  'O': '#FFA500',
  'P': '#ce952d',
  'Q': '#f2c204',
  'R': '#ef6d67',
  'S': '#ef6d67',
  'T': '#bc4540',
  'U': '#8c058c',
  'V': '#EE82EE',
  'W': '#c1c1bd',
  'X': '#e5453c',
  'Y': '#f2d710',
  'Z': '#7a7a77',
}

export const auth0ErrorMessage = "Wir konnten Sie leider nicht anmelden. Ursache: "

export const twilioLoginErrorMessage = "Wir konnten leider keine Verbindung zu Twilio herstellen. " +
  "Bitte laden Sie diese Seite neu und kontaktieren Sie das IT-Team."

export const twilioClientFailureErrorMessage = "Wir haben leider die Verbindung zu Twilio verloren." +
  " Bitte laden Sie diese Seite neu"

export const apiErrorMessage = "Wir haben leider keine gültige Antwort von der API erhalten. " +
  "Bitte laden Sie diese Seite neu und kontaktieren Sie das IT-Team."

export const notFoundErrorMessage = "Die URL ist ungültig"

export const decryptionErrorMessage =
  "Die Nachricht konnte nicht entschlüsselt werden. Grund: die Echtheit " +
  "dieser Nachricht konnte nicht bestätigt werden. Bitte melden Sie dies dem Tech-Team."

export const qualificationGroupToLabel = {
  'QualificationGroup.CARE_COMPANION': 'Pflegehilfskraft',
  'QualificationGroup.QUALIFIED_CAREGIVER': 'Fachkraft',
}

export const caregiverQualificationToLabel = {
  'CaregiverQualification.QUALIFIED': 'Fachkraft',
  'CaregiverQualification.CARE_COMPANION': 'Pflegehilfskraft',
}

export const constraintKindToLabel = {
  'ShiftOptimizerConstraintKind.DUMMY_EMPLOYEE_ASSIGNMENTS': 'Unbesetzte Dienste',
  'ShiftOptimizerConstraintKind.DUMMY_SHIFTS': 'Platzhalter-Dienste',
  'ShiftOptimizerConstraintKind.EARLY_SHIFT_AFTER_LATE_SHIFT': 'Frühdienst direkt nach Spätdienst',
  'ShiftOptimizerConstraintKind.MAX_CONSECUTIVE_WORKING_DAYS': 'Max. Arbeitstage am Stück überschritten',
  'ShiftOptimizerConstraintKind.MAX_CONSECUTIVE_WORKING_WEEKENDS': 'Ma. Arbeits-WE am Stück überschritten',
  'ShiftOptimizerConstraintKind.MAX_FREE_DAYS_PER_MONTH': 'Max. freie Tage überschritten',
  'ShiftOptimizerConstraintKind.MAX_WEEKENDS': 'Max. freie Wochenenden überschritten',
  'ShiftOptimizerConstraintKind.MAX_WORKING_DAYS_PER_MONTH': 'Max. Arbeitstage überschritten',
  'ShiftOptimizerConstraintKind.MIN_WEEKENDS': 'Min. freie Wochenenden unterschritten',
  'ShiftOptimizerConstraintKind.MORE_THAN_ONE_SHIFT_PER_DAY': 'Mehr als ein Dienst pro Tag',
  'ShiftOptimizerConstraintKind.NON_BINARY_ASSIGNMENTS': 'Dienst nicht korrekt zugeordnet',
  'ShiftOptimizerConstraintKind.PREFERRED_FREE_DAYS': 'Pflegekraft arbeitet an wunschfreiem Tag',
  'ShiftOptimizerConstraintKind.PREFERRED_WORKING_DAYS': 'Pflegekraft hat frei an Wunscharbeitstag',
  'ShiftOptimizerConstraintKind.SINGLE_FREE_DAYS': 'Einzelne freie Tage',
  'ShiftOptimizerConstraintKind.TOUR_PREFERENCES':
    'Der Pflegekraft wurde ein von ihr nicht bevorzugter Dienst zugeordnet',
  'ShiftOptimizerConstraintKind.TOURS': 'Der Pflegekraft wurde ein für sie unzulässiger Dienst zugeordnet',
  'ShiftOptimizerConstraintKind.TRANSITIONS': 'Transitionen',
  'ShiftOptimizerConstraintKind.UNASSIGNED_SHIFTS': 'Dienste wurden nicht zugeordnet',
  'ShiftOptimizerConstraintKind.ABSENT_DAYS': 'Pflegekraft arbeitet an einem Urlaubstag / ist krankgemeldet',
}

export const editingDisabledStationIds = ['1', '2', '3', '4']

export const weekendDayIndexes = [0, 6]

export const shiftTimeToAbbreviation = {
  'Früh': 'DMF',
  'Frühschicht': 'DMF',
  'Spät': 'DMS',
}

export const shiftKindAbbreviationToColorCodes = {
  'K': ['#B8C5D0', '#B8C5D0'],
  'FF': ['#B8C5D0', '#B8C5D0'],
  'S': ['#B8C5D0', '#FF8C76'],
  'F': ['#FF8C76', '#B8C5D0'],
  'D': ['#FF8C76', '#FF8C76'],
  'X': ['#3F5F7A', '#3F5F7A'],
  'B': ['#00B0E7', '#00B0E7'],
  'U': ['#FFD47C', '#FFD47C'],
}

export const weekdays = [
  { key: 'mo', value: 0, text: 'Mo' },
  { key: 'di', value: 1, text: 'Di' },
  { key: 'mi', value: 2, text: 'Mi' },
  { key: 'do', value: 3, text: 'Do' },
  { key: 'fr', value: 4, text: 'Fr' },
  { key: 'sa', value: 5, text: 'Sa' },
  { key: 'so', value: 6, text: 'So' },
]

export const orderStatusToStatusCode = {
  'ACCEPTED': 3,
  'CANCELLED': 1,
  'CONFIRMED': 4,
  'REJECTED': 1,
  'REQUESTED': 2,
}

export const tourOrderStatusToStatusLabel = {
  'ACCEPTED': 'Bestätigung ausstehend',
  'CANCELLED': 'Storniert',
  'CONFIRMED': 'Auftrag bestätigt',
  'REJECTED': 'Abgelehnt',
  'REQUESTED': 'Angefragt',
}

export const orderStatusToColorCode = {
  'ACCEPTED': '#00B0E7',
  'CANCELLED': '#FF826E',
  'CONFIRMED': '#00E794',
  'REJECTED': '#FF826E',
  'REQUESTED': '#3F5F7A',
}

export const roleToRedirectPath = {
  'talea': '/tasks',
  'customer': '/tour-orders'
}
