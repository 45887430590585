import { useEffect, useRef } from 'react'

import _ from 'lodash'

// Drop-in replacement for `useEffect` which deep-compares `dependencies` instead of a reference-comparison
export const useDeepCompareEffect = (callback, dependencies) => useEffect(
  callback, useDeepCompareMemoize(dependencies)
)

const useDeepCompareMemoize = value => {
  const ref = useRef()
  if (!_.isEqual(value, ref.current)) ref.current = value
  return ref.current
}
