import React, { useState } from 'react'

import _ from 'lodash'
import gql from 'graphql-tag'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useQuery } from '@apollo/react-hooks'

import ApolloErrorButton from '../../components/ApolloErrorButton'
import ServiceEditDialog from '../../components/services/ServiceEditDialog'
import StationSelect from '../../components/StationSelect'
import { useModal } from '../../hooks'

import styles from './ServicesPage.module.css'

const ServicesPage = ({ history, match }) => {
  const stationId = match.params.stationId
  const updatePath = stationId => history.push({ pathname: match.path.replace(':stationId', stationId) })
  const { loading, error, data, refetch } = useQuery(
    SERVICES_QUERY, { fetchPolicy: 'network-only', variables: { stationId } }
  )
  const services = data && _.orderBy(data.services, 'name')
  const [editingServiceId, setEditingServiceId] = useState(null)
  const [isShowingEditDialog, toggleEditDialog] = useModal()
  return <div className={styles.ServicesPage}>
    <Card className={styles.Toolbar} square={true}>
      <StationSelect onChange={stationId => updatePath(stationId)} value={stationId} variant="input" />
    </Card>
    <Card className={styles.Services}>
      <CardHeader title="Leistungen" />
      <Divider />
      <CardContent className={styles.TableContainer}>
        <Table className={styles.Table}>
          <TableHead>
            <TableRow>
              <TableCell>Abkürzung</TableCell>
              <TableCell>PH</TableCell>
              <TableCell>Bezeichnung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services && services.map(s =>
              <TableRow key={s.id} onClick={() => {setEditingServiceId(s.id); toggleEditDialog()}}>
                <TableCell>{s.abbreviation}</TableCell>
                <TableCell>{s.canBeProvidedByCareCompanion && "✓"}</TableCell>
                <TableCell>{s.name}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
        {error && <ApolloErrorButton error={error} />}
        <Fab
          className={styles.AddButton}
          color="primary"
          onClick={() => {setEditingServiceId(null); toggleEditDialog()}}
        >
          <Add />
        </Fab>
      </CardContent>
    </Card>
    {isShowingEditDialog && <ServiceEditDialog
      onClose={toggleEditDialog} onEdit={refetch} serviceId={editingServiceId} stationId={stationId}
    />}
  </div>
}

const SERVICES_QUERY = gql`
  query services($stationId: String!) { services(stationId: $stationId) {
    abbreviation canBeProvidedByCareCompanion id name
  } }
`

export default ServicesPage
