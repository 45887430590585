import React from 'react'

import { Icon } from 'semantic-ui-react'

import styles from './index.module.css'

const CaregiverProfile = ({ caregiverUser }) =>
  <div className={styles.CaregiverProfile}>
    <div className={styles.Avatar}>
      <Icon disabled name='user' size='massive' />
      <h3>{`${caregiverUser.firstName} ${caregiverUser.lastName}`}</h3>
    </div>
  </div>

export default CaregiverProfile
