import { getMessageIdToMessage } from '../utils/helpers'
import {
  apiErrorMessage,
  decryptionErrorMessage,
  twilioClientFailureErrorMessage,
  twilioLoginErrorMessage,
} from '../constants'

const initialState = {
  clientIdToClient: null,
  isClientsFetched: false,
  currentMessageId: null,
  error: null,
  isInitializing: true,
  isWaitingForCreateOrUpdateMessageResponse: false,
  messageIdToMessage: null,
  isMessagesFetched: false,
  pushNotification: { isIgnored: true },
  twilioClientConnectionState: null,
  userIdToUser: null,
}

export const chatReducers = (state = initialState, action) => ({
  SET_CURRENT_MESSAGE_ID: () => ({
    ...state,
    currentMessageId: action.payload
  }),
  ADD_MESSAGE_SUCCESS: () => ({
    ...state,
    isWaitingForCreateOrUpdateMessageResponse: false,
    messageIdToMessage: {
      ...state.messageIdToMessage,
      [action.payload.id]: {
        ...action.payload,
        submessageIdToSubmessage: (state.messageIdToMessage[action.payload.id] &&
          state.messageIdToMessage[action.payload.id].submessageIdToSubmessage)
          ? state.messageIdToMessage[action.payload.id].submessageIdToSubmessage
          : {}
      }
    },
  }),
  ADD_SUBMESSAGE_SUCCESS: () => ({
    ...state,
    isWaitingForCreateOrUpdateMessageResponse: false,
    messageIdToMessage: {
      ...state.messageIdToMessage,
      [action.payload.parentMessageId]: {
        ...state.messageIdToMessage[action.payload.parentMessageId],
        submessageIdToSubmessage: {
          ...state.messageIdToMessage[action.payload.parentMessageId].submessageIdToSubmessage,
          [action.payload.id]: action.payload
        }
      }
    },
  }),
  FETCH_REQUEST: () => ({
    ...state,
    isInitializing: false,
    isWaitingForCreateOrUpdateMessageResponse: true,
  }),
  FETCH_MESSAGES_SUCCESS: () => ({
    ...state,
    isInitializing: false,
    messageIdToMessage: getMessageIdToMessage(action.payload),
    isMessagesFetched: true,
  }),
  FETCH_GRAPHQL_USERS_SUCCESS: () => ({
    ...state,
    userIdToUser: action.payload,
  }),
  FETCH_GRAPHQL_CLIENTS_SUCCESS: () => ({
    ...state,
    clientIdToClient: action.payload,
    isClientsFetched: true
  }),
  FETCH_GRAPHQL_FAILURE: () => ({
    ...state,
    isInitializing: false,
    isWaitingForCreateOrUpdateMessageResponse: false,
    error: apiErrorMessage,
  }),
  MESSAGE_DECRYPTION_FAILURE: () => ({
    ...state,
    error: decryptionErrorMessage,
  }),
  REQUEST_TWILIO_LOGIN_FAILURE: () => ({
    ...state,
    isInitializing: false,
    error: twilioLoginErrorMessage,
  }),
  SEND_PUSH_NOTIFICATION: () => ({
    ...state,
    pushNotification: {
      title: action.payload.title,
      message: action.payload.message,
      isIgnored: (
        state.pushNotification.message && (state.pushNotification.message.id === action.payload.message.id)
      ) || action.payload.isIgnored
    }
  }),
  TWILIO_CLIENT_IS_CONNECTING: () => ({
    ...state,
    isInitializing: true,
    twilioClientConnectionState: action.payload,
  }),
  TWILIO_CLIENT_IS_CONNECTED: () => ({
    ...state,
    error: null,
    isInitializing: false,
    twilioClientConnectionState: action.payload,
  }),
  TWILIO_CLIENT_CONNECTION_FAILURE: () => ({
    ...state,
    error: twilioClientFailureErrorMessage,
    twilioClientConnectionState: action.payload,
  }),
  UPDATE_MESSAGE_SUCCESS: () => ({
    ...state,
    isWaitingForCreateOrUpdateMessageResponse: false,
    messageIdToMessage: {
      ...state.messageIdToMessage,
      [action.payload.id]: {
        ...action.payload,
        submessageIdToSubmessage: (state.messageIdToMessage[action.payload.id] &&
          state.messageIdToMessage[action.payload.id].submessageIdToSubmessage)
          ? state.messageIdToMessage[action.payload.id].submessageIdToSubmessage
          : {}
      }
    },
  }),
  UPDATE_SUBMESSAGE_SUCCESS: () => ({
    ...state,
    isWaitingForCreateOrUpdateMessageResponse: false,
    messageIdToMessage: {
      ...state.messageIdToMessage,
      [action.payload.parentMessageId]: {
        ...state.messageIdToMessage[action.payload.parentMessageId],
        submessageIdToSubmessage: {
          ...state.messageIdToMessage[action.payload.parentMessageId].submessageIdToSubmessage,
          [action.payload.id]: action.payload
        }
      }
    },
  }),
}[action.type] || (() => state))()
