import React, { Fragment, useState } from 'react'
import gql from 'graphql-tag'

import { Button, Icon } from "semantic-ui-react"
import { withRouter } from "react-router-dom"

import apolloClient from "../../utils/apolloClient"
import { getUserRole, hasPermission } from './../../utils/auth'
import TourOrderModal from '../../components/tour-orders/TourOrderModal'
import PlannedTourEditDialog from '../../components/tour-planner2/PlannedTourEditDialog'
import FileUploadModal from "../../components/tour-orders/FileUploadModal"
import { orderStatusToColorCode } from "../../constants"
import TourOrdersTable from './TourOrdersTable'
import { useModal } from '../../hooks'

import styles from './index.module.css'

function TourOrders({ match }) {
  const checkedTourOrderIds = []
  const [refetchHash, setRefetchHash] = useState(null)
  const refetch = () => setRefetchHash(Math.random())
  const isTaleaUser = getUserRole().includes('talea')
  return (
    <div className={styles.TourOrders}>
      {isTaleaUser && <TourCreationButton onClose={refetch} />}
      <div className={styles.TourOrdersContainer}>
        <TourOrdersTable
          isTaleaUser={isTaleaUser}
          onSelectionChange={tourOrderIds => {
            checkedTourOrderIds.length = 0
            checkedTourOrderIds.push(...tourOrderIds)
          }}
          refetchHash={refetchHash}
          tableName="Aufträge"
        />
        <TourOrderButtons tourOrderIds={checkedTourOrderIds} onTourOrdersChanged={refetch} />
      </div>
      <ActionButtons onTourOrdersCreated={refetch} />
    </div>
  )
}

const TourOrderButtons = ({ tourOrderIds, onTourOrdersChanged }) => (
  <div className={styles.TourOrderButtons}>
    {[
      ['Annehmen', 'acceptOrder:careShop', 'ACCEPTED', styles.AcceptButton],
      ['Ablehnen', 'rejectOrder:careShop', 'REJECTED', styles.RejectButton],
    ].map(([label, permission, status, style]) => hasPermission(permission) &&
      <Button
        className={style}
        key={status}
        onClick={() => {Promise.all(tourOrderIds.map(id =>
          apolloClient()
            .mutate({ mutation: UPDATE_TOUR_ORDER_STATUS, variables: { id, status } })
            .catch(error => console.log(error)) // TODO: Do proper error handling
        )).then(onTourOrdersChanged)}}
        style={{ backgroundColor: orderStatusToColorCode[status] }}
      >{label}</Button>
    )}
  </div>
)

const ActionButtons = ({ onTourOrdersCreated }) => {
  const [isFileUploadModalOpen, toggleIsFileUploadModalOpen] = useModal()
  const [isTourOrderModalOpen, toggleIsTourOrderModalOpen] = useModal()
  const [selectedStationId] = useState(null)
  return <Fragment>
    {hasPermission('createOrder:careShop') &&
      <div className={styles.FloatingAddButton}>
        <button
          className="circular ui icon button" onClick={toggleIsTourOrderModalOpen}
        >
          <i className="icon plus alternate"></i>
        </button>
      </div>
    }
    {hasPermission('uploadPDF:tourOrder') &&
      <div className={styles.FloatingUploadButton}>
        <button
          className="circular ui icon button" onClick={toggleIsFileUploadModalOpen}
        >
          <i className="icon cloud upload"></i>
        </button>
      </div>
    }
    {isTourOrderModalOpen && <TourOrderModal
      onClose={toggleIsTourOrderModalOpen}
      onTourOrdersCreated={onTourOrdersCreated}
    />}
    {isFileUploadModalOpen && <FileUploadModal
      onClose={toggleIsFileUploadModalOpen}
      selectedStationId={selectedStationId}
      tourOrder={null}
    />}
  </Fragment>
}

const TourCreationButton = ({ onClose }) => {
  const [isModalOpen, toggleIsModalOpen] = useModal()
  return <Fragment>
    <Button
      className={styles.CreatePlannedTourButton}
      color='blue'
      icon
      labelPosition='left'
      onClick={toggleIsModalOpen}
    >
      <Icon name='plus' /> Tour hinzufügen
    </Button>
    {isModalOpen && <PlannedTourEditDialog
      date={new Date()}
      onClose={() => {toggleIsModalOpen(); onClose()}}
      stationId={6}
    />}
  </Fragment>
}

const UPDATE_TOUR_ORDER_STATUS = gql`
  mutation updateTourOrder($id: String!, $status: TourOrderStatus) {
    updateTourOrder(id: $id, status: $status) { tourOrder { id } }
  }
`

export default withRouter(TourOrders)
