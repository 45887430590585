import React from 'react'

import { Mutation, Query } from 'react-apollo'
import { Button, Dropdown, Form, Icon, Modal } from 'semantic-ui-react'

import { CREATE_SPECIAL_SERVICE } from '../../constants/mutations'
import { GET_CLIENTS, GET_SERVICES, GET_SHIFT_KINDS } from '../../constants/queries'

class SpecialServiceModal extends React.Component {
  state = {
    clientId: null,
    serviceId: null,
    shiftKindId: null,
  }

  render = () => (
    <Modal
      closeIcon
      onClose={this.props.onClose}
      onOpen={this.props.onOpen}
      open={this.props.isOpened}
      size="tiny"
    >
      <Modal.Header>Spezielle Leistung hinzufügen</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <ServiceDropdown
              onChangeServiceId={serviceId => this.setState({ serviceId })}
              serviceId={this.state.serviceId}
              stationId={this.props.stationId}
            />
            <ShiftKindDropdown
              onChangeShiftKindId={shiftKindId => this.setState({ shiftKindId })}
              shiftKindId={this.state.shiftKindId}
              stationId={this.props.stationId}
            />
            <ClientDropdown
              clientId={this.state.clientId}
              onChangeClientId={clientId => this.setState({ clientId })}
              stationId={this.props.stationId}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' onClick={this.props.onClose}>
          <Icon name='remove' /> Abbrechen
        </Button>
        <CreateSpecialServiceButton
          clientId={this.state.clientId}
          onCompleted={() => {
            this.setState({ clientId: null, serviceId: null, shiftKindId: null })
            this.props.onClose()
          }}
          serviceId={this.state.serviceId}
          shiftKindId={this.state.shiftKindId}
        />
      </Modal.Actions>
    </Modal>
  )
}

const ServiceDropdown = ({ serviceId, onChangeServiceId, stationId }) => (
  <Query query={GET_SERVICES} variables={{ stationId }}>
    {({ data, loading }) =>
      <Form.Field>
        <label>Leistung*:</label>
        <Dropdown
          onChange={(e, { value }) => onChangeServiceId(value)}
          options={data.services
            ? data.services.map(service => ({
              text: `${service.abbreviation} - ${service.name}`,
              value: service.id,
            }))
            : []
          }
          loading={loading}
          placeholder='Bitte auswählen'
          search
          selection
          value={serviceId}
        />
      </Form.Field>
    }
  </Query>
)

const ShiftKindDropdown = ({ shiftKindId, onChangeShiftKindId, stationId }) => (
  <Query query={GET_SHIFT_KINDS} variables={{ stationId }}>
    {({ data, loading }) =>
      <Form.Field>
        <label>Dienstart*:</label>
        <Dropdown
          onChange={(e, { value }) => onChangeShiftKindId(value)}
          options={data.shiftKinds
            ? data.shiftKinds.map(shiftKind => ({ text: shiftKind.name, value: shiftKind.id }))
            : []
          }
          loading={loading}
          placeholder='Bitte auswählen'
          search
          selection
          value={shiftKindId}
        />
      </Form.Field>
    }
  </Query>
)

const ClientDropdown = ({ clientId, onChangeClientId, stationId }) => (
  <Query query={GET_CLIENTS} variables={{ stationIds: [stationId], isActive: true }}>
    {({ data, loading }) =>
      <Form.Field>
        <label>Klient:</label>
        <Dropdown
          onChange={(e, { value }) => onChangeClientId(value)}
          options={data.clients
            ? data.clients.map(client => ({
              text: `${client.lastName}, ${client.firstName}`,
              value: client.id,
            }))
            : []
          }
          loading={loading}
          placeholder='Bitte auswählen'
          search
          selection
          value={clientId}
        />
      </Form.Field>
    }
  </Query>
)

const CreateSpecialServiceButton = ({ clientId, serviceId, shiftKindId, onCompleted }) => (
  <Mutation
    mutation={CREATE_SPECIAL_SERVICE}
    onCompleted={onCompleted}
    //TODO: show error to the user
    onError={error => console.error(error)}
    refetchQueries={['getSpecialServices']}
    variables={{ clientId, serviceId, shiftKindId }}
  >
    {createSpecialService =>
      <Button color='green' onClick={createSpecialService} disabled={!(serviceId && shiftKindId)}>
        <Icon name='checkmark' /> Hinzufügen
      </Button>
    }
  </Mutation>
)

export default SpecialServiceModal
