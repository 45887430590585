import React from 'react'
import { Redirect } from 'react-router-dom'

import _ from 'lodash'
import gql from "graphql-tag"
import { CircularProgress } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'

import ApolloErrorButton from '../ApolloErrorButton'

const StationRedirect = ({ path }) => {
  const { loading, error, data } = useQuery(gql`query stations { stations { id code name } }`)
  const stations = data && _.orderBy(data.stations, 'name')
  const station = stations && (_.find(stations, s => s.code === 'SPR') || _.first(stations))
  if (!!station) return <Redirect to={path(station.id)} />
  return loading
    ? <CircularProgress />
    : (!!error ? <ApolloErrorButton error={error} /> : <span>Keine Stationen gefunden</span>)
}

export default StationRedirect
