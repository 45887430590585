import React from 'react'

import PropTypes from 'prop-types'
import { Icon, Modal } from 'semantic-ui-react'

import './ErrorDialog.css'

const ErrorDialog = ({ error, isOpened = true, onClose, title = "Es tut uns leid, ein Fehler ist aufgetreten" }) =>
  <Modal className="ErrorDialog" onClose={onClose} open={isOpened} closeIcon>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Content>
      <Modal.Description className="error">
        {error.message && <p>{error.message}</p>}
        {
          errorMessages([
            ...(error.graphQLErrors || []),
            ...((error.networkError && error.networkError.result && error.networkError.result.errors) || [])
          ])
        }
      </Modal.Description>
    </Modal.Content>
  </Modal>

const errorMessages = errors => {
  return errors && errors.length > 0 &&
    <ul>{errors.map((e, i) => <li key={i}><Icon name='info circle' /> {e.message}</li>)}</ul>
}

ErrorDialog.propTypes = {
  error: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
}

export default ErrorDialog
