import React from 'react'

import { withRouter } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'

import './Steps.css'

const Steps = props => (
  <Button.Group className="Steps">
    {steps.map((step, index) =>
      <Button
        active={props.location.pathname === `${props.match.url}/${step.id}`}
        onClick={() => props.history.push(`${props.match.url}/${step.id}`)}
        key={index}
      >
        <Icon name={step.icon} />
        {step.title}
      </Button>
    )}
  </Button.Group>
)

const steps = [
  {
    icon: 'home',
    title: 'Dienste',
    id: 'shifts'
  },
  {
    icon: 'user',
    title: 'Mitarbeiter',
    id: 'caregivers'
  },
  {
    icon: 'calendar',
    title: 'Planung',
    id: 'planning'
  },
  {
    icon: 'numbered list',
    title: 'Vormonat',
    id: 'previous-month'
  },
  {
    icon: 'lab',
    title: 'Ergebnis',
    id: 'shift-optimizer'
  },
]

export default withRouter(Steps)
