import gql from 'graphql-tag'

// TODO: Use real fragments
const PLANNED_VISIT_FRAGMENT = `
  client {
    apartmentKey
    city
    healthInsuranceName
    firstName
    houseNumber
    id
    lastName
    lat
    lng
    station { code id }
    street
    zipCode
  }
  estimatedVisitOffset
  hint
  historicallyEarliestStartDatetime
  historicallyLatestEndDatetime
  isRescheduled
  requiredCaregiverQualification
  serviceGroups
  services { abbreviation id name }
  shiftTime { id name }
  tourOrder { code id status }
`

const PLANNED_TOURS_QUERY = gql`
  query plannedTours($ids: [String]!) {
    plannedTours(ids: $ids) {
      endDatetime
      estimatedDrivingDuration
      estimatedDuration
      estimatedEndDatetime
      id
      name
      plannedActivities {
        __typename
        additionalDrivingDuration
        duration
        endDatetime
        estimatedDrivingDuration
        estimatedEndDatetime
        estimatedStartDatetime
        id
        lat
        lng
        position
        startDatetime
        ...on PlannedGenericActivityType {
          name
        }
        ...on PlannedVisitType {
          ${PLANNED_VISIT_FRAGMENT}
        }
        ...on ReschedulingPlannedVisitType {
          ${PLANNED_VISIT_FRAGMENT}
          isRescheduledPlannedVisitDeleted
        }
      }
      shift {
        abbreviation
        caregiver { id qualificationGroup }
        id
        name
        shiftKind {
          abbreviation
          id
          name
          qualificationGroup
        }
        startDatetime
        user { firstName id lastName }
      }
      startDatetime
      station { id name }
    }
  }
`

export default PLANNED_TOURS_QUERY
