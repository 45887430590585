import React from 'react'

import { Mutation } from 'react-apollo'
import { Button, Checkbox, Dropdown, Input } from 'semantic-ui-react'

import { qualificationGroupToLabel } from '../../../constants'
import { UPDATE_SHIFT_KIND } from '../../../constants/mutations'
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

import './Shift.css'

class Shift extends React.Component {
  state = {
    abbreviation: '',
    isActive: true,
    isForDay0: false,
    isForDay1: false,
    isForDay2: false,
    isForDay3: false,
    isForDay4: false,
    isForDay5: false,
    isForDay6: false,
    isModalOpened: false,
    name: '',
    qualificationGroup: '',
    shiftTimeId: '',
  }

  render = () =>
    <Mutation
      mutation={UPDATE_SHIFT_KIND}
      variables={{
        ...this.state,
        qualificationGroup: this.state.qualificationGroup
          ? this.state.qualificationGroup.replace('QualificationGroup.', '')
          : null,
        id: this.props.shiftKind.id,
      }}
      refetchQueries={['getShiftKinds']}
    >
      {updateShiftKind =>
        <div className={this.props.classNames}>
          <Button
            label={`${this.state.isActive ? 'Nicht v' : 'V'}erplanen`}
            onClick={() => this.setState({ isModalOpened: true })}
            icon={this.state.isActive ? 'remove' : 'undo'}
          />
          <ConfirmationDialog
            description={
              `Sind Sie sicher, dass der Dienst ${this.state.isActive ? 'nicht ' : ''}verplant werden soll?`
            }
            header={`Dienst ${this.state.isActive ? 'nicht ' : ''}verplanen`}
            onClickConfirm={() => this.setState(
              prevState => ({ isActive: !prevState.isActive, isModalOpened: false }),
              updateShiftKind
            )}
            onClose={() => this.setState({ isModalOpened: false })}
            onOpen={() => this.setState({ isModalOpened: true })}
            open={this.state.isModalOpened}
          />
          <Input
            disabled={!this.state.isActive || !this.props.isEditable}
            onBlur={updateShiftKind}
            onChange={(e, data) => this.setState({ name: data.value })}
            placeholder="Name (z.B. F1, Spät 2)"
            ref={nameInput => this.nameInput = nameInput}
            value={this.state.name}
          />
          <Input
            disabled={!this.state.isActive || !this.props.isEditable}
            onBlur={updateShiftKind}
            onChange={(e, data) => this.setState({ abbreviation: data.value })}
            placeholder="Abkürzung (z.B. F1, S2)"
            value={this.state.abbreviation}
          />
          {this.state.isActive && this.renderShiftAttributes(updateShiftKind)}
        </div>
      }
    </Mutation>

  renderShiftAttributes = updateShiftKind =>
    <React.Fragment>
      <Dropdown
        className={this.props.isEditable ? 'isEditable' : ''}
        disabled={!this.props.isEditable}
        onChange={(e, data) => this.setState(
          prevState => ({ shiftTimeId: data.value }),
          updateShiftKind
        )}
        options={Object.values(this.props.shiftTimes)
          .map(shiftTime => ({ text: shiftTime.name, value: shiftTime.id }))
        }
        placeholder="Früh / Spät"
        selection
        value={this.state.shiftTimeId}
      />
      <Dropdown
        className="isEditable"
        onChange={(e, data) => this.setState(
          prevState => ({ qualificationGroup: data.value }),
          updateShiftKind
        )}
        options={Object.keys(qualificationGroupToLabel).map(qualificationGroup => ({
          text: qualificationGroupToLabel[qualificationGroup],
          value: qualificationGroup,
        }))}
        placeholder="Qualifikation"
        value={this.state.qualificationGroup}
        selection
      />
      {[...Array(7).keys()].map(day =>
        <Checkbox
          checked={this.state[`isForDay${day}`]}
          disabled={!this.props.isEditable}
          onChange={(e, data) => this.setState(
            prevState => ({ [`isForDay${day}`]: data.checked }),
            updateShiftKind
          )}
          key={day}
        />
      )}
    </React.Fragment>

  componentDidMount = () => {
    if (this.props.isCreated) {
      this.nameInput.focus()
    }

    if (this.props.shiftKind) {
      this.setState({
        abbreviation: this.props.shiftKind.abbreviation,
        isActive: this.props.shiftKind.isActive,
        ...[...new Array(7).keys()].reduce((isForDays, day) => ({
          ...isForDays,
          [`isForDay${day}`]: this.props.shiftKind[`isForDay${day}`]
        }), {}),
        name: this.props.shiftKind.name,
        qualificationGroup: this.props.shiftKind.qualificationGroup,
        shiftTimeId: this.props.shiftKind.shiftTime.id,
      })
    }
  }
}

export default Shift
