import React from 'react'

import _ from 'lodash'
import { Mutation } from 'react-apollo'
import { Checkbox } from 'semantic-ui-react'

import DesiredTimeWindowModal from './DesiredTimeWindowModal'
import DesiredTimeWindows from './DesiredTimeWindows'
import { UPDATE_DESIRED_TIME_WINDOWS } from '../../constants/mutations'

import styles from './index.module.css'


class ClientSettings extends React.Component {
  state = {
    isOpened: false,
    clientDesiredTimeWindows: this.props.client.clientDesiredTimeWindows || [],
    canAcceptLeasing: this.props.client.canAcceptLeasing || false
  }

  render = () => {
    return (
      <Mutation
        mutation={UPDATE_DESIRED_TIME_WINDOWS}
        variables={{
          id: this.props.client.id,
          canAcceptLeasing: this.state.canAcceptLeasing,
          clientDesiredTimeWindows: this.state.clientDesiredTimeWindows.map(w => _.omit({
            ...w,
            'serviceGroup': w.serviceGroup.replace('ServiceGroup.', '')
          }, ['id', '__typename']))
        }}
        onCompleted={() => this.setState(
          () => ({ isOpened: false }),
          this.props.getClientDetails(this.props.client.id)
        )}
      >
        {(updateDesiredTimeWindow, { data }) => (
          <div className={styles.ClientSettings}>
            <div className={styles.IsClientActive}>
              <Checkbox label="Ist Aktiv" checked={!this.props.client.isActive} disabled />
            </div>
            <div className={styles.CanAcceptLeasing}>
              <Checkbox
                label="Leasingkrafteinsatz in Ordnung"
                checked={this.state.canAcceptLeasing}
                onClick={() => this.setState(
                  prevState => ({ canAcceptLeasing: !this.state.canAcceptLeasing }),
                  updateDesiredTimeWindow,
                  this.props.getClientDetails(this.props.client.id))
                }
              />
            </div>
            <div className={styles.UnwantedCaregivers}>
              <h4 className={styles.UnwantedCaregiversHeader}>Ungewünschte Pflegekräfte:</h4>
              <ul className={styles.UnwantedCaregiversList}>
                {this.props.client.caregiverPreferences
                  .map(c =>
                    <li className={styles.UnwantedCaregiver} key={c.id}>
                      {c.caregiver.user.firstName} {c.caregiver.user.lastName}
                    </li>
                  )
                }
              </ul>
            </div>
            <div className={styles.DesiredTimeWindows}>
              {this.props.client.clientDesiredTimeWindows &&
                <DesiredTimeWindows
                  onAddTimeWindow={() => this.setState({ isOpened: true })}
                  desiredTimeWindows={this.state.clientDesiredTimeWindows}
                  onRemoveTimeWindow={timeWindowId => this.setState(
                    prevState => ({
                      clientDesiredTimeWindows: prevState.clientDesiredTimeWindows.filter(
                        desiredTimeWindow => desiredTimeWindow.id !== timeWindowId
                      )
                    }),
                    updateDesiredTimeWindow
                  )}
                />
              }
            </div>
            <DesiredTimeWindowModal
              client={this.props.client}
              open={this.state.isOpened}
              onOpen={() => this.setState({ isOpened: true })}
              onClose={() => this.setState({ isOpened: false })}
              getClientDetails={this.props.getClientDetails}
              onAddDesiredTimeWindow={timeWindow => this.setState(
                prevState => ({ clientDesiredTimeWindows: [...prevState.clientDesiredTimeWindows, timeWindow] }),
                updateDesiredTimeWindow,
              )}
            />
          </div>
        )}
      </Mutation>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      this.setState({
        clientDesiredTimeWindows: this.props.client.clientDesiredTimeWindows,
        canAcceptLeasing: this.props.client.canAcceptLeasing,
      })
    }
  }
}

export default ClientSettings
