import React from 'react'

import { Query } from 'react-apollo'
import { Button, Dropdown, Popup } from 'semantic-ui-react'

import { GET_SERVICES } from '../../../constants/queries'

import styles from './ServiceSelector.module.css'

class ServiceSelector extends React.Component {
  state = {
    selectedServiceId: null,
  }

  render = () => (
    <Query query={GET_SERVICES} variables={{ stationId: this.props.stationId }}>
      {({ loading, data }) =>
        <Popup
          className={styles.ServiceSelector}
          flowing
          on='click'
          onClose={this.props.onClose}
          onOpen={this.props.onOpen}
          open={this.props.isOpened}
          position='bottom right'
          trigger={<Button icon='add'></Button>}
          content={
            <Dropdown
              className={styles.Dropdown}
              loading={loading}
              options={(data.services && data.services
                .filter(s => !this.props.unwantedServices.map(s => s.id).includes(s.id))
                .map(s => ({
                  value: s.id,
                  text: s.abbreviation ? `${s.abbreviation} - ${s.name}` : s.name,
                  service: s
                }))
              ) || []}
              label='Leistungen'
              onChange={(_, data) => this.setState(
                prevState => ({ selectedServiceId: data.value }),
                this.props.onAddUnwantedService(
                  data.options.find(o => o.service.id === data.value)['service']
                )
              )}
              value={this.state.selectedServiceId}
              placeholder='Bitte auswählen'
              fluid
              search
              selection
            />
          }
        />
      }
    </Query>
  )
}

export default ServiceSelector
