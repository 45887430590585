import React from 'react'

import { withRouter } from 'react-router-dom'

import Services from '../../components/services-legacy/Services'
import SpecialServices from '../../components/services-legacy/SpecialServices'

import styles from './ServicesPage.module.css'

const ServicesPage = ({ match }) => (
  <div className={styles.ServicesPage}>
    <Services stationId={match.params.stationId} />
    <SpecialServices stationId={match.params.stationId} />
  </div>
)

export default withRouter(ServicesPage)
