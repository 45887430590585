import React, { useState } from 'react'

import gql from 'graphql-tag'
import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { Done, SettingsBackupRestore } from '@material-ui/icons'
import { useMutation } from '@apollo/react-hooks'

import styles from './StationCard.module.css'

const StationCard = ({ station }) => {
  const [originalHint, setOriginalHint] = useState(station.hint || "") // TODO: Use apollo cache instead
  const [hint, setHint] = useState(originalHint)
  const isHintDirty = hint !== originalHint
  const resetHint = () => setHint(originalHint)
  const [updateStation, { error, loading }] = useMutation(
    UPDATE_STATION,
    {
      onCompleted: ({ createOrUpdateStation }) => setOriginalHint(createOrUpdateStation.station.hint),
      variables: { hint, id: station.id }
    }
  )
  return <Card><CardContent className={styles.CardContent}>
    <Avatar>{station.code}</Avatar>
    <Typography variant="h5">{station.name}</Typography>
    <Table className={styles.Table} size="small">
      <TableBody>
        {['city', 'houseNumber', 'name', 'phoneNumber', 'street', 'zipCode'].map(attribute =>
          <TableRow key={attribute}>
            <TableCell>{attribute
              .replace(/([A-Z])/g, ' $1') // insert a space before all caps
              .replace(/^./, s => s.toUpperCase()) // uppercase the first character
            }</TableCell>
            <TableCell>{station[attribute]}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
    <TextField
      className={styles.Hint}
      fullWidth
      helperText={isHintDirty ?
        (loading ? "Wird gespeichert..." : (error ? "Fehler beim Speichern" : "Noch nicht gespeichert"))
        : null
      }
      label="Hinweis"
      margin="dense"
      multiline
      onChange={e => setHint(e.target.value)}
      rowsMax="10"
      value={hint}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <IconButton disabled={!isHintDirty || loading} edge="end" onClick={resetHint}>
              <SettingsBackupRestore />
            </IconButton>
            {loading
              ? <CircularProgress size={33} />
              : <IconButton disabled={!isHintDirty} edge="end" onClick={updateStation}><Done /></IconButton>
            }
          </InputAdornment>
        )
      }}
    />
  </CardContent></Card>
}

const UPDATE_STATION = gql`
  mutation updateStation($hint: String $id: String!) {
    createOrUpdateStation(hint: $hint id: $id) { station { hint id } }
  }
`

export default StationCard
