import React, { useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
} from '@material-ui/core'
import gql from 'graphql-tag'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import ApolloErrorButton from '../ApolloErrorButton'
import { useEffectAfterRender } from '../../hooks'

import styles from './ServiceEditDialog.module.css'

function ServiceEditDialog({ onClose = () => {}, onEdit = () => {}, serviceId, stationId }) {
  const [abbreviation, setAbbreviation] = useState("")
  const [name, setName] = useState("")
  const [canBeProvidedByCareCompanion, setCanBeProvidedByCareCompanion] = useState(false)
  const isNameEmpty = !name || (name.trim().length === 0)
  const [fetchService, { loading: isFetching, data: fetchData, error: fetchError }] = useLazyQuery(
    SERVICE_QUERY, { fetchPolicy: 'network-only', variables: { id: serviceId } }
  )
  useEffect(() => { !!serviceId && fetchService() }, [serviceId])
  const service = fetchData && (fetchData.services.length > 0) && fetchData.services[0]
  useEffectAfterRender(
    () => {
      setAbbreviation(service.abbreviation || "")
      setCanBeProvidedByCareCompanion(service.canBeProvidedByCareCompanion || false)
      setName(service.name || "")
    },
    [service]
  )
  const [createOrUpdateService, { error: updateError, loading: isUpdating }] = useMutation(
    CREATE_OR_UPDATE_SERVICE,
    {
      onCompleted: () => {onEdit(); onClose()},
      variables: { abbreviation, canBeProvidedByCareCompanion, id: serviceId, name, stationId },
    }
  )
  const isLoading = isFetching || isUpdating
  const error = fetchError || updateError
  return (
    <Dialog className={styles.ServiceEditDialog} onClose={onClose} open={true}>
      <DialogTitle>Leistung {serviceId ? "bearbeiten" : "anlegen"}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          disabled={isLoading}
          fullWidth
          label="Abkürzung"
          margin="normal"
          onChange={e => setAbbreviation(e.target.value)}
          placeholder="Abkürzung (Code)"
          value={abbreviation}
        />
        <TextField
          autoFocus
          disabled={isLoading}
          error={!isFetching && isNameEmpty}
          fullWidth
          helperText={!isFetching && isNameEmpty && "Ein Name muss vergeben werden"}
          label="Name"
          margin="normal"
          onChange={e => setName(e.target.value)}
          placeholder="Vollständiger Name (ggfs. mit Abkürzung)"
          value={name}
        />
        <div>{/* This extra div creates a block container to occupy the whole line */}
          <FormControlLabel
            control={
              <Switch
                checked={canBeProvidedByCareCompanion}
                onChange={e => setCanBeProvidedByCareCompanion(e.target.checked)}
              />
            }
            label="Kann auch von Pflegehilfskraft durchgeführt werden"
            margin="normal"
          />
        </div>
        {error && <ApolloErrorButton error={error} />}
      </DialogContent>
      {isLoading && <LinearProgress />}
      <DialogActions>
        <Button
          disabled={isNameEmpty || isLoading || !!fetchError}
          onClick={createOrUpdateService}
        >Service {serviceId ? "aktualisieren" : "erstellen"}</Button>
        <Button color="secondary" disabled={isLoading} onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  )
}

const CREATE_OR_UPDATE_SERVICE = gql`
  mutation createOrUpdateService(
    $abbreviation: String
    $canBeProvidedByCareCompanion: Boolean
    $id: String
    $name: String
    $stationId: String
  ) {
    createOrUpdateService(
      abbreviation: $abbreviation
      canBeProvidedByCareCompanion: $canBeProvidedByCareCompanion
      id: $id
      name: $name
      stationId: $stationId
    ) { service { id } }
  }
`

const SERVICE_QUERY = gql`
  query service($id: String!) {
    services(id: $id) { abbreviation canBeProvidedByCareCompanion id name station { id } }
  }
`

export default ServiceEditDialog
