import React from 'react'

import _ from 'lodash'
import gql from 'graphql-tag'
import pluralize from 'pluralize'
import { Box, Grid, LinearProgress, Typography, } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'

import ApolloErrorButton from '../../components/ApolloErrorButton'
import StationCard from './StationCard'

import styles from './index.module.css'

const Stations = () => {
  const { loading, data, error } = useQuery(STATIONS_QUERY, { fetchPolicy: 'network-only' })
  const stations = data && _.sortBy(data.stations, ['name'])
  return <Box className={styles.Stations}>
    {loading && <LinearProgress />}
    {error && <ApolloErrorButton error={error} />}
    {stations && <StationsList stations={stations} />}
  </Box>
}

const StationsList = ({ stations }) => <>
  <Typography variant="caption">
    {stations
      ? `${pluralize("Station", stations.length, true)} ${pluralize("wird", stations.length)} angezeigt`
      : "Lade..."
    }
  </Typography>
  <Grid
    className={styles.Grid}
    container
    spacing={3}
  >
    {stations.map(s => <Grid
      item
      key={s.id}
      lg={4}
      md={6}
      sm={6}
      xs={12}
    ><StationCard station={s} /></Grid>)}
  </Grid>
</>

const STATIONS_QUERY = gql`query getStations { stations {
  city
  code
  deputyManagerFirstName
  deputyManagerLastName
  deputyManagerPhoneNumber
  emailAddress
  hint
  houseNumber
  id
  managerFirstName
  managerLastName
  managerPhoneNumber
  name
  phoneNumber
  street
  zipCode
} }`

export default Stations
