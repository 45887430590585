import React from 'react'
import { Redirect } from "react-router-dom"

import Auth from "../../utils/auth"

import './index.css'

class LogoutScreen extends React.Component {
  constructor(props) {
    super(props)
    this.auth = new Auth()
    this.auth.logout()
  }

  render() {
    return (
      <div className="LogoutScreen">
        <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
      </div>
    )
  }
}

export default LogoutScreen
