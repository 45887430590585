import React from 'react'

import { Mutation } from 'react-apollo'
import { Button, Checkbox, Dropdown, Form, Input, Popup } from 'semantic-ui-react'

import { qualificationGroupToLabel } from '../../../constants'
import { UPDATE_USER } from '../../../constants/mutations'
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

import './Caregiver.css'

class Caregiver extends React.Component {
  state = {
    canBeAssignedShifts: true,
    canHaveSingleFreeDays: true,
    canSplitWeekends: true,
    filteredShifts: [],
    firstName: '',
    isModalOpened: false,
    lastName: '',
    maximumConsecutiveWorkdays: '',
    maximumConsecutiveWorkingWeekends: '',
    possibleShiftIds: [],
    preferredShiftIds: [],
    qualificationGroup: '',
    shiftsPerWeek: '',
  }

  render = () => (
    <Mutation
      mutation={UPDATE_USER}
      variables={{
        caregiver: {
          canBeAssignedShifts: this.state.canBeAssignedShifts,
          canHaveSingleFreeDays: this.state.canHaveSingleFreeDays,
          canSplitWeekends: this.state.canSplitWeekends,
          maximumConsecutiveWorkdays: this.state.maximumConsecutiveWorkdays
            ? parseInt(this.state.maximumConsecutiveWorkdays, 10)
            : 0,
          maximumConsecutiveWorkingWeekends: this.state.maximumConsecutiveWorkingWeekends
            ? parseInt(this.state.maximumConsecutiveWorkingWeekends, 10)
            : 0,
          qualificationGroup: this.state.qualificationGroup.replace('QualificationGroup.', ''),
          shiftsPerWeek: this.state.shiftsPerWeek
            ? parseInt(this.state.shiftsPerWeek, 10)
            : 0,
          shiftConditions: [
            ...this.props.user.caregiver.shiftConditions
              .filter(s => !!s.date)
              .map(s => s.shiftKind
                ? ({
                  conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''),
                  date: s.date,
                  shiftKindId: s.shiftKind && s.shiftKind.id,
                })
                : ({ conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''), date: s.date })
              ),
            ...this.state.possibleShiftIds.map(id => ({
              conditionKind: 'POSSIBLE_SHIFT', shiftKindId: id
            })),
            ...this.state.preferredShiftIds.map(id => ({
              conditionKind: 'PREFERRED_SHIFT', shiftKindId: id
            })),
          ],
        },
        firstName: this.state.firstName,
        id: this.props.user.id,
        isActive: true,
        lastName: this.state.lastName,
      }}
      refetchQueries={['getUsersAndShifts']}
    >
      {updateUser =>
        <div className={this.props.classNames}>
          <Button
            label={`${this.state.canBeAssignedShifts ? 'Nicht v' : 'V'}erplanen`}
            onClick={() => this.setState({ isModalOpened: true })}
            icon={this.state.canBeAssignedShifts ? 'remove user' : 'undo'}
          />
          <ConfirmationDialog
            description={
              `Sind Sie sicher, dass der Mitarbeiter ${this.state.canBeAssignedShifts ? 'nicht ' : ''}verplant werden soll?`
            }
            header={`Mitarbeiter ${this.state.canBeAssignedShifts ? 'nicht ' : ''}verplanen`}
            onClickConfirm={() => this.setState(
              prevState => ({ canBeAssignedShifts: !prevState.canBeAssignedShifts, isModalOpened: false }),
              updateUser
            )}
            onClose={() => this.setState({ isModalOpened: false })}
            onOpen={() => this.setState({ isModalOpened: true })}
            open={this.state.isModalOpened}
          />
          <Input
            onBlur={updateUser}
            onChange={(e, input) => this.setState({ lastName: input.value })}
            disabled={!this.state.canBeAssignedShifts || !this.props.isEditable}
            ref={nameInput => this.nameInput = nameInput}
            value={this.state.lastName}
          />
          <Input
            onBlur={updateUser}
            disabled={!this.state.canBeAssignedShifts || !this.props.isEditable}
            onChange={(e, input) => this.setState({ firstName: input.value })}
            value={this.state.firstName}
          />
          {this.state.canBeAssignedShifts && this.renderCaregiverAttributes(updateUser)}
        </div>
      }
    </Mutation>
  )

  renderCaregiverAttributes = updateUser =>
    <React.Fragment>
      <Dropdown
        disabled={!this.props.isEditable}
        onChange={(e, input) => this.setState(
          prevState => ({
            qualificationGroup: input.value,
            possibleShiftIds: this.props.shiftKinds
              .filter(s => s.qualificationGroup === input.value)
              .map(s => s.id),
            preferredShiftIds: [],
          }),
          updateUser
        )}
        options={Object.keys(qualificationGroupToLabel).map(qualificationGroup => ({
          text: qualificationGroupToLabel[qualificationGroup],
          value: qualificationGroup,
        }))}
        selection
        value={this.state.qualificationGroup}
      />
      <Input
        onBlur={updateUser}
        onChange={(e, input) => this.setState({ maximumConsecutiveWorkdays: input.value })}
        value={this.state.maximumConsecutiveWorkdays}
      />
      <Input
        onBlur={updateUser}
        onChange={(e, input) => this.setState({ maximumConsecutiveWorkingWeekends: input.value })}
        value={this.state.maximumConsecutiveWorkingWeekends}
      />
      <Input
        onBlur={updateUser}
        onChange={(e, input) => this.setState({ shiftsPerWeek: input.value })}
        value={this.state.shiftsPerWeek}
      />
      <Checkbox
        onChange={(e, input) => this.setState(
          prevState => ({ canHaveSingleFreeDays: input.checked }),
          updateUser
        )}
        checked={this.state.canHaveSingleFreeDays}
      />
      <Checkbox
        onChange={(e, input) => this.setState(
          prevState => ({ canSplitWeekends: input.checked }),
          updateUser
        )}
        checked={this.state.canSplitWeekends}
      />
      <Popup
        trigger={
          <div className="Shifts">
            {this.state.possibleShiftIds.map(id => this.props.shiftKindIdToName[id]).join(', ')}
          </div>
        }
        on='click'
        content={
          <Form.Dropdown
            label="Mögliche Dienste"
            multiple
            onChange={(e, input) => this.setState(
              prevState => ({ possibleShiftIds: input.value }),
              updateUser
            )}
            options={this.state.filteredShifts}
            selection
            value={this.state.possibleShiftIds}
          />
        }
      />
      <Popup
        trigger={
          <div className="Shifts">
            {this.state.preferredShiftIds.map(id => this.props.shiftKindIdToName[id]).join(', ')}
          </div>
        }
        on='click'
        content={
          <Form.Dropdown
            label="Bevorzugte Dienste"
            multiple
            onChange={(e, input) => this.setState(
              prevState => ({ preferredShiftIds: input.value }),
              updateUser
            )}
            options={this.state.filteredShifts}
            selection
            value={this.state.preferredShiftIds}
          />
        }
      />
    </React.Fragment>

  componentDidMount = () => {
    if (this.props.isCreated) {
      this.nameInput.focus()
    }

    if (this.props.user && this.props.shiftKinds) {
      this.setState({
        canBeAssignedShifts: this.props.user.caregiver.canBeAssignedShifts,
        canHaveSingleFreeDays: this.props.user.caregiver.canHaveSingleFreeDays,
        canSplitWeekends: this.props.user.caregiver.canSplitWeekends,
        filteredShifts: this.props.shiftKinds.map(s => ({ text: s.name, value: s.id, key: s.id })),
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        maximumConsecutiveWorkdays: this.props.user.caregiver.maximumConsecutiveWorkdays,
        maximumConsecutiveWorkingWeekends: this.props.user.caregiver.maximumConsecutiveWorkingWeekends,
        possibleShiftIds: this.getShiftIdsFromCondition('POSSIBLE_SHIFT'),
        preferredShiftIds: this.getShiftIdsFromCondition('PREFERRED_SHIFT'),
        qualificationGroup: this.props.user.caregiver.qualificationGroup,
        shiftsPerWeek: this.props.user.caregiver.shiftsPerWeek,
      })
    }
  }

  getShiftIdsFromCondition = conditionKind => this.props.user.caregiver.shiftConditions
    .filter(c => !c.date && (c.conditionKind === `ShiftConditionKind.${conditionKind}`))
    .map(c => c.shiftKind && c.shiftKind.id)
}

export default Caregiver
