import React from 'react'
import _ from 'lodash'
import { Button, Dropdown, Icon, Label } from 'semantic-ui-react'

import { sortOptions } from '../../constants'
import {
  filterMessagesByStatuses,
  filterMessagesByUserId,
  sortMessages,
} from '../../utils/helpers'
import NoEntry from '../../components/taskmanager/NoEntry'
import Sort from '../svgs/sort.svg'
import Task from '../../components/taskmanager/Task'

import './Tasks.css'

class Tasks extends React.Component {
  state = {
    statusFilters: ['active'],
    sortOption: 'date',
    shouldSortAsc: false,
    userFilter: null,
  }

  render() {
    return (
      <div className="Tasks">
        <div className="Subheader">
          {this.renderFilter()}
          <Label circular>
            {this.sortedTaskedMessages(this.filteredTaskedMessages(this.props.taskedMessages))
              .filter(m => m.task.status === 'opened')
              .length}
          </Label>
          {this.renderSort()}
        </div>
        <div className="TaskList">
          {
            (taskedMessages =>
              taskedMessages.length > 0
                ? taskedMessages.map(message =>
                  <Task
                    key={message.id}
                    message={message}
                    clientIdToClient={this.props.clientIdToClient}
                    userIdToUser={this.props.userIdToUser}
                    loggedInUserId={this.props.loggedInUserId}
                    createAndUpdateMessage={this.createAndUpdateMessage}
                    onClickTask={this.props.onClickTask}
                  />
                )
                : <NoEntry text="Keine Aufgaben" />
            )(this.sortedTaskedMessages(this.filteredTaskedMessages(this.props.taskedMessages)))
          }
        </div>
      </div>
    )
  }

  renderFilter = () => (
    <Dropdown
      text={this.renderFilterText()}
    >
      <Dropdown.Menu className="Filter">
        <Dropdown.Item
          onClick={e => this.setUserFilter(null)}
          className={this.state.userFilter === null ? 'active' : ''}
        >
          Alle Aufgaben
        </Dropdown.Item>
        <Dropdown.Item
          onClick={e => this.setUserFilter(this.props.loggedInUserId)}
          className={this.state.userFilter === this.props.loggedInUserId ? 'active' : ''}
        >
          Meine Aufgaben
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>
          <Dropdown
            options={this.props.userIdToUser && _.sortBy(
              Object.keys(this.props.userIdToUser).map(userId => ({
                value: userId,
                text: (user => `${user.firstName} ${user.lastName}`)(this.props.userIdToUser[userId])
              })),
              ['text']
            )}
            value={this.state.userFilter}
            onChange={(e, { value }) => this.setUserFilter(value)}
            placeholder='Mitarbeiter auswählen'
            className="UserDropdown"
            icon={'search'}
            open
            search
            selection
            selectOnBlur={false}
          />
        </Dropdown.Header>
      </Dropdown.Menu>
    </Dropdown>
  )

  renderFilterText = () => (this.state.userFilter === this.props.loggedInUserId
    ? 'Meine Aufgaben'
    : this.state.userFilter
      ? (user => `${user.firstName} ${user.lastName} Aufgaben`)(
        this.props.userIdToUser[this.state.userFilter]
      )
      : 'Alle Aufgaben'
  )

  renderSort = () => (
    <div className="Sort">
      <Icon
        name={this.state.shouldSortAsc ? 'long arrow down' : 'long arrow up'}
        onClick={() => this.setState({ shouldSortAsc: !this.state.shouldSortAsc })}
      />
      <Dropdown icon={<img src={Sort} alt='sort' />} direction='left'>
        <Dropdown.Menu>
          {Object.keys(sortOptions).map(sortOption =>
            <Dropdown.Item
              key={sortOption}
              onClick={() => this.setState({ sortOption })}
              className={this.state.sortOption === sortOption ? 'active' : ''}
            >
              {sortOptions[sortOption].label}
            </Dropdown.Item>)}
          <Dropdown.Divider />
          <Dropdown.Header>
            {
              [
                { value: 'active', label: 'Aktiv' },
                { value: 'closed', label: 'Abgeschlossen' }
              ].map(statusFilter =>
                <Button
                  onClick={(e, _) => {this.setStatusFilter(statusFilter.value); e.stopPropagation()}}
                  active={this.state.statusFilters.includes(statusFilter.value)}
                  key={statusFilter.value}
                >
                  {statusFilter.label}
                </Button>)
            }
          </Dropdown.Header>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

  filteredTaskedMessages = taskedMessages =>
    (this.state.userFilter || this.state.statusFilters)
      ? filterMessagesByUserId(filterMessagesByStatuses(
        taskedMessages,
        _.flatMap(this.state.statusFilters, statusFilter =>
          ({
            'active': ['opened', 'in-progress', 'read'],
            'closed': ['closed'],
          }[statusFilter] || [])
        )
      ), this.state.userFilter)
      : taskedMessages

  sortedTaskedMessages = taskedMessages => sortMessages(
    taskedMessages,
    sortOptions[this.state.sortOption].objectKey,
    [this.state.shouldSortAsc ? 'asc' : 'desc']
  )

  setUserFilter = userId => this.setState({
    isFilterOpened: false,
    userFilter: userId,
  });

  setStatusFilter = status => this.setState({
    statusFilters: this.state.statusFilters.includes(status)
      ? [...this.state.statusFilters].filter(statusFilter => statusFilter !== status)
      : [...this.state.statusFilters, status]
  });

  createAndUpdateMessage = data => {
    this.props.updateMessage(data.message)

    if (!data.comment) return

    this.props.createMessage({
      parentId: data.message.id,
      tag: data.message.tag,
      taskStatus: 'opened',
      text: data.comment,
      taskDueDatetime: data.commentDueDate,
      taskAssigneeUserId: data.commentAssigneeUserId,
    })
  }

  componentDidUpdate = prevProps => {
    if (this.props.loggedInUserId !== prevProps.loggedInUserId) {
      this.setState({ userFilter: this.props.loggedInUserId })
    }
  }
}

export default Tasks
