import React from 'react'

import { messageTagLabel } from '../../constants'
import { filterMessagesByCategory } from '../../utils/helpers'
import Messages from '../../components/taskmanager/clientpage/Messages'

import * as API from '../../utils/API'

import './ClientMessages.css'


class ClientMessages extends React.Component {
  state = {
    categoryFilter: 'all',
    isModalOpened: false,
    client: null,
  }

  componentDidMount() {
    API.fetchClientDetails(this.props.clientId).then(response => {
      this.setState({ client: response.clients[0] })
    })
  }

  render = () => {
    return (
      <div className="ClientMessages">
        <div className="MainArea">
          <div className="FilterButtons">
            {
              (messageCategories => Object.keys(messageCategories).map(category =>
                <button
                  key={category}
                  onClick={() => this.setState({ categoryFilter: category })}
                  className={this.state.categoryFilter === category ? 'active' : ''}
                >
                  {messageCategories[category]}
                </button>)
              )({ all: 'Alle', ...messageTagLabel })
            }
          </div>
          <Messages
            messages={this.props.clientMessages && this.filteredMessages(this.props.clientMessages)}
            clientIdToClient={this.props.clientIdToClient}
            loggedInUserId={this.props.loggedInUserId}
            userIdToUser={this.props.userIdToUser}
            updateMessage={this.props.updateMessage}
            onClickMessage={this.handleTaskModal}
          />
        </div>
      </div>
    )}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentMessageId !== this.props.currentMessageId) {
      this.setState({
        isModalOpened: true,
      })
    }
  }

  filteredMessages = messages => (this.state.categoryFilter !== 'all')
    ? filterMessagesByCategory(messages, this.state.categoryFilter)
    : messages

  handleTaskModal = () => this.setState({ isModalOpened: !this.state.isModalOpened })
}

export default ClientMessages
