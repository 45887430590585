import React, { Component } from 'react'

import { Accordion, Checkbox, Icon, Menu, Table } from 'semantic-ui-react'

import * as API from '../../utils/API'

import './index.css'

class LiveVisitsApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedStation: 'medical',
      isExpandingAll: false,
      reloading: false,
      reloadError: null,
      tours: [],
      expandedTours: [],
    }
    this.reloadTimer = null
  }

  render = () => (
    <div className="VisitsOfTodayApp">
      <Menu>
        {
          (stationIdToName => Object.keys(stationIdToName).map(stationId => (
            <Menu.Item
              key={stationId}
              active={this.state.selectedStation === stationId}
              onClick={() => this.setState({ selectedStation: stationId }, this.reloadVisits)}
            >
              {stationIdToName[stationId]}
            </Menu.Item>
          )))({
            'medical': "Medical",
            'insulaner': "Insulaner Mitte/Süd",
          })
        }
      </Menu>
      <Checkbox
        label="Alle Touren aufklappen"
        toggle
        checked={this.state.isExpandingAll}
        onChange={() => this.setState(prevState => ({ isExpandingAll: !prevState.isExpandingAll }))}
      />
      <p>
        {this.state.reloading
          ? "Aktualisiere..."
          : this.state.reloadError || `Heute: ${this.state.tours.length} Touren`}
      </p>
      <div className="ToursContainer">
        <div className="Tours">
          <Accordion exclusive={false} styled>
            {this.renderTourPanels()}
          </Accordion>
        </div>
      </div>
    </div>
  )

  renderTourPanels = () => this.state.tours.map(tour => (
    (active => [
      <Accordion.Title
        key={`${tour.name}-title`}
        active={this.state.expandedTours.includes(tour.name)}
        onClick={() => this.setState(prevState => ({
          expandedTours: prevState.expandedTours.includes(tour.name)
            ? prevState.expandedTours.filter(name => name !== tour.name)
            : prevState.expandedTours.concat([tour.name])
        }))}
      >
        <Icon name='dropdown' />
        {tour.name} – {' '}
        {tour.actualStartTime
          ? `Begonnen um ${tour.actualStartTime} Uhr von `
          : "Noch nicht begonnen"}
        {tour.actualStartTime && tour.visits
          .filter(visit => !!visit.caregiverName)
          .reduce((names, visit) => names.concat(
            names.includes(visit.caregiverName) ? [] : [visit.caregiverName]), [])
          .map((name, index) => (index > 0 ? ', ' : '') + name)}
      </Accordion.Title>,
      <Accordion.Content
        key={`${tour.name}-content`}
        active={this.state.isExpandingAll || this.state.expandedTours.includes(tour.name)}
      >
        <Table>
          <Table.Body>
            {
              tour.visits.map(visit => (
                <Table.Row
                  key={`${visit.plannedStartTime}-${visit.plannedEndTime}-${visit.clientName}`}
                  className={
                    (visit.actualStartTime &&
                      ((visit.actualEndTime && 'done') ||
                      'inProgress')) ||
                      'open'
                  }
                >
                  <Table.Cell>{visit.plannedStartTime}</Table.Cell>
                  <Table.Cell>{visit.plannedEndTime}</Table.Cell>
                  <Table.Cell>{visit.clientName}</Table.Cell>
                  <Table.Cell>{visit.actualStartTime}</Table.Cell>
                  <Table.Cell>{visit.actualEndTime}</Table.Cell>
                  <Table.Cell>{visit.caregiverName}</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      </Accordion.Content>,
    ])(this.state.expandedTours.includes(tour.name))
  ))

  componentDidMount = () => {
    //TODO: super.componentDidMount()
    this.reloadTimer = setInterval(this.reloadVisits, 30 * 1000 /* 30 seconds */)
    this.reloadVisits()
  }

  componentWillUnmount = () => {
    clearInterval(this.reloadTimer)
    //TODO: super.componentWillUnmount()
  }

  reloadVisits = () => {
    this.setState({ reloading: true }, () => {
      API.getVisitsOfToday(this.state.selectedStation)
        .then(response => this.setState({
          reloading: false,
          reloadError: null,
          tours: response,
        })).catch(error => this.setState({
          reloading: false,
          reloadError: error.toString(),
        }))
    })
  }
}

export default LiveVisitsApp
