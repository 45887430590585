import React from "react"

import { Dropdown } from "semantic-ui-react"

import { caregiverQualificationToLabel } from '../../constants'

const CaregiverQualificationDropdown = ({ className, nullText, onSelect, selection }) => (
  <Dropdown
    className={className}
    compact
    onChange={(e, { value }) => onSelect(value === '_all' ? null : value)}
    options={[
      { key: 'all', value: '_all', text: nullText || '(Alle)' },
      ...Object.entries(caregiverQualificationToLabel).map(([q, label]) => (
        { key: q, value: q.replace('CaregiverQualification.', ''), text: label }
      ))
    ]}
    selection
    value={selection || '_all'}
  />
)

export default CaregiverQualificationDropdown
