import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Button, Dropdown, Icon } from 'semantic-ui-react'

import { sortOptions } from '../../../constants/index'
import {
  filterMessagesByStatuses,
  filterMessagesByUserId,
  getTaskedMessages,
  getUntaskedMessages,
  sortMessages,
} from "../../../utils/helpers"
import Sort from '../../svgs/sort.svg'
import Message from './Message'
import NoEntry from '../../taskmanager/NoEntry'

import './Messages.css'

class Messages extends React.Component {
  state = {
    messageType: 'all',
    statusFilters: [''],
    sortOption: 'date',
    shouldSortAsc: false,
    userFilter: null,
  }

  render() {
    return (
      <div className="Messages">
        <div className="Subheader">
          {this.renderSortOptions()}
        </div>
        <div className="MessageContainer">
          <div className="MessageList">
            {this.props.messages &&
              (messages =>
                messages.length > 0
                  ? messages.map(message =>
                    <Message
                      key={message.id}
                      message={message}
                      clientIdToClient={this.props.clientIdToClient}
                      userIdToUser={this.props.userIdToUser}
                      loggedInUserId={this.props.loggedInUserId}
                      createAndUpdateMessage={this.createAndUpdateMessage}
                      onClickMessage={this.props.onClickMessage}
                    />
                  )
                  : <NoEntry text="Keine Aufgaben" />
              )(this.sortedMessages(this.filterMessagesByUser(this.filterMessageByType(this.props.messages))))
            }
          </div>
        </div>
      </div>
    )
  }

  renderSortOptions = () => (
    <div className="SortOptions">
      <Icon
        name={this.state.shouldSortAsc ? 'long arrow down' : 'long arrow up'}
        onClick={() => this.setState({ shouldSortAsc: !this.state.shouldSortAsc })}
      />
      <Dropdown icon={<img src={Sort} alt='sort' />} direction='left'>
        <Dropdown.Menu>
          {Object.keys(sortOptions).map(sortOption =>
            <Dropdown.Item
              key={sortOption}
              onClick={() => this.setState({ sortOption })}
              className={this.state.sortOption === sortOption ? 'active' : ''}
            >
              {sortOptions[sortOption].label}
            </Dropdown.Item>)}
          <Dropdown.Divider />
          <Dropdown.Header>
            <Button.Group>
              {[{ value: 'all', label: 'All' }].map(statusFilter =>
                <Button
                  onClick={(e, _) => {this.setState({ messageType: 'all', statusFilters: [] }); e.stopPropagation()}}
                  active={(this.state.messageType === 'all')}
                  key={statusFilter.value}
                >
                  {statusFilter.label}
                </Button>)
              }
              <Button.Or />
              {[{ value: 'active', label: 'Aktiv' }, { value: 'closed', label: 'Abgeschlossen' }].map(statusFilter =>
                <Button
                  onClick={(e, _) => {this.setStatusFilter(statusFilter.value); this.setState({ messageType: 'tasked' }); e.stopPropagation()}}
                  active={(this.state.messageType === 'tasked') && this.state.statusFilters.includes(statusFilter.value)}
                  key={statusFilter.value}
                >
                  {statusFilter.label}
                </Button>)
              }
              <Button.Or />
              {[{ value: 'feed', label: 'Feeds' }].map(statusFilter =>
                <Button
                  onClick={(e, _) => {this.setStatusFilter(statusFilter.value); this.setState({ messageType: 'untasked' }); e.stopPropagation()}}
                  active={(this.state.messageType === 'untasked') && this.state.statusFilters.includes(statusFilter.value)}
                  key={statusFilter.value}
                >
                  {statusFilter.label}
                </Button>)
              }
            </Button.Group>
          </Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Header style={{ 'display': 'none' }}>
            {[{ value: 'active', label: 'Aktiv' }, { value: 'closed', label: 'Abgeschlossen' }, { value: 'feed', label: 'Feeds' }].map(statusFilter =>
              <Button
                onClick={(e, _) => {this.setStatusFilter(statusFilter.value); e.stopPropagation()}}
                active={this.state.statusFilters.includes(statusFilter.value)}
                key={statusFilter.value}
              >
                {statusFilter.label}
              </Button>)}
          </Dropdown.Header>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

  filterMessagesByUser = taskedMessages =>
    (this.state.userFilter)
      ? filterMessagesByUserId(taskedMessages, this.state.userFilter)
      : taskedMessages

  filterMessageByType = messages => (
    {
      'all': messages,
      'tasked': filterMessagesByStatuses(getTaskedMessages(messages), _.flatMap(this.state.statusFilters, statusFilter => ({
        'active': ['opened', 'in-progress', 'read'],
        'closed': ['closed'],
      }[statusFilter] || []))),
      'untasked': getUntaskedMessages(messages),
    }[this.state.messageType] || 'all')

  sortedMessages = messages => sortMessages(
    messages,
    sortOptions[this.state.sortOption].objectKey,
    [this.state.shouldSortAsc ? 'asc' : 'desc']
  )

  setUserFilter = userId => this.setState({
    isFilterOpened: false,
    userFilter: userId,
  });

  setStatusFilter = status => this.setState({
    statusFilters: this.state.statusFilters.includes(status)
      ? [...this.state.statusFilters].filter(statusFilter => statusFilter !== status)
      : [...this.state.statusFilters, status]
  });

  createAndUpdateMessage = data => {
    this.props.updateMessage(data.message)

    if (!data.comment) return

    this.props.createMessage({
      parentId: data.message.id,
      tag: data.message.tag,
      taskStatus: 'opened',
      text: data.comment,
      taskDueDatetime: data.commentDueDate,
      taskAssigneeUserId: data.commentAssigneeUserId,
    })
  }

  componentDidUpdate = prevProps => {
    if (this.props.loggedInUserId !== prevProps.loggedInUserId) {
      this.setState({ userFilter: this.props.loggedInUserId })
    }
  }
}

Messages.propTypes = {
  messages: PropTypes.array,
  clientIdToClient: PropTypes.object,
  loggedInUserId: PropTypes.string,
  onClickMessage: PropTypes.func,
}
export default Messages
