import React from 'react'

import { Mutation } from 'react-apollo'
import _ from 'lodash'

import { UPDATE_CAREGIVER } from '../../../constants/mutations'
import ErrorDialog from '../../../components/dialogs/ErrorDialog'
import UnwantedServicesTable from './UnwantedServicesTable'
import ServiceSelector from './ServiceSelector'

import styles from './index.module.css'

class UnwantedServices extends React.Component {
  state = {
    unwantedServices: this.props.caregiverUser.caregiver.unwantedServices || [],
    isServiceSelectorOpened: false,
    error: null,
  }

  render = () => (
    <Mutation
      mutation={UPDATE_CAREGIVER}
      variables={{
        id: this.props.caregiverUser.caregiver.id,
        unwantedServiceIds: this.state.unwantedServices.map(s => s.id)
      }}
      refetchQueries={['getUserById']}
      onError={error => this.setState({ error })}
    >
      {updateCaregiver =>
        <div className={styles.UnwantedServices}>
          <div className={styles.Header}>
            <h4>Kann folgende Leistungen nicht erfüllen:</h4>
            <ServiceSelector
              isOpened={this.state.isServiceSelectorOpened}
              onOpen={() => this.setState({ isServiceSelectorOpened: true })}
              onClose={() => this.setState({ isServiceSelectorOpened: false })}
              onAddUnwantedService={unwantedService => this.setState(
                prevState => ({
                  unwantedServices: [...prevState.unwantedServices, unwantedService],
                  isServiceSelectorOpened: false,
                }),
                updateCaregiver
              )}
              unwantedServices={this.state.unwantedServices}
              stationId={this.props.stationId}
            />
          </div>
          <UnwantedServicesTable
            unwantedServices={_.orderBy(this.state.unwantedServices, ['abbreviation'])}
            onRemoveUnwantedService={unwantedServiceId => this.setState(
              prevState => ({ unwantedServices:
                prevState.unwantedServices.filter(s => s.id !== unwantedServiceId) }),
              updateCaregiver
            )}
          />
          {this.state.error &&
            <ErrorDialog
              error={this.state.error}
              isOpened={!!this.state.error}
              onClose={() => this.setState({ error: null })}
            />
          }
        </div>
      }
    </Mutation>
  )

  componentDidUpdate = prevProps => {
    if (
      prevProps.caregiverUser.caregiver.unwantedServices !==
      this.props.caregiverUser.caregiver.unwantedServices
    ) {
      this.setState({ unwantedServices: this.props.caregiverUser.caregiver.unwantedServices })
    }
  }
}

export default UnwantedServices
