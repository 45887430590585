import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import { Modal } from 'semantic-ui-react'

import Header from './Header'
import ClosingSection from './ClosingSection'
import CommentingSection from './CommentingSection'
import Subtask from '../Subtask'
import { createMessage, updateMessage } from '../../../actions/TwilioActions'

import './index.css'

class ShowTaskModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isUserDropdownOpen: false,
      isDatepickerOpen: false,
    }
  }

  render(){
    const message = this.props.messageIdToMessage[this.props.currentMessageId]
    return (
      <Modal
        className="ShowTaskModal"
        open={this.props.isOpened}
        onClose={this.props.onCloseDialog}
        onOpen={message && this.onOpenModal(message)}
        size='small'
      >
        {message && this.renderModal(message)}
      </Modal>
    )
  }

  renderModal = message => {
    return (
      <React.Fragment>
        <Modal.Header>
          <Header
            loggedInUserId={this.props.loggedInUserId}
            clientIdToClient={this.props.clientIdToClient}
            userIdToUser={this.props.userIdToUser}
            message={message}
            updateMessage={this.props.updateMessage}
          />
        </Modal.Header>
        <Modal.Content>
          <div className="Subtasks">
            {
              _.values(message.submessageIdToSubmessage).length > 0 &&
              _.values(message.submessageIdToSubmessage).map(submessage =>
                <Subtask
                  clientIdToClient={this.props.clientIdToClient}
                  userIdToUser={this.props.userIdToUser}
                  message={submessage}
                  isParentTaskClosed={message.task && message.task.status === 'closed'}
                  key={submessage.id}
                  updateMessage={this.props.updateMessage}
                />
              )
            }
          </div>
        </Modal.Content>
        <Modal.Actions>
          {(message.task && message.task.status === 'closed' &&
            _.values(message.submessageIdToSubmessage).length > 0)
            ? <ClosingSection
              userIdToUser={this.props.userIdToUser}
              message={message}
              submessage={_.last(_.values(message.submessageIdToSubmessage))}
              updateMessage={this.props.updateMessage}
            />
            : <CommentingSection
              userIdToUser={this.props.userIdToUser}
              message={message}
              loggedInUserId={this.props.loggedInUserId}
              isFetching={this.props.isWaitingForCreateOrUpdateMessageResponse}
              createMessage={this.props.createMessage}
              updateMessage={this.props.updateMessage}
            />
          }
        </Modal.Actions>
      </React.Fragment>
    )
  }

  onOpenModal = message => {
    if (!message.task && this.props.isWaitingForCreateOrUpdateMessageResponse) return

    if (
      message.task && message.task.assigneeUser &&
      (message.task.assigneeUser.id === this.props.loggedInUserId) &&
      (['new', 'opened'].includes(message.task.status))
    ) {
      this.props.updateMessage({
        id: message.id,
        taskStatus: 'read',
      })
    }
  }
}

const mapStateToProps = globalState => ({
  clientIdToClient: globalState.chat.clientIdToClient,
  userIdToUser: globalState.chat.userIdToUser,
  messageIdToMessage: globalState.chat.messageIdToMessage,
  currentMessageId: globalState.chat.currentMessageId,
  isWaitingForCreateOrUpdateMessageResponse: globalState.chat.isWaitingForCreateOrUpdateMessageResponse,
  loggedInUserId: globalState.auth.loggedInUser.data['http://api.talea.de/uuid'],
})

const mapDispatchToProps = dispatch => ({
  createMessage: data => dispatch(createMessage(data)),
  updateMessage: data => dispatch(updateMessage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowTaskModal)
