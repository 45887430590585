import React, { useEffect, useState } from 'react'

import AccessTime from '@material-ui/icons/AccessTime'
import AddIcon from '@material-ui/icons/Add'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
  Checkbox, Divider, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Typography
} from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks'

import PlannedTourEditDialog from '../tour-planner2/PlannedTourEditDialog'
import TimeEditor from '../TimeEditor'
import { parseISO } from 'date-fns'
import { useModal, usePopup } from '../../hooks'

import styles from './PlannedTourHeader.module.css'

const PlannedTourHeader = ({
  color, isCheckable, isChecked, onChange, onCheck, plannedTour: propPlannedTour
}) => {
  const [plannedTour, setPlannedTour] = useState(propPlannedTour)
  useEffect(() => setPlannedTour(propPlannedTour), [propPlannedTour])
  const [isMenuOpen, showMenu, closeMenu, menuAnchorElement] = usePopup()
  const [isShowingEditDialog, toggleEditDialog] = useModal()
  const [updateStartDatetime, { loading: isUpdatingStartDatetime }] =
    useMutation(UPDATE_PLANNED_TOUR_DATETIMES, { onCompleted: () => {/* TODO: move onCompleted() here */} })
  const [insertBreak, { loading: isInsertingBreak }] =
    useMutation(
      CREATE_PLANNED_BREAK,
      {
        onCompleted: () => { closeMenu(); onChange() },
        onError: e => alert(e.message), // TODO: Proper error handling
        variables: {
          duration: 30 * 60, plannedTourId: plannedTour.id, startDatetime: plannedTour.startDatetime
        },
      }
    )
  const [insertGenericActivity, { loading: isInsertingGenericActivity }] =
    useMutation(
      CREATE_PLANNED_GENERIC_ACTIVITY,
      {
        onCompleted: () => { closeMenu(); onChange() },
        onError: e => alert(e.message), // TODO: Proper error handling
        variables: {
          duration: 10 * 60,
          name: "Aktivität",
          plannedTourId: plannedTour.id,
          startDatetime: plannedTour.startDatetime,
        },
      }
    )
  const isMenuLoading = isInsertingBreak || isInsertingGenericActivity
  return <div className={styles.PlannedTourHeader}>
    <span className={styles.Name}>{plannedTour.name}</span>
    <div className={styles.Time}>
      {plannedTour.startDatetime && <TimeEditor
        className={styles.TimeEditor}
        date={new Date(plannedTour.startDatetime)}
        isInputOnly={true}
        loading={isUpdatingStartDatetime}
        onChange={startDate =>
          updateStartDatetime({ variables: { id: plannedTour.id, startDatetime: startDate.toISOString() } })
            .then(() => setPlannedTour({ ...plannedTour, startDatetime: startDate }))
            .then(onChange)
            .catch(e => alert(e.message) /* TODO: Better error handling */)
        }
        shouldAutoFocus
        text={[plannedTour.startDatetime, plannedTour.estimatedEndDatetime].map(formatTime).join(' - ')}
      />}
    </div>
    <span className={styles.DrivingTime}>
      <DirectionsCarIcon /> {formatDuration(plannedTour.estimatedDrivingDuration)}
    </span>
    <span className={styles.Duration}>
      <AccessTime /> {formatDuration(plannedTour.estimatedDuration)}
    </span>
    <span className={styles.Caregiver}>
      {plannedTour.shift && plannedTour.shift.shiftKind && (plannedTour.shift.shiftKind.abbreviation + " – ")}
      {plannedTour.shift && plannedTour.shift.user &&
        (user => `${user.firstName} ${user.lastName}`)(plannedTour.shift.user)
      }
    </span>
    {plannedTour.shift && plannedTour.shift.caregiver &&
      (([className, label]) =>
        <span className={[styles.CaregiverQualification, className].join(' ')}>{label}</span>
      )(
        {
          'QualificationGroup.CARE_COMPANION': [styles.CareCompanion, 'PH'],
          'QualificationGroup.QUALIFIED_CAREGIVER': [styles.QualifiedCaregiver, 'PFK'],
        }[plannedTour.shift.caregiver.qualificationGroup]
      )
    }
    <div className={styles.MenuButtons}>
      {isCheckable && <Checkbox
        checked={isChecked} className={styles.Checkbox} onChange={e => onCheck && onCheck(e.target.checked)}
      />}
      <IconButton className={styles.MenuButton} onClick={showMenu} size="small"><MoreVertIcon /></IconButton>
    </div>
    <Menu anchorEl={menuAnchorElement} keepMounted onClose={closeMenu} open={isMenuOpen}>
      <MenuItem
        className={isMenuLoading ? "Mui-disabled" : ""} onClick={() => {closeMenu(); toggleEditDialog()}}
      >
        <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
        Bearbeiten
      </MenuItem>
      <Divider />
      {/*TODO: Use styling instead of `&nbsp;` and therefor make new `MenuSectionHeader` component*/}
      <Typography variant="overline">&nbsp;&nbsp;Aktivitäten</Typography>
      <MenuItem className={isMenuLoading ? "Mui-disabled" : ""} onClick={insertBreak}>
        <ListItemIcon><AddIcon fontSize="small" /></ListItemIcon>Pause einfügen
      </MenuItem>
      <MenuItem className={isMenuLoading ? "Mui-disabled" : ""} onClick={insertGenericActivity}>
        <ListItemIcon><AddIcon fontSize="small" /></ListItemIcon>Generische Aktivität einfügen
      </MenuItem>
      {isMenuLoading && <LinearProgress />}
    </Menu>
    {isShowingEditDialog && <PlannedTourEditDialog
      date={parseISO(plannedTour.startDatetime)}
      onClose={toggleEditDialog}
      onEdit={onChange}
      plannedTourId={plannedTour.id}
    />}
  </div>
}

const UPDATE_PLANNED_TOUR_DATETIMES = gql`
  mutation updatePlannedTourDatetimes($id: String!, $startDatetime: DateTime!) {
    createOrUpdatePlannedTour(id: $id, startDatetime: $startDatetime) {
      plannedTour { id }
    }
  }
`

const CREATE_PLANNED_BREAK = gql`
  mutation createPlannedBreak($duration: Int!, $plannedTourId: String!, $startDatetime: NullableDateTime!) {
    createOrUpdatePlannedBreak(
      duration: $duration, plannedTourId: $plannedTourId, startDatetime: $startDatetime
    ) { plannedBreak { id } }
  }
`

const CREATE_PLANNED_GENERIC_ACTIVITY = gql`
  mutation createPlannedGenericActivity(
    $duration: Int!, $name: String!, $plannedTourId: String!, $startDatetime: NullableDateTime!
  ) {
    createOrUpdatePlannedGenericActivity(
      duration: $duration, name: $name, plannedTourId: $plannedTourId, startDatetime: $startDatetime
    ) { plannedGenericActivity { id } }
  }
`

const formatTime = date => date ? dayjs(date).format('HH:mm') : "k.A."

const formatDuration = duration => ((duration === null) || isNaN(duration))
  ? "k.A."
  : moment.duration(duration, 'seconds').format("hh:mm", { trim: false })

export default PlannedTourHeader
