import React from 'react'

import dayjs from 'dayjs'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Mutation, Query } from 'react-apollo'
import { Button, Loader } from 'semantic-ui-react'

import { GET_USERS_AND_SHIFTS } from '../../../constants/queries'
import { CREATE_USER } from '../../../constants/mutations'
import { editingDisabledStationIds } from '../../../constants'
import { orderedCaregivers } from '../../../utils/helpers'
import Caregiver from './Caregiver'
import Placeholder from '../Placeholder'

import './index.css'

class Caregivers extends React.Component {
  state = {
    createdUserId: null,
    planningStartDate: dayjs().add(1, 'month').startOf('month').endOf('day'),
  }

  render = () => (
    <Query
      query={GET_USERS_AND_SHIFTS}
      variables={{ isActive: true, stationId: this.props.match.params.stationId }}
    >
      {({ data, loading }) => (
        <div className="Caregivers">
          <Loader active={loading} />
          <div className="Headers">
            {headers.map((header, index) => <div key={index}>{header}</div>)}
          </div>
          <div className="CaregiversList">
            {(data && data.users && data.users.filter(user => user.isActive && user.caregiver).length > 0)
              ? [
                ...orderedCaregivers(data.users.filter(user => user.lastName)),
                ...data.users.filter(user => user.isActive && user.caregiver && !user.lastName),
              ].map((user, index, users) =>
                <Caregiver
                  isCreated={this.state.createdUserId === user.id}
                  isEditable={!editingDisabledStationIds.includes(this.props.match.params.stationId)}
                  classNames={this.classNames(users, user)}
                  key={user.id}
                  shiftKindIdToName={data.shiftKinds.reduce((shiftKindIdToName, shiftKind) => ({
                    ...shiftKindIdToName,
                    [shiftKind.id]: shiftKind.name
                  }), {})}
                  shiftKinds={_.orderBy(data.shiftKinds, ['name'])}
                  user={user}
                />
              )
              : !loading && <Placeholder
                header={'Keine Mitarbeiter vorhanden'}
                subheader={'Bitte fügen Sie einen Mitarbeiter hinzu'}
              />
            }
            {data && data.shiftKinds && this.renderCreateCaregiverButton(
              _.orderBy(data.shiftKinds, ['name'])
            )}
          </div>
          <Link to={`/shift-planning/${this.props.match.params.stationId}/planning`}>
            <Button primary>Weiter</Button>
          </Link>
        </div>
      )}
    </Query>
  )

  classNames = (users, user) => ([
    'Caregiver',
    ((user === _.findLast(
      users,
      user => user.caregiver.qualificationGroup === 'QualificationGroup.QUALIFIED_CAREGIVER' && user.caregiver.canBeAssignedShifts
    )) || (user ===
    _.findLast(
      users,
      user => user.caregiver.canBeAssignedShifts
    ))) && 'LastQualifiedCaregiver',
    !(editingDisabledStationIds.includes(this.props.match.params.stationId)) && 'isEditable',
  ].filter(Boolean).join(' '))

  renderCreateCaregiverButton = shiftKinds => (
    <Mutation
      mutation={CREATE_USER}
      variables={{
        caregiver: {
          canBeAssignedShifts: true,
          canHaveSingleFreeDays: true,
          canSplitWeekends: true,
          maximumConsecutiveWorkdays: 7,
          maximumConsecutiveWorkingWeekends: 4,
          qualificationGroup: 'QUALIFIED_CAREGIVER',
          shiftsPerWeek: 7,
          shiftConditions: shiftKinds
            .filter(s => s.qualificationGroup === 'QualificationGroup.QUALIFIED_CAREGIVER')
            .map(s => ({ conditionKind: 'POSSIBLE_SHIFT', shiftKindId: s.id })),
        },
        firstName: '',
        isActive: true,
        isQualifiedCaregiver: true,
        lastName: '',
        stationIds: [this.props.match.params.stationId],
      }}
      refetchQueries={['getUsersAndShifts']}
      onCompleted={data => this.setState({ createdUserId: data.createUser.user.id })}
    >
      {createUser =>
        <Button
          disabled={editingDisabledStationIds.includes(this.props.match.params.stationId)}
          onClick={createUser}
          primary
        >
          Mitarbeiter hinzufügen
        </Button>
      }
    </Mutation>
  )
}

const headers = [
  '',
  'Nachname',
  'Vorname',
  'Qualifikation',
  'Max Arbeitstage am Stück',
  'Max Arbeits-WE am Stück',
  'Dienste/ Woche',
  'Einzelne freie Tage erlaubt',
  'Geteilte Wochenenden erlaubt',
  'Mögliche Dienste',
  'Bevorzugte Dienste',
]

export default Caregivers
