import React from 'react'
import { connect } from 'react-redux'
import { Divider } from 'semantic-ui-react'

import PropTypes from 'prop-types'

import TaskDescription from '../TaskDescription'
import TaskMetadata from '../TaskMetadata'
import TaskAvatar from '../TaskAvatar'
import { setCurrentMessageId } from '../../../actions/TwilioActions'

import './Message.css'

class Message extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className={`Message ${this.props.message.tags.join(' ')} ${this.props.message.task &&
            this.props.message.task.status}`
          }
          onClick={() => {this.props.setCurrentMessageId(this.props.message.id); this.props.onClickMessage()}}
        >
          <TaskAvatar tags={this.props.message.tags && this.props.message.tags} />
          <TaskDescription
            message={this.props.message}
            client={this.props.clientIdToClient && this.props.clientIdToClient[this.props.message.clientId]}
            user={this.props.userIdToUser && this.props.userIdToUser[this.props.message.userId]}
          />
          <TaskMetadata
            message={this.props.message}
            userIdToUser={this.props.userIdToUser}
          />
        </div>
        <Divider />
      </React.Fragment>
    )
  }
}

Message.propTypes = {
  message: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    stationId: PropTypes.number.isRequired,
    submessageIdToSubmessage: PropTypes.object,
    tags: PropTypes.array.isRequired,
    task: PropTypes.object,
    text: PropTypes.string,
    twilioSid: PropTypes.string,
  }),
  clientIdToClient: PropTypes.object.isRequired,
  userIdToUser: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  setCurrentMessageId: id => dispatch(setCurrentMessageId(id))
})

export default connect(null, mapDispatchToProps)(Message)
