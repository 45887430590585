import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import _ from 'lodash'

import { messageTagLabel } from '../../constants'
import Tasks from '../../components/taskmanager/Tasks'
import Feed from '../../components/taskmanager/Feed'
import ShowTaskModal from '../../components/taskmanager/ShowTaskModal'
import PushNotification from '../../components/PushNotification'

import { createMessage, getMessages, initTaskManagement, updateMessage, } from '../../actions/TwilioActions'
import { filterMessagesByCategory, getTaskedMessages, getUntaskedMessages } from '../../utils/helpers'

import './index.css'


class TaskManager extends React.Component {
  state = {
    categoryFilter: 'all',
    isModalOpened: false,
  }

  render = () => {
    return (
      <div className="TaskManager">
        <Route path='*' component={PushNotification} />
        <div className="Header">
          <h3>Aufgaben</h3>
          <div className="FilterButtons">
            {
              (messageCategories => Object.keys(messageCategories).map(category =>
                <button
                  key={category}
                  onClick={() => this.setState({ categoryFilter: category })}
                  className={this.state.categoryFilter === category ? 'active' : ''}
                >
                  {messageCategories[category]}
                </button>)
              )({ all: 'Alle', ...messageTagLabel })
            }
          </div>
        </div>
        <div className="MainArea">
          <Tasks
            taskedMessages={
              this.filteredMessages(getTaskedMessages(_.values(this.props.messageIdToMessage)))
            }
            loggedInUserId={this.props.loggedInUserId}
            clientIdToClient={this.props.clientIdToClient}
            userIdToUser={this.props.userIdToUser}
            createMessage={this.props.createMessage}
            updateMessage={this.props.updateMessage}
            onClickTask={this.handleTaskModal}
          />
        </div>
        <div className="RightSidebar">
          <Feed
            untaskedMessages={
              this.filteredMessages(getUntaskedMessages(_.values(this.props.messageIdToMessage)))
            }
            loggedInUserId={this.props.loggedInUserId}
            clientIdToClient={this.props.clientIdToClient}
            userIdToUser={this.props.userIdToUser}
            createMessage={this.props.createMessage}
            updateMessage={this.props.updateMessage}
            onClickFeedItem={this.handleTaskModal}
          />
        </div>
        {this.state.isModalOpened &&
          <ShowTaskModal
            isOpened={this.state.isModalOpened}
            onCloseDialog={this.handleTaskModal}
          />
        }
      </div>
    )
  }

  componentDidMount() {
    if (!this.props.messageIdToMessage) {
      this.props.initTaskManagement()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.twilioClientConnectionState === 'disconnecting') &&
      (this.props.twilioClientConnectionState === 'connected')
    ) {
      this.props.fetchMessages()
    }
  }

  filteredMessages = messages => (this.state.categoryFilter !== 'all')
    ? filterMessagesByCategory(messages, this.state.categoryFilter)
    : messages

  handleTaskModal = () => this.setState({ isModalOpened: !this.state.isModalOpened });

}


const mapStateToProps = globalState => ({
  clientIdToClient: globalState.chat.clientIdToClient,
  loggedInUserId: globalState.auth.loggedInUser.data['http://api.talea.de/uuid'],
  messageIdToMessage: globalState.chat.messageIdToMessage,
  pushNotification: globalState.chat.pushNotification,
  userIdToUser: globalState.chat.userIdToUser,
  twilioClientConnectionState: globalState.chat.twilioClientConnectionState,
})

const mapDispatchToProps = dispatch => ({
  initTaskManagement: () => dispatch(initTaskManagement()),
  fetchMessages: () => dispatch(getMessages()),
  createMessage: data => {
    dispatch(createMessage(data))
  },
  updateMessage: data => {
    dispatch(updateMessage(data))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskManager)
