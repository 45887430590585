import React, { useState } from 'react'

import { TextField } from '@material-ui/core'

import styles from './index.module.css'

// TODO: Show some loading indicator when `isLoading` is true
const TextEditor = ({ className, isLoading, label, onChange, size, value, ...rest }) => {
  const [inputValue, setInputValue] = useState(value)
  const finishEditing = e => onChange && onChange(e.target.value)
  return <TextField
    className={[styles[size], styles.Input, className].join(' ')}
    defaultValue={inputValue}
    label={label}
    onBlur={finishEditing}
    onChange={e => setInputValue(e.target.value)}
    onClick={e => e.preventDefault()}
    onFocus={e => e.target.select()}
    onKeyPress={e => {if (e.key === 'Enter') finishEditing(e)}}
    {...rest}
  />
}

export default TextEditor
