import React from 'react'
import PropTypes from 'prop-types'

import TextLink from './TextLink'

const ClientLink = ({ client }) =>
  <span className="ClientLink">
    {client
      ? <TextLink to={`/clients/${client.id}`} text={`${client.lastName}, ${client.firstName}`} />
      : "Unbekannt"
    }
  </span>

ClientLink.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
}

export default ClientLink
