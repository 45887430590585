import React from 'react'

import StationPicker from '../../components/StationPicker'

import styles from './index.module.css'

const ServicesHome = () => (
  <div className={styles.ServicesHome}>
    <h1>Leistungen</h1>
    <StationPicker />
  </div>
)

export default ServicesHome
