import React from 'react'
import { connect } from 'react-redux'

import Notification from 'react-web-notification'


class PushNotification extends React.Component {
  state = {
    isPushNotificationActive: false
  }

  render = () => {
    const user = (
      (userIdToUser, message) => userIdToUser && message && userIdToUser[message.userId]
    )(this.props.userIdToUser, this.props.pushNotification.message)
    return (
      <React.Fragment>
        {this.state.isPushNotificationActive && user &&
          <Notification
            ignore={this.props.pushNotification.isIgnored}
            title={`${user.firstName} ${user.lastName} ${this.props.pushNotification.title}`}
            timeout={5000}
            options={{
              body: this.props.pushNotification.message.text,
              icon: "https://talea.de/wp-content/uploads/2017/05/favicon-1_15x15.png",
            }}
            onShow={() => this.setState({ isPushNotificationActive: false })}
          />
        }
      </React.Fragment>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pushNotification !== this.props.pushNotification) {
      this.setState({ isPushNotificationActive: true })
    }
  }
}

const mapStateToProps = globalState => ({
  loggedInUserId: globalState.auth.loggedInUser.data['http://api.talea.de/uuid'],
  pushNotification: globalState.chat.pushNotification,
  userIdToUser: globalState.chat.userIdToUser,
})


export default connect(mapStateToProps, null)(PushNotification)
