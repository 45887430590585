import React from 'react'
import { Query } from 'react-apollo'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { Button, Header, Icon, Loader, Table } from 'semantic-ui-react'
import _ from 'lodash'

import { GET_MATCHING_TOUR } from '../../constants/queries'

import './Result.css'


class Result extends React.Component{
  render() {
    const queryValues = queryString.parse(this.props.location.search)
    return (
      <Query
        query={GET_MATCHING_TOUR}
        variables={
          {
            newClient: {
              city: queryValues.city,
              street: queryValues.street,
              zipCode: queryValues.zipCode
            },
            qualificationGroup: queryValues.qualificationGroup,
            pastDaysCount: queryValues.pastDaysCount ? queryValues.pastDaysCount : 10
          }
        }
      >
        {
          ({ data, loading, error }) => {
            if (loading) return <Loader active={loading} />
            if (error) return <p>ERROR</p>
            return (
              <div className="Result">
                <Link to={'/client-matching'}>
                  <Button
                    labelPosition='left'
                    icon='left chevron'
                    content='Zurück'
                  />
                </Link>
                <Header as='h2' color='teal' textAlign='center'>Passende Touren</Header>
                <Table striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Station</Table.HeaderCell>
                      <Table.HeaderCell>Tour</Table.HeaderCell>
                      <Table.HeaderCell>
                        Distanz (in KM) <Icon name='long arrow alternate down' />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Kapazität (in %)</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    { data.matchingTours && _.sortBy(data.matchingTours, ['distance']).map((tour, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{tour.station.name}</Table.Cell>
                        <Table.Cell>{tour.tourName}</Table.Cell>
                        <Table.Cell>{_.round(tour.distance, 2)}</Table.Cell>
                        <Table.Cell>{_.round(tour.capacity * 100.0, 2)}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            )
          }
        }
      </Query>
    )
  }
}

export default Result
