import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import _ from 'lodash'
import copy from 'copy-to-clipboard'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import moment from 'moment'
import AccessTime from '@material-ui/icons/AccessTime'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoIcon from '@material-ui/icons/Info'
import LocationOffIcon from '@material-ui/icons/LocationOff'
import TextsmsIcon from '@material-ui/icons/Textsms'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import {} from 'moment-duration-format'
import { Card, Collapse, IconButton, Tooltip } from '@material-ui/core'
import { Icon } from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'

import StationSelect from '../../components/StationSelect'
import TextEditor from '../TextEditor'
import { useEffectAfterRender } from '../../hooks'

import styles from './PlannedActivity.module.css'


const PlannedVisit = React.forwardRef((
  { className, isClientStationCodeVisible, onChangeDuration, plannedActivity, ...rest }, ref
) => {
  const isRejectedOrCancelled = plannedActivity.tourOrder &&
    ["TourOrderStatus.CANCELLED", "TourOrderStatus.REJECTED"].includes(plannedActivity.tourOrder.status)
  return <PlannedActivity
    className={[
      className,
      plannedActivity.isRescheduledPlannedVisitDeleted && styles.IsRescheduledPlannedVisitDeleted,
      isRejectedOrCancelled && styles.RejectedOrCancelled,
      plannedActivity.isRescheduled && styles.IsRescheduled,
      styles.Qualification,
      {
        'CaregiverQualification.QUALIFIED': styles.RequiresQualifiedCaregiver,
        'CaregiverQualification.CARE_COMPANION': _.isEqual(plannedActivity.serviceGroups, ['SGB_V'])
          ? styles.RequiresCareCompanionWithSgbVOnlyServices : styles.RequiresCareCompanion,
      }[plannedActivity.requiredCaregiverQualification],
    ].filter(Boolean).join(' ')}
    expansion={<PlannedVisitExpansion onChangeDuration={onChangeDuration} plannedVisit={plannedActivity} />}
    header={<PlannedVisitHeader
      isClientStationCodeVisible={isClientStationCodeVisible} plannedVisit={plannedActivity}
    />}
    plannedActivity={plannedActivity}
    ref={ref}
    {...rest}
  />
})

const PlannedVisitHeader = ({ isClientStationCodeVisible, plannedVisit }) =>
  <div className={styles.PlannedVisitHeader}>
    <div className={styles.Name}>
      {(
        isClientStationCodeVisible ||
        shouldPseudonymizeClient(plannedVisit) ||
        (!!plannedVisit.plannedTour && plannedVisit.plannedTour.station.isSpringerStation)
      ) && <span className={styles.Code}>{plannedVisit.client.station.code}</span>}
      {shouldPseudonymizeClient(plannedVisit)
        ? plannedVisit.client.id.substring(0, 5)
        : [plannedVisit.client.lastName, plannedVisit.client.firstName].filter(Boolean).join(", ")
      }
    </div>
    <div className={styles.PlannedVisitIcons}>{plannedVisit.client.apartmentKey && <VpnKeyIcon />}</div>
  </div>

const PlannedVisitExpansion = ({ onChangeDuration, plannedVisit }) =>
  <div>
    {plannedVisit.tourOrder &&
      <Link className={styles.TourOrderLink} to={`/tour-orders/${plannedVisit.tourOrder.id}`}>
        Bestellung {plannedVisit.tourOrder.code}
      </Link>
    }
    <div className={styles.DrivingDurationAndVisitDuration}>
      {plannedVisit.estimatedDrivingDuration > 0 &&
        <span className={styles.DrivingTime}>
          <DirectionsCarIcon /> {Math.round(plannedVisit.estimatedDrivingDuration / 60)} minutes
        </span>
      }
      <span className={styles.Duration}>
        <AccessTime /> {Math.round(plannedVisit.duration / 60)} minutes
      </span>
      {plannedVisit.hint &&
        <Tooltip title={plannedVisit.hint} placement="right">
          <TextsmsIcon className={styles.HintIcon} />
        </Tooltip>
      }
    </div>
    <div className={styles.HistoricalTimes}>
      <span>Historische Zeit:</span>
      <span>{
        (
          plannedVisit.historicallyEarliestStartDatetime &&
          plannedVisit.historicallyLatestEndDatetime
        )
          ? `
            ${dayjs(plannedVisit.historicallyEarliestStartDatetime).format('HH:mm')} -
            ${dayjs(plannedVisit.historicallyLatestEndDatetime).format('HH:mm')}
          `
          : 'k.A.'
      }</span>
    </div>
    <div className={styles.PlannedTimes}>
      <span>Urspr. gepl. Zeit:</span>
      <span>{
        (plannedVisit.startDatetime && plannedVisit.endDatetime)
          ? `
            ${dayjs(plannedVisit.startDatetime).format('HH:mm')} -
            ${dayjs(plannedVisit.endDatetime).format('HH:mm')}
          `
          : 'k.A.'
      }</span>
    </div>
    <div>
      {
        plannedVisit.client.streetHouseNumber ||
        `${plannedVisit.client.street || ''} ${plannedVisit.client.houseNumber || ''}`
      }
    </div>
    <div>
      {
        plannedVisit.client.zipCodeCity ||
        `${plannedVisit.client.zipCode || ''} ${plannedVisit.client.city || ''}`
      }
    </div>
    <div>{plannedVisit.client.healthInsuranceName}</div>
    {plannedVisit.services.length > 0 &&
      <ul>
        <p className={styles.ServicesTitle}>
          Leistungen {plannedVisit.shiftTime && `- ${plannedVisit.shiftTime.name}`}
        </p>
        {plannedVisit.services.map(service => (
          <li key={service.id} className={styles.Service}>
            <span className={styles.ServiceAbbreviation}>{service.abbreviation} - </span> {service.name}
          </li>
        ))}
      </ul>
    }
    <div className={styles.AdditionalDrivingDuration}>
      Extra Fahrzeit:
      <AdditionalDrivingTimeEditor onChange={onChangeDuration} plannedVisit={plannedVisit} />
      min
    </div>
  </div>

const PlannedBreak = React.forwardRef(({ onChangeDuration, plannedActivity, ...rest }, ref) => {
  return <PlannedActivity
    header={<div className={styles.PlannedBreakHeader}>
      Pause <DurationEditor onChange={onChangeDuration} plannedActivity={plannedActivity} /> min
    </div>}
    plannedActivity={plannedActivity}
    ref={ref}
    {...rest}
  />
})

const PlannedGenericActivity = React.forwardRef(
  ({ onChangeDuration, plannedActivity, ...rest }, ref) => {
    const [name, setName] = useState(plannedActivity.name)
    const [locationStationId, setLocationStationId] = useState(null)
    const [update, { isUpdating }] = useMutation(
      UPDATE_PLANNED_GENERIC_ACTIVITY,
      {
        onCompleted: onChangeDuration, // TODO: Don't call back when only name changed
        onError: e => alert(e.message) /* TODO: Better error handling */,
        variables: { id: plannedActivity.id, locationStationId, name },
      }
    )
    useEffectAfterRender(update, [name, locationStationId])
    return <PlannedActivity
      expansion={
        <div className={styles.PlannedGenericActivityExpansion}>
          <StationSelect label="Station auswählen" onChange={setLocationStationId} />
          <div className={styles.PlannedGenericActivityDuration}>
            Dauer:
            <DurationEditor onChange={onChangeDuration} plannedActivity={plannedActivity} />
            min
          </div>
        </div>
      }
      header={
        <TextEditor
          isLoading={isUpdating}
          onChange={setName}
          size="small"
          value={plannedActivity.name}
        />
      }
      plannedActivity={plannedActivity}
      ref={ref}
      {...rest}
    />
  }
)

const PlannedActivity = React.forwardRef((
  {
    className,
    expansion,
    header,
    index,
    isDraggingEnabled,
    isExpandable = true,
    isExpanded,
    isIndexVisible,
    isSelected,
    onToggleExpansion,
    onToggleSelection,
    plannedActivity,
    selectionCount,
    ...rest
  },
  ref
) => <Card
  className={[className, styles.PlannedActivity, isSelected && styles.IsSelected].filter(Boolean).join(' ')}
  onClick={event => {
    if (event.defaultPrevented) { return } // Ignore clicks which initiate a drag&drop operation
    event.preventDefault()
    onToggleSelection && onToggleSelection(plannedActivity.id, isToggleInSelectionGroupKeyUsed(event))
  }}
  ref={ref}
  {...rest}
>
  <Header index={isIndexVisible && index} plannedActivity={plannedActivity}>{header}</Header>
  {!!expansion && <IconButton
    className={[styles.ExpandButton, isExpanded && styles.IsExpanded].join(' ')}
    onClick={e => {e.preventDefault(); onToggleExpansion && onToggleExpansion(!isExpanded)}}
    size="small"
  >
    {isExpandable && <ExpandMoreIcon />}
  </IconButton>}
  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
    {expansion}
    <Tooltip title="Klicken um die ID zu kopieren" interactive>
      <IconButton
        className={styles.IdCopy} onClick={e => {e.preventDefault(); copy(plannedActivity.id)}} size="small"
      >
        <InfoIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  </Collapse>
  {((selectionCount || 0) > 1) && <div className={styles.SelectionCount}>{selectionCount}</div>}
</Card>)

const Header = ({ children, isClientStationCodeVisible, index, plannedActivity }) => (
  <div className={styles.Header}>
    {children}
    <div className={[styles.Time, !plannedActivity.lat && styles.TimeDisabled].filter(Boolean).join(' ')}>
      <span>{formatTime(plannedActivity.estimatedStartDatetime || plannedActivity.startDatetime)}</span>
      <span>{formatTime(plannedActivity.estimatedEndDatetime || plannedActivity.endDatetime)}</span>
      {plannedActivity.estimatedVisitOffset &&
        <span className={styles.StartOffset}>
          <Duration isSigned seconds={plannedActivity.estimatedVisitOffset} />
        </span>
      }
      {(plannedActivity.additionalDrivingDuration !== 0) &&
        <Icon className={styles.AdditionalDrivingTimeIndicator} name='circle' size='small' />
      }
      {!plannedActivity.lat &&
        <div className={styles.MissingLocationWarning}><LocationOffIcon /></div>
      }
    </div>
    {![undefined, null].includes(index) && <div className={styles.Index}>{index + 1}</div>}
  </div>
)

const AdditionalDrivingTimeEditor = ({ onChange, plannedVisit }) => {
  const [value, setValue] = useState(Math.round((plannedVisit.additionalDrivingDuration || 0) / 60))
  const [update, { loading }] = useMutation(
    UPDATE_ADDITIONAL_DRIVING_DURATION,
    {
      onCompleted: onChange,
      onError: e => alert(e.message) /* TODO: Better error handling */,
      variables: { additionalDrivingDuration: value * 60, id: plannedVisit.id },
    }
  )
  useEffectAfterRender(update, [value])
  return <TextEditor
    className={styles.AdditionalDrivingDurationEditor}
    disabled={!onChange}
    isLoading={loading}
    onChange={value => {
      const intValue = value.trim().length === 0 ? 0 : parseInt(value)
      if (!isNaN(intValue)) setValue(intValue)
    }}
    size='small'
    value={value.toString()}
  />
}

const DurationEditor = ({ onChange, plannedActivity, ...rest }) => {
  const [value, setValue] = useState(Math.round((plannedActivity.duration || 0) / 60))
  const [update, { loading }] = useMutation(
    {
      'PlannedBreakType': UPDATE_PLANNED_BREAK,
      'PlannedGenericActivityType': UPDATE_PLANNED_GENERIC_ACTIVITY
    }[plannedActivity.__typename],
    {
      onCompleted: onChange,
      onError: e => alert(e.message) /* TODO: Better error handling */,
      variables: { duration: value * 60, id: plannedActivity.id },
    }
  )
  useEffectAfterRender(update, [value])
  return <TextEditor
    className={styles.DurationEditor}
    isLoading={loading}
    onChange={value => {
      const intValue = value.trim().length === 0 ? 0 : parseInt(value)
      if (!isNaN(intValue)) setValue(intValue)
    }}
    size='small'
    value={value.toString()}
    {...rest}
  />
}

const formatTime = datetime => datetime ? dayjs(datetime).format('HH:mm') : 'k.A.'

// Determines if the platform specific toggle selection in group key was used
const isToggleInSelectionGroupKeyUsed = event => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0
  return isUsingWindows ? event.ctrlKey : event.metaKey
}

const Duration = ({ isSigned, seconds, units }) =>
  ((isSigned && (seconds > 0) && '+') || '') +
    moment.duration(seconds, 'seconds').format('hh:mm', { trim: false })

// TODO: Remove this client side pseudonomization, assign proper roles to users to avoid seeing clear names
const shouldPseudonymizeClient = plannedVisit =>
  !['1', '2', '3', '4', '5'].includes(plannedVisit.client.station.id)

const UPDATE_ADDITIONAL_DRIVING_DURATION = gql`
  mutation updatePlannedVisitDrivingDuration($id: String!, $additionalDrivingDuration: Int!) {
    createOrUpdatePlannedVisit(id: $id, additionalDrivingDuration: $additionalDrivingDuration) {
      plannedVisit { id }
    }
  }
`

const UPDATE_PLANNED_BREAK = gql`
  mutation updatePlannedBreak($id: String!, $duration: Int) {
    createOrUpdatePlannedBreak(id: $id, duration: $duration) { plannedBreak { id } }
  }
`

const UPDATE_PLANNED_GENERIC_ACTIVITY = gql`
  mutation updatePlannedGenericActivity(
    $id: String!, $duration: Int, $locationStationId: Int, $name: String
  ) {
    createOrUpdatePlannedGenericActivity(
      id: $id, duration: $duration, locationStationId: $locationStationId, name: $name
    ) { plannedGenericActivity { id } }
  }
`

export { PlannedBreak, PlannedGenericActivity, PlannedVisit }
