import { useMemo, useState } from 'react'

import _ from 'lodash'

export const useLocalStorage = (key, initialValue, options) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })
  const storeValue = value => {
    try {
      const newValue = value instanceof Function ? value(value) : value
      if (newValue !== storedValue) {
        setStoredValue(newValue)
        window.localStorage.setItem(key, JSON.stringify(newValue))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const shouldDebounce = (options || {}).shouldDebounce
  const debounceInterval = (options || {}).debounceInterval || 750
  const debouncedStoreValue = useMemo(
    () => _.debounce(storeValue, debounceInterval), [shouldDebounce, debounceInterval]
  )
  return [storedValue, value => (shouldDebounce ? debouncedStoreValue : storeValue)(value)]
}

export default useLocalStorage
