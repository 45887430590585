import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import HealthInsuranceGroup from './HealthInsuranceGroup'
import ApolloErrorButton from '../ApolloErrorButton'
import { useEffectAfterRender, useMergeQuery } from '../../hooks'

const ClientHealthInsuranceGroup = ({ clientId }) => {
  // TODO: Fetch client health insurance group id via merge query
  const { loading: isFetching, error, data, refetchClient } = useMergeQuery(
    CLIENT_QUERY,
    {
      fetchPolicy: 'network-only',
      mergeVariable: 'ids',
      unmerge: (data, { ids }) => ({ clients: data.clients.filter(c => ids.includes(c.id)) }),
      variables: { ids: [clientId] },
    }
  )
  useEffectAfterRender(refetchClient, [clientId])
  const client = data && data.clients && (data.clients.length === 1) && data.clients[0]
  const fetchedId = client && client.healthInsuranceGroup && client.healthInsuranceGroup.id
  const [selectedId, setSelectedId] = useState(null)
  useEffect(() => setSelectedId(fetchedId), [fetchedId])
  const [updateClient, { loading: isUpdating }] = useMutation(
    UPDATE_CLIENT, { onError: () => setSelectedId(fetchedId) /* Reset selected id on error */}
  )
  if (!!error) return <ApolloErrorButton error={error} />
  return <HealthInsuranceGroup
    isLoading={isFetching || isUpdating}
    onSelect={id => {
      if (!id) return // API can't yet unassign the health insurance group from client
      setSelectedId(id)
      updateClient({ variables: { id: clientId, healthInsuranceGroupId: id } })
    }}
    selection={selectedId}
  />
}

const CLIENT_QUERY = gql`
  query clients($ids: [String]!) { clients(ids: $ids) { healthInsuranceGroup { id } id } }
`

const UPDATE_CLIENT = gql`
  mutation updateClient($id: String!, $healthInsuranceGroupId: String!) {
    updateClient(healthInsuranceGroupId: $healthInsuranceGroupId, id: $id) {
      client { id healthInsuranceGroup { id } }
    }
  }
`

export default ClientHealthInsuranceGroup
