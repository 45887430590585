import * as types from '../constants/'

export const fetchAuth0Request = () => ({
  type: types.FETCH_AUTH0_LOGIN_REQUEST,
})

export const requestAuth0Success = payload => ({
  type: types.REQUEST_AUTH0_LOGIN_SUCCESS,
  payload,
})

export const alreadyAuthenticated = payload => ({
  type: types.AUTH_SUCCESS,
  payload,
})

export const AuthError = payload => ({
  type: types.AUTH_FAILURE,
  payload,
})
