import React from 'react'

import { Header, Icon } from 'semantic-ui-react'

import './Placeholder.css'

const Placeholder = ({ header, subheader }) => (
  <Header className="Placeholder" icon>
    <Icon name='file outline' />
    {header}
    <Header.Subheader>{subheader}</Header.Subheader>
  </Header>
)

export default Placeholder
