export const menuItems = [
  {
    path: null,
    label: 'Stammdaten',
    children: [
      {
        path: 'clients',
        label: 'Klienten',
        perform: 'visit:clients'
      },
      {
        path: 'clients-map',
        label: 'Klientenkarte',
        perform: 'visit:clientsMap'
      },
      {
        path: 'caregivers',
        label: 'Pflegekräfte',
        perform: 'visit:caregivers'
      },
      {
        path: 'services',
        label: 'Leistungen',
        perform: 'visit:services'
      },
      {
        path: 'services-legacy',
        label: 'Leistungen (alt)',
        perform: 'visit:services'
      },
      {
        path: 'stations',
        label: 'Stationen',
        perform: 'visit:stations',
      },
    ],
  },
  {
    path: 'tasks',
    label: 'Aufgaben',
    perform: 'visit:tasks'
  },
  {
    path: null,
    label: 'Planung',
    children: [
      {
        path: 'shift-planner2/6',
        label: 'Dienstplanung',
        perform: 'visit:shiftPlanner'
      },
      {
        path: 'tour-planner2',
        label: 'Tourenplanung',
        perform: 'visit:tourPlanning'
      },
      {
        path: 'shift-planning',
        label: 'Automatische Dienstplanung',
        perform: 'visit:shiftPlanning'
      },
      {
        path: 'live-visits',
        label: 'Live-Einsätze',
        perform: 'visit:liveVisits'
      },
      {
        path: 'reco-engine',
        label: 'Ausfall-Assistent',
        perform: 'visit:recoEngine',
      },
      {
        path: 'client-matching',
        label: 'Matching-Assistent',
        perform: 'visit:clientMatching'
      },
    ],
  },
  {
    path: null,
    label: 'Kooperation',
    children: [
      {
        path: 'tour-orders',
        label: 'Kooperations-Assistent',
        perform: 'visit:careShop'
      }
    ],
  },
]

