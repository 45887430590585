import React from 'react'

import dayjs from 'dayjs'
import { Button, Icon } from 'semantic-ui-react'

import './index.css'

const MonthSelector = props => (
  <div className="MonthSelector">
    <Button icon labelPosition='left' onClick={() => props.onClickPreviousMonth()}>
      <Icon name='left arrow' />
      Vormonat
    </Button>
    <div className="SelectedMonth">{dayjs(props.monthDate).format('MMMM YYYY')}</div>
    <Button icon labelPosition='right' onClick={() => props.onClickNextMonth()}>
      Nächster Monat
      <Icon name='right arrow' />
    </Button>
  </div>
)

export default MonthSelector
