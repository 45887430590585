import React from 'react'

import StationPicker from '../../components/StationPicker'

import styles from './index.module.css'

const CaregiversHome = () => (
  <div className={styles.CaregiversHome}>
    <h1>Pflegekräfte</h1>
    <StationPicker />
  </div>
)

export default CaregiversHome
