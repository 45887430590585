import React, { useState } from 'react'

import dayjs from 'dayjs'
import { Label } from 'semantic-ui-react'

import TimePicker from '../TimePicker'

import styles from './index.module.css'

const TimeEditor = ({ className, date, isInputOnly = true, isLoading, onChange, size, text }) => {
  const [isEditing, setIsEditing] = useState(false)
  return <div className={[styles[size], className].join(' ')}>
    {isEditing
      ? <TimePicker
        date={date}
        isInputOnly={isInputOnly}
        loading={isLoading}
        onBlur={() => setIsEditing(false)}
        onChange={e => {setIsEditing(false); onChange(e)}}
        onFocus={e => e.target.select()}
        shouldAutoFocus
        size={size}
      />
      : <Label as='a' className={styles[size]} onClick={() => setIsEditing(true)}>
        {text || dayjs(date).format('HH:mm')}
      </Label>
    }
  </div>
}

export default TimeEditor
