import React, { useState } from 'react'

import _ from 'lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Divider,
  Menu,
  Slider,
  Typography,
} from '@material-ui/core'
import { ArrowDropDown, Cancel, ExpandMore } from '@material-ui/icons'
import { addMinutes, endOfDay, format, getHours, getMinutes, startOfDay } from 'date-fns'
import { KeyboardDatePicker } from '@material-ui/pickers'

import EventPropagationStopper from '../../components/EventPropagationStopper'
import SelectButton from '../../components/SelectButton'
import StationSelect from '../../components/StationSelect'
import { usePopup } from '../../hooks'

import styles from './Toolbar.module.css'

const Toolbar = ({
  className,
  date,
  isUnscheduledOrderedOrReschedulingOrdered,
  onChangeDate,
  onChangeIsUnscheduledOrderedOrReschedulingOrdered,
  onChangeStationId,
  onChangeStationIds,
  onChangeUnscheduledStationIds,
  onChangeUnscheduledTimeRange,
  stationId,
  stationIds,
  unscheduledStationIds,
  unscheduledTimeRange,
}) => {
  const isUnscheduledTimeRangeModified = (
    (unscheduledTimeRange[0].getHours() * 60 + unscheduledTimeRange[0].getMinutes() > 0) ||
    (unscheduledTimeRange[1].getHours() * 60 + unscheduledTimeRange[1].getMinutes() < 24 * 60 - 1)
  )
  const isUnscheduledOrderedOrReschedulingOrderedFilterActive =
    [false, true].includes(isUnscheduledOrderedOrReschedulingOrdered)
  const isUnscheduledOrderedOrReschedulingOrderedToLabel = {
    null: "Alle Einsatzarten", true: "Bestellte Einsätze", false: "Unbestellte Einsätze"
  }
  const [idToStation, setIdToStation] = useState({})
  const onLoadStations = stations => setIdToStation(_.keyBy(stations, 'id'))
  return (
    <Accordion className={[styles.Toolbar, className].filter(Boolean).join(' ')}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div className={styles.Chips}>
          {(
            (stationIds.length === 0) &&
            (unscheduledStationIds.length === 0) &&
            !isUnscheduledTimeRangeModified &&
            !isUnscheduledOrderedOrReschedulingOrderedFilterActive
          ) && <span className={styles.NoFilterLabel}>Keine Filter aktiv</span>}
          {stationIds.filter(id => id in idToStation).map(i =>
            <Chip
              className={styles.Chip}
              deleteIcon={<Cancel />}
              key={i}
              label={idToStation[i].name}
              onDelete={() => onChangeStationIds(stationIds.filter(id => id !== i))}
            />
          )}
          {unscheduledStationIds.filter(id => id in idToStation).map(i =>
            <Chip
              className={[styles.Chip, styles.Unscheduled].join(' ')}
              deleteIcon={<Cancel />}
              key={i}
              label={idToStation[i].name}
              onDelete={() => onChangeUnscheduledStationIds(unscheduledStationIds.filter(id => id !== i))}
            />
          )}
          {isUnscheduledTimeRangeModified &&
            <Chip
              className={[styles.Chip, styles.Unscheduled].join(' ')}
              deleteIcon={<Cancel />}
              key="unscheduledTimeRange"
              label={
                `${format(unscheduledTimeRange[0], 'HH:mm')} - ${format(unscheduledTimeRange[1], 'HH:mm')}`
              }
              onDelete={() => onChangeUnscheduledTimeRange([startOfDay(new Date()), endOfDay(new Date())])}
            />
          }
          {isUnscheduledOrderedOrReschedulingOrderedFilterActive &&
            <Chip
              className={[styles.Chip, styles.Unscheduled].join(' ')}
              deleteIcon={<Cancel />}
              key="isUnscheduledOrderedOrReschedulingOrdered"
              label={
                isUnscheduledOrderedOrReschedulingOrderedToLabel[isUnscheduledOrderedOrReschedulingOrdered]
              }
              onDelete={() => onChangeIsUnscheduledOrderedOrReschedulingOrdered(null)}
            />
          }
        </div>
        <EventPropagationStopper shouldStopOnClick>
          <StationSelect
            className={styles.StationPicker}
            onChange={onChangeStationId}
            value={stationId}
            variant="input"
          />
        </EventPropagationStopper>
        <EventPropagationStopper shouldStopOnClick>
          <KeyboardDatePicker
            autoOk
            className={styles.DatePicker}
            error={isNaN(date)}
            format="dd.MM.yyyy"
            helperText={isNaN(date) ? "Ungültige Eingabe" : null}
            margin="normal"
            onChange={date => !isNaN(date) && onChangeDate(date)}
            value={date}
          />
        </EventPropagationStopper>
      </AccordionSummary>
      <AccordionDetails>
        <Divider />
        <div className={styles.Buttons}>
          <ButtonGroup label="Touren">
            <StationSelect
              label="Stationen"
              isMultiSelect={true}
              onChange={onChangeStationIds}
              onLoad={onLoadStations}
              value={stationIds}
            />
          </ButtonGroup>
          <Divider orientation="vertical" />
          <ButtonGroup label="Unverplante Einsätze">
            <StationSelect
              label="Stationen"
              isMultiSelect={true}
              onChange={onChangeUnscheduledStationIds}
              value={unscheduledStationIds}
            />
            <TimeRangeButton
              label="Startzeit"
              onChange={onChangeUnscheduledTimeRange}
              sliderClassName={styles.UnscheduledTimeRange}
              value={unscheduledTimeRange}
            />
            <SelectButton
              label="Einsatzarten"
              onChange={v => onChangeIsUnscheduledOrderedOrReschedulingOrdered(v === 'all' ? null : v)}
              options={[null, true, false].map(v => ({
                label: isUnscheduledOrderedOrReschedulingOrderedToLabel[v], value: v === null ? 'all' : v
              }))}
              value={isUnscheduledOrderedOrReschedulingOrdered === null
                ? 'all' : isUnscheduledOrderedOrReschedulingOrdered
              }
            />
          </ButtonGroup>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

const ButtonGroup = ({ children, label }) =>
  <div className={styles.ButtonGroup}>
    <Typography variant="overline">{label}</Typography>
    {children}
  </div>

const TimeRangeButton = ({ label, onChange, sliderClassName, step = 15, value }) => {
  const minutes = d => getHours(d) * 60 + getMinutes(d)
  const [isMenuOpen, showMenu, closeMenu, menuAnchorElement] = usePopup()
  return <>
    <Button onClick={showMenu}> {label} <ArrowDropDown /></Button>
    <Menu anchorEl={menuAnchorElement} onClose={closeMenu} open={isMenuOpen}>
      <Slider
        className={sliderClassName}
        defaultValue={value.map(minutes)}
        marks={[
          ...Array.from(
            { length: 24 },
            (v, i) => ({ label: i % 3 === 0 ? `${i}:00` : "", value: i * 60 })
          ),
          { label: "23:59", value: 24 * 60 - 1 },
        ]}
        max={24 * 60 - 1}
        min={0}
        onChangeCommitted={(e, values) => onChange &&
          onChange(values.map(v => addMinutes(startOfDay(new Date()), v)))
        }
        step={step}
        valueLabelDisplay="on"
        valueLabelFormat={v => `${Math.floor(v / 60)}:${String(v % 60).padStart(2, "0")}`}
      />
    </Menu>
  </>
}

export default Toolbar
