import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from "semantic-ui-react"

import styles from './index.module.css'


class Client extends React.Component {
  state = {
    isExpanded: false,
    visitInfoText: '',
  }

  render() {
    const { client, onClientEdit } = this.props
    return (
      <div
        className={[styles.Client, this.state.isExpanded ? styles.isExpanded : styles.isCollapsed].join(' ')}
      >
        <div
          className={styles.ClientDescription}
          onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
        >
          <div>{client.lastName}, {client.firstName}</div>
          <div>{client.healthInsuranceGroup && client.healthInsuranceGroup.abbreviation}</div>
          <div>{client.healthInsuranceName}</div>
          <div>{client.phoneNumber}</div>
          <div>{client.streetHouseNumber}, {client.zipCodeCity}</div>
          <div>{client.careLevel}</div>
          <div>{client.apartmentKey}</div>
          <div>{client.station.code}</div>
          <div className={styles.ActionButtons}>
            <Button circular color='blue' icon='pencil' onClick={() => onClientEdit(client)} />
            <Link to={`/clients/${client.id}/visits`}>
              <Button circular color='green' icon='calendar outline' />
            </Link>
          </div>
        </div>
        <div className={styles.ClientDetails}>
          <div><div>Diagnose</div><div>{client.diagnosis}</div></div>
          <div><div>Adresszusatz</div><div>{client.addressSuffix}</div></div>
        </div>
      </div>
    )
  }
}

export default Client
