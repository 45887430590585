import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import FloatingMenuItem from './FloatingMenuItem'
import CreateMessageModal from './CreateMessageModal'

import { getCategoryLabel } from '../../../utils/helpers'
import { createMessage } from '../../../actions'

import './index.css'

class ComposeMessage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      assigneeUserId: null,
      isImportant: false,
      isToggled: false,
      isCreateTaskModalOpened: false,
      messageBody: '',
      category: [],
      taskDueDatetime: null
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isToggled && <div className="ModalOverlay"></div>}
        {this.state.isCreateTaskModalOpened &&
        <CreateMessageModal
          taskDueDatetime={this.state.taskDueDatetime}
          assigneeUserId={this.state.assigneeUserId}
          title={getCategoryLabel(this.state.category)}
          messageBody={this.state.messageBody}
          isImportant={this.state.isImportant}
          onChangeMessageImportance={() => {this.setState({ isImportant: !this.state.isImportant })}}
          onChangeAssignedUserId={userId => this.setState({ assigneeUserId: userId })}
          onChangeMessageBody={(_, data) => this.setState({ messageBody: data.value })}
          onChangeTaskDueDatetime={date => this.setState({ taskDueDatetime: date })}
          handleSubmit={this.handleSubmit}
          userIdToUser={this.props.userIdToUser || []}
          client={this.props.client}
          isCreateTaskModalOpened={this.state.isCreateTaskModalOpened}
          onCloseCreateTaskModal={this.closeCreateTaskModal}
          isFetching={this.props.isFetching}
        />
        }
        {this.state.isToggled && (
          <FloatingMenuItem showDialog={this.openCreateTaskModal} />
        )}
        <div className="FloatingAddButton">
          <button
            className="circular ui icon button"
            onClick={() => this.setState({ isToggled: !this.state.isToggled })}
          >
            {
              this.state.isToggled
                ? <i className="icon remove"></i>
                : <i className="icon pencil alternate"></i>
            }
          </button>
        </div>
      </React.Fragment>
    )
  }

  openCreateTaskModal = category => this.setState({
    assigneeUserId: null,
    category: [category],
    isCreateTaskModalOpened: true,
    isToggled: !this.state.isToggled,
    isImportant: false,
    messageBody: '',
  });

  closeCreateTaskModal = () => this.setState({
    assigneeUserId: null,
    isCreateTaskModalOpened: false,
    messageBody: '',
    category: ''
  });

  handleSubmit = () => {
    this.props.createMessage({
      message: {
        clientId: this.props.client.id,
        taskAssigneeUserId: this.state.assigneeUserId,
        taskDueDatetime: this.state.taskDueDatetime ? moment(this.state.taskDueDatetime).format() : null,
        tags: (this.state.isImportant
          ? this.state.category.concat('important')
          : this.state.category).filter(Boolean),
        text: this.state.messageBody,
        taskStatus: (this.state.messageBody && (this.state.assigneeUserId === this.props.loggedInUserId))
          ? 'in-progress'
          : this.state.assigneeUserId ? 'opened' : 'new',
      },
      isParentMessage: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentMessageId !== this.props.currentMessageId) {
      this.setState({
        isCreateTaskModalOpened: false,
      })
    }
  }
}

const mapStateToProps = globalState => ({
  currentMessageId: globalState.chat.currentMessageId,
  isFetching: globalState.chat.isWaitingForCreateOrUpdateMessageResponse,
  loggedInUserId: globalState.auth.loggedInUser.data['http://api.talea.de/uuid'],
  messageIdToMessage: globalState.chat.messageIdToMessage,
})

const mapDispatchToProps = dispatch => ({
  createMessage: data => {
    dispatch(createMessage(data.message, data.isParentMessage))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMessage)
