import CryptoJS from 'crypto-js'

// TODO: there are probably libraries we can use to do this.
let base64map = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"

/*
https://gitlab.com/talea/fahrtwind/wikis/Twilio%20message%20encryption%20specs
# Authenticated encryption of Twilio messages

* Encryption algorithm: AES-CBC
* Signing algorithm: HMAC-SHA256

### Message format:
* Base64 of:
 * 16 bytes IV
 * x bytes encrypted message (AES-CBC-128)
 * 32 bytes SHA256 signature of (IV+encrypted message), using HMAC key

### Key format:
* Base64 of
 * 16 bytes message key
 * 32 bytes HMAC key
*/

export const decrypt = (b64_cyphertext, key_b64) =>
  new Promise((resolve, reject) => {
    let iv_and_message_bytes = base64ToBytes(b64_cyphertext)
    let iv_bytes = iv_and_message_bytes.slice(0, 16)
    let message_bytes = iv_and_message_bytes.slice(16, iv_and_message_bytes.length - 32)
    let hmac_bytes = iv_and_message_bytes.slice(iv_and_message_bytes.length - 32, iv_and_message_bytes.length)

    let message_b64 = bytesToBase64(message_bytes)
    let iv_b64 = bytesToBase64(iv_bytes)
    let hmac_b64 = bytesToBase64(hmac_bytes)

    let parsed_key = CryptoJS.enc.Base64.parse(key_b64)

    let parsed_key_hex = parsed_key.toString(CryptoJS.enc.Hex)

    let msg_key_hex = parsed_key_hex.slice(0, 32)
    let hmac_key_hex = parsed_key_hex.slice(32, parsed_key_hex.length)

    let parsed_msg_key = CryptoJS.enc.Hex.parse(msg_key_hex)
    let parsed_hmac_key = CryptoJS.enc.Hex.parse(hmac_key_hex)

    let msg_iv = CryptoJS.enc.Base64.parse(iv_b64)
    let msg_cyphertext = CryptoJS.enc.Base64.parse(message_b64)
    let msg_hmac = CryptoJS.enc.Base64.parse(hmac_b64)

    let iv_hex = bytesToHex(iv_bytes)
    let msg_hex = bytesToHex(message_bytes)

    let iv_and_message = CryptoJS.enc.Base64.parse(bytesToBase64(hexToBytes(iv_hex + msg_hex)))

    let calculated_hmac = CryptoJS.HmacSHA256(iv_and_message, parsed_hmac_key)
    let calculated_hmac_hex = calculated_hmac.toString(CryptoJS.enc.Hex)

    if (calculated_hmac_hex === msg_hmac.toString()){
      let decrypted = CryptoJS.AES.decrypt(
        msg_cyphertext.toString(CryptoJS.enc.Base64), parsed_msg_key, { iv: msg_iv }
      )
      let utf8Decrypted = CryptoJS.enc.Utf8.stringify(decrypted)
      resolve(utf8Decrypted)
    } else {
      reject(
        'calculated_hmac_hex: ' +
        calculated_hmac_hex +
        ' and msg_hmac.toString(): ' +
        msg_hmac.toString() + ' is not same'
      )
    }
  })

const bytesToBase64 = bytes => {
  let base64 = []
  for (let i = 0; i < bytes.length; i += 3) {
    let triplet = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
    for (let j = 0; j < 4; j++) {
      if (i * 8 + j * 6 <= bytes.length * 8)
        base64.push(base64map.charAt((triplet >>> 6 * (3 - j)) & 0x3F))
      else base64.push("=")
    }
  }
  return base64.join("")
}

const base64ToBytes = base64 => {
  let bytes = []
  // Remove non-base-64 characters
  base64 = base64.replace(/[^A-Z0-9+/]/ig, "")
  for (let i = 0, imod4 = 0; i < base64.length; imod4 = ++i % 4) {
    if (imod4 === 0) continue
    bytes.push(
      ((base64map.indexOf(base64.charAt(i - 1)) & (Math.pow(2, -2 * imod4 + 8) - 1)) << (imod4 * 2)) |
      (base64map.indexOf(base64.charAt(i)) >>> (6 - imod4 * 2))
    )
  }
  return bytes
}

const hexToBytes = hex => {
  let bytes = []
  for (let c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16))
  return bytes
}

const bytesToHex = bytes => {
  let hex = []
  for (let i = 0; i < bytes.length; i++) {
    hex.push((bytes[i] >>> 4).toString(16))
    hex.push((bytes[i] & 0xF).toString(16))
  }
  return hex.join("")
}
