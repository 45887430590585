import React from "react"
import { useDropzone } from 'react-dropzone'
import { Image } from "semantic-ui-react"

import StationDropdown from "../../StationDropdown"

import HappyCloud from "../../svgs/happy_cloud.svg"
import styles from './FileUploadForm.module.css'

const FileUploadForm = ({ onAcceptFile, onSelectStationId, stationId }) => {
  const onDrop = acceptedFiles => onAcceptFile(acceptedFiles[0])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <section className={styles.FormContent}>
      <div className={[styles.UploadForm, isDragActive ? styles.DragActive : ''].join(' ')} {...getRootProps()}>
        <input {...getInputProps()} />
        <Image
          className={styles.CloudSvg}
          size='small'
          src={HappyCloud}
          avatar
        />
        <h4>Drag & Drop zum Hochladen</h4>
        <span>
          Legen Sie hier Ihren Tourenplan im PDF-Format ab,
          oder klicken Sie auf "Tourenplan hochladen" und wählen Sie das Dokument aus.
        </span>
      </div>
      <div className={styles.StationSelection}>
        <StationDropdown canSelectNull={false} onSelect={onSelectStationId} value={stationId} />
      </div>
      <div className={styles.UploadButtonWrapper}>
        <button
          disabled={!stationId}
          onClick={() => this.uploadFile()}
          className={styles.UploadButton}
        >
          Tourenplan hochladen
        </button>
        <input
          type="file"
          accept="application/pdf"
          className={styles.InputField}
          onChange={event => onAcceptFile(event.target.files[0])}
        />
      </div>
    </section>
  )
}

export default FileUploadForm
