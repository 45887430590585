import gql from 'graphql-tag'

const CREATE_SHIFT_KIND = gql`
  mutation createShiftKind(
    $abbreviation: String!,
    $isActive: Boolean!,
    $isForDay0: Boolean!,
    $isForDay1: Boolean!,
    $isForDay2: Boolean!,
    $isForDay3: Boolean!,
    $isForDay4: Boolean!,
    $isForDay5: Boolean!,
    $isForDay6: Boolean!,
    $name: String!,
    $qualificationGroup: QualificationGroup!,
    $shiftTimeId: Int!,
    $stationId: Int!,
  ) {
    createShiftKind(
      abbreviation: $abbreviation,
      isActive: $isActive,
      isForDay0: $isForDay0,
      isForDay1: $isForDay1,
      isForDay2: $isForDay2,
      isForDay3: $isForDay3,
      isForDay4: $isForDay4,
      isForDay5: $isForDay5,
      isForDay6: $isForDay6,
      name: $name,
      qualificationGroup: $qualificationGroup,
      shiftTimeId: $shiftTimeId,
      stationId: $stationId
    ) {
      shiftKind {
        id
        name
      }
    }
  }
`

const UPDATE_SHIFT_KIND = gql`
  mutation updateShiftKind(
    $abbreviation: String,
    $id: String!,
    $isActive: Boolean!,
    $isForDay0: Boolean,
    $isForDay1: Boolean,
    $isForDay2: Boolean,
    $isForDay3: Boolean,
    $isForDay4: Boolean,
    $isForDay5: Boolean,
    $isForDay6: Boolean,
    $name: String,
    $qualificationGroup: QualificationGroup,
    $shiftTimeId: Int,
  ) {
    updateShiftKind(
      abbreviation: $abbreviation,
      id: $id,
      isActive: $isActive,
      isForDay0: $isForDay0,
      isForDay1: $isForDay1,
      isForDay2: $isForDay2,
      isForDay3: $isForDay3,
      isForDay4: $isForDay4,
      isForDay5: $isForDay5,
      isForDay6: $isForDay6,
      name: $name,
      qualificationGroup: $qualificationGroup,
      shiftTimeId: $shiftTimeId,
    ) {
      shiftKind {
        id
        name
      }
    }
  }
`

const UPDATE_DESIRED_TIME_WINDOWS = gql`
  mutation updateClient(
    $id: String!,
    $canAcceptLeasing: Boolean,
    $clientDesiredTimeWindows: [ClientDesiredTimeWindowInputType]
  ) {
    updateClient(
      id: $id,
      canAcceptLeasing: $canAcceptLeasing
      clientDesiredTimeWindows: $clientDesiredTimeWindows
    ) {
      client {
        id
      }
    }
  }
`
const CREATE_USER = gql`
  mutation createUser(
    $caregiver: CaregiverInputType,
    $firstName: String!,
    $initials: String,
    $isActive: Boolean!,
    $isQualifiedCaregiver: Boolean!,
    $lastName: String!,
    $stationIds: [Int]!
  ) {
    createUser(
      caregiver: $caregiver,
      firstName: $firstName,
      initials: $initials,
      isActive: $isActive,
      isQualifiedCaregiver: $isQualifiedCaregiver,
      lastName: $lastName,
      stationIds: $stationIds
    ) {
      user {
        id
      }
    }
  }
`

const UPDATE_USER = gql`
  mutation updateUser(
    $caregiver: CaregiverInputType,
    $firstName: String,
    $id: String!,
    $initials: String,
    $isActive: Boolean!,
    $isQualifiedCaregiver: Boolean,
    $lastName: String,
    $stationIds: [Int]
  ) {
    updateUser(
      caregiver: $caregiver,
      firstName: $firstName,
      id: $id,
      initials: $initials,
      isActive: $isActive,
      isQualifiedCaregiver: $isQualifiedCaregiver,
      lastName: $lastName,
      stationIds: $stationIds
    ) {
      user {
        id
      }
    }
  }
`

const OPTIMIZE_SHIFTS = gql`
  mutation optimizeShifts(
    $plannedMonthDate: Date!,
    $sheetWorkbookName: String,
    $state: GermanFederalStateCodeEnum,
    $stationId: Int!,
  ) {
    optimizeShifts(
      plannedMonthDate: $plannedMonthDate,
      sheetWorkbookName: $sheetWorkbookName,
      state: $state,
      stationId: $stationId,
    ) {
      sheetWorkbookName
    }
  }
`

const UPDATE_SHIFT_OPTIMIZER_PARAMETERS = gql`
  mutation updateShiftOptimzerParameters(
    $consecutiveTourBlocksWeight: Int,
    $dummyEmployeeAssignmentsPenalty: Int,
    $dummyEmployeeAssignmentsPenaltyWeekend: Int,
    $dummyShiftForPartTimersPenalty: Int,
    $dummyShiftPenalty: Int,
    $flexibleEmployeesOnWeekendsWeight: Int,
    $freeWeekendsEveryoneWeight: Int,
    $freeWeekendsMinMaxWeight: Int,
    $maxFreeWeekendsCount: Int,
    $minFreeWeekendsCount: Int,
    $preferredFreeDaysWeight: Int,
    $preferredToursWeight: Int,
    $shouldUseDummyEmployee: Boolean,
    $shouldUseDummyShift: Boolean,
    $slackConsecutiveDaysPenalty: Int,
    $slackFreeDaysPenalty: Int,
    $slackWorkingDaysPenalty: Int,
    $stationId: Int!,
    $transitionsWeight: Int,
  ) {
    updateShiftOptimizerParameters(
      consecutiveTourBlocksWeight: $consecutiveTourBlocksWeight,
      dummyEmployeeAssignmentsPenalty: $dummyEmployeeAssignmentsPenalty,
      dummyEmployeeAssignmentsPenaltyWeekend: $dummyEmployeeAssignmentsPenaltyWeekend,
      dummyShiftForPartTimersPenalty: $dummyShiftForPartTimersPenalty,
      dummyShiftPenalty: $dummyShiftPenalty,
      flexibleEmployeesOnWeekendsWeight: $flexibleEmployeesOnWeekendsWeight,
      freeWeekendsEveryoneWeight: $freeWeekendsEveryoneWeight,
      freeWeekendsMinMaxWeight: $freeWeekendsMinMaxWeight,
      maxFreeWeekendsCount: $maxFreeWeekendsCount,
      minFreeWeekendsCount: $minFreeWeekendsCount,
      preferredFreeDaysWeight: $preferredFreeDaysWeight,
      preferredToursWeight: $preferredToursWeight,
      shouldUseDummyEmployee: $shouldUseDummyEmployee,
      shouldUseDummyShift: $shouldUseDummyShift,
      slackConsecutiveDaysPenalty: $slackConsecutiveDaysPenalty,
      slackFreeDaysPenalty: $slackFreeDaysPenalty,
      slackWorkingDaysPenalty: $slackWorkingDaysPenalty,
      stationId: $stationId,
      transitionsWeight: $transitionsWeight
    ) {
      shiftOptimizerParameters {
        consecutiveTourBlocksWeight
        dummyEmployeeAssignmentsPenalty
        dummyEmployeeAssignmentsPenaltyWeekend
        dummyShiftForPartTimersPenalty
        dummyShiftPenalty
        flexibleEmployeesOnWeekendsWeight
        freeWeekendsEveryoneWeight
        freeWeekendsMinMaxWeight
        maxFreeWeekendsCount
        minFreeWeekendsCount
        preferredFreeDaysWeight
        preferredToursWeight
        shouldUseDummyEmployee
        shouldUseDummyShift
        slackConsecutiveDaysPenalty
        slackFreeDaysPenalty
        slackWorkingDaysPenalty
        station {
          id
        }
        transitionsWeight
      }
    }
  }
`

const UPDATE_CAREGIVER = gql`
  mutation updateCaregiver(
    $unwantedServiceIds: [String],
    $id: String!,
    $desiredWorkingTimes: [CaregiverDesiredWorkingTimeInputType],
  ) {
    updateCaregiver(
      unwantedServiceIds: $unwantedServiceIds,
      id: $id,
      desiredWorkingTimes: $desiredWorkingTimes,
    ) {
      caregiver {
        id
      }
    }
  }
`

const CREATE_SPECIAL_SERVICE = gql`
  mutation createSpecialService($clientId: String, $shiftKindId: String!, $serviceId: String!) {
    createSpecialService(serviceId: $serviceId, clientId: $clientId, shiftKindId: $shiftKindId) {
      specialService {
        id
      }
    }
  }
`

const DELETE_SPECIAL_SERVICE = gql`
  mutation deleteSpecialService($id: String!) {
    deleteSpecialService(id: $id) {
      specialService {
        id
      }
    }
  }
`

const UPDATE_SERVICE_SETTINGS = gql`
  mutation updateServiceSettings($id: String!, $canBeProvidedByCareCompanion: Boolean!) {
    updateServiceSettings(id: $id, canBeProvidedByCareCompanion: $canBeProvidedByCareCompanion) {
      service {
        id
      }
    }
  }
`

export {
  CREATE_SHIFT_KIND,
  CREATE_SPECIAL_SERVICE,
  CREATE_USER,
  DELETE_SPECIAL_SERVICE,
  OPTIMIZE_SHIFTS,
  UPDATE_CAREGIVER,
  UPDATE_DESIRED_TIME_WINDOWS,
  UPDATE_SERVICE_SETTINGS,
  UPDATE_SHIFT_OPTIMIZER_PARAMETERS,
  UPDATE_SHIFT_KIND,
  UPDATE_USER,
}
