import React, { useEffect, useMemo, useState } from 'react'

import _ from "lodash"
import dayjs from 'dayjs'
import Fuse from 'fuse.js'
import gql from 'graphql-tag'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import pluralize from 'pluralize'
import Toolbar from '@material-ui/core/Toolbar'
import { Loader } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'

import ApolloErrorButton from '../ApolloErrorButton'
import PlannedTourCopyingModal from './PlannedTourCopyingModal'
import PlannedTourDeletionDialog from './PlannedTourDeletionDialog'
import PlannedTourEditDialog from './PlannedTourEditDialog'
import PlannedTour from './PlannedTour'
import SearchBar from '../SearchBar'
import { useDeepCompareEffect, useModal, usePopup } from '../../hooks'

import styles from './PlannedTours.module.css'

const PlannedTours = ({
  className,
  colors,
  date,
  isDraggingEnabled,
  multiDraggingPlannedActivityId,
  onChangeVisiblePlannedTourIds,
  onLoaded,
  onToggleSelection,
  onUnassignedPlannedVisitsChange,
  plannedTourIdToRefetchHash,
  primaryStationId,
  secondaryStationIds,
  selectedPlannedActivityIds,
}) => {
  const [checkedPlannedTourIds, setCheckedPlannedTourIds] = useState([])
  const [expandedPlannedTourIds, setExpandedPlannedTourIds] = useState([])
  const [expandedPlannedActivityIds, setExpandedPlannedActivityIds] = useState([])
  const [tourFilter, setTourFilter] = useState("")
  const { loading, error, data, refetch } = useQuery(
    PLANNED_TOURS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        beforeStartDatetime: dayjs(date).add(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        fromStartDatetime: dayjs(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        stationIds: [primaryStationId, ...secondaryStationIds],
      },
    }
  )
  const plannedTours = (data && data.plannedTours) || []
  const displayedPlannedTours = useMemo(
    () => _sortedPlannedTours(_filteredPlannedTours(plannedTours, tourFilter)),
    [plannedTours.map(t => t.id).join(','), tourFilter]
  )
  useEffect(
    () => onChangeVisiblePlannedTourIds(
      displayedPlannedTours.map(t => t.id).filter(id => expandedPlannedTourIds.includes(id))
    ),
    [displayedPlannedTours, expandedPlannedTourIds]
  )
  useEffect(() => onLoaded(error ? [] : displayedPlannedTours), [displayedPlannedTours, error])
  // Reset UI state for tours and visits when the set of displayed tours has changed
  useDeepCompareEffect(
    () => {
      // TODO: Keep previously checked and expanded planned tour IDs
      setCheckedPlannedTourIds([])
      setExpandedPlannedTourIds(plannedTours.map(t => t.id))
      setExpandedPlannedActivityIds([])
    },
    [plannedTours.map(t => t.id)]
  )
  return (
    <div className={[styles.PlannedTours, className].join(' ')}>
      {loading && <Loader active />}
      <ToursToolbar
        checkedPlannedTourIds={checkedPlannedTourIds}
        date={date}
        onChangeTourFilter={setTourFilter}
        onPlannedToursCopied={refetch /* TODO: Only refetch if copied tours are on same day */}
        onPlannedToursCreated={refetch /* TODO: Only refetch if created tours are on same day */}
        onPlannedToursDeleted={(_, unassignedPlannedVisits) => {
          refetch()
          if (unassignedPlannedVisits.length > 0) onUnassignedPlannedVisitsChange()
        }}
        primaryStationId={primaryStationId}
      />
      <div className={[styles.Grid, loading && styles.Loading].join(' ')}>
        {error && <ApolloErrorButton error={error} />}
        {!loading && (plannedTours.length === 0) && "Keine Touren vorhanden"}
        {displayedPlannedTours.map((t, index) =>
          <PlannedTour
            color={colors[index % colors.length]}
            expandedActivityIds={expandedPlannedActivityIds}
            isCheckable={true}
            isChecked={checkedPlannedTourIds.includes(t.id)}
            isDraggingEnabled={isDraggingEnabled}
            isDroppable={isDraggingEnabled}
            isExpanded={expandedPlannedTourIds.includes(t.id)}
            key={t.id}
            multiDraggingPlannedActivityId={multiDraggingPlannedActivityId}
            onCheck={isChecked => setCheckedPlannedTourIds(isChecked
              ? [...checkedPlannedTourIds, t.id]
              : checkedPlannedTourIds.filter(id => id !== t.id)
            )}
            onExpand={isExpanded => setExpandedPlannedTourIds(isExpanded
              ? [...expandedPlannedTourIds, t.id]
              : expandedPlannedTourIds.filter(id => id !== t.id)
            )}
            onToggleExpansion={plannedActivityId => setExpandedPlannedActivityIds(
              expandedPlannedActivityIds.includes(plannedActivityId)
                ? expandedPlannedActivityIds.filter(id => id !== plannedActivityId)
                : [...expandedPlannedActivityIds, plannedActivityId]
            )}
            onToggleSelection={onToggleSelection}
            plannedTour={t}
            refetchHash={plannedTourIdToRefetchHash[t.id]}
            selectedActivityIds={selectedPlannedActivityIds}
          />
        )}
      </div>
    </div>
  )
}

const ToursToolbar = ({
  checkedPlannedTourIds,
  date,
  onChangeTourFilter,
  onPlannedToursCopied,
  onPlannedToursCreated,
  onPlannedToursDeleted,
  primaryStationId,
}) => {
  const [isShowingEditDialog, toggleEditDialog] = useModal()
  const [isShowingDeletionDialog, toggleDeletionDialog] = useModal()
  const [isShowingTourCopyingModal, toggleTourCopyingModal] = useModal()
  const [
    isBulkActionMenuOpen, showBulkActionMenu, closeBulkActionMenu, bulkActionMenuAnchorElement
  ] = usePopup()
  return (
    <Toolbar className={styles.Toolbar} disableGutters={true} variant="dense">
      <SearchBar
        className={styles.SearchBar}
        onDebouncedChange={onChangeTourFilter}
        placeholder="Filter (Tourname, Stationscode, Pflegekraft, Dienst/Ohne, PFK/PHK)"
        shouldHidePlaceholderOnBlur={true}
      />
      <IconButton onClick={showBulkActionMenu}><MoreVertIcon /></IconButton>
      <Menu
        anchorEl={bulkActionMenuAnchorElement}
        keepMounted
        onClose={closeBulkActionMenu}
        open={isBulkActionMenuOpen}
      >
        <MenuItem onClick={e => {closeBulkActionMenu(e); toggleEditDialog()}}>
          Neue Tour hinzufügen
        </MenuItem>
        <MenuItem
          disabled={checkedPlannedTourIds.length === 0}
          onClick={e => {closeBulkActionMenu(e); toggleTourCopyingModal()}}
        >Selektierte {pluralize("Tour", checkedPlannedTourIds.length)} kopieren</MenuItem>
        <MenuItem
          disabled={checkedPlannedTourIds.length === 0}
          onClick={e => {closeBulkActionMenu(e); toggleDeletionDialog()}}
        >Selektierte {pluralize("Tour", checkedPlannedTourIds.length)} löschen</MenuItem>
      </Menu>
      {isShowingEditDialog && <PlannedTourEditDialog
        date={date}
        onClose={toggleEditDialog}
        onEdit={onPlannedToursCreated}
        stationId={primaryStationId}
      />}
      {isShowingDeletionDialog && <PlannedTourDeletionDialog
        onClose={toggleDeletionDialog}
        onDelete={onPlannedToursDeleted}
        plannedTourIds={checkedPlannedTourIds}
      />}
      {isShowingTourCopyingModal && <PlannedTourCopyingModal
        initialDate={date}
        onClose={toggleTourCopyingModal}
        onCopy={onPlannedToursCopied}
        plannedTourIds={checkedPlannedTourIds}
        stationId={primaryStationId}
      />}
    </Toolbar>
  )
}

// TODO: plannedActivities should not be fetched; currently required for D&D index correction -> API should do it
const PLANNED_TOURS_QUERY = gql`
  query plannedTours($beforeStartDatetime: DateTime, $fromStartDatetime: DateTime, $stationIds: [Int]) {
    plannedTours(
      beforeStartDatetime: $beforeStartDatetime
      fromStartDatetime: $fromStartDatetime
      stationIds: $stationIds
    ) {
      endDatetime
      estimatedEndDatetime
      id
      name
      plannedActivities { __typename id }
      shift {
        caregiver { id qualificationGroup }
        id
        user { firstName id lastName }
      }
      station { code id name }
    }
  }
`

const _sortedPlannedTours = plannedTours => _.sortBy(
  plannedTours,
  [
    t => (t.shift && {
      'QualificationGroup.QUALIFIED': 0,
      'QualificationGroup.CARE_COMPANION': 1,
    }[t.shift.caregiver.qualificationGroup]) || Infinity,
    t => t.name,
    t => t.id,
  ]
)

const _filteredPlannedTours = (plannedTours, filterText) => filterText
  .split(' ')
  .map(t => t.trim())
  .filter(Boolean)
  .reduce(
    (filteredTours, term) => new Fuse(
      filteredTours,
      {
        keys: [
          'caregiverQualificationGroup',
          'name',
          'shiftBinding',
          'stationCode',
          'userFirstName',
          'userLastName',
        ],
        threshold: 0.3,
      }
    ).search(term),
    plannedTours.map(t => ({
      caregiverQualificationGroup: (
        t.shift &&
        ((t.shift.caregiver.qualificationGroup === 'QualificationGroup.QUALIFIED_CAREGIVER') ? "pfk" : "phk")
      ),
      id: t.id,
      name: t.name,
      plannedTour: t,
      shiftBinding: t.shift ? "dienst" : "ohne",
      userFirstName: t.shift && t.shift.user.firstName,
      userLastName: t.shift && t.shift.user.lastName,
      stationCode: t.station.code,
    }))
  )
  .map(t => t.plannedTour)

export default PlannedTours
