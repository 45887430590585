import React from 'react'

import styles from './OrderSteps.module.css'

const OrderSteps = ({ currentStep }) => (
  <ul className={styles.OrderSteps}>
    <li className={currentStep >= 1 ? styles.Active : ''}>Anfrage ausstehend</li>
    <li className={currentStep >= 2 ? styles.Active : ''}>Anfrage wird geprüft</li>
    <li className={currentStep >= 3 ? styles.Active : ''}>Von Talea bestätigt</li>
    <li className={currentStep >= 4 ? styles.Active : ''}>Auftrag erfolgreich</li>
  </ul>
)

export default OrderSteps
