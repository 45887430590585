import React from "react"

import { ApolloConsumer, ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { Modal as SemanticModel } from "semantic-ui-react"

/*
  Problem this component solves: Semantic-UI.Modals are mounted outside the root component, hence the apollo
  client isn't available via the context.
  Solution this component provides: This modal class restores the apollo context by providing a new provider
  _within_ the semantic-ui modal component. Hence children inside this modal can also expect to have the
  apollo context.
*/
const Modal = ({ children, className, isOpened, onClose, onMount }) =>
  <ApolloConsumer>
    {apolloClient =>
      <SemanticModel
        className={className}
        dimmer='inverted'
        onClose={onClose}
        onMount={onMount}
        open={isOpened === undefined ? true : isOpened}
      >
        <ApolloProvider client={apolloClient}>
          <ApolloHooksProvider client={apolloClient}>
            {children}
          </ApolloHooksProvider>
        </ApolloProvider>
      </SemanticModel>
    }
  </ApolloConsumer>

export default Modal
