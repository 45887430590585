import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import * as API from '../../utils/API'

import './ClientVisits.css'


class ClientVisits extends React.Component {
  constructor() {
    super()
    this.startDatetime = moment().add(-1, 'd').startOf('day')
    this.dayCount = 8
    this.state = {
      client: null,
    }
  }

  componentDidMount() {
    API.fetchClientVisits(
      this.props.match.params.clientId,
      {
        visitsStartAfterDatetime: this.startDatetime,
        visitsStartBeforeDatetime: moment(this.startDatetime).add(this.dayCount + 1, 'd').startOf('day'),
      },
    ).then(response => this.setState({ client: response.clients[0] }))
  }

  render = () => {
    const client = this.state.client
    return (
      <div className="ClientVisits">
        {client &&
          <React.Fragment>
            <Link to={`/clients/${this.props.match.params.clientId}`}>
              <Icon name='arrow left' />
              {client.lastName}, {client.firstName}
            </Link>
            <div className="Header">
              <h3>Einsätze für {client.lastName}, {client.firstName}</h3>
            </div>
            <div className="ClientVisitsContainer">
              <ul>
                {((plannedVisits, actualVisits) => [...Array(this.dayCount).keys()].map(dayIndex => (
                  <li key={dayIndex}>
                    <h4>{moment(this.startDatetime).add(dayIndex, 'd').format('DD.MM.YY')}</h4>
                    <Visits visits={plannedVisits[dayIndex]} type="planned" />
                    <Visits visits={actualVisits[dayIndex]} type="actual" />
                  </li>
                )))(
                  this.dateIndexToVisits(client.plannedVisits),
                  this.dateIndexToVisits(client.actualVisits)
                )}
              </ul>
            </div>
            {((client.plannedVisits.length) > 0 || (client.actualVisits.length) > 0) && (
              <ul className="Legend">
                <li className="planned">Geplanter Einsatz</li>
                <li className="planned-unassigned">Nicht zugewiesener Einsatz</li>
                <li className="actual">Durchgeführter Einsatz</li>
              </ul>
            )}
            {(client.plannedVisits.length === 0) && (client.actualVisits.length === 0) &&
              <h2 className='NoVisits'>Keine Einsätze diese Woche</h2>
            }
          </React.Fragment>
        }
      </div>
    )
  }

  dateIndexToVisits = visits => [...Array(this.dayCount).keys()].map(dayIndex =>
    _.sortBy(
      visits
        // TODO: Filter via GraphQL query
        .filter(visit => ['ActualVisitType', 'PlannedVisitType'].includes(visit.__typename))
        .filter(visit =>
          moment(visit.startDatetime).format('YYYY-MM-DD') ===
          moment(this.startDatetime).add(dayIndex, 'd').format('YYYY-MM-DD')
        ),
      ['startDatetime']
    )
  )
}

export default (ClientVisits)

const Visits = ({ visits, type }) => visits.map(visit => (
  <ul className={`Services ${visit.user ? type : `${type}-unassigned`}`} key={visit.startDatetime}>
    <li>{moment(visit.startDatetime).format('HH:mm')}</li>
    <li>{(((type === 'planned' ? visit.plannedTour : visit.actualTour) || {}).shift || {}).abbreviation}</li>
    <li>{visit.user && `${visit.user.firstName} ${visit.user.lastName}`}</li>
    <li>
      {visit.services && visit.services.map(service =>
        <dl key={service.id}>
          {service.abbreviation || service.name.split(' ', 2)[0].substring(0, 5)}
          <Popup trigger={<Icon name='info circle' />} content={service.name} />
        </dl>
      )}
    </li>
  </ul>
))
