import React from 'react'
import { Image } from 'semantic-ui-react'

import PropTypes from 'prop-types'

import CategoryIcon from './CategoryIcon'
import supplyManagementWhite from '../svgs/supplyManagementWhite.svg'
import Avatar from '../svgs/avatar.svg'

import { getMessageCategory } from '../../utils/helpers'
import { messageTags } from '../../constants'

import './TaskAvatar.css'

const TaskAvatar = ({ tags }) => (
  <div className="TaskAvatar">
    <div className="InnerAvatar">
      <Image
        size='small'
        src={Avatar}
        avatar
      />
      {
        (tags => ({
          'health': <CategoryIcon category="health" name='heart' />,
          'visit-planning': <CategoryIcon category="visit-planning" name='calendar' />,
          'drug-order': <img src={supplyManagementWhite} alt="orderMed" className="drug-order CategoryIcon" />,
          'general': <CategoryIcon category="general" name='comment' />,
        }[getMessageCategory(messageTags, tags)]))(tags)
      }
    </div>
  </div>
)

TaskAvatar.propTypes = {
  tags: PropTypes.array,
}

export default TaskAvatar

