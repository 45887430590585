import React from 'react'

//TODO: Use module css
import styles from './index.module.css'

const ClientMapMarker = ({
  className,
  client,
  hint,
  hintTrigger = "hover",
  isFocused,
  isSelected,
  label,
  onMarkerClick,
  $dimensionKey,
  $geoService,
  $getDimensions,
  $hover,
  $onMouseAllow,
  $prerender,
  ...rest
}) => (
  <div
    className={[
      styles.ClientMapMarker,
      isFocused && styles.IsFocused,
      isSelected && styles.IsSelected,
      { 'focus': styles.FocusTrigger, 'hover': styles.HoverTrigger }[hintTrigger],
      className,
    ].join(' ')}
    {...rest}
  >
    <div className={styles.Label} onClick={onMarkerClick}>{label}</div>
    <div className={styles.Hint}>
      {!!hint
        ? hint
        : <div className={styles.ClientDetails}>
          <p className={styles.Name}>
            {[client.lastName, client.firstName].filter(Boolean).join(", ")}
          </p>
          <p className={styles.Address}>
            {client.street}, {client.zipCode || client.zip_code}&nbsp;{client.city}
          </p>
        </div>
      }
    </div>
  </div>
)

export default ClientMapMarker
