import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { AppBar, Button } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import LoggedInUser from './LoggedInUser'
import Logo from '../../images/talea-logo-white.png'
import MenuWithDropdown from './MenuWithDropdown'
import { hasPermission } from '../../../utils/auth'
import { menuItems } from '../../../constants/menu'

import styles from './index.module.css'


const Header = ({ loggedInUser, logout }) => {
  const appVersion = process.env.REACT_APP_VERSION
  const loggedInUserPermissions = loggedInUser && loggedInUser['http://api.talea.de/permissions']

  return (
    <AppBar className={styles.Header} position="static" elevation={0}>
      <Toolbar>

        <div className={styles.Home}>
          <Typography className={styles.Title} variant="h6">My</Typography>
          <img alt="logo" className={styles.Logo} src={Logo} />
          <Typography className={styles.Version} variant="caption">{appVersion}</Typography>
        </div>

        {loggedInUserPermissions && menuItems.map((menuItem, index) => menuItem.path
          ? hasPermission(menuItem.perform) &&
            <Button className={styles.MenuButton} component={Link} key={index} to={`/${menuItem.path}`}>
              {menuItem.label}
            </Button>
          : (
            (menuItem.children.map(c => hasPermission(c.perform)).filter(Boolean).length > 0) &&
            <MenuWithDropdown menuItem={menuItem} key={index} />
          )
        )}
        <div className={styles.ProfileLink}>
          <LoggedInUser loggedInUser={loggedInUser} logout={logout} />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Header)
