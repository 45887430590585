import React from 'react'

import styles from './index.module.css'

const UnwantedClients = ({ caregiver }) => (
  <div className={styles.UnwantedClients}>
    <h4 className={styles.UnwantedClientsHeader}>Ungewünschte Klienten:</h4>
    <ul className={styles.UnwantedClientsList}>
      {caregiver.clientPreferences
        .map(c =>
          <li className={styles.UnwantedClient} key={c.id}>
            {c.client.firstName} {c.client.lastName}
          </li>
        )
      }
    </ul>
  </div>
)

export default UnwantedClients
