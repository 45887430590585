import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import Auth from '../../utils/auth'
import Error from '../Error'
import { AuthError, requestAuth0Success } from '../../actions/Auth0Actions'
import { getRedirectPath } from './../../utils/helpers'
import { roleToRedirectPath } from './../../constants'

import './index.css'


class OAuthCallback extends Component {
  constructor(props) {
    super(props)
    this.auth = new Auth()
  }

  render = () =>
    <div className="OAuthCallback">
      {this.props.isAuthenticated &&
        <Redirect to={`${getRedirectPath(this.auth.getUserRole(), roleToRedirectPath)}`} />}
      {
        this.props.error
          ? (
            <div className="Error">
              <Error text={this.props.error} />
              <Link to="/"><Button labelPosition="left" icon="left chevron" content="Zurück zur Login-Seite" /></Link>
            </div>
          )
          : (<p>Sie werden eingeloggt...</p>)
      }
    </div>

  componentDidMount() {
    this.auth.handleCallback()
      .then(authData => {
        this.props.dispatch(requestAuth0Success(authData))
      })
      .catch(error => {
        this.props.dispatch(AuthError(error))
      })
  }
}

OAuthCallback.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

const mapStateToProps = globalState => ({
  isAuthenticated: globalState.auth.isAuthenticated,
  error: globalState.auth.error,
})

export default connect(mapStateToProps, null)(OAuthCallback)
