import React from 'react'

import dayjs from 'dayjs'
import _ from 'lodash'
import { Mutation, Query } from 'react-apollo'
import { Grid } from 'react-virtualized'
import { Button, Dimmer, Form, Header, Icon, Input, List, Loader, Popup } from 'semantic-ui-react'

import { constraintKindToLabel, weekendDayIndexes } from '../../../constants'
import { OPTIMIZE_SHIFTS, UPDATE_SHIFT_OPTIMIZER_PARAMETERS } from '../../../constants/mutations'
import { GET_SHIFT_OPTIMIZER_RESULTS } from '../../../constants/queries'

import './index.css'

class ShiftOptimizer extends React.Component {
  state = {
    error: null,
  }

  render = () => (
    <Query
      query={GET_SHIFT_OPTIMIZER_RESULTS}
      variables={{
        isActive: true,
        plannedMonthDate: this.props.plannedMonthDate.format('YYYY-MM-DD'),
        stationId: this.props.match.params.stationId,
      }}
    >
      {({ data, loading, error }) => (
        <div className="ShiftOptimizer">
          <Loader active={loading} />
          {data.users && data.shiftOptimizerResults && data.shiftOptimizerParameters &&
            <React.Fragment>
              <Dimmer.Dimmable dimmed={error || !!this.state.error} blurring >
                <ShiftOptimizerResults
                  constraintViolations={data.shiftOptimizerResults.constraintViolations || []}
                  assignments={data.shiftOptimizerResults.assignments || []}
                  startDate={this.props.plannedMonthDate}
                  users={_.orderBy(
                    [...new Set(
                      data.shiftOptimizerResults.assignments.map(assignment => assignment.user)
                    )].filter(Boolean) || [],
                    ['caregiver.qualificationGroup', 'lastName'],
                    ['desc', 'asc']
                  )}
                />
                <Dimmer
                  active={
                    error ||
                    !!this.state.error ||
                    (data.shiftOptimizerResults && data.shiftOptimizerResults.assignments.length === 0)
                  }
                >
                  <Header icon inverted>
                    {(error || !!this.state.error) &&
                      <React.Fragment><Icon name='bug' /><p>Ein Fehler ist aufgetreten!</p></React.Fragment>
                    }
                    {(data.shiftOptimizerResults && data.shiftOptimizerResults.assignments.length === 0) &&
                      <React.Fragment><Icon name='warning circle' /><p>Kein Ergebnis!</p></React.Fragment>
                    }
                    <Header.Subheader>
                      {error && <p>{error}</p>}
                      {this.state.error && this.state.error.graphQLErrors &&
                        this.state.error.graphQLErrors.map((e, i) => <p key={i}>{e}</p>)}
                      {this.state.error && this.state.error.networkError &&
                        <p>Netzwerk Fehler: {this.state.error.networkError}</p>}
                      {(data.shiftOptimizerResults && data.shiftOptimizerResults.assignments.length === 0) &&
                        <p>Wurden die Dienste oder die Mitarbeiter richtig hinzugefügt?</p>
                      }
                    </Header.Subheader>
                  </Header>
                </Dimmer>
              </Dimmer.Dimmable>
              <ActionBar
                constraintViolations={data.shiftOptimizerResults.constraintViolations || []}
                shiftOptimizerParameters={data.shiftOptimizerParameters[0]}
                startDate={this.props.plannedMonthDate}
                stationId={this.props.match.params.stationId}
                onError={error => this.setState({ error })}
              />
            </React.Fragment>
          }
        </div>
      )
      }
    </Query>
  )

  componentDidUpdate = prevProps => {
    if (prevProps.plannedMonthDate !== this.props.plannedMonthDate) {
      this.setState({ error: null })
    }
  }
}

class ShiftOptimizerResults extends React.Component {
  state = {
    scrollValueX: 0,
    scrollValueY: 0,
  }

  render = () => (
    <div className="ShiftOptimizerResults">
      <header>
        <div className="Name">Name</div>
        {[...Array(this.props.startDate.daysInMonth()).keys()]
          .map(i => this.props.startDate.add(i, 'day').format('YYYY-MM-DD'))
          .map((date, index) =>
            <div className="Day" key={index}>
              <span>{dayjs(date).format('dd.')}</span>
              <span>{dayjs(date).format('DD')}</span>
            </div>
          )
        }
      </header>
      <Grid
        cellRenderer={this.renderTableCell}
        className="Table"
        columnCount={this.props.startDate.daysInMonth() + 1}
        columnWidth={({ index }) => index === 0 ? 150 : 40}
        height={window.innerHeight - 255}
        rowCount={this.props.users.length}
        rowHeight={40}
        width={window.innerWidth - 20}
      />
      <Grid
        cellRenderer={this.renderFooterCell}
        className="Footer"
        columnCount={this.props.startDate.daysInMonth() + 1}
        columnWidth={({ index }) => index === 0 ? 150 : 40}
        height={40}
        rowCount={1}
        rowHeight={40}
        width={window.innerWidth - 20}
      />
    </div>
  )

  renderTableCell = ({ columnIndex, key, rowIndex, style }) => {
    const date = this.props.startDate.add(columnIndex - 1, 'day').format('YYYY-MM-DD')

    return (
      <div className={this.classNames(date, rowIndex)} style={style} key={key}>
        {columnIndex === 0
          ? <div className="Name">
            {`${this.props.users[rowIndex].lastName} ${this.props.users[rowIndex].firstName}`}
          </div>
          : this.renderDayShiftPlan(date, rowIndex)
        }
      </div>
    )
  }

  renderFooterCell = ({ columnIndex, key, rowIndex, style }) => {
    const date = this.props.startDate.add(columnIndex - 1, 'day').format('YYYY-MM-DD')

    return (
      <div className="Cell" style={style} key={key}>
        {columnIndex === 0
          ? <div className="Title">
            <Icon name='warning sign' /><span>Nicht zugeordnete Dienste</span>
          </div>
          : this.renderDayShiftPlan(date, rowIndex, 'isDummyUser')
        }
      </div>
    )
  }

  renderDayShiftPlan = (date, rowIndex, isDummyUser = false) =>
    <DayShiftPlan
      date={date}
      shiftNames={this.props.assignments
        .filter(result => result.date === date)
        .filter(result => isDummyUser
          ? result.isDummyUser
          : result.user && (result.user.id === this.props.users[rowIndex].id))
        .map(result => result.isDummyShift
          ? shiftTypeToLabel['isDummyShift']
          : result.isFreeShift
            ? shiftTypeToLabel['isFreeShift']
            : result.shiftKind ? result.shiftKind.name : ''
        )
        .join(', ')
      }
      dayConstraintViolations={!isDummyUser && this.props.constraintViolations.filter(c =>
        (c.date === date) &&
        (c.user && (c.user.id === this.props.users[rowIndex].id))
      )}
    />


  classNames = (date, rowIndex) => ([
    'Cell',
    rowIndex % 2 && 'isEven',
    weekendDayIndexes.includes(dayjs(date).day()) && 'isWeekend',
    (this.props.users[rowIndex] === _.findLast(
      this.props.users,
      user => user.caregiver.qualificationGroup === 'QualificationGroup.QUALIFIED_CAREGIVER'
    )) && 'isLastQualifiedCaregiver',
  ].filter(Boolean).join(' '))
}

class DayShiftPlan extends React.Component {
  render = () =>
    <Popup
      className="DayShiftPlanPopup"
      trigger={(this.props.shiftNames || this.props.dayConstraintViolations) &&
        <div className="DayShiftPlan">
          {this.props.shiftNames}
          {this.props.dayConstraintViolations && this.props.dayConstraintViolations.length > 0 &&
            <Icon name='warning sign' />}
        </div>
      }
      on='hover'
      content={
        <div className="Content">
          {this.props.shiftNames && (<div className="Shifts">Dienste: {this.props.shiftNames}</div>)}
          <List className="Constraints">
            {this.props.dayConstraintViolations &&
              this.props.dayConstraintViolations.map((c, index) =>
                <List.Item key={index}>{constraintKindToLabel[c.constraintKind]} {c.value}</List.Item>
              )}
          </List>
        </div>
      }
    />
}

class ActionBar extends React.Component {
  state = {
    sheetWorkbookName: '',
  }

  render = () => (
    <div className="ActionBar">
      <Popup
        className="ActionBarPopup Settings"
        content={
          <ShiftOptimizerParameters
            stationId={this.props.stationId}
            shiftOptimizerParameters={this.props.shiftOptimizerParameters}
          />
        }
        on='click'
        position='top right'
        trigger={<Button icon='settings' circular />}
      />
      <Popup
        className="ActionBarPopup ConstraintViolations"
        content={
          <div className="ConstraintViolations">
            <p>Verletzte Bedingungen:</p>
            <List className="Constraints">
              {this.props.constraintViolations
                .filter(c => !c.date)
                .map((c, index) =>
                  <List.Item key={index}>
                    {constraintKindToLabel[c.constraintKind]} {c.value}
                  </List.Item>
                )
              }
            </List>
          </div>
        }
        on='click'
        position='top left'
        trigger={<Button icon='chart pie' circular />}
      />
      {this.renderExportButton()}
    </div>
  )

  renderExportButton = () => (
    <Mutation mutation={OPTIMIZE_SHIFTS}
      onError={error => this.props.onError(error)}
      variables={{
        plannedMonthDate: dayjs(this.props.startDate).format('YYYY-MM-DD'),
        sheetWorkbookName: this.state.sheetWorkbookName || null,
        state: 'BE',
        stationId: this.props.stationId,
      }}
      refetchQueries={['getShiftOptimizerResults']}
    >
      {(optimizeShifts, { loading }) =>
        <React.Fragment>
          <Input
            onChange={(e, data) => this.setState({ sheetWorkbookName: data.value })}
            placeholder="Name des Google Sheets (optional)"
            value={this.state.sheetWorkbookName}
          />
          <Button
            loading={loading}
            onClick={() => {this.props.onError(null); optimizeShifts()}}
            primary
          >
            Berechnen
          </Button>
        </React.Fragment>
      }
    </Mutation>
  )
}

class ShiftOptimizerParameters extends React.Component {
  state = {
    ...[
      'consecutiveTourBlocksWeight',
      'dummyEmployeeAssignmentsPenalty',
      'dummyEmployeeAssignmentsPenaltyWeekend',
      'dummyShiftForPartTimersPenalty',
      'dummyShiftPenalty',
      'flexibleEmployeesOnWeekendsWeight',
      'freeWeekendsEveryoneWeight',
      'freeWeekendsMinMaxWeight',
      'maxFreeWeekendsCount',
      'minFreeWeekendsCount',
      'preferredFreeDaysWeight',
      'preferredToursWeight',
      'slackConsecutiveDaysPenalty',
      'slackFreeDaysPenalty',
      'slackWorkingDaysPenalty',
      'transitionsWeight',
    ].reduce((parameters, parameter) => ({
      ...parameters,
      [parameter]: this.props.shiftOptimizerParameters[`${parameter}`] || '',
    }), {}),
    shouldUseDummyEmployee: this.props.shiftOptimizerParameters.shouldUseDummyEmployee || false,
    shouldUseDummyShift: this.props.shiftOptimizerParameters.shouldUseDummyShift || false,
  }

  render = () => (
    <Mutation
      mutation={UPDATE_SHIFT_OPTIMIZER_PARAMETERS}
      variables={{
        ..._.keys(_.omit(this.state, ['shouldUseDummyEmployee', 'shouldUseDummyShift']))
          .reduce((parameters, parameter) => ({
            ...parameters,
            [parameter]: this.state[parameter] ? parseInt(this.state[parameter], 10) : 0,
          }), {}),
        shouldUseDummyEmployee: this.state.shouldUseDummyEmployee,
        shouldUseDummyShift: this.state.shouldUseDummyShift,
        stationId: this.props.stationId,
      }}
      refetchQueries={['getShiftOptimizerResults']}
    >
      {updateShiftOptimizerParameters => (
        <Form className="ShiftOptimizerParameters">
          {_.keys(_.omit(this.state, ['shouldUseDummyEmployee', 'shouldUseDummyShift']))
            .map((parameter, index) =>
              <Form.Field key={index}>
                <label>{parameterToLabel[parameter]}</label>
                <Input
                  value={this.state[parameter]}
                  onChange={(e, { value }) => this.setState({ [parameter]: value })}
                  onBlur={updateShiftOptimizerParameters}
                />
              </Form.Field>
            )}
          {['shouldUseDummyEmployee', 'shouldUseDummyShift'].map((parameter, index) =>
            <Form.Checkbox
              key={index}
              label={parameterToLabel[parameter]}
              checked={this.state[parameter]}
              onChange={(e, { checked }) => this.setState(
                prevState => ({ [parameter]: checked }),
                () => updateShiftOptimizerParameters()
              )}
            />)
          }
        </Form>
      )}
    </Mutation>
  )
}

const parameterToLabel = {
  consecutiveTourBlocksWeight: 'Gewichtung: Blöcke des selben Dienstes',
  dummyEmployeeAssignmentsPenalty: 'Strafe: Zuordnung an Dummy-Mitarbeiter',
  dummyEmployeeAssignmentsPenaltyWeekend: 'Strafe: Zuordnung an Dummy-Mitarbeiter am Wochenende',
  dummyShiftForPartTimersPenalty: 'Strafe: Zuordnung von Dummy-Diensten an Teilzeitmitarbeiter',
  dummyShiftPenalty: 'Strafe: Zuordnung von Dummy-Diensten',
  flexibleEmployeesOnWeekendsWeight: 'Gewichtung: Flexible Mitarbeiter arbeiten an Wochenenden',
  freeWeekendsEveryoneWeight: 'Gewichtung: Wochenende frei',
  freeWeekendsMinMaxWeight: 'Gewichtung: Richtige Anzahl freier Wochenenden',
  maxFreeWeekendsCount: 'Maximalanzahl freier Wochenenden',
  minFreeWeekendsCount: 'Minimalanzahl freier Wochenenden',
  preferredFreeDaysWeight: 'Gewichtung: wunschfreie Tage',
  preferredToursWeight: 'Gewichtung: Wunscharbeitstage',
  shouldUseDummyEmployee: 'Dummy-Mitarbeiter berücksichtigen',
  shouldUseDummyShift: 'Dummy-Dienste vergeben',
  slackConsecutiveDaysPenalty: 'Strafe: Überschreitung der maximal zulässigen aufeinanderfolgenden Arbeitstage',
  slackFreeDaysPenalty: 'Strafe: Überschreitung der maximalen freien Tage',
  slackWorkingDaysPenalty: 'Strafe: Überschreitung der maximalen Arbeitstage',
  transitionsWeight: 'Gewichtung: möglichst große Blöcke freier Tage',
}

const shiftTypeToLabel = {
  isDummyShift: 'Dienst',
  isDummyUser: 'Nicht zugeordnete Dienste',
  isFreeShift: 'frei',
}

export default ShiftOptimizer
