import React from 'react'

import dayjs from 'dayjs'
import { AutoSizer, Grid, ScrollSync } from 'react-virtualized'
import { Input } from 'semantic-ui-react'
import DayCell from './DayCell'
import { weekendDayIndexes } from '../../../constants'
import apolloClient from "../../../utils/apolloClient"
import PlannedTourDropdown from './../../PlannedTourDropdown'
import gql from 'graphql-tag'

import 'react-virtualized/styles.css'
import styles from './index.module.css'


class ShiftPlan extends React.Component {
  constructor(props) {
    super(props)
    this.monthHeaderGridRef = React.createRef()
  }

  render = () => {
    const caregiverListWidth = 160
    const columnCount = this.props.startDate.add(this.props.months, 'month').diff(this.props.startDate, 'day')
    const caregiversColumnCount = this.props.caregivers.length
    const columnWidth = 160
    //TODO use the autosizer to calculate the height
    const height = window.innerHeight - 192
    const overscanColumnCount = 0
    const overscanRowCount = 5
    const rowHeight = 36
    const scrollbarSize = 0
    return (
      <div className={styles.ShiftPlan}>
        <ScrollSync>
          {({ onScroll, scrollLeft, scrollTop }) => (
            <div className={styles.GridRow}>
              <div style={{ position: 'absolute', left: 0, top: 0 }}>
                <Grid
                  cellRenderer={this.renderLeftColumnHeader}
                  className={styles.HeaderGrid}
                  columnCount={1}
                  columnWidth={caregiverListWidth}
                  height={rowHeight * 2}
                  rowCount={2}
                  rowHeight={rowHeight}
                  width={caregiverListWidth}
                />
              </div>
              <div
                className={styles.MonthDaysContainer}
                style={{ position: 'absolute', left: 0, top: rowHeight * 2 }}
              >
                <Grid
                  cellRenderer={this.renderMonthlyDays}
                  className={styles.CaregiverList}
                  columnCount={1}
                  columnWidth={caregiverListWidth}
                  height={height - (scrollbarSize + 10)}
                  overscanColumnCount={overscanColumnCount}
                  overscanRowCount={overscanRowCount}
                  rowCount={columnCount}
                  rowHeight={rowHeight}
                  scrollTop={scrollTop}
                  width={caregiverListWidth}
                />
              </div>
              <div className={styles.GridColumn}>
                <AutoSizer disableHeight>
                  {({ width }) => {
                    return (
                      <React.Fragment>
                        <div style={{
                          height: rowHeight * 2,
                          width: width - scrollbarSize - caregiverListWidth,
                          marginLeft: caregiverListWidth
                        }}>
                          <Grid
                            cellRenderer={this.renderColumnHeader}
                            className={styles.HeaderGrid}
                            columnWidth={({ index }) => {
                              return columnWidth * this.props.startDate.add(index, 'month').daysInMonth()
                            }}
                            columnCount={this.props.months}
                            height={rowHeight}
                            overscanColumnCount={overscanColumnCount}
                            ref={this.monthHeaderGridRef}
                            rowCount={1}
                            rowHeight={rowHeight}
                            scrollLeft={scrollLeft}
                            width={width - caregiverListWidth}
                            {...this.props}
                          />
                          <Grid
                            cellRenderer={this.renderCaregiver}
                            className={styles.HeaderGrid}
                            columnCount={caregiversColumnCount}
                            columnWidth={columnWidth}
                            height={rowHeight}
                            overscanColumnCount={overscanColumnCount}
                            rowCount={1}
                            rowHeight={rowHeight}
                            scrollLeft={scrollLeft}
                            width={width - caregiverListWidth}
                            {...this.props}
                          />
                        </div>
                        <div style={
                          { height, width: width - caregiverListWidth, marginLeft: caregiverListWidth }
                        }>
                          <Grid
                            cellRenderer={this.renderDayCell}
                            className={styles.Days}
                            columnCount={this.props.caregivers.length}
                            columnWidth={columnWidth}
                            height={height}
                            onScroll={onScroll}
                            overscanColumnCount={overscanColumnCount}
                            overscanRowCount={overscanRowCount}
                            rowCount={columnCount}
                            rowHeight={rowHeight}
                            width={width - caregiverListWidth}
                            {...this.props}
                          />
                        </div>
                      </React.Fragment>
                    )}}
                </AutoSizer>
              </div>
            </div>
          )}
        </ScrollSync>
      </div>
    )
  }

  renderDayCell = ({ columnIndex, key, rowIndex, style }) => {
    const date = this.props.startDate.add(rowIndex, 'day').format('YYYY-MM-DD')
    const caregiverId = this.props.caregivers[columnIndex]['id']
    const shift = this.props.shifts.find(shift =>
      (shift.caregiver.id === caregiverId) && (dayjs(shift.startDatetime).format('YYYY-MM-DD') === date)
    )
    const refetchShifts = this.props.onChangeShifts
    return (
      <div
        key={key}
        style={style}
        className={[
          style,
          styles.DayCellWrapper,
          weekendDayIndexes.includes(this.props.startDate.add(rowIndex, 'day').day()) ? styles.isWeekend : '',
          rowIndex % 2 ? styles.isEvenRow : '',
        ].join(' ')}
      >
        <div className={styles.DayCellContent}>
          <DayCell
            caregiverId={caregiverId}
            columnIndex={columnIndex}
            date={date}
            isWeekend={weekendDayIndexes.includes(this.props.startDate.add(rowIndex, 'day').day())}
            rowIndex={rowIndex}
            shift={shift}
            shiftKinds={this.props.shiftKinds}
          />
          {
            shift && shift.shiftKind && <PlannedTourDropdown
              canSelectNull={true}
              date={this.props.startDate.add(rowIndex, 'day').format('YYYY-MM-DD')}
              extraPlannedTour={shift && shift.plannedTour}
              inlineStyles={{
                border: 'none',
                boxShadow: 'none',
                fontSize: '12px',
                minWidth: 'auto',
                maxWidth: '100%',
                width: '100%',
                height: '100%',
                minHeight: '100%',
                lineHeight: '100%',
              }}
              isUpward={rowIndex > 14} /*Prevent dropdowns at the bottom of the calendar from being cut off*/
              onSelect={plannedTourId => this.createOrUpdateShift(
                caregiverId, date, plannedTourId, shift, refetchShifts
              )}
              shouldRefetchOnOpen={true}
              stationId={this.props.stationId}
              value={shift && shift.plannedTour && shift.plannedTour.id}
            />
          }
        </div>
      </div>
    )
  }

  renderColumnHeader = ({ columnIndex, key, style }) => {
    return (
      <div className={styles.MonthHeaderCell} key={key} style={style}>Caregivers</div>
    )
  }

  renderMonthlyDays = ({ rowIndex, key, style }) => {
    return (
      <div
        className={[
          styles.DayHeaderCell,
          weekendDayIndexes.includes(this.props.startDate.add(rowIndex, 'day').day())
            ? styles.isWeekend
            : ''
        ].join(' ')}
        key={key}
        style={style}
      >
        <span>{this.props.startDate.add(rowIndex, 'day').format('DD.MM')}</span>
        <span>{this.props.startDate.add(rowIndex, 'day').format('dd.')}</span>
      </div>
    )
  }

  renderLeftColumnHeader = ({ key, style, rowIndex }) => {
    return (
      <div key={key} style={style}>
        {rowIndex === 0
          ? <div className={styles.Title}>Filters</div>
          : <Input className={styles.SearchField} icon='search' placeholder="Suche..." />
        }
      </div>
    )
  }

  renderCaregiver = ({ key, columnIndex, style }) => {
    return (
      <div
        className={[
          styles.Caregiver,
          (
            this.props.caregivers[columnIndex]['qualificationGroup'] ===
            'QualificationGroup.QUALIFIED_CAREGIVER'
          )
            ? styles.QualifiedCaregiver : styles.NonQualifiedCaregiver,
        ].join(' ')}
        key={key}
        style={style}
      >
        <div className={styles.CaregiverName}>
          {(user => `${user.lastName}, ${user.firstName}`)(this.props.caregivers[columnIndex]['user'])}
        </div>
      </div>
    )
  }

  componentDidUpdate = prevProps => {
    if (prevProps.startDate !== this.props.startDate) {
      this.monthHeaderGridRef.current.recomputeGridSize()
    }
  }

  createOrUpdateShift = (caregiverId, date, plannedTourId, shift, refetchShifts) =>
    apolloClient().mutate({
      mutation: CREATE_OR_UPDATE_SHIFT,
      variables: {
        caregiverId: caregiverId,
        date: date,
        id: shift && shift.id,
        stationId: this.props.stationId,
        plannedTourId: plannedTourId || '',
        shiftKindId: shift && shift.shiftKind.id,
      }
    }).then(refetchShifts)
}


const CREATE_OR_UPDATE_SHIFT = gql`
  mutation createOrUpdateShift(
    $caregiverId: String!
    $date: Date!
    $id: String
    $plannedTourId: String
    $shiftKindId: String!
    $stationId: Int!
  ) {
    createOrUpdateShift(
      caregiverId: $caregiverId
      date: $date
      id: $id
      plannedTourId: $plannedTourId
      shiftKindId: $shiftKindId
      stationId: $stationId
    ) {
      shift {
        id
        plannedTour {
          id
          name
        }
        shiftKind {
          id
        }
      }
    }
  }
`

export default ShiftPlan
