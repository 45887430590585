import React from 'react'

import { withRouter } from 'react-router-dom'
import { compose, graphql, Query } from 'react-apollo'
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

import UnwantedServices from '../../components/caregivers/unwanted-services'
import DesiredWorkingTimes from '../../components/caregivers/desired-working-times'
import CaregiverProfile from '../../components/caregivers/caregiver-profile'
import UnwantedClients from '../../components/caregivers/unwanted-clients'
import { GET_USER_BY_ID, GET_USERS } from '../../constants/queries'

import styles from './Caregivers.module.css'

class Caregivers extends React.Component {
  state = {
    selectedCaregiverUserId: this.props.match.params.caregiverUserId || null,
  }

  render = () => (
    <div className={styles.Caregivers}>
      <div className={styles.CaregiversSearch}>
        <Dropdown
          className={styles.Dropdown}
          loading={this.props.data.loading}
          options={this.props.data.users && this.props.data.users.length > 0
            ? this.sortedCaregivers().map(user => ({
              value: user.id,
              text: `${user.lastName}, ${user.firstName}`
            }))
            : []
          }
          label='Pflegekräfte'
          onChange={(_, data) => this.setState(
            prevState => ({ selectedCaregiverUserId: data.value }),
            this.props.history.replace(`/caregivers/${this.props.match.params.stationId}/${data.value}`)
          )}
          value={this.state.selectedCaregiverUserId}
          placeholder='Bitte auswählen'
          fluid
          search
          selection
        />
      </div>
      {this.state.selectedCaregiverUserId &&
        <Query query={GET_USER_BY_ID} variables={{ id: this.state.selectedCaregiverUserId }}>
          {({ data }) => data.users
            ? <Caregiver caregiverUser={_.first(data.users)} stationId={this.props.match.params.stationId} />
            : null
          }
        </Query>
      }
    </div>
  )

  sortedCaregivers = () => _.orderBy(
    this.props.data.users.map(u => u.caregiver && u), ['lastName', 'firstName']
  ).filter(Boolean)
}

const Caregiver = ({ caregiverUser, stationId }) => (
  <div className={styles.Caregiver}>
    <CaregiverProfile caregiverUser={caregiverUser} />
    <DesiredWorkingTimes caregiverUser={caregiverUser} />
    <UnwantedServices caregiverUser={caregiverUser} stationId={stationId} />
    <UnwantedClients caregiver={caregiverUser.caregiver} />
  </div>
)

export default compose(
  withRouter,
  graphql(GET_USERS, { options: ({ match }) => ({ variables: {
    isActive: true,
    stationId: match.params.stationId
  } }) })
)(Caregivers)
