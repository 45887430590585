import React from 'react'
import { Button, Checkbox, Table } from 'semantic-ui-react'

import moment from 'moment'

import { weekdays } from '../../constants'

import styles from './DesiredTimeWindows.module.css'

const DesiredTimeWindows = ({ desiredTimeWindows, onAddTimeWindow, onRemoveTimeWindow }) =>
  <div className={styles.ServiceSelector}>
    <div className={styles.Header}>
      <h4>Feste Besuchszeiten:</h4>
      <Button icon='plus' onClick={onAddTimeWindow} />
    </div>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Startzeit</Table.HeaderCell>
          <Table.HeaderCell>Endzeit</Table.HeaderCell>
          <Table.HeaderCell>Wochentagen</Table.HeaderCell>
          <Table.HeaderCell>V</Table.HeaderCell>
          <Table.HeaderCell>XI</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {desiredTimeWindows.map((timeWindow, index) =>
          <Table.Row key={index}>
            <Table.Cell><Button icon='delete' onClick={() => onRemoveTimeWindow(timeWindow.id)} /></Table.Cell>
            <Table.Cell>{moment(timeWindow.earliestStartTime, 'HH:mm:ss').format('HH:mm')}</Table.Cell>
            <Table.Cell>{moment(timeWindow.latestEndTime, 'HH:mm:ss').format('HH:mm')}</Table.Cell>
            <Table.Cell>
              {[...new Array(7).keys()]
                .map(day => timeWindow[`isForDay${day}`] && weekdays[`${day}`]['text'])
                .filter(Boolean)
                .join(', ')
              }
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                checked={timeWindow.serviceGroup.replace('ServiceGroup.', '') === 'SGB_V'}
                disabled
              />
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                checked={timeWindow.serviceGroup.replace('ServiceGroup.', '') === 'SGB_XI'}
                disabled
              />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </div>

export default DesiredTimeWindows
