import React from 'react'

import { Button, Table } from 'semantic-ui-react'
import moment from 'moment'

import { weekdays } from '../../../constants'

import styles from './WorkingTimesTable.module.css'

const WorkingTimesTable = ({ desiredWorkingTimes, onRemoveWorkingTime }) => (
  <Table className={styles.WorkingTimesTable} celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell>Startzeit</Table.HeaderCell>
        <Table.HeaderCell>Endzeit</Table.HeaderCell>
        <Table.HeaderCell>Wochentagen</Table.HeaderCell>
        <Table.HeaderCell>Früh/Spät</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {desiredWorkingTimes.map((workingTime, index) =>
        <Table.Row key={index}>
          <Table.Cell>
            <Button icon='delete' onClick={() => onRemoveWorkingTime(workingTime.id)} />
          </Table.Cell>
          <Table.Cell>{moment(workingTime.startTime, 'HH:mm:ss').format('HH:mm')}</Table.Cell>
          <Table.Cell>{moment(workingTime.endTime, 'HH:mm:ss').format('HH:mm')}</Table.Cell>
          <Table.Cell>
            {[...new Array(7).keys()]
              .map(day => workingTime[`isForDay${day}`] && weekdays[`${day}`]['text'])
              .filter(Boolean)
              .join(', ')
            }
          </Table.Cell>
          <Table.Cell>{workingTime.isForEarlyShift ? 'Früh' : 'Spät'}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
)

export default WorkingTimesTable
