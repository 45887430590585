import auth0 from 'auth0-js'

export const isAuthenticated = () => {
  // TODO: Handle case where stored value does not exist or is malformed
  const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
  return new Date().getTime() < expiresAt
}

export const getUserPermissions = () => JSON.parse(localStorage.getItem('permissions'))

export const hasPermission = permission => getUserPermissions().includes(permission)

export const getAccessToken = () => localStorage.getItem('access_token')

export const getUserRole = () => JSON.parse(localStorage.getItem('role'))

export default class Auth {
  tokenRenewalTimeout;
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: 'talea-pflege.eu.auth0.com',
      clientID: 'IRD6TDmN2qXUHqXyvJgwH9YZGwyHBBjM',
      audience: 'backend',
      scope: 'openid profile',
      responseType: 'token id_token',
      redirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port
        ? `:${window.location.port}`
        : ''
      }/oauth/callback`,
    })
    this.scheduleRenewal()
  }

  scheduleRenewal() {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    let delay = expiresAt - Date.now()
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => this.renewToken(), delay)
    }
  }

  login() {
    this.auth0.authorize()
  }

  logout() {
    localStorage.clear()
    clearTimeout(this.tokenRenewalTimeout)
  }

  handleCallback() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((error, session) => {
        if (session && session.accessToken && session.idToken) {
          this.setSession(session)
          resolve(session)
        } else {
          reject(error || 'Received session object is invalid')
        }
      })
    })
  }

  setSession(session) {
    const expiresAt = JSON.stringify((session.expiresIn * 3600) + new Date().getTime())
    localStorage.setItem('access_token', session.accessToken)
    localStorage.setItem('expires_at', expiresAt)
    localStorage.setItem('role', JSON.stringify(session.idTokenPayload['http://api.talea.de/roles']))
    localStorage.setItem(
      'permissions', JSON.stringify(session.idTokenPayload['http://api.talea.de/permissions'])
    )
    // schedule a token renewal
    this.scheduleRenewal()
  }

  isAuthenticated = isAuthenticated

  getUser() {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(getAccessToken(), (error, user) => {
        if (user) {
          resolve(user)
        } else {
          reject(error || 'Unknown error')
        }
      })
    })
  }

  getUserRole = getUserRole

  getUserPermissions = getUserPermissions

  hasPermission = hasPermission

  renewToken() {
    this.auth0.checkSession({}, (error, result) => {
      if (error) {
        // TODO: Need to notify use that token renewal failed.
        console.error(error)
      } else {
        this.setSession(result)
      }
    })
  }
}
