import React from 'react'

import _ from 'lodash'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Button, Dropdown, Form, Icon } from 'semantic-ui-react'

import ErrorDialog from '../dialogs/ErrorDialog'
import { OPTIMIZE_PLANNED_TOURS } from '../../constants/mutations/tour-optimizer'
import { GET_STATIONS } from '../../constants/queries'
import { GET_PLANNED_TOURS } from '../../constants/queries/tour-optimizer'

import './index.css'

class RecoEngine extends React.Component {
  state = {
    date: new Date(),
    error: null,
    stationId: null,
    tourId: null,
    userId: null,
  }

  render = () => {
    return (
      <div className="RecoEngine">
        <Form>
          <Form.Field>
            <label>Station:</label>
            <StationPicker
              onChangeStationId={(e, { value }) => this.setState(
                { stationId: value, userId: null, tourId: null }
              )}
              stationId={this.state.stationId}
            />
          </Form.Field>
          <Form.Field>
            <label>Datum:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              onChange={date => this.setState({ date, userId: null, tourId: null })}
              placeholderText="Datum"
              popperPlacement="top-end"
              selected={this.state.date}
            />
          </Form.Field>
          {this.renderEmployeeAndTourPicker()}
          {this.renderButton()}
        </Form>
        {this.state.error && <ErrorDialog
          error={this.state.error}
          isOpened={!!this.state.error}
          onClose={() => this.setState({ error: null })}
        />}
      </div>
    )
  }

  renderEmployeeAndTourPicker = () =>
    <Query
      query={GET_PLANNED_TOURS}
      variables={{
        date: moment(this.state.date).format('YYYY-MM-DD'),
        stationId: parseInt(this.state.stationId),
      }}
      skip={!this.state.stationId}
    >
      {({ data, loading }) => (
        <React.Fragment>
          <Form.Field>
            <label>Ausgefallener Mitarbeiter:</label>
            <EmployeePicker
              users={(data && data.plannedTours && data.plannedTours
                .map(tour => tour.shift && tour.shift.user)
                .filter((user, index, users) => users.indexOf(user) === index)
                .filter(Boolean)
              ) || []}
              onChangeUserId={(e, { value }) => this.setState({
                userId: value,
                tourId: data.plannedTours
                  .find(tour => tour.shift && (tour.shift.user.id === value))['id']
              })}
              loading={loading}
              stationId={this.state.stationId}
              userId={this.state.userId}
            />
          </Form.Field>
          <Form.Field>
            <label>Ausfallende Tour:</label>
            <TourPicker
              tours={(data && data.plannedTours && data.plannedTours
                .reduce((tours, tour) => tour.shift
                  ? [...tours, {
                    startDatetime: tour.startDatetime,
                    endDatetime: tour.endDatetime,
                    shift: tour.shift,
                    user: tour.shift.user,
                    id: tour.id,
                  }]
                  : tours, []).filter(Boolean)
              ) || []}
              onChangeTourId={(e, { value }) => this.setState({
                userId: data.plannedTours.find(tour => tour.id === value)['shift']['user']['id'],
                tourId: value
              })}
              loading={loading}
              stationId={this.state.stationId}
              tourId={this.state.tourId}
              userId={this.state.userId}
            />
          </Form.Field>
        </React.Fragment>
      )}
    </Query>

  renderButton = () =>
    <Form.Field>
      <Mutation
        mutation={OPTIMIZE_PLANNED_TOURS}
        variables={{
          cancelledTourPublicIds: [this.state.tourId],
          date: moment(this.state.date).format('YYYY-MM-DD'),
          stationId: parseInt(this.state.stationId),
        }}
        onCompleted={_ => this.props.history.push(
          `/tour-planning/${this.state.stationId}/day/${this.state.date.format('YYYY-MM-DD')}`
        )}
        onError={error => this.setState({ error })}
      >
        {(optimizeToursDay, { loading }) =>
          <Button
            disabled={!(this.state.tourId && this.state.stationId)}
            onClick={optimizeToursDay}
            loading={loading}
          >
            Unbesetzte Einsätze automatisch verteilen
          </Button>
        }
      </Mutation>
    </Form.Field>
}

const StationPicker = ({ stationId, onChangeStationId }) =>
  <Query query={GET_STATIONS}>
    {({ data, loading }) =>
      <Dropdown
        onChange={onChangeStationId}
        options={(data.stations && data.stations.map(station => ({
          text: station.name,
          value: station.id,
        }))) || []}
        placeholder="Station"
        loading={loading}
        selection
        value={stationId}
      />
    }
  </Query>

const EmployeePicker = ({ users, userId, stationId, onChangeUserId, loading }) =>
  <Dropdown
    disabled={!stationId}
    loading={loading}
    onChange={onChangeUserId}
    options={_.orderBy(
      users.map(user => ({ text: `${user.firstName} ${user.lastName}`, value: user.id })),
      ['text']
    )}
    placeholder="Mitarbeiter"
    selection
    selectOnBlur={false}
    value={userId}
  />

const TourPicker = ({ tours, tourId, stationId, userId, loading, onChangeTourId }) =>
  <Dropdown
    disabled={!(stationId && userId)}
    loading={loading}
    onChange={onChangeTourId}
    onFocus={onChangeTourId}
    options={_.orderBy(
      tours.map(tour => ({
        content:
          <Tour
            endDatetime={tour.endDatetime}
            shiftName={tour.shift.name}
            startDatetime={tour.startDatetime}
            user={tour.user}
          />,
        text: tour.shift.name,
        value: tour.id,
      })),
      ['text']
    )}
    placeholder="Tour"
    selection
    selectOnBlur={false}
    value={tourId}
  />

const Tour = ({ shiftName, user, startDatetime, endDatetime }) =>
  <div className="Tour">
    <div className="ShiftName">
      <Icon name='car' />
      {shiftName /* the tour's name is actually the shift's name here */}
    </div>
    <div className="User">
      <Icon name='user' />
      {user && `${user.firstName} ${user.lastName}`}
    </div>
    <div className="Time">
      <Icon name='clock' />
      {`${moment(startDatetime).format('HH:mm')} - ${moment(endDatetime).format('HH:mm')}`}
    </div>
  </div>

export default withRouter(RecoEngine)
