import React from 'react'
import moment from 'moment'
import { Button, Dropdown, Form, Icon, Modal } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import { setHours, setMinutes } from "date-fns"

import { weekdays } from '../../../constants'

import styles from './WorkingTimeModal.module.css'

class WorkingTimeModal extends React.Component {
  state = {
    endTime: setHours(setMinutes(new Date(), 0), 14),
    isForEarlyShift: true,
    startTime: setHours(setMinutes(new Date(), 0), 6),
    weekdays: [0, 1, 2, 3, 4, 5, 6],
  }

  render = () => (
    <Modal
      className={styles.WorkingTimeModal}
      closeIcon
      onClose={this.props.onClose}
      onOpen={this.props.onOpen}
      open={this.props.isOpened}
      size="tiny"
    >
      <Modal.Header>Arbeitszeit hinzufügen</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>Wochentag(e):</label>
              <Dropdown
                multiple
                onChange={(e, { value }) => this.setState({ weekdays: value })}
                options={weekdays}
                placeholder='bitte auswählen'
                search
                selection
                value={this.state.weekdays}
              />
            </Form.Field>
            <Form.Field>
              <Form.Select
                fluid
                label='Früh / Spät'
                options={[
                  { key: true, text: 'Früh', value: true },
                  { key: false, text: 'Spät', value: false },
                ]}
                onChange={(e, { value }) => this.setState({ isForEarlyShift: value })}
                placeholder='Service Group'
                value={this.state.isForEarlyShift}
              />
            </Form.Field>
            <Form.Field>
              <label>Dienstbeginn:</label>
              <DatePicker
                dateFormat="HH:mm"
                onChange={startTime => this.setState({ startTime })}
                selected={this.state.startTime}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Zeit"
                timeFormat="HH:mm"
                timeIntervals={15}
              />
            </Form.Field>
            <Form.Field>
              <label>Dienstsende:</label>
              <DatePicker
                dateFormat="HH:mm"
                onChange={endTime => this.setState({ endTime })}
                selected={this.state.endTime}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Zeit"
                timeFormat="HH:mm"
                timeIntervals={15}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' onClick={this.props.onClose}>
          <Icon name='remove' /> Abbrechen
        </Button>
        <Button color='green' onClick={() => this.addWorkingTime()}>
          <Icon name='checkmark' /> Speichern
        </Button>
      </Modal.Actions>
    </Modal>
  )

  addWorkingTime = () => this.props.onAddWorkingTime({
    startTime: moment(this.state.startTime).format('HH:mm'),
    endTime: moment(this.state.endTime).format('HH:mm'),
    isForEarlyShift: this.state.isForEarlyShift,
    ...[...new Array(7).keys()].reduce((days, day) => ({
      ...days,
      [`isForDay${day}`]: this.state.weekdays.includes(day)
    }), {}),
  })
}

export default WorkingTimeModal
