import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from './store'
import MainRoutes from './screens/MainRoutes'

import 'semantic-ui-css/semantic.min.css'
import './index.css'

export default ReactDOM.render(
  <Provider store={store}>
    <MainRoutes />
  </Provider>,
  document.getElementById('root'),
)
