import React from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Segment } from 'semantic-ui-react'

import './index.css'

const Error = ({ text }) => (
  <Segment inverted className="Error ui grid middle aligned">
    <div className="ui column center aligned">
      <Message negative className="red inverted">
        <Message.Header>Es tut uns leid, ein Fehler ist aufgetreten.</Message.Header>
        <p>{text}</p>
        <Button primary onClick={window.location.reload}>Neu Laden</Button>
      </Message>
    </div>
  </Segment>
)

Error.propTypes = {
  text: PropTypes.string,
}

export default Error
