import React, { Fragment } from 'react'
import { Button, Icon } from 'semantic-ui-react'

import ErrorDialog from '../dialogs/ErrorDialog'
import { useModal } from '../../hooks'

import './index.module.css'

const ApolloErrorButton = ({ error, title }) => {
  const [isModalOpen, toggleModal] = useModal()
  return <Fragment>
    <Button circular onClick={toggleModal} size='small'>
      <Icon color='red' name='warning sign' />
      Fehler
    </Button>
    <ErrorDialog error={error} isOpened={isModalOpen} onClose={toggleModal} title={title} />
  </Fragment>
}

export default ApolloErrorButton
