import React, { Fragment } from "react"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { Input, Loader, Message } from "semantic-ui-react"

import Client from './../../components/client'
import ClientModal from "./../../components/client/ClientModal"
import ErrorDialog from "../../components/dialogs/ErrorDialog"

import styles from './index.module.css'

class ClientHome extends React.Component {
  state = {
    error: null,
    isClientModalOpen: false,
    nameQuery: '',
    selectedClient: null,
  };

  render = () => <div className={styles.Clients}>
    <div className={styles.Search}>
      <span>Filter:</span>
      <Input
        className={styles.Query}
        onChange={(_, { value }) => this.setState({ nameQuery: value })}
        value={this.state.nameQuery}
      />
    </div>
    <div className={styles.Headers}>
      {["Name", "KK-Gr.", "KK", "Telefon", "Adresse", "Pflegegrad", "Schlüssel", "Dienst"].map(
        h => <div key={h}>{h}</div>
      )}
    </div>
    <div className={styles.ListContainer}>
      <Query
        notifyOnNetworkStatusChange
        onError={error => this.handleError({ error })}
        query={GET_CLIENTS}
        variables={{ isActive: true, nameQuery: this.state.nameQuery }}
      >
        {({ loading, error, data }) => <Fragment>{loading
          ? <Loader active={loading} />
          : <div className={styles.List}>
            {
              data && data.clients.length > 0
                ? data.clients.map(c => <Client key={c.id} client={c} onClientEdit={this.handleClientEdit} />)
                : <Message floating>Kein Klient vorhanden</Message>
            }
          </div>
        }</Fragment>}
      </Query>
    </div>
    {this.state.error && <ErrorDialog
      error={this.state.error}
      isOpened={!!this.state.error}
      onClose={() => this.setState({ error: null })}
    />}
    {this.state.isClientModalOpen && <ClientModal
      client={this.state.selectedClient}
      isOpened={this.state.isClientModalOpen}
      onClose={this.handleClientModal}
      onError={this.handleError}
    />}
  </div>

  handleError = error => { this.setState({ error: error.error })}

  handleClientModal = () => this.setState({ isClientModalOpen: !this.state.isClientModalOpen });

  handleClientEdit = client => this.setState({
    isClientModalOpen: !this.state.isClientModalOpen, selectedClient: client
  })
}

export const GET_CLIENTS = gql`
  query getClients($stationIds: [Int], $id: String, $isActive: Boolean, $nameQuery: String) {
    clients(id: $id, isActive: $isActive, nameQuery: $nameQuery, stationIds: $stationIds) {
      addressSuffix
      apartmentKey
      careLevel
      city
      diagnosis
      firstName
      id
      houseNumber
      healthInsuranceGroup { abbreviation }
      healthInsuranceName
      isActive
      lastName
      station {
        code
      }
      streetHouseNumber
      insurances {
        name
        insuranceType
      }
      phoneNumber
      zipCodeCity
    }
  }
`

export default ClientHome
