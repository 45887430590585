import React from 'react'

const ClientColumn = props => (InputComponent => (
  <React.Fragment>
    <h5>{props.title}</h5>
    <InputComponent
      maxLength={props.maxLength}
      onChange={(_, { value }) => props.onChange(value)}
      placeholder={props.placeholder}
      value={props.value || ''}
    />
  </React.Fragment>
))(props.component)

export default ClientColumn