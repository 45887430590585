import React from 'react'
import { Dropdown, Icon, Label } from 'semantic-ui-react'

import FeedItem from '../../components/taskmanager/FeedItem'
import NoEntry from '../../components/taskmanager/NoEntry'
import { sortMessages } from '../../utils/helpers'
import Sort from '../svgs/sort.svg'

import './Feed.css'

class Feed extends React.Component {
  state = {
    shouldSortAsc: false,
    sortOption: 'date',
  }

  render = () => (
    <div className="Feed">
      <div className="Subheader">
        <div className="Title">
          <p>Feed</p>
          {this.props.untaskedMessages &&
          <Label circular>
            {this.props.untaskedMessages.length}
          </Label>}
        </div>
        {this.renderSort()}
      </div>
      <div className="FeedItems">
        {
          (untaskedMessages =>
            untaskedMessages.length > 0
              ? untaskedMessages.map(message =>
                <FeedItem
                  key={message.id}
                  message={message}
                  loggedInUserId={this.props.loggedInUserId}
                  clientIdToClient={this.props.clientIdToClient}
                  userIdToUser={this.props.userIdToUser}
                  createMessage={this.props.createMessage}
                  updateMessage={this.props.updateMessage}
                  onClickFeedItem={this.props.onClickFeedItem}
                />)
              : <NoEntry text="Keine Nachrichten" />
          )(this.state.sortOption
            ? this.sortedUntaskedMessages(this.props.untaskedMessages)
            : this.props.untaskedMessages
          )
        }
      </div>
    </div>
  )

  renderSort = () => (
    <div className="Sort">
      <Icon
        name={this.state.shouldSortAsc ? 'long arrow down' : 'long arrow up'}
        onClick={() => this.setState({ shouldSortAsc: !this.state.shouldSortAsc })}
      />
      <Dropdown icon={<img src={Sort} alt='sort' />} direction='left'>
        <Dropdown.Menu>
          {[{ value: 'date', label: 'Nach Erstellungsdatum' }].map(sortOption =>
            <Dropdown.Item
              key={sortOption.value}
              onClick={() => this.setState({ sortOption: sortOption.value })}
              className={this.state.sortOption === sortOption.value ? 'active' : ''}
            >
              {sortOption.label}
            </Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

  sortedUntaskedMessages = untaskedMessages => sortMessages(
    untaskedMessages,
    'timestamp',
    [this.state.shouldSortAsc ? 'asc' : 'desc']
  )
}

export default Feed
