import React from 'react'

import DatePicker from 'react-datepicker'
import { Button, Dimmer, Form, Image, Loader, Modal, TextArea } from 'semantic-ui-react'
import Avatar from '../../svgs/avatar.svg'

import './CreateMessageModal.css'

const CreateMessageModal = props => (
  <Modal
    className="CreateMessageModal"
    open={props.isCreateTaskModalOpened}
    size='small'
  >
    {props.isFetching && <Dimmer active inverted> <Loader size='small' /></Dimmer>}
    <Modal.Header>
      <div className="Top">
        <h3>{props.title}</h3>
        <div className="ImportantTag">
          <Button
            basic
            className={props.isImportant ? 'important' : ''}
            onClick={() => props.onChangeMessageImportance()}
          >Wichtig</Button>
        </div>
        <Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>Bearbeiter:</label>
              <Form.Dropdown
                options={
                  Object.keys(props.userIdToUser)
                    .filter(userId => props.userIdToUser[userId].isActive)
                    .map(userId => ({
                      value: userId,
                      text: ((user => `${user.firstName} ${user.lastName}`))(props.userIdToUser[userId])
                    }))
                }
                value={props.assignedUserId}
                onChange={(e, { value }) => props.onChangeAssignedUserId(value)}
                icon={'search'}
                placeholder='Bitte auswählen'
                search
                selection
                selectOnBlur={false}
              />
            </Form.Field>
            <Form.Field>
              <label>Fällig am</label>
              <DatePicker
                selected={props.taskDueDatetime}
                onChange={date => props.onChangeTaskDueDatetime(date)}
                dateFormat="dd/MM/yyyy"
                openToDate={new Date()}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <div className="Client">
        <Image size='mini' src={Avatar} avatar />
        <span>{props.client && props.client.firstName + ' ' + props.client.lastName}</span>
      </div>
    </Modal.Header>
    <Modal.Content>
      <TextArea
        rows={2}
        placeholder='Aufgabenbeschreibung...'
        value={props.messageBody}
        onChange={props.onChangeMessageBody}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => props.onCloseCreateTaskModal()}>Abbrechen</Button>
      <Button onClick={props.handleSubmit} disabled={!props.messageBody} primary>Aufgabe erstellen</Button>
    </Modal.Actions>
  </Modal>
)

export default CreateMessageModal
