import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Mutation } from 'react-apollo'
import { Button, Icon, Loader, Message } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import _ from 'lodash'

import PlannedTourDropdown from '../PlannedTourDropdown'
import PlannedTourEditDialog from '../tour-planner2/PlannedTourEditDialog'

import styles from './PlannedTourAssignment.module.css'

const PlannedTourAssignment = ({ sourcePlannedTourId }) => {
  const childRef = useRef()
  const { error, loading, data } = useQuery(
    SOURCE_PLANNED_TOUR_QUERY, { variables: { id: sourcePlannedTourId } }
  )
  const sourcePlannedTour = data && _.first(data.plannedTours)
  const [tourAssignmentErrorMessage, setTourAssignmentErrorMessage] = useState(null)
  const [selectedPlannedTourId, setSelectedPlannedTourId] = useState(null)
  const [isTourCreationModalOpen, setIsTourCreationModalOpen] = useState(false)
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false)
  useEffect(() => {
    selectedPlannedTourId && childRef.current.refetch()
    setIsSuccessMessageVisible(false)
  }, [selectedPlannedTourId])
  useEffect(() => { error && setTourAssignmentErrorMessage(error.message)}, [error])

  return (
    <div className={styles.PlannedTourAssignment}>
      {loading && <Loader active={loading} />}
      {tourAssignmentErrorMessage && <Message negative size='tiny'><p>{tourAssignmentErrorMessage}</p></Message>}
      <Button
        color='blue'
        icon labelPosition='left'
        onClick={() => setIsTourCreationModalOpen(!isTourCreationModalOpen)}
      >
        <Icon name='plus' />
        Tour hinzufügen
      </Button>
      {sourcePlannedTour &&
        <Fragment>
          <Button as='div' labelPosition='left'>
            <PlannedTourDropdown
              date={new Date(sourcePlannedTour.startDatetime)}
              ref={childRef}
              onSelect={v => setSelectedPlannedTourId(v)}
              stationId={6}
              shouldRefetchOnOpen={true}
              value={selectedPlannedTourId}
            />
            <Mutation
              mutation={MOVE_PLANNED_VISITS}
              onError={error => {alert(`Failed to move planned visit: ${error}`)}}
              onCompleted={() => setIsSuccessMessageVisible(!isSuccessMessageVisible)}
              refetchQueries={['tourOrders']}
            >
              {(movePlannedVisits, { data, error, loading }) =>
                <Fragment>
                  <Button
                    loading={loading}
                    onClick={() => movePlannedVisits({
                      variables: {
                        plannedVisitIds: sourcePlannedTour.plannedVisits.map(v => v.id),
                        toPlannedTourId: selectedPlannedTourId || ' ',
                        atPosition: -1
                      }
                    })}
                    primary
                  >Alle Einsätze an diese Tour zuweisen</Button>
                  <p>{isSuccessMessageVisible && 'Erfolgreich übertragen!'}</p>
                </Fragment>
              }
            </Mutation>
          </Button>
          {isTourCreationModalOpen && <PlannedTourEditDialog
            canSelectNull={true}
            date={new Date(sourcePlannedTour.startDatetime)}
            onClose={() => setIsTourCreationModalOpen(!isTourCreationModalOpen)}
            onEdit={plannedTourId /* TODO: argument undefined */ => setSelectedPlannedTourId(plannedTourId)}
            stationId={6}
          />}
        </Fragment>
      }
    </div>
  )
}

const SOURCE_PLANNED_TOUR_QUERY = gql`
  query getSourcePlannedTour($id: String) {
    plannedTours(id: $id) {
      id
      plannedVisits {
        id
      }
      startDatetime
    }
  }
`

// TODO: Rename mutation to `movePlannedActivities` and rename `plannedVisitIds`, too
const MOVE_PLANNED_VISITS = gql`
  mutation movePlannedVisits(
    $atPosition: Int!,
    $plannedVisitIds: [String]!,
    $toPlannedTourId: String!,
  ) {
    movePlannedActivities (
      atPosition: $atPosition,
      plannedActivityIds: $plannedVisitIds,
      toPlannedTourId: $toPlannedTourId,
    ) { movedPlannedActivities { id } }
  }
`

export default PlannedTourAssignment
