import gql from 'graphql-tag'
import {
  TOUR_OPTIMIZER_TOUR_CONSTRAINT_VIOLATIONS_FRAGMENT,
  TOUR_OPTIMIZER_VISIT_CONSTRAINT_VIOLATIONS_FRAGMENT,
} from '../../constraint-violations'

const TOUR_OPTIMIZER_DAY_FRAGMENT = `
  tourOptimizerDay {
    createdDatetime
    date
    hasBeenAppliedSinceLastChange
    id
    tourOptimizerTours {
      endDatetime
      id
      name
      shift {
        abbreviation
        caregiver {
          qualificationGroup
        }
        id
        name
        user {
          firstName
          id
          lastName
        }
      }
      startDatetime
      tourOptimizerActivities {
        duration
        id
        startDatetime
        ... on TourOptimizerVisitType {
          plannedVisit {
            client {
              id
              firstName
              lastName
            }
            hasSgbVService
            id
          }
          ${TOUR_OPTIMIZER_VISIT_CONSTRAINT_VIOLATIONS_FRAGMENT}
        }
        __typename
      }
      ${TOUR_OPTIMIZER_TOUR_CONSTRAINT_VIOLATIONS_FRAGMENT}
    }
    tourOptimizerMetric {
      assignedVisitCount
      assignedVisitsDrivingTimeAverage
      averageAssignedCaregiverHistoricalVisitPercentage
      totalDeviationFromAverageTimesDuration
      totalDrivingTimeDuration
      unassignedVisitCount
      visitsAssignedToTop1CaregiverPercentage
      visitsAssignedToTop3CaregiverPercentage
    }
    unassignedTourOptimizerActivities {
      duration
      id
      startDatetime
      ... on TourOptimizerVisitType {
        plannedVisit {
          client {
            id
            firstName
            lastName
          }
          hasSgbVService
          id
          ... on ReschedulingPlannedVisitType {
          visitOrder {
            id
            station {
              id
              name
            }
          }
         }
        }
      }
      __typename
    }
  }
`

const OPTIMIZE_PLANNED_TOURS = gql`
  mutation optimizePlannedTours(
    $cancelledTourPublicIds: [String],
    $date: Date!,
    $maxRelaxationIterationPerConstraintTypeCount: Int,
    $maxStrictnessTimeWindowStandardDeviation: Float,
    $relaxationConfigurations: [TourOptimizerRelaxationConfigurationType]
    $stationId: Int!,
  ) {
    optimizePlannedTours(
      cancelledTourPublicIds: $cancelledTourPublicIds,
      date: $date,
      maxRelaxationIterationPerConstraintTypeCount: $maxRelaxationIterationPerConstraintTypeCount,
      maxStrictnessTimeWindowStandardDeviation: $maxStrictnessTimeWindowStandardDeviation,
      relaxationConfigurations: $relaxationConfigurations
      stationId: $stationId,
    ) {
      ${TOUR_OPTIMIZER_DAY_FRAGMENT}
    }
  }
`

const APPLY_TOUR_OPTIMIZER_DAY_CHANGES = gql`
  mutation applyTourOptimizerDayChanges($tourOptimizerDayId: String!) {
    applyTourOptimizerDayChanges(tourOptimizerDayId: $tourOptimizerDayId) {
      errors {
        code
        message
      }
    }
  }
`

const MOVE_TOUR_OPTIMIZER_ACTIVITIES = gql`
  mutation moveTourOptimizerActivities(
    $targetIndex: Int!,
    $targetTourOptimizerTourId: String,
    $tourOptimizerActivityIds: [String]!,
  ) {
    moveTourOptimizerActivities (
      targetIndex: $targetIndex,
      targetTourOptimizerTourId: $targetTourOptimizerTourId,
      tourOptimizerActivityIds: $tourOptimizerActivityIds,
    ) {
      ${TOUR_OPTIMIZER_DAY_FRAGMENT}
    }
  }
`

const DELETE_TOUR_OPTIMIZER_VISITS = gql`
  mutation deleteTourOptimizerVisits($tourOptimizerVisitIds: [String]!) {
    deleteTourOptimizerVisits(tourOptimizerVisitIds: $tourOptimizerVisitIds) {
      affectedTourOptimizerDays {
        createdDatetime
        date
        hasBeenAppliedSinceLastChange
      }
    }
  }
`

const DEFAULT_MAX_RELAXATION_ITERATIONS_PER_CONSTRAINT_TYPE = 3

const DEFAULT_MAX_STRICTNESS_TIME_WINDOW_STANDARD_DEVIATION = 1.3

const DEFAULT_RELAXATION_CONFIGURATIONS = [
  {
    constraintType: 'PRIMARY_CAREGIVER',
    initialStrictness: 1.0,
    isRelaxedGlobally: false,
    maxUnassignedBecauseOfReason: 5,
    minStrictness: 0.0,
    relaxationIncrement: 0.5,
  },
  {
    constraintType: 'TIME_WINDOW',
    initialStrictness: 1.0,
    isRelaxedGlobally: false,
    maxUnassignedBecauseOfReason: 5,
    minStrictness: 0.0,
    relaxationIncrement: 0.5,
  },
  {
    constraintType: 'QUALIFICATION',
    initialStrictness: 1.0,
    isRelaxedGlobally: true,
    maxUnassignedBecauseOfReason: 5,
    minStrictness: 0.0,
    relaxationIncrement: 0.5,
  },
  {
    constraintType: 'SAME_TOUR_RELATION',
    initialStrictness: 1.0,
    isRelaxedGlobally: true,
    maxUnassignedBecauseOfReason: 5,
    minStrictness: 0.0,
    relaxationIncrement: 0.5,
  },
  {
    constraintType: 'TOUR_LENGTH',
    initialStrictness: 1.0,
    isRelaxedGlobally: true,
    maxUnassignedBecauseOfReason: 5,
    minStrictness: 0.0,
    relaxationIncrement: 0.5,
  }
]

export {
  APPLY_TOUR_OPTIMIZER_DAY_CHANGES,
  DEFAULT_MAX_RELAXATION_ITERATIONS_PER_CONSTRAINT_TYPE,
  DEFAULT_MAX_STRICTNESS_TIME_WINDOW_STANDARD_DEVIATION,
  DEFAULT_RELAXATION_CONFIGURATIONS,
  DELETE_TOUR_OPTIMIZER_VISITS,
  MOVE_TOUR_OPTIMIZER_ACTIVITIES,
  OPTIMIZE_PLANNED_TOURS,
}
