import React, { useRef, useState } from 'react'

import BackspaceIcon from '@material-ui/icons/Backspace'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'

import { useDebounce, useEffectAfterRender } from '../../hooks'

import styles from './index.module.css'

export default function SearchBar({
  className, onDebouncedChange, placeholder = "Suche", shouldHidePlaceholderOnBlur = false, ...rest
}) {
  const inputRef = useRef(null)
  const [value, setValue] = useState("")
  const debouncedValue = useDebounce(value, 500)
  useEffectAfterRender(() => onDebouncedChange && onDebouncedChange(debouncedValue), [debouncedValue])
  return (
    <Paper className={[
      styles.SearchBar, shouldHidePlaceholderOnBlur && styles.HiddenPlaceholderOnBlur, className
    ].filter(Boolean).join(' ')}>
      <IconButton onClick={() => {inputRef.current.focus(); inputRef.current.select()}}>
        <SearchIcon />
      </IconButton>
      <InputBase
        inputRef={inputRef}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
        value={value}
        {...rest}
      />
      <IconButton
        className={[styles.ClearButton, value.length || styles.ClearButtonHidden].filter(Boolean).join(' ')}
        onClick={e => {setValue(""); inputRef.current.focus(); onDebouncedChange && onDebouncedChange("")}}>
        <BackspaceIcon />
      </IconButton>
    </Paper>
  )
}
