import React from 'react'
import { Route } from 'react-router-dom'

import Forbidden from '../components/Forbidden'
import { hasPermission } from '../utils/auth'

const PrivateRoute = ({ component: Component, requiredPermission, ...rest }) => (
  <Route
    render={props => hasPermission(requiredPermission) ? <Component {...props} /> : <Forbidden />} {...rest}
  />
)

export default PrivateRoute
