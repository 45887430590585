import React from 'react'

import { Box } from '@material-ui/core'

import styles from './index.module.css'

const EventPropagationStopper = ({
  className,
  shouldStopOnBlur = false,
  shouldStopOnClick = false,
  shouldStopOnFocus = false,
  ...rest
}) => (
  <Box
    className={[styles.EventPropagationStopper, className].filter(Boolean).join(' ')}
    onBlur={e => shouldStopOnBlur && e.stopPropagation()}
    onClick={e => shouldStopOnClick && e.stopPropagation()}
    onFocus={e => shouldStopOnFocus && e.stopPropagation()}
    {...rest}
  />
)

export default EventPropagationStopper
