import React from 'react'
import { Link } from "react-router-dom"

import { Button, Message } from "semantic-ui-react"

import Auth from './../utils/auth'
import { getRedirectPath } from "../utils/helpers"
import { roleToRedirectPath } from "../constants"

import styles from './Forbidden.module.css'

class Forbidden extends React.Component {
  constructor(props) {
    super(props)
    this.auth = new Auth()
  }

  render() {
    return (
      <div className={styles.Forbidden}>
        <Message warning className={styles.Message}>
          <Message.Header>
            Sie haben leider nicht die erforderlichen Zugriffsrechte für diese Seite
          </Message.Header>
          <p>
            <Link to={`${getRedirectPath(this.auth.getUserRole(), roleToRedirectPath)}`}>
              <Button labelPosition="left" icon="left chevron" content="Zurück zur vorherigen Seite" basic />
            </Link> oder wenden Sie sich bitte an unser Supportteam.
          </p>
        </Message>
      </div>
    )
  }
}

export default Forbidden
