import React from 'react'
import moment from 'moment'
import { Button, Icon } from 'semantic-ui-react'

import './ClosingSection.css'

class ClosingSection extends React.Component {
  render = () => (
    <div className="ClosingSection">
      <div className="Message">
        <Icon name='checkmark' />
        <div>
          <p>{this.props.submessage.text}</p>
          <p className="Metadata">
            {this.props.userIdToUser &&
              ((user, timestamp) => `Erstellt von ${user}, ${timestamp}`)(
                (user => user ? `${user.firstName} ${user.lastName}` : "unbekannt")(
                  this.props.userIdToUser[this.props.submessage.userId]
                ),
                moment(this.props.submessage.timestamp).fromNow()
              )
            }
          </p>
        </div>
      </div>
      <div className="Actions">
        <p>Aufgabe abgeschlossen</p>
        <Button
          onClick={() => this.props.updateMessage({ id: this.props.message.id, taskStatus: 'in-progress' })}
        >
          Wieder öffnen
        </Button>
      </div>
    </div>
  )
}

export default ClosingSection
