import { auth0ErrorMessage, twilioLoginErrorMessage } from '../constants/'

const initialState = {
  users: [],
  loggedInUser: {
    data: {},
    twilio: {},
  },
  isAuthenticated: false,
  isInitializing: false,
  error: null,
}

export const authReducers = (state = initialState, action) => ({
  FETCH_AUTH0_LOGIN_REQUEST: () => ({
    ...state,
    isInitializing: true,
  }),
  FETCH_TWILIO_LOGIN_REQUEST: () => ({
    ...state,
    isInitializing: true,
  }),
  FETCH_REQUEST_SUCCESS: () => ({
    ...state,
    isInitializing: true,
    users: action.payload,
  }),
  AUTH_SUCCESS: () => ({
    ...state,
    isAuthenticated: true,
    loggedInUser: {
      ...state.loggedInUser,
      data: action.payload,
    },
  }),
  AUTH_FAILURE: () => ({
    ...state,
    isAuthenticated: false,
    isInitializing: false,
    error: auth0ErrorMessage + action.payload.errorDescription,
  }),
  REQUEST_AUTH0_LOGIN_SUCCESS: () => ({
    ...state,
    isAuthenticated: true,
    loggedInUser: {
      ...state.loggedInUser,
      data: action.payload,
    },
  }),
  REQUEST_TWILIO_LOGIN_SUCCESS: () => ({
    ...state,
    isInitializing: false,
    loggedInUser: {
      ...state.loggedInUser,
      twilio: action.payload,
    },
  }),
  REQUEST_TWILIO_LOGIN_FAILURE: () => ({
    ...state,
    isInitializing: false,
    error: twilioLoginErrorMessage,
  }),
}[action.type] || (() => state))()
