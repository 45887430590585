import React from 'react'

import dayjs from 'dayjs'
import { Button } from 'semantic-ui-react'
import { throttle } from 'lodash'

import ClientHealthInsuranceGroup from '../client/ClientHealthInsuranceGroup'

import styles from './PlannedVisit.module.css'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

class PlannedVisit extends React.Component {
  state = { isExpanded: false, hint: '' }

  constructor(props) {
    super(props)
    this.updateVisit = throttle(this.updateVisit.bind(this), 3000)
  }

  render = () =>
    <div className={[styles.PlannedVisit, this.state.isExpanded ? styles.isExpanded : styles.isCollapsed].join(' ')}>
      <div className={styles.VisitDescription} onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>
        <div>{this.props.index}</div>
        <div>{this.props.visit.client && `${this.props.visit.client.firstName} ${this.props.visit.client.lastName}`}</div>
        <div>
          {this.props.visit.services
            .map(service => service.serviceGroup && service.serviceGroup.replace(/ServiceGroup.|_/gi, ' '))
            .filter((service, index, services) => services.indexOf(service) === index)
            .join(', ')}
        </div>
        <ClientHealthInsuranceGroup clientId={this.props.visit.client.id} />
        <div>{this.props.visit.services.map(service => service.abbreviation).filter(Boolean).join(', ')}</div>
        <div>
          <span>{dayjs(this.props.visit.startDatetime).format('HH:mm')} </span>
          -
          <span> {dayjs(this.props.visit.endDatetime).format('HH:mm')}</span>
        </div>
        <div className={styles.ActionButtons}><Button circular icon='pencil' /><Button circular icon='trash' /></div>
      </div>
      <div className={styles.VisitDetails}>
        <div className={styles.Services}>
          <div className={styles.ServicesHeader}>Leistungen</div>
          <div className={styles.ServicesList}>
            {this.props.visit.services.map(service => (
              <div className={styles.Service} key={service.id}>{service.abbreviation} - {service.name}</div>
            ))}
          </div>
        </div>
        <div className={styles.VisitInfo}>
          <div className={styles.VisitInfoHeader}>Zusätzliche Einsatzinformationen</div>
          <Mutation mutation={UPDATE_PLANNED_VISIT}>
            {updateVisitMutation =>
              <textarea
                className={styles.VisitInfoText}
                defaultValue={this.props.visit.hint}
                onChange={e => this.updateVisit(updateVisitMutation, this.props.visit.id, e.target.value)}
                placeholder="Zusätzliche Einsatzinformationen"
              />
            }
          </Mutation>
        </div>
      </div>
    </div>

  updateVisit = (mutation, id, hint) => mutation({ variables: { id, hint } })
}

const UPDATE_PLANNED_VISIT = gql`
  mutation createOrUpdatePlannedVisit($id: String!, $hint: String) {
    createOrUpdatePlannedVisit(id: $id, hint: $hint) { plannedVisit { id } }
  }
`

export default PlannedVisit
