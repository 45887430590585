import React from "react"

import gql from "graphql-tag"
import { Dropdown } from "semantic-ui-react"
import { Query } from "react-apollo"

import styles from './HealthInsuranceGroup.module.css'

const HealthInsuranceGroup = ({ className, isLoading, onSelect, selection }) => (
  <Query onError={error => this.handleError({ error })} query={INSURANCE_GROUP_QUERY}>
    {({ loading, error, data }) => data &&
      <Dropdown
        className={styles.HealthInsuranceGroup}
        compact
        loading={loading || isLoading}
        onChange={(e, { value }) => onSelect(value === '_null' ? null : value)}
        options={[
          ...([{ key: '_null', value: '_null', text: '(Keine)' }]),
          ...(loading ? [] : data.insuranceGroups.map(s => ({ key: s.id, value: s.id, text: s.abbreviation })))
        ]}
        selection
        value={selection || '_null'}
      />
    }
  </Query>
)

const INSURANCE_GROUP_QUERY = gql`query insuranceGroups { insuranceGroups { id abbreviation } }`

export default HealthInsuranceGroup
