import React from 'react'

import moment from 'moment'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import { Button, Dropdown, Icon, Popup } from 'semantic-ui-react'

import PersonAvatar from './avatars/PersonAvatar'
import RelativeTimestamp from './RelativeTimestamp'

import 'react-datepicker/dist/react-datepicker.css'
import './Subtask.css'

class Subtask extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isUserDropdownOpen: false,
      isDatepickerOpen: false,
    }
  }

  render(){
    const isDisabled = (this.props.message.task && this.props.message.task.status === 'closed') ||
      this.props.isParentTaskClosed

    return (
      <div className="Subtask" key={this.props.message.id}>
        <div className="SubtaskDescription">
          <div className="User">
            {this.props.userIdToUser &&
              <PersonAvatar person={this.props.userIdToUser[this.props.message.userId]} />}
          </div>
          <div className="Content">
            {this.props.message.text}
            <div className="Metadata">
              {this.props.userIdToUser &&
                ((user, timestamp) =>
                  <p>{user}, <RelativeTimestamp timestamp={timestamp} /></p>
                )(
                  (user => user ? `${user.firstName} ${user.lastName}` : "unbekannt")(
                    this.props.userIdToUser[this.props.message.userId]
                  ), this.props.message.timestamp
                )
              }
            </div>
          </div>
        </div>
        <div className="Actions">
          {this.props.message.task && this.props.message.task.assigneeUser &&
            <Button
              onClick={this.updateTaskStatus}
              disabled={this.props.isParentTaskClosed}
              circular
              icon
              basic
            >
              <Icon
                name='check'
                color={this.props.message.task.status === 'closed' ? 'green' : 'grey'}
                size='big'
              />
            </Button>
          }
          <Popup
            on='click'
            trigger={
              <div className="Duedate">
                {moment(this.props.message.task && this.props.message.task.dueDatetime).isValid()
                  ? <p>{moment(this.props.message.task.dueDatetime).format('DD/MM')}</p>
                  : <Icon name='clock' size='big' />}
              </div>
            }
            content={
              <DatePicker
                selected={(this.props.message.task && moment(this.props.message.task.dueDatetime).isValid())
                  ? new Date(this.props.message.task.dueDatetime)
                  : null
                }
                onChange={date => this.updateTaskDueDate(date)}
                openToDate={new Date()}
                inline
              />
            }
            disabled={isDisabled}
            open={this.state.isDatepickerOpen}
            onOpen={() => this.setState({ isDatepickerOpen: true })}
            onClose={() => this.setState({ isDatepickerOpen: false })}
          />
          <Popup
            on='click'
            trigger={
              <div>
                {
                  this.props.userIdToUser &&
                    <PersonAvatar person={
                      this.props.userIdToUser[
                        this.props.message.task &&
                        this.props.message.task.assigneeUser &&
                        this.props.message.task.assigneeUser.id
                      ]
                    } />
                }
              </div>
            }
            content={
              <Dropdown
                options={_.sortBy(
                  Object.keys(this.props.userIdToUser)
                    .filter(userId => this.props.userIdToUser[userId].isActive)
                    .map(userId => ({
                      value: userId,
                      text: (user => `${user.firstName} ${user.lastName}`)(this.props.userIdToUser[userId])
                    })),
                  ['text']
                )}
                value={this.props.message.task && this.props.message.task.assigneeUser &&
                  this.props.message.task.assigneeUser.id
                }
                onChange={(e, { value }) => this.updateAssigneeUserId(value)}
                icon={'search'}
                placeholder='Bitte auswählen'
                search
                selection
              />
            }
            disabled={isDisabled}
            open={this.state.isUserDropdownOpen}
            onOpen={() => this.setState({ isUserDropdownOpen: true })}
            onClose={() => this.setState({ isUserDropdownOpen: false })}
          />
        </div>
      </div>
    )
  }

  updateAssigneeUserId = assigneeUserId => {
    this.setState({ assigneeUserId })

    this.props.updateMessage({
      id: this.props.message.id,
      taskAssigneeUserId: assigneeUserId
    })
  }

  updateTaskDueDate = taskDueDate => {
    this.setState({ taskDueDate })

    this.props.updateMessage({
      id: this.props.message.id,
      taskDueDatetime: moment(taskDueDate).format()
    })
  }

  updateTaskStatus = () => {
    this.props.updateMessage({
      id: this.props.message.id,
      taskStatus: this.props.message.task.status === 'closed' ? 'in-progress' : 'closed',
    })
  }
}

export default Subtask
