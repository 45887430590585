import React from 'react'

import _ from 'lodash'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Dimmer, Loader } from 'semantic-ui-react'

import ApolloErrorButton from '../../components/ApolloErrorButton'
import MonthSelector from '../../components/MonthSelector'
import ShiftPlan from '../../components/shift-planner2/shift-plan'
import CaregiverQualificationDropdown from '../../components/CaregiverQualificationDropdown'

import styles from './index.module.css'

class ShiftPlanner2 extends React.Component {
  state = {
    plannedMonthFirstDayDate: dayjs().startOf('month').startOf('day'),
    selectedCaregiverQualification: null,
  }

  render = () => {
    return (
      <div className={styles.ShiftPlanner}>
        <div className={styles.Toolbar}>
          <CaregiverQualificationDropdown
            className={styles.CaregiverQualificationDropdown}
            nullText='Alle Qualifikationen'
            onSelect={data => this.setState({ selectedCaregiverQualification: data })}
            selection={this.state.selectedCaregiverQualification}
          />
          <MonthSelector
            onClickPreviousMonth={() => this.setState(prevState => ({
              plannedMonthFirstDayDate: dayjs(prevState.plannedMonthFirstDayDate).subtract(1, 'month')
            }))}
            onClickNextMonth={() => this.setState(prevState => ({
              plannedMonthFirstDayDate: dayjs(prevState.plannedMonthFirstDayDate).add(1, 'month')
            }))}
            monthDate={this.state.plannedMonthFirstDayDate}
          />
        </div>
        {/* TODO: `ShiftPlan` should load all queries, not this component */}
        <Query
          query={GET_CAREGIVERS}
          variables={{
            canBeAssignedShifts: true,
            caregiverQualification: this.state.selectedCaregiverQualification,
            isActive: true,
            stationId: this.props.match.params.stationId,
          }}
        >
          {({ data }) => {
            const caregivers = data && data.caregivers
            return <Query
              query={GET_SHIFT_KINDS} variables={{ stationId: this.props.match.params.stationId }}
            >
              {({ data }) => {
                const shiftKinds = data && data.shiftKinds
                return <Query
                  query={GET_SHIFTS}
                  variables={{
                    stationId: this.props.match.params.stationId,
                    startFromDatetime: this.state.plannedMonthFirstDayDate.format(),
                    endBeforeDatetime:
                      this.state.plannedMonthFirstDayDate.add(1, 'month').add(1, 'hour').format(),
                  }}
                >
                  {({ data, error, loading, refetch }) =>
                    <Dimmer.Dimmable dimmed={loading}>
                      <Dimmer active={loading} inverted><Loader /></Dimmer>
                      {error
                        ? <ApolloErrorButton error={error} />
                        : <ShiftPlan
                          caregivers={caregivers ? sortedCaregivers(caregivers) : []}
                          months={1}
                          onChangeShifts={refetch}
                          shiftKinds={shiftKinds || []}
                          shifts={(data && data.shifts) || []}
                          startDate={this.state.plannedMonthFirstDayDate}
                          stationId={this.props.match.params.stationId}
                        />
                      }
                    </Dimmer.Dimmable>
                  }
                </Query>
              }}
            </Query>
          }}
        </Query>
      </div>
    )
  }
}

const sortedCaregivers = caregivers => _.orderBy(caregivers, ['user.lastName', 'user.firstName'])

const GET_CAREGIVERS = gql`
  query getCaregivers(
    $id: String
    $canBeAssignedShifts: Boolean
    $caregiverQualification: CaregiverQualification
    $isActive: Boolean
    $stationId: Int
  ) {
    caregivers(
      id: $id
      canBeAssignedShifts: $canBeAssignedShifts
      caregiverQualification: $caregiverQualification
      isActive: $isActive
      stationId: $stationId
    ) {
      canBeAssignedShifts
      id
      qualificationGroup
      user {
        firstName
        isActive
        lastName
      }
    }
  }
`

const GET_SHIFT_KINDS = gql`
  query getShiftKinds ($id: String, $stationId: Int) {
    shiftKinds (id: $id, stationId: $stationId) {
      abbreviation
      id
      isActive
      isForDay0
      isForDay1
      isForDay2
      isForDay3
      isForDay4
      isForDay5
      isForDay6
      name
      qualificationGroup
      shiftTime {
        id
        name
      }
      station {
        id
      }
    }
  }
`

const GET_SHIFTS = gql`
  query getShifts (
    $caregiverId: String, $stationId: Int, $startFromDatetime: DateTime, $endBeforeDatetime: DateTime
  ) {
    shifts (
      caregiverId: $caregiverId
      stationId: $stationId
      startFromDatetime: $startFromDatetime
      endBeforeDatetime: $endBeforeDatetime
    ) {
      abbreviation
      caregiver {
        id
      }
      endDatetime
      id
      name
      plannedTour {
        id
        name
      }
      shiftKind {
        abbreviation
        id
        name
      }
      startDatetime
    }
  }
`

export default ShiftPlanner2
