import React from 'react'
import { Button, Image } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import Logo from '../images/talea-logo-white.png'

import styles from './index.module.css'

const LoginScreen = ({ auth }) => (
  <div className={styles.LoginScreen}>
    <div className={styles.LoginForm}>
      <div className={styles.MarketingContainer}>
        <Image src={Logo} className={styles.TaleaLogo} />
        <div className={styles.MarketingText}>
          <h2>Die Pflege der Zukunft</h2>
          <p>Jahrelange Erfahrung trifft auf innovative Ansätze.</p>
        </div>
      </div>
      <div className={styles.LoginContainer}>
        <div className={styles.LoginWrapper}>
          <h3>MyTalea Plattform für Pflegedienst-Partner</h3>
          <Button className={styles.LoginButton} color='blue' fluid size='large' onClick={() => auth.login()}>Login</Button>
        </div>
      </div>
    </div>
  </div>
)

LoginScreen.propTypes = {
  auth: PropTypes.object.isRequired,
}

export default LoginScreen
