import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { getAccessToken } from './auth'

const api = process.env.REACT_APP_API_URL

//TODO according to the docs we should query the schema during build to get the possible types
const introspectionQueryResultData = {
  __schema: {
    types: [{
      "kind": "INTERFACE",
      "name": "TourOptimizerActivityType",
      "possibleTypes": [
        { "name": "TourOptimizerBreakType" },
        { "name": "TourOptimizerVisitType" }
      ]
    }]
  }
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const apolloClient = () => new ApolloClient({
  link: new HttpLink({
    uri: `${api}/graphql`,
    headers: { Authorization: `Bearer ${getAccessToken()}` },
    credentials: 'same-origin',
  }),
  cache: new InMemoryCache({ fragmentMatcher }),
  connectToDevTools: true,
})

export default apolloClient
