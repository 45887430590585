import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import styles from './MenuWithDropdown.module.css'
import { hasPermission } from '../../../utils/auth'

function MenuWithDropdown({ locationPathname, menuItem, loggedInUserPermissions }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button className={styles.MenuWithDropdown}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        {menuItem.label} <ExpandMoreIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menuItem.children.map((child, index) => hasPermission(child.perform) &&
          <MenuItem component={Link} key={index} onClick={handleClose} to={`/${child.path}`}>
            {child.label}
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default MenuWithDropdown
