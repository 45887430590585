import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { Button, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import { filterMessagesByClientId, getLocationParameter } from '../../utils/helpers'
import { createMessage, getMessages, getUsersAndClients } from '../../actions'

import PushNotification from '../../components/PushNotification'
import ShowTaskModal from '../../components/taskmanager/ShowTaskModal'
import ComposeMessage from '../../components/taskmanager/ComposeMessage'
import ClientInfo from '../../components/taskmanager/clientpage/ClientInfo'
import ClientSettings from '../../components/client-settings'
import ClientMessages from './ClientMessages'

import * as API from '../../utils/API'
import './ClientPage.css'


class ClientPage extends React.Component {
  state = {
    categoryFilter: 'all',
    isModalOpened: false,
    client: null,
    clientMessages: null,
    isSettingView: false,
  }

  componentDidMount() {
    this.getClientDetails(this.props.match.params.clientId)
    const params = getLocationParameter(this.props.location, 'view')
    if (params === 'settings') {
      this.setState({ isSettingView: true })
    }
    if (this.props.messageIdToMessage) {
      this.setState({
        clientMessages: filterMessagesByClientId(_.values(this.props.messageIdToMessage || {}),
          this.props.match.params.clientId)
      })
    }
  }

  getClientDetails = clientId => {
    API.fetchClientDetails(clientId).then(response => {
      this.setState({ client: response.clients[0] })
    })
  }

  render = () => {
    return (
      <div className="ClientPage">
        <Route path='*' component={PushNotification} />
        <div className="LeftSidebar">
          {this.state.client && <ClientInfo client={this.state.client} />}
        </div>
        <div className="MainArea">
          <Button.Group widths='2'>
            <Button
              onClick={() => this.setState({ isSettingView: false })}
              active={this.state.isSettingView ? false : true}
            >
              Aufgabe
            </Button>
            <Button
              onClick={() => this.setState({ isSettingView: true })}
              active={this.state.isSettingView ? true : false}
            >
              Besonderheiten
            </Button>
          </Button.Group>
          {
            !this.state.isSettingView && this.state.clientMessages && this.props.clientIdToClient && (
              <ClientMessages
                clientId={this.props.match.params.clientId}
                clientMessages={this.state.clientMessages}
                clientIdToClient={this.props.clientIdToClient}
                loggedInUserId={this.props.loggedInUserId}
                userIdToUser={this.props.userIdToUser}
              />
            )
          }
          {!this.state.isSettingView && !this.state.clientMessages && <Loader active />}
          {
            this.state.isSettingView && this.state.client && (
              <ClientSettings
                clientId={this.props.match.params.clientId}
                client={this.state.client}
                getClientDetails={this.getClientDetails}
              />
            )
          }
        </div>
        {this.state.isModalOpened &&
          <ShowTaskModal
            isOpened={this.state.isModalOpened}
            onCloseDialog={this.handleTaskModal}
          />
        }
        {!this.state.isSettingView &&
          <ComposeMessage
            userIdToUser={this.props.userIdToUser}
            client={this.props.clientIdToClient &&
              this.props.clientIdToClient[this.props.match.params.clientId]
            }
            createMessage={this.props.createMessage}
          />
        }

      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentMessageId !== this.props.currentMessageId) {
      this.setState({
        isModalOpened: true,
      })
    }
    if (!this.props.isMessagesFetched || prevProps.isMessagesFetched !== this.props.isMessagesFetched) {
      this.props.fetchMessages()
    }
    if (!this.props.isClientsFetched || prevProps.isClientsFetched !== this.props.isClientsFetched) {
      this.props.fetchUsersAndClients()
    }
    if (prevProps.messageIdToMessage !== this.props.messageIdToMessage) {
      this.setState({
        clientMessages: filterMessagesByClientId(_.values(this.props.messageIdToMessage || {}),
          this.props.match.params.clientId)
      })
    }
  }

  handleTaskModal = () => this.setState({ isModalOpened: !this.state.isModalOpened })
}

const mapStateToProps = globalState => ({
  clientIdToClient: globalState.chat.clientIdToClient,
  isClientsFetched: globalState.chat.isClientsFetched,
  userIdToUser: globalState.chat.userIdToUser,
  messageIdToMessage: globalState.chat.messageIdToMessage,
  isMessagesFetched: globalState.chat.isMessagesFetched,
  currentMessageId: globalState.chat.currentMessageId,
  loggedInUserId: globalState.auth.loggedInUser.data['http://api.talea.de/uuid'],
})

const mapDispatchToProps = dispatch => ({
  fetchMessages: () => dispatch(getMessages()),
  fetchUsersAndClients: () => dispatch(getUsersAndClients()),
  createMessage: data => {
    dispatch(createMessage(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientPage)
