import React from 'react'

import _ from 'lodash'
import { Link } from 'react-router-dom'
import { compose, graphql, Mutation } from 'react-apollo'
import { Button, Loader } from 'semantic-ui-react'

import { editingDisabledStationIds } from '../../../constants'
import { GET_SHIFT_KINDS, GET_SHIFT_TIMES } from '../../../constants/queries'
import { CREATE_SHIFT_KIND } from '../../../constants/mutations'
import { orderedShiftKinds } from '../../../utils/helpers'
import Placeholder from '../Placeholder'
import Shift from './Shift'

import './index.css'

class Shifts extends React.Component {
  state = {
    createdShiftKindId: null,
  }

  render = () => (
    <div className="Shifts">
      <Loader active={this.props.getShiftKinds.loading || this.props.getShiftTimes.loading} />
      <div className="Headers">
        {headers.map((header, index) => <div key={index}>{header}</div>)}
      </div>
      <div className="ShiftsList">
        {(this.props.getShiftKinds.shiftKinds && this.props.getShiftKinds.shiftKinds.length > 0)
          ? [
            ...orderedShiftKinds(this.props.getShiftKinds.shiftKinds.filter(s => s.name)),
            ...this.props.getShiftKinds.shiftKinds.filter(s => s.isActive && !s.name),
          ].map((shiftKind, index, shiftKinds) =>
            <Shift
              classNames={this.classNames(shiftKinds, shiftKind)}
              isCreated={this.state.createdShiftKindId === shiftKind.id}
              isEditable={!editingDisabledStationIds.includes(this.props.match.params.stationId)}
              key={shiftKind.id}
              shiftKind={shiftKind}
              shiftTimes={filteredShiftTimes(this.props.getShiftTimes.shiftTimes)}
            />
          )
          : !this.props.getShiftKinds.loading && <Placeholder
            header={'Keine Dienste vorhanden'}
            subheader={'Bitte fügen Sie einen Dienst hinzu'}
          />
        }
        {this.props.getShiftTimes && this.props.getShiftTimes.shiftTimes && this.renderCreateShiftKindButton(
          (filteredShiftTimes(this.props.getShiftTimes.shiftTimes).length > 0)
            ? filteredShiftTimes(this.props.getShiftTimes.shiftTimes)[0].id
            : null
        )}
      </div>
      <Link to={`/shift-planning/${this.props.match.params.stationId}/caregivers`}>
        <Button primary>Weiter</Button>
      </Link>
    </div>
  )

  classNames = (shiftKinds, shiftKind) => ([
    'Shift',
    ((shiftKind === _.findLast(
      shiftKinds,
      s => s.isActive && s.qualificationGroup === 'QualificationGroup.QUALIFIED_CAREGIVER'
    )) || (shiftKind === _.findLast(shiftKinds, s => s.isActive))) &&
      'LastQualifiedShift',
    !editingDisabledStationIds.includes(this.props.match.params.stationId) && 'isEditable',
  ].filter(Boolean).join(' '))

  renderCreateShiftKindButton = shiftTimeId => (
    <Mutation
      mutation={CREATE_SHIFT_KIND}
      variables={{
        abbreviation: '',
        isActive: true,
        isForDay0: false,
        isForDay1: false,
        isForDay2: false,
        isForDay3: false,
        isForDay4: false,
        isForDay5: false,
        isForDay6: false,
        name: '',
        qualificationGroup: 'QUALIFIED_CAREGIVER',
        shiftTimeId: shiftTimeId,
        stationId: this.props.match.params.stationId,
      }}
      refetchQueries={['getShiftKinds']}
      onCompleted={data => this.setState({
        createdShiftKindId: data.createShiftKind.shiftKind.id
      })}
    >
      {createShiftKind =>
        <Button
          disabled={!(shiftTimeId) || editingDisabledStationIds.includes(this.props.match.params.stationId)}
          onClick={createShiftKind}
          primary
        >
          Dienst hinzufügen
        </Button>
      }
    </Mutation>
  )
}

// TODO filter directly in the GraphQl query
const filteredShiftTimes = shiftTimes => shiftTimes
  ? shiftTimes.filter(shiftTime => shiftTime.name === 'Früh' || shiftTime.name === 'Spät')
  : []

const headers = [
  '',
  'Dienst',
  'Abkürzung',
  'Früh/Spät',
  'Qualifikation',
  'Mo.',
  'Di.',
  'Mi.',
  'Do.',
  'Fr.',
  'Sa.',
  'So.',
]

export default compose(
  graphql(GET_SHIFT_KINDS, {
    name: 'getShiftKinds',
    options: ({ match }) => ({ variables: { stationId: match.params.stationId } })
  }),
  graphql(GET_SHIFT_TIMES, {
    name: 'getShiftTimes'
  })
)(Shifts)
