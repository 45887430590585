import React, { forwardRef, useMemo, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import _ from 'lodash'
import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import gql from 'graphql-tag'
import MaterialTable from 'material-table'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { useQuery } from '@apollo/react-hooks'

import ApolloErrorButton from '../../components/ApolloErrorButton'
import { caregiverQualificationToLabel, tourOrderStatusToStatusLabel } from '../../constants'
import { format, parse } from 'date-fns'
import { useEffectAfterRender } from '../../hooks'

import styles from './TourOrdersTable.module.css'

function TourOrdersTable({ history, isTaleaUser, onSelectionChange, refetchHash, tableName }) {
  const [previouslyLoadedTourOrders, setPreviouslyLoadedTourOrders] = useState(new Set())
  const { error: tourOrdersError, loading: isLoadingTourOrders, data, refetch } = useQuery(
    tourOrdersQuery(isTaleaUser),
    {
      onCompleted: ({ tourOrders }) => {
        // When a different set of tour orders is loaded, we need to reset the selection as otherwise not
        // visible tour orders are still being remembered as selected.
        const tourOrderSet = new Set(tourOrders)
        if (!_.isEqual(tourOrderSet, previouslyLoadedTourOrders)) {
          setPreviouslyLoadedTourOrders(tourOrderSet)
          onSelectionChange([])
        }
      }
    }
  )
  const { error: stationsError, loading: isLoadingStations, data: stationsData } = useQuery(STATIONS_QUERY)
  const isLoading = isLoadingTourOrders || isLoadingStations
  const tourOrders = data && data.tourOrders
  const stations = useMemo(
    () => stationsData && stationsData.stations && _.orderBy(stationsData.stations, ['name']), [stationsData]
  )
  // Todo: Try giving dynamic height
  const maxBodyHeight = window.innerHeight - 300
  const tableRows = tourOrders && tourOrders.map(tourOrder => ({
    caregiverQualification: tourOrder.requiredCaregiverQualification,
    code: tourOrder.code,
    creationDate: format(Date.parse(tourOrder.createdAtDatetime), 'dd.MM.yyyy'),
    id: tourOrder.id,
    partnerStationName: tourOrder.partnerStation.name,
    plannedTourDate: Date.parse(tourOrder.plannedTour.startDatetime),
    plannedTourDateString: format(Date.parse(tourOrder.plannedTour.startDatetime), 'dd.MM.yyyy'),
    scheduling: tourOrder.hasAllPlannedVisitsScheduled
      ? 'all'
      : (tourOrder.hasAnyPlannedVisitScheduled ? 'partially' : 'none'),
    startEndTime: [
      tourOrder.plannedTour.startDatetime, tourOrder.plannedTour.endDatetime
    ].map(d => format(Date.parse(d), 'HH:mm')).join('-'),
    status: tourOrder.status.replace('TourOrderStatus.', ''),
    url: `/tour-orders/${tourOrder.id}`,
  }))
  useEffectAfterRender(refetch, [refetchHash])
  if (tourOrdersError || stationsError)
    return <ApolloErrorButton error={tourOrdersError || stationsError} />
  return <div className={styles.TableContainer}><MaterialTable
    columns={[
      {
        customFilterAndSearch: (term, row) => {
          const fromDate = parse(term, 'd.M.yyyy', new Date())
          return fromDate && (fromDate <= row.plannedTourDate)
        },
        customSort: (a, b) => a.plannedTourDate - b.plannedTourDate,
        defaultFilter: format(new Date(), 'dd.MM.yyyy'),
        defaultSort: 'asc',
        field: "plannedTourDateString",
        title: "Einsatzdatum",
      },
      {
        field: "partnerStationName",
        lookup: (stations || []).reduce(
          (lookup, station) => ({ ...lookup, [station.name]: station.name }), {}
        ),
        title: "Partner",
      },
      { field: "startEndTime", title: "Einsatzzeitraum" },
      { field: "code", title: "Auftragsnummer" },
      { field: "creationDate", title: "Anfragedatum" },
      {
        field: "caregiverQualification",
        lookup: caregiverQualificationToLabel,
        title: "Qualifikationen",
      },
      {
        field: "status",
        lookup: tourOrderStatusToStatusLabel,
        title: "Status",
      },
      {
        field: "scheduling",
        lookup: {
          'all': 'Alle',
          'partially': 'Teil',
          'none': 'Ohne',
        },
        title: "Plan",
      },
    ].map(c => ({
      render: row => <Link to={row.url}>{c.lookup ? c.lookup[row[c.field]] : row[c.field]}</Link>, ...c
    }))}
    data={tableRows}
    icons={tableIcons}
    isLoading={isLoading}
    onSelectionChange={tourOrders => onSelectionChange(tourOrders.map(o => o.id))}
    options={{
      filtering: true,
      maxBodyHeight: maxBodyHeight + 'px',
      paging: false,
      search: false,
      selection: true,
    }}
    title="Aufträge"
  /></div>
}

const STATIONS_QUERY = gql`query stations { stations { id name } }`

const tourOrdersQuery = isTaleaUser => gql`
  query tourOrders(
    $plannedTourBeforeStartDate: Date,
    $plannedTourFromStartDate: Date,
    $requiredCaregiverQualifications: [CaregiverQualification],
    $stationId: Int,
    $statuses: [TourOrderStatus]
  ) {
    tourOrders(
      plannedTourBeforeStartDate: $plannedTourBeforeStartDate
      plannedTourFromStartDate: $plannedTourFromStartDate
      requiredCaregiverQualifications: $requiredCaregiverQualifications
      stationId: $stationId
      statuses: $statuses
    ) {
      code
      createdAtDatetime
      ${isTaleaUser ? 'hasAllPlannedVisitsScheduled' : ''}
      ${isTaleaUser ? 'hasAnyPlannedVisitScheduled' : ''}
      id
      partnerStation {
        id
        name
      }
      plannedTour {
        endDatetime
        id
        name
        startDatetime
      }
      requiredCaregiverQualification
      status
    }
  }
`

const tableIcons = [
  ['Add', AddBox],
  ['Check', Check],
  ['Clear', Clear],
  ['Delete', DeleteOutline],
  ['DetailPanel', ChevronRight],
  ['Edit', Edit],
  ['Export', SaveAlt],
  ['Filter', FilterList],
  ['ResetSearch', Clear],
  ['Search', Search],
  ['SortArrow', ArrowUpward],
  ['ThirdStateCheck', Remove],
  ['ViewColumn', ViewColumn],
].reduce((r, [name, Component]) => (
  { ...r, [name]: forwardRef((props, ref) => <Component {...props} ref={ref} />) }
), {})

export default withRouter(TourOrdersTable)
