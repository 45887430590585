import pluralize from 'pluralize'

const initPluralize = () => {
  pluralize.addPluralRule(/Einsatz$/i, 'Einsätze')
  pluralize.addPluralRule(/Station$/i, 'Stationen')
  pluralize.addPluralRule(/Tour$/i, 'Touren')
  pluralize.addPluralRule(/wird$/i, 'werden')
}

export default initPluralize
