import { useContext, useEffect, useReducer, useState } from 'react'

import _ from 'lodash'
import { getApolloContext } from '@apollo/react-common'

import { useDeepCompareEffect } from '.'

export const useMergeQuery = (query, options = {}) => {
  const client = useContext(getApolloContext()).client
  if (!client.queryMerger) client.queryMerger = new QueryMerger(client)
  // TODO: `refetch` should overwrite variables, see: https://www.apollographql.com/docs/react/api/react-hooks
  const [refetchTick, refetch] = useReducer(t => t + 1, 0)
  const [result, setResult] = useState({
    called: false, client, data: null, error: null, loading: true, networkStatus: null, refetch: refetch
  })
  useDeepCompareEffect(
    () => {
      if (!result.loading) setResult({ ...result, loading: true })
      client.queryMerger.query(query, options, refetch, setResult)
    },
    [{ ...options, onError: undefined, onCompleted: undefined, unmerge: undefined }, refetchTick]
  )
  useEffect(() => () => client.queryMerger.removeQuery(query, setResult), [])
  return result
}

class QueryMerger {
  constructor(client) {
    this.client = client
    this.queryBodyToDebouncer = {}
  }

  query(query, options, refetch, setResult) {
    const queryBody = query.loc.source.body + JSON.stringify(
      Object.assign({}, options.variables, { [options.mergeVariable]: undefined })
    )
    const debouncer = (
      this.queryBodyToDebouncer[queryBody] ||
      (this.queryBodyToDebouncer[queryBody] = {
        queuedQueries: [],
        debouncedQuery: _.debounce(() => {
          const queries = this.queryBodyToDebouncer[queryBody].queuedQueries
          this.queryBodyToDebouncer[queryBody] = undefined
          const variables = {
            ...queries[0].options.variables,
            ...(options.mergeVariable
              ? { [options.mergeVariable]: _.union(
                queries.flatMap(q => q.options.variables[options.mergeVariable])
              ) }
              : {}
            ),
          }
          this.client
            .query({ query, ...{ ...options, variables } })
            .then(r => queries.forEach(q => q.setResult({
              called: false, // only used for lazy querying
              client: this.client,
              data: !!q.options.unmerge ? q.options.unmerge(r.data, q.options.variables) : r.data,
              error: null,
              loading: false,
              networkStatus: r.networkStatus,
              refetch: q.refetch,
            })))
            .catch(error => queries.forEach(q => q.setResult({
              called: false,
              client: this.client,
              data: null,
              error,
              loading: false,
              networkStatus: null,
              refetch: q.refetch,
            })))
        }, 100)
      })
    )
    debouncer.queuedQueries.push({ query, options, refetch, setResult })
    debouncer.debouncedQuery()
  }

  removeQuery(query, setResult) {
    // TODO: Implement
    // When all queries are removed, stop debouncer, cancel query
  }
}
