import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import gql from 'graphql-tag'
import pluralize from 'pluralize'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { useMutation } from '@apollo/react-hooks'

import ApolloErrorButton from '../ApolloErrorButton'

const PlannedTourDeletionDialog = ({ onClose, onDelete, plannedTourIds }) => {
  const [result, setResult] = useState(null)
  const [deletePlannedTours, { error, loading }] = useMutation(
    DELETE_PLANNED_TOURS,
    {
      onCompleted: ({ deletePlannedTours }) => {
        setResult(deletePlannedTours)
        onDelete(deletePlannedTours.deletedPlannedTours, deletePlannedTours.unassignedPlannedVisits)
      },
      variables: { ids: plannedTourIds },
    }
  )
  const close = () => !loading && onClose && onClose()
  return (
    <Dialog onClose={close} open>
      <DialogTitle>{pluralize("Tour", plannedTourIds.length, true)} löschen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Du kannst hier die selektierten Touren löschen. Davon betroffene Einsätze zeigen wir dir nach dem
          Löschen der Touren wieder als unverplante Einsätze an.
        </DialogContentText>
      </DialogContent>
      <List>
        <ListItem button disabled={loading || !!result} onClick={() => deletePlannedTours()}>
          <ListItemText>Ausgewählte Touren löschen</ListItemText>
        </ListItem>
        <ListItem
          button
          disabled={loading || !!result}
          onClick={() => deletePlannedTours(
            { variables: { shouldAlsoDeleteFuturePlannedToursOfSamePlannedTourGroups: true } }
          )}
        >
          <ListItemText secondary="Nur für Touren relevant die als Teil einer Tourgruppe erstellt wurden">
            Ausgewählte Touren inklusive zukünftiger Touren der gleichen Tourgruppe löschen
          </ListItemText>
        </ListItem>
      </List>
      {loading && <LinearProgress />}
      {error && <ApolloErrorButton error={error} />}
      {result && <DialogContent>
        <Typography>
          Ergebnis: {pluralize("Tour", result.deletedPlannedTours.length, true)} wurden gelöscht,&nbsp;
          {pluralize("Einsatz", result.unassignedPlannedVisits.length, true)} sind wieder unverplant.
        </Typography>
      </DialogContent>}
      <DialogActions>
        <Button color="secondary" disabled={loading} onClick={close}>Schließen</Button>
      </DialogActions>
    </Dialog>
  )
}

const DELETE_PLANNED_TOURS = gql`
  mutation deletePlannedTours(
    $ids: [String]!
    $shouldAlsoDeleteFuturePlannedToursOfSamePlannedTourGroups: Boolean
  ) {
    deletePlannedTours(
      ids: $ids
      shouldAlsoDeleteFuturePlannedToursOfSamePlannedTourGroups:
        $shouldAlsoDeleteFuturePlannedToursOfSamePlannedTourGroups
    ) {
      deletedPlannedTours { id }
      unassignedPlannedVisits { id }
    }
  }
`

export default PlannedTourDeletionDialog
