import Client from 'twilio-chat'
import * as types from '../constants/'
import * as API from '../utils/API'
import { decrypt } from '../utils/encryption'
import { convertArrayToObject } from '../utils/helpers'
import moment from 'moment/moment'

let twilioClient = {}

const startCreateOrUpdateRequest = () => ({
  type: types.FETCH_REQUEST,
})

const fetchTwilioRequest = () => ({
  type: types.FETCH_TWILIO_LOGIN_REQUEST,
})

const fetchTwilioError = () => ({
  type: types.REQUEST_TWILIO_LOGIN_FAILURE,
})

const messageDecrytError = () => ({
  type: types.MESSAGE_DECRYPTION_FAILURE,
})

const authenticateWithTwilioSuccess = payload => ({
  type: types.REQUEST_TWILIO_LOGIN_SUCCESS,
  payload,
})

export const fetchGraphqlUsersSuccess = payload => ({
  type: types.FETCH_GRAPHQL_USERS_SUCCESS,
  payload,
})

export const fetchGraphqlClientsSuccess = payload => ({
  type: types.FETCH_GRAPHQL_CLIENTS_SUCCESS,
  payload,
})

export const fetchGraphqlFailure = payload => ({
  type: types.FETCH_GRAPHQL_FAILURE,
  payload,
})

export const twilioClientConnectionFailure = payload => ({
  type: types.TWILIO_CLIENT_CONNECTION_FAILURE,
  payload,
})

export const twilioClientIsConnecting = payload => ({
  type: types.TWILIO_CLIENT_IS_CONNECTING,
  payload,
})

export const twilioClientIsConnected = payload => ({
  type: types.TWILIO_CLIENT_IS_CONNECTED,
  payload,
})

const fetchMessagesSuccess = payload => ({
  type: types.FETCH_MESSAGES_SUCCESS,
  payload,
})

export const setCurrentMessageId = payload => ({
  type: types.SET_CURRENT_MESSAGE_ID,
  payload,
})

const addNewMessageSuccess = payload => ({
  type: types.ADD_MESSAGE_SUCCESS,
  payload,
})

const updateMessageSuccess = payload => ({
  type: types.UPDATE_MESSAGE_SUCCESS,
  payload,
})

const addNewSubmessageSuccess = payload => ({
  type: types.ADD_SUBMESSAGE_SUCCESS,
  payload,
})

const updateSubmessageSuccess = payload => ({
  type: types.UPDATE_SUBMESSAGE_SUCCESS,
  payload,
})

const sendPushNotification = payload => ({
  type: types.SEND_PUSH_NOTIFICATION,
  payload
})

export const initTaskManagement = () => dispatch => {
  dispatch(getUsersAndClients())
  dispatch(authenticateWithTwilio())
}

//TODO: Users and Clients should be separated into 2 different queries
export const getUsersAndClients = () => dispatch => {
  API.fetchUsersAndClients()
    .then(response => {
      dispatch(fetchGraphqlUsersSuccess(convertArrayToObject(response.users)))
      dispatch(fetchGraphqlClientsSuccess(convertArrayToObject(response.clients)))
      dispatch(getMessages())
    })
    .catch(error => {
      dispatch(fetchGraphqlFailure())
      console.error('getUsersAndClients:', error)
    })
}

export const getMessages = () => dispatch => {
  API.fetchMessages()
    .then(res => {
      dispatch(fetchMessagesSuccess(res.messages))
    })
    .catch(error => {
      dispatch(fetchGraphqlFailure())
      console.error('getMessages:', error)
    })
}

const authenticateWithTwilio = () => dispatch => {
  dispatch(fetchTwilioRequest())
  dispatch(fetchTwilioAccessToken(subscribeTwilioEvents))
}

const fetchTwilioAccessToken = handler => dispatch => {
  API.getTwilioToken()
    .then(tokenResponse => {
      localStorage.setItem('encryptionKey', tokenResponse.encryptionKey)
      dispatch(authenticateWithTwilioSuccess(tokenResponse))
      dispatch(handler(tokenResponse))
    })
    .catch(error => {
      dispatch(fetchTwilioError())
      console.error('getTwilioToken error', error)
    })
}

const subscribeTwilioEvents = tokenResponse => async dispatch => {
  twilioClient = await Client.create(tokenResponse.accessToken)
  twilioClient.getUserChannelDescriptors()
    .then(paginator => {
      paginator.items.forEach(channelDescriptor => {
        dispatch(subscribeToChannelEvents(channelDescriptor))
      })
    })
    .catch(error => {
      console.error('getUserChannelDescriptors:', error)
    })

  twilioClient.on('tokenAboutToExpire', () => {
    dispatch(refreshTwilioToken())
  })

  twilioClient.on('tokenExpired', () => {
    dispatch(refreshTwilioToken())
  })

  // TODO: Might need to refactor these code. Now I only considered disconnected state.
  // For more: https://media.twiliocdn.com/sdk/js/chat/releases/3.0.0/docs/Client.html#event:connectionStateChanged__anchor
  twilioClient.on('connectionStateChanged', connectionState => {
    (connectionState => (connectionState === 'connected')
      ? dispatch(twilioClientIsConnected(connectionState))
      : (connectionState === 'connecting')
        ? dispatch(twilioClientIsConnecting(connectionState))
        : dispatch(twilioClientConnectionFailure(connectionState))
    )(connectionState)
  })
}

const subscribeToChannelEvents = channelDescriptor => dispatch => {
  channelDescriptor.getChannel().then(channel => {
    channel.on('updated', response => {
      if (response.updateReasons.indexOf('lastMessage') < 0) return
      let newMessage = response.channel.messagesEntity.messagesByIndex.get(response.channel.state.lastMessage.index)
      if (!newMessage) return
      decrypt(newMessage.state.attributes.encryptedMessage, localStorage.encryptionKey)
        .then(response => {
          let decryptedMessage = JSON.parse(response)
          if (!newMessage) return
          dispatch(({
            'create': decryptedMessage.parentMessageId ? handleNewSubMessage : handleNewMessage,
            'update': decryptedMessage.parentMessageId ? handleSubMessageUpdate : handleMessageUpdate,
          }[newMessage.state.attributes.type])(decryptedMessage))
        })
        .catch(errorMessage => {
          dispatch(messageDecrytError())
          console.error('decryptedMessage error:', errorMessage)
        })
    })
  }).catch(error => console.error('channelDescriptor', error))
}

const handleNewMessage = message => dispatch => {
  dispatch(addNewMessageSuccess(message))
  dispatch(sendPushNotification({
    isIgnored: false,
    title: "Neue Nachricht empfangen",
    message,
  }))
}

const handleNewSubMessage = message => dispatch => {
  dispatch(addNewSubmessageSuccess(message))
  dispatch(sendPushNotification({
    isIgnored: false,
    title: "Neuer Kommentar empfangen",
    message,
  }))
}

const handleMessageUpdate = message => dispatch => {
  dispatch(updateMessageSuccess(message))
  dispatch(sendPushNotification({
    isIgnored: false,
    title: "Nachricht aktualisiert",
    message,
  }))
}

const handleSubMessageUpdate = message => dispatch => {
  dispatch(updateSubmessageSuccess(message))
  dispatch(sendPushNotification({
    isIgnored: false,
    title: "Kommentar aktualisiert",
    message,
  }))
}

const refreshTwilioToken = () => dispatch => {
  dispatch(fetchTwilioAccessToken(setNewTwilioToken))
}

const setNewTwilioToken = tokenResponse => {
  twilioClient.updateToken(tokenResponse.accessToken)
}

export const updateMessage = data => dispatch => {
  dispatch(startCreateOrUpdateRequest())
  API.updateMessage({ ...data, lastUpdatedTimestamp: moment().format() })
    .then(response => (message => {
      dispatch(sendPushNotification({
        isIgnored: true,
        title: "GraphQL updated message",
        message: message,
      }))
      message.parentMessageId
        ? dispatch(updateSubmessageSuccess(message))
        : dispatch(updateMessageSuccess(message))
    })(response.updateMessage.message))
    .catch(error => {
      dispatch(fetchGraphqlFailure())
      console.error('API.updateMessage:', error)
    })
}

export const createMessage = (data, isParentMessage) => dispatch => {
  dispatch(startCreateOrUpdateRequest())
  API.createMessage({ ...data, timestamp: moment().format() })
    .then(response => (message => {
      if (isParentMessage) {
        dispatch(setCurrentMessageId(response.createMessage.message.id))
      }
      dispatch(sendPushNotification({
        isIgnored: true,
        title: "graphql nachricht empfangen",
        message: message,
      }))
      message.parentMessageId
        ? dispatch(addNewSubmessageSuccess(message))
        : dispatch(addNewMessageSuccess(message))
    })(response.createMessage.message)
    )
    .catch(error => {
      dispatch(fetchGraphqlFailure())
      console.error('API.createMessage:', error)
    })
}
