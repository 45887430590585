import React, { useMemo, useState } from "react"

import { useQuery } from '@apollo/react-hooks'
import GoogleMap from 'google-map-react'
import _ from "lodash"

import AddressSearchBar from '../AddressSearchBar'
import ClientMapMarker from '../maps/ClientMapMarker'
import config from '../../constants/config'
import MapMarker from '../MapMarker'
import PLANNED_TOURS_QUERY from './plannedToursQuery.js'
import TourPath from '../maps/TourPath'
import { useEffectAfterRender } from '../../hooks'
import { PlannedVisit } from './PlannedActivity.js'

import mapOptions from './Map.options.json'
import styles from './Map.module.css'

const Map = ({ className, ...rest }) => {
  const [places, setPlaces] = useState([])
  return (
    <div className={[styles.Map, className].filter(Boolean).join(' ')}>
      <AddressSearchBar
        className={styles.AddressSearchBar}
        onSelect={place => setPlaces([...places, place])}
        placeholder="Adresse eingeben und mit Enter bestätigen"
        preferredLocality="Berlin"
        shouldHidePlaceholderOnBlur={true}
      />
      <InternalMap
        className={styles.InternalMap}
        onDeletePlace={place => setPlaces(places.filter(p => p !== place))}
        places={places}
        {...rest}
      />
    </div>
  )
}

const InternalMap = ({
  className,
  nearbyPlannedVisits,
  onDeletePlace,
  places,
  plannedTourIds,
  plannedTourIdToColor,
  plannedTourIdToRefetchHash,
  selectedPlannedActivityIds,
}) => {
  // TODO: Query error handling
  const { data, refetch } = useQuery(
    PLANNED_TOURS_QUERY, { fetchPolicy: 'network-only', variables: { ids: plannedTourIds } }
  )
  const plannedTours = (data && data.plannedTours) || []
  const [view, setView] = useState({
    bounds: {
      ne: { lat: 0, lng: 0 },
      nw: { lat: 0, lng: 0 },
      se: { lat: 0, lng: 0 },
      sw: { lat: 0, lng: 0 },
    },
    center: { lat: 52.5192, lng: 13.4061 },
    marginBounds: {},
    size: { width: 0, height: 0 },
    zoom: 11,
  })
  const [focusedPlannedActivityId, setFocusedPlannedActivityId] = useState(null)
  const createMapOptions = useMemo(() => () => mapOptions, [])
  // TODO: We only need to refetch planned tours specified in `plannedTourIdToRefetchHash`, keep others
  useEffectAfterRender(refetch, [plannedTourIdToRefetchHash])
  const onPlannedVisitMapMarkerClick = v => {
    if (focusedPlannedActivityId !== v.id)
      // Center selected visit and zoom in if not already zoomed in
      setView({
        ...view, center: { lat: v.client.lat, lng: v.client.lng }, zoom: Math.max(view.zoom, 13)
      })
    setFocusedPlannedActivityId(focusedPlannedActivityId === v.id ? null : v.id)
  }
  return <div className={className}>
    <GoogleMap
      bootstrapURLKeys={{ key: config.googleMapsApiKey }}
      className={[styles.GoogleMap, className].filter(Boolean).join(' ')}
      center={view.center}
      onChange={setView}
      options={createMapOptions}
      zoom={view.zoom}
    >
      {[
        ...plannedTours.map(t =>
          <TourPath
            color={plannedTourIdToColor[t.id]}
            key={t.id}
            lat={view.bounds.nw.lat}
            latLngs={sortedPlannedVisits(t.plannedActivities).map(v => v.client)}
            lng={view.bounds.nw.lng}
            mapView={view}
          />
        ),
        ...plannedTours.map(t => sortedPlannedVisits(t.plannedActivities).map((v, index) =>
          <PlannedVisitMapMarker
            className={styles.TourPlannedVisit}
            index={index}
            isFocused={focusedPlannedActivityId === v.id}
            isSelected={selectedPlannedActivityIds.includes(v.id)}
            key={v.id}
            lat={v.client.lat}
            lng={v.client.lng}
            onMarkerClick={() => onPlannedVisitMapMarkerClick(v)}
            plannedVisit={v}
            style={{ '--border-color': plannedTourIdToColor[t.id] }}
          />
        )),
        ...nearbyPlannedVisits.map((v, index) =>
          <PlannedVisitMapMarker
            className={styles.UnscheduledPlannedVisit}
            index={index}
            isFocused={focusedPlannedActivityId === v.id}
            key={v.id}
            lat={v.client.lat}
            lng={v.client.lng}
            onMarkerClick={() => onPlannedVisitMapMarkerClick(v)}
            plannedVisit={v}
          />
        ),
        ...places.map((p, i) =>
          <MapMarker
            key={`place-${i}`}
            lat={p.lat}
            lng={p.lng}
            onDelete={() => onDeletePlace(p)}
            place={p}
          />
        )
      ]}
    </GoogleMap>
  </div>
}

const PlannedVisitMapMarker = ({ className, index, plannedVisit, ...rest }) => (
  <ClientMapMarker
    className={[
      styles.Qualification,
      {
        'CaregiverQualification.CARE_COMPANION': _.isEqual(plannedVisit.serviceGroups, ['SGB_V'])
          ? styles.RequiresCareCompanionWithSgbVOnlyServices : styles.RequiresCareCompanion,
        'CaregiverQualification.QUALIFIED': styles.RequiresQualifiedCaregiver,
      }[plannedVisit.requiredCaregiverQualification],
      className,
    ].filter(Boolean).join(' ')}
    client={plannedVisit.client}
    hint={<PlannedActivityHint plannedActivity={plannedVisit} />}
    hintTrigger="focus"
    label={index + 1}
    {...rest}
  />
)


const PlannedActivityHint = ({ plannedActivity }) => <PlannedVisit
  className={styles.PlannedActivityHint} isExpandable={false} isExpanded={true} plannedActivity={plannedActivity}
/>

// TODO: Let API sort planned visits accordingly so that we can remove this code
const sortedPlannedVisits = plannedActivities => _.sortBy(
  plannedActivities.filter(a => ['PlannedVisitType', 'ReschedulingPlannedVisitType'].includes(a.__typename)),
  ['position', 'startDatetime']
)

export default Map
