import React, { useEffect, useState } from 'react'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import gql from 'graphql-tag'
import pluralize from 'pluralize'
import { format, parseISO, setHours, setMinutes } from 'date-fns'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import ApolloErrorButton from '../ApolloErrorButton'
import StationSelect from '../StationSelect'
import { useEffectAfterRender } from '../../hooks'

import styles from './PlannedTourEditDialog.module.css'

function PlannedTourEditDialog({ date, onClose = () => {}, onEdit = () => {}, plannedTourId, stationId }) {
  const [isQualifiedCaregiver, setIsQualifiedCaregiver] = useState(false)
  const [endDate, setEndDate] = useState(date || new Date())
  const [endTime, setEndTime] = useState(setMinutes(setHours(new Date(), 14), 0))
  const [name, setName] = useState("")
  const [startDate, setStartDate] = useState(date || new Date())
  const [startTime, setStartTime] = useState(setMinutes(setHours(new Date(), 6), 0))
  const [shouldCreateTourCoordinationActivities, setShouldCreateTourCoordinationActivities] =
    useState(!plannedTourId)
  const [tourCoordinationActivitiesLocationStationId, setTourCoordinationActivitiesLocationStationId] =
    useState(null)
  const [hint, setHint] = useState("")
  const [weekdays, setWeekdays] = useState({ ...new Array(7).fill(0) })
  const [
    shouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup,
    setShouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup
  ] = useState(false)
  const isTourGroup = (
    !(isNaN(startDate) || isNaN(endDate)) && (endDate.toDateString() !== startDate.toDateString())
  )
  const isInputValid = !(isNaN(startDate) || isNaN(endDate) || isNaN(startTime) || isNaN(endTime))
  // TODO: Disable all input as long as we are fetching or have a fetch error
  const [fetchPlannedTour, { loading: isFetching, data: fetchData, error: fetchError }] = useLazyQuery(
    PLANNED_TOUR_QUERY, { fetchPolicy: 'network-only', variables: { id: plannedTourId } }
  )
  useEffect(() => { !!plannedTourId && fetchPlannedTour() }, [plannedTourId])
  const plannedTour = fetchData && (fetchData.plannedTours.length > 0) && fetchData.plannedTours[0]
  useEffectAfterRender(
    () => {
      setEndDate(parseISO(plannedTour.startDatetime)) // Some tours have no end date -> always show start date
      setEndTime(parseISO(plannedTour.endDatetime || plannedTour.startDatetime)) // Idem
      setHint(plannedTour.hint || "")
      setName(plannedTour.name || "")
      setStartDate(parseISO(plannedTour.startDatetime))
      setStartTime(parseISO(plannedTour.startDatetime))
    },
    [plannedTour]
  )
  const [createOrUpdatePlannedTour, { error: updateError, loading: isUpdating }] = useMutation(
    isTourGroup ? CREATE_PLANNED_TOUR_GROUP : CREATE_OR_UPDATE_PLANNED_TOUR,
    {
      onCompleted: () => {onEdit(); onClose()},
      variables: isInputValid
        ? {
          hint,
          shouldCreateTourCoordinationActivities,
          tourCoordinationActivitiesLocationStationId,
          ...(isTourGroup
            ? {
              endDate: format(endDate, 'yyyy-MM-dd'),
              plannedToursEndTime: format(endTime, 'HH:mm'),
              plannedToursName: name,
              plannedToursStartTime: format(startTime, 'HH:mm'),
              requiredCaregiverQualification: isQualifiedCaregiver ? 'QUALIFIED' : 'CARE_COMPANION',
              startDate: format(startDate, 'yyyy-MM-dd'),
              stationId: stationId,
              timezone: "Europe/Berlin",
              weekdays: Object.keys(weekdays).filter(day => parseInt(weekdays[day]))
            }
            : {
              endDatetime: setMinutes(
                setHours(endDate, endTime.getHours()), endTime.getMinutes()
              ).toISOString(),
              id: plannedTourId,
              name,
              shouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup,
              startDatetime: setMinutes(
                setHours(startDate, startTime.getHours()), startTime.getMinutes()
              ).toISOString(),
              stationId: plannedTourId ? undefined : stationId,
            }
          )
        }
        : {}
    }
  )
  const isLoading = isFetching || isUpdating
  const error = fetchError || updateError
  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>Tour {plannedTourId ? "bearbeiten" : "anlegen"}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          disabled={isLoading}
          fullWidth
          label="Tourname"
          margin="normal"
          onChange={e => setName(e.target.value)}
          value={name}
        />
        <KeyboardDatePicker
          autoOk
          disabled={!!plannedTourId /* Date can't be changed for existing tours */ || isLoading}
          error={isNaN(startDate)}
          format="dd.MM.yyyy"
          helperText={isNaN(startDate) ? "Ungültige Eingabe" : null}
          label="Von"
          margin="normal"
          onChange={setStartDate}
          value={startDate}
        />
        <KeyboardTimePicker
          autoOk
          disabled={isLoading}
          error={isNaN(startTime)}
          format="HH:mm"
          helperText={isNaN(startTime) ? "Ungültige Eingabe" : null}
          label="Startzeit"
          margin="normal"
          onChange={setStartTime}
          value={startTime}
        />
        <KeyboardDatePicker
          autoOk
          disabled={!!plannedTourId /* Date can't be changed for existing tours */ || isLoading}
          error={isNaN(endDate)}
          format="dd.MM.yyyy"
          helperText={isNaN(endDate) ? "Ungültige Eingabe" : null}
          label="Bis"
          margin="normal"
          minDate={startDate}
          onChange={setEndDate}
          value={endDate}
        />
        <KeyboardTimePicker
          autoOk
          disabled={isLoading}
          error={isNaN(endTime)}
          format="HH:mm"
          helperText={isNaN(endTime) ? "Ungültige Eingabe" : null}
          label="Endzeit"
          margin="normal"
          onChange={setEndTime}
          value={endTime}
        />
        <div>{/* This extra div creates a block container to occupy the whole line */}
          <FormControlLabel
            control={
              <Switch
                checked={shouldCreateTourCoordinationActivities}
                onChange={e => setShouldCreateTourCoordinationActivities(e.target.checked)}
              />
            }
            label="Aktivitäten für Koordinationszeit einfügen"
            margin="normal"
          />
        </div>
        <StationSelect
          disabled={!shouldCreateTourCoordinationActivities}
          noValueLabel="(Ort für Aktivitäten auswählen)"
          onChange={setTourCoordinationActivitiesLocationStationId}
          value={tourCoordinationActivitiesLocationStationId}
        />
        <TextField
          disabled={isLoading}
          fullWidth
          label="Hinweis"
          margin="normal"
          multiline
          onChange={e => setHint(e.target.value)}
          rowsMax="5"
          value={hint}
        />
        {!!plannedTourId &&
          <FormControlLabel
            control={<Checkbox
              checked={shouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup}
              disabled={isLoading}
              onChange={e => setShouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup(e.target.checked)}
            />}
            label="Zukünftige Touren der gleichen Tourgruppe ebenso aktualisieren"
          />
        }
        {isTourGroup && <Paper>
          <DialogTitle>Tourgruppe</DialogTitle>
          <DialogContent>
            <Typography variant="caption">Qualifikation</Typography>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox
                  checked={isQualifiedCaregiver}
                  disabled={isLoading}
                  onChange={() => setIsQualifiedCaregiver(!isQualifiedCaregiver)}
                />}
                label="Fachkraft benötigt"
              />
            </FormGroup>
            <Typography variant="caption">Wochentage</Typography>
            <FormGroup margin="normal" row>
              {WEEKDAYS.map((day, i) => (
                <FormControlLabel
                  className={styles.WeekdayLabel}
                  control={<Checkbox
                    checked={Boolean(weekdays[i])}
                    disabled={isLoading}
                    key={i}
                    onChange={() => setWeekdays(Object.assign({}, weekdays, { [i]: weekdays[i] ^ 1 }))}
                  />}
                  key={i}
                  label={day}
                  labelPlacement="bottom"
                />
              ))}
            </FormGroup>
          </DialogContent>
        </Paper>}
        {error && <ApolloErrorButton error={error} />}
      </DialogContent>
      {isLoading && <LinearProgress />}
      <DialogActions>
        <Button
          disabled={!isInputValid || isLoading || !!fetchError}
          onClick={createOrUpdatePlannedTour}
        >{pluralize("Tour", isTourGroup ? 2 : 1)} {plannedTourId ? "aktualisieren" : "erstellen"}</Button>
        <Button color="secondary" disabled={isLoading} onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  )
}

const CREATE_OR_UPDATE_PLANNED_TOUR = gql`
  mutation createOrUpdatePlannedTour(
    $endDatetime: DateTime
    $hint: String
    $id: String
    $name: String
    $shouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup: Boolean
    $shouldCreateTourCoordinationActivities: Boolean
    $startDatetime: DateTime
    $stationId: Int
    $tourCoordinationActivitiesLocationStationId: Int
  ) {
    createOrUpdatePlannedTour(
      endDatetime: $endDatetime
      hint: $hint
      id: $id
      name: $name
      shouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup:
        $shouldAlsoUpdateFuturePlannedToursOfSamePlannedTourGroup
      shouldCreateTourCoordinationActivities: $shouldCreateTourCoordinationActivities
      startDatetime: $startDatetime
      stationId: $stationId
      tourCoordinationActivitiesLocationStationId: $tourCoordinationActivitiesLocationStationId
    ) {
      plannedTour {
        id
      }
    }
  }
`

const CREATE_PLANNED_TOUR_GROUP = gql`
  mutation createOrUpdatePlannedTourGroup(
    $endDate: Date
    $hint: String
    $plannedToursEndTime: Time
    $plannedToursName: String
    $plannedToursStartTime: Time
    $requiredCaregiverQualification: CaregiverQualification
    $shouldCreateTourCoordinationActivities: Boolean
    $startDate: Date
    $stationId: Int!
    $timezone: String
    $tourCoordinationActivitiesLocationStationId: Int
    $weekdays: [Int!]
  ) {
    createOrUpdatePlannedTourGroup(
      endDate: $endDate
      hint: $hint
      plannedToursEndTime: $plannedToursEndTime
      plannedToursName: $plannedToursName
      plannedToursStartTime: $plannedToursStartTime
      requiredCaregiverQualification: $requiredCaregiverQualification
      shouldCreateTourCoordinationActivities: $shouldCreateTourCoordinationActivities
      startDate: $startDate
      stationId: $stationId
      timezone: $timezone
      tourCoordinationActivitiesLocationStationId: $tourCoordinationActivitiesLocationStationId
      weekdays: $weekdays
    ) {
      plannedTourGroup {
        id
      }
    }
  }
`

const PLANNED_TOUR_QUERY = gql`
  query plannedTour($id: String!) {
    plannedTours(id: $id) {
      endDatetime
      hint
      id
      name
      startDatetime
      station { id }
    }
  }
`

const WEEKDAYS = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]

export default PlannedTourEditDialog
