import React from 'react'
import PropTypes from 'prop-types'

import { Button, Modal } from 'semantic-ui-react'

import './ConfirmationDialog.css'

const ConfirmationDialog = props => (
  <Modal
    onClose={props.onClose}
    open={props.open}
    size='small'
  >
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>{props.description}</p>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={props.onClose}>Abbrechen</Button>
      <Button onClick={props.onClickConfirm} primary>Bestätigen</Button>
    </Modal.Actions>
  </Modal>
)

ConfirmationDialog.propTypes = {
  description: PropTypes.string,
  header: PropTypes.string,
  onClickConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
}

export default ConfirmationDialog
