import React from 'react'

import { format } from 'date-fns'

import StationRedirect from '../../components/StationRedirect'

import styles from './TourPlanner2Home.module.css'

const TourPlanner2Home = () => <div className={styles.TourPlanner2Home}>
  <StationRedirect path={stationId => `/tour-planner2/${stationId}/${format(new Date(), 'yyyy-MM-dd')}`} />
</div>

export default TourPlanner2Home
