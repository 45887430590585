import React from 'react'

import _ from 'lodash'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { Droppable } from 'react-beautiful-dnd'

import ApolloErrorButton from '../ApolloErrorButton'
import {
  DraggablePlannedBreak, DraggablePlannedGenericActivity, DraggablePlannedVisit
} from './DraggablePlannedActivity'

import PlannedTourHeader from './PlannedTourHeader'
import PLANNED_TOURS_QUERY from './plannedToursQuery.js'
import { useEffectAfterRender, useMergeQuery } from '../../hooks'

import styles from './PlannedTour.module.css'

const PlannedTour = ({
  className,
  color,
  expandedActivityIds,
  isCheckable,
  isChecked,
  isDraggingEnabled,
  isDroppable,
  isExpandable = true,
  isExpanded = true,
  multiDraggingPlannedActivityId,
  onCheck,
  onExpand,
  onToggleExpansion, // TODO: Rename to onToggleActivityExpansion
  onToggleSelection, // TODO: Rename to onToggleActivitySelection
  /*
    Minimum required attribute is `id` because this component fetched the planned tour.
    Other attributes can be provided so that already known attributes can be display as long as the tour
    is being fetched.
  */
  plannedTour: propPlannedTour,
  refetchHash,
  selectedActivityIds,
}) => {
  const { loading, error, data, refetch } = useMergeQuery(
    PLANNED_TOURS_QUERY,
    {
      fetchPolicy: 'network-only',
      mergeVariable: 'ids',
      unmerge: (data, { ids }) => ({ plannedTours: data.plannedTours.filter(t => ids.includes(t.id)) }),
      variables: { ids: [propPlannedTour.id] },
    }
  )
  const plannedTour = data && data.plannedTours && data.plannedTours[0]
  useEffectAfterRender(refetch, [refetchHash])
  return <div
    className={[styles.PlannedTour, className, ...(isExpanded ? [] : [styles.Collapsed])].join(' ')}
  >
    <PlannedTourHeader
      color={color}
      isCheckable={isCheckable}
      isChecked={isChecked}
      onChange={refetch}
      onCheck={onCheck}
      plannedTour={plannedTour || propPlannedTour}
    />
    {isExpandable &&
      <IconButton
        className={[styles.ExpandButton, isExpanded && styles.IsExpanded].join(' ')}
        onClick={e => {e.preventDefault(); onExpand(!isExpanded)}}
        size="small"
        style={{ color }}
      >
        {isExpanded ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    }
    {loading && <CircularProgress className={styles.Loader} />}
    {error && <ApolloErrorButton error={error} />}
    {plannedTour && isExpanded &&
      <Droppable droppableId={plannedTour.id} isDropDisabled={!isDroppable || loading}>
        {droppableProvided =>
          <PlannedActivities
            className={loading && styles.Loading}
            droppableProvided={droppableProvided}
            expandedActivityIds={expandedActivityIds}
            isDraggingEnabled={isDraggingEnabled && !loading}
            multiDraggingPlannedActivityId={multiDraggingPlannedActivityId}
            onToggleExpansion={onToggleExpansion}
            onToggleSelection={onToggleSelection}
            plannedTour={plannedTour}
            refetch={refetch}
            selectedActivityIds={selectedActivityIds}
          />
        }
      </Droppable>
    }
    {false && <Button startIcon={<AddIcon />}>Aktivität hinzufügen</Button>}
  </div>
}

const PlannedActivities = ({
  className,
  droppableProvided,
  expandedActivityIds,
  isDraggingEnabled,
  multiDraggingPlannedActivityId,
  onToggleExpansion,
  onToggleSelection,
  plannedTour,
  refetch,
  selectedActivityIds,
}) => (
  <div
    className={[styles.PlannedActivities, className].join(' ')}
    ref={droppableProvided.innerRef}
    style={droppableProvided.droppableProps.style}
  >
    {_.sortBy(plannedTour.plannedActivities, ['position', 'startDatetime'])
      .map((plannedActivity, index) => {
        const isSelected = _.includes(selectedActivityIds, plannedActivity.id)
        const isExpanded = _.includes(expandedActivityIds, plannedActivity.id)
        const isGhosting =
          isSelected &&
          multiDraggingPlannedActivityId &&
          (multiDraggingPlannedActivityId !== plannedActivity.id)
        const [PlannedActivity, extraPropsFn] = _.find(
          [
            // Each of the activity component definitions has the following data structure:
            // [[<from activity types>], [<to component type>, {<with extra props function>}]]
            [['PlannedBreakType'], [DraggablePlannedBreak]],
            [['PlannedGenericActivityType'], [DraggablePlannedGenericActivity]],
            [
              ['PlannedVisitType', 'ReschedulingPlannedVisitType'],
              [
                DraggablePlannedVisit,
                () => ({
                  isClientStationCodeVisible: plannedActivity.client.station.id !== plannedTour.station.id,
                })
              ]
            ],
          ],
          d => d[0].includes(plannedActivity.__typename)
        )[1]
        return <PlannedActivity
          {...(extraPropsFn ? extraPropsFn() : {})}
          className={styles.PlannedActivity}
          index={index}
          isDraggingEnabled={isDraggingEnabled}
          isExpanded={isExpanded}
          isGhosting={isGhosting}
          isSelected={isSelected}
          key={plannedActivity.id}
          onChangeDuration={refetch}
          onToggleExpansion={() => onToggleExpansion(plannedActivity.id)}
          onToggleSelection={onToggleSelection}
          plannedActivity={plannedActivity}
          selectionCount={selectedActivityIds && selectedActivityIds.length}
        />
      })
    }
    {droppableProvided.placeholder}
  </div>
)

export default PlannedTour
