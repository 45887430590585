import React from 'react'

import { graphql, Mutation } from 'react-apollo'
import { Button } from 'semantic-ui-react'
import _ from 'lodash'

import { GET_SPECIAL_SERVICES } from '../../constants/queries'
import { DELETE_SPECIAL_SERVICE } from '../../constants/mutations'
import SpecialServiceModal from './SpecialServiceModal'

import styles from './SpecialServices.module.css'

class SpecialServices extends React.Component {
  state = {
    isSpecialServiceModalOpened: false,
  }

  render = () => (
    <div className={styles.SpecialServices}>
      <div className={styles.Header}>
        <h3>Spezielle Leistungen</h3>
        <Button onClick={() => this.setState({ isSpecialServiceModalOpened: true })} icon="add" />
      </div>
      <div className={styles.ListHeader}>
        <div></div>
        <div>Leistung</div>
        <div>Dienstart</div>
        <div>Klient</div>
      </div>
      <ul className={styles.SpecialServicesList}>
        {this.props.data.specialServices &&
          _.orderBy(this.props.data.specialServices, ['specialService.service.abbreviation'])
            .map(specialService =>
              <li className={styles.SpecialService} key={specialService.id}>
                <RemoveButton serviceId={specialService.id} />
                <div>
                  {specialService.service.abbreviation && `${specialService.service.abbreviation} - `}
                  {specialService.service.name}
                </div>
                <div>{specialService.shiftKind.name}</div>
                {specialService.client &&
                  <div>{specialService.client.lastName}, {specialService.client.firstName}</div>
                }
              </li>
            )
        }
      </ul>
      <SpecialServiceModal
        stationId={this.props.stationId}
        isOpened={this.state.isSpecialServiceModalOpened}
        onOpen={() => this.setState({ isSpecialServiceModalOpened: true })}
        onClose={() => this.setState({ isSpecialServiceModalOpened: false })}
      />
    </div>
  )
}

const RemoveButton = ({ serviceId }) => (
  <Mutation
    mutation={DELETE_SPECIAL_SERVICE}
    variables={{ id: serviceId }}
    refetchQueries={['getSpecialServices']}
  >
    {deleteSpecialService =>
      <Button onClick={deleteSpecialService} className={styles.RemoveButton} icon='remove' />
    }
  </Mutation>
)

export default graphql(
  GET_SPECIAL_SERVICES,
  { options: props => ({ variables: { stationId: props.stationId } }) }
)(SpecialServices)
