import React, { useEffect, useMemo } from "react"

import _ from 'lodash'
import gql from "graphql-tag"

import SelectButton from '../SelectButton'
import { useMergeQuery } from '../../hooks'

import styles from './index.module.css'

const StationSelect = ({ className, onLoad, ...rest }) => {
  const { loading, error, data } = useMergeQuery(gql`query stations { stations { id name } }`)
  const stations = useMemo(() => data ? _.orderBy(data.stations, 'name') : [], [data])
  useEffect(() => onLoad && onLoad(stations, error, loading), [loading, error, stations])
  return <SelectButton
    className={[styles.StationSelect, className].filter(Boolean).join(' ')}
    errorMessage={error && error.message}
    isLoading={loading}
    options={stations.map(s => ({ label: s.name, value: s.id }))}
    {...rest}
  />
}

export default StationSelect
