import React from "react"
import { Button, Form, Icon } from "semantic-ui-react"
import { Mutation } from "react-apollo"
import gql from "graphql-tag"
import Auth from "../../utils/auth"

import styles from "../../screens/tour-orders/TourOrder.module.css"


const GET_TOUR_ORDER = gql`
  query tourOrders($tourOrderId: String) {
    tourOrders(tourOrderId: $tourOrderId) {
      createdAtDatetime
      id
      partnerStation {
        id
      }
      plannedTour {
        id
      }
      requiredCaregiverQualification
      status
    }
  }
`

const UPDATE_TOUR_ORDER = gql`
  mutation updateTourOrder($id: String!, $status: TourOrderStatus) {
    updateTourOrder(id: $id, status: $status) { tourOrder { id } }
  }
`

class TourOrderActionButtons extends React.Component {
  auth = new Auth();
  render() {
    const orderStatus = (this.props.tourOrder && this.props.tourOrder.status.replace('TourOrderStatus.', '')) || ''
    const isOrderCancelledOrRejected = ['CANCELLED', 'REJECTED'].includes(orderStatus)
    return (
      <Mutation
        mutation={UPDATE_TOUR_ORDER}
        refetchQueries={[{ query: GET_TOUR_ORDER, variables: { tourOrderId: this.props.tourOrder.id } }]}
      >
        {updateTourOrder => (
          <Form className={styles.FreeTextForm}>
            <div className={styles.Actions}>
              {this.auth.hasPermission('rejectOrder:careShop') &&
                <Button
                  disabled={isOrderCancelledOrRejected}
                  className={styles.CancelButton}
                  onClick={() => updateTourOrder({ variables: { id: this.props.tourOrder.id, status: 'REJECTED' } })}
                >Ablehnen</Button>
              }
              {this.auth.hasPermission('acceptOrder:careShop') &&
                <Button
                  disabled={['ACCEPTED', 'CANCELLED', 'CONFIRMED', 'REJECTED'].includes(orderStatus)}
                  className={styles.ConfirmedButton}
                  primary
                  onClick={() => updateTourOrder({ variables: { id: this.props.tourOrder.id, status: 'ACCEPTED' } })}
                >Annehmen</Button>
              }
              {this.auth.hasPermission('cancelOrder:careShop') &&
                <Button
                  disabled={isOrderCancelledOrRejected}
                  className={styles.CancelButton}
                  onClick={() => updateTourOrder({ variables: { id: this.props.tourOrder.id, status: 'CANCELLED' } })}
                >
                  Stornieren
                </Button>
              }
              {this.auth.hasPermission('confirmOrder:careShop') &&
                (status => status === 'REQUESTED'
                  ? <Button disabled>Anfrage wird von Talea geprüft</Button>
                  : <Button
                    disabled={['CONFIRMED', 'CANCELLED', 'REJECTED'].includes(status)}
                    className={styles.ConfirmedButton}
                    onClick={() => updateTourOrder({ variables: { id: this.props.tourOrder.id, status: 'CONFIRMED' } })}
                  >
                    <Icon name='check' /> Bestellung bestätigen
                  </Button>
                )(orderStatus)
              }
              <Button
                color='teal'
                disabled={['CANCELLED', 'REJECTED'].includes(orderStatus)}
                icon
                labelPosition='right'
                onClick={this.props.handleFileUploadModal}
              >
                Tourplan hochladen
                <Icon name='cloud upload' />
              </Button>
            </div>
          </Form>
        )}
      </Mutation>
    )
  }
}

export default TourOrderActionButtons
