import apolloClient from '../utils/apolloClient'

import {
  CREATE_MESSAGE,
  GET_CLIENT_DETAILS,
  GET_CLIENT_VISITS,
  GET_CLIENTS_AND_USERS,
  GET_CLIENTS_BY_NAME,
  GET_MESSAGES,
  GET_STATIONS,
  GET_TWILIO_TOKEN,
  GET_VISITS_OF_TODAY,
  UPDATE_MESSAGE,
} from "../constants/queries"

import { MOVE_TOUR_OPTIMIZER_ACTIVITIES } from '../constants/mutations/tour-optimizer'
import { GET_TOUR_OPTIMIZER_DAY } from '../constants/queries/tour-optimizer/index'

export const getClientsByName = (nameQuery, isActive) =>
  apolloClient().query({
    query: GET_CLIENTS_BY_NAME,
    variables: {
      nameQuery: nameQuery,
      isActive: isActive
    }
  }).then(res => res.data.clients)

export const getVisitsOfToday = stationName =>
  apolloClient().query({
    query: GET_VISITS_OF_TODAY,
    variables: {
      stationName: stationName
    }
  }).then(res => res.data.legacyDbActualTours)

export const getTwilioToken = () =>
  apolloClient().query({ query: GET_TWILIO_TOKEN }).then(res => res.data.twilioSession)

export const fetchUsersAndClients = () =>
  apolloClient().query({
    query: GET_CLIENTS_AND_USERS,
    variables: {
      isActive: true,
    }
  }).then(res => res.data)

export const fetchClientDetails = clientId =>
  apolloClient().query({
    query: GET_CLIENT_DETAILS,
    variables: { id: clientId },
  }).then(res => res.data)

export const fetchClientVisits = (clientId, options) =>
  apolloClient().query({
    query: GET_CLIENT_VISITS,
    variables: {
      id: clientId,
      startFromDatetime: options.visitsStartAfterDatetime.format(),
      startBeforeDatetime: options.visitsStartBeforeDatetime.format(),
    },
  }).then(res => res.data)

export const fetchMessages = () =>
  apolloClient().query({ query: GET_MESSAGES }).then(res => res.data)

export const createMessage = data =>
  apolloClient().mutate({ mutation: CREATE_MESSAGE, variables: data }).then(res => res.data)

export const updateMessage = data =>
  apolloClient().mutate({ mutation: UPDATE_MESSAGE, variables: data }).then(res => res.data)

export const moveTourOptimizerActivities = data =>
  apolloClient().mutate({
    mutation: MOVE_TOUR_OPTIMIZER_ACTIVITIES,
    variables: {
      targetIndex: data.targetIndex,
      targetTourOptimizerTourId: data.targetTourOptimizerTourId,
      tourOptimizerActivityIds: data.tourOptimizerActivityIds
    },
  }).then(res => res.data)

export const fetchStations = () => apolloClient().query({ query: GET_STATIONS }).then(res => res.data)

export const fetchTourOptimizerDay = (date, stationId) =>
  apolloClient().query({
    query: GET_TOUR_OPTIMIZER_DAY, variables: { date, stationId }
  }).then(res => res.data)
