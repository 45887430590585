import {
  alphabetToHexColorCode,
  messageTagLabel,
  messageTags,
  shiftKindAbbreviationToColorCodes } from "./../constants"
import _ from "lodash"
import dayjs from 'dayjs'

export const getTaskedMessages = messages =>
  messages.filter(message => (message.task && message.task.assigneeUser))

export const getUntaskedMessages = messages =>
  messages.filter(message => !message.task || (message.task && !message.task.assigneeUser))

export const convertArrayToObject = array => (
  array.reduce((normalizedData, array) => {
    normalizedData[array.id] = {
      ...array
    }
    return normalizedData
  }, {})
)

export const getMessageIdToMessage = array => (
  array.reduce((normalizedData, array) => {
    normalizedData[array.id] = {
      ...array,
      submessageIdToSubmessage: convertArrayToObject(_.orderBy(array.submessages, ['timestamp']))
    }
    return normalizedData
  }, {})
)

export const getCategoryLabel = localTags =>
  messageTagLabel[getMessageCategory(messageTags, localTags)]

export const getMessageCategory = (messageTags, localTags) =>
  (singleMessageTag => singleMessageTag ? singleMessageTag : 'general')(
    messageTags.find(messageTag => localTags.includes(messageTag))
  )

export const filterMessagesByCategory = (messages, category) => (category === '' || category === 'general')
  ? messages.filter(message => !message.tags.includes('drug-order') &&
    !message.tags.includes('health') && !message.tags.includes('visit-planning')
  )
  : messages.filter(message => message.tags.includes(category))

export const filterMessagesByUserId = (messages, userId) => userId
  ? messages.filter(m =>
    [m, ..._.values(m.submessageIdToSubmessage)]
      .filter(m => m.task && m.task.assigneeUser && (m.task.assigneeUser.id === userId)).length > 0
  )
  : messages

export const filterMessagesByClientId = (messages, clientId) =>
  messages.filter(message => message.clientId && (message.clientId === clientId))

export const filterMessagesByStatuses = (messages, statuses) => (statuses.length > 0)
  ? messages.filter(message => message.task && statuses.includes(message.task.status))
  : []

export const sortMessages = (messages, iteratees, orders) => _.sortBy(
  _.orderBy(messages, iteratees, orders),
  [m => !m.tags.includes('important')]
)

export const getPersonColor = person => alphabetToHexColorCode[person.firstName.charAt(0)]

export const orderedShiftKinds = shiftKinds => _.orderBy(
  shiftKinds,
  ['isActive', 'qualificationGroup', 'name'],
  ['desc', 'desc', 'asc']
)

export const orderedCaregivers = users => _.orderBy(
  users.filter(user => !!user.caregiver),
  ['caregiver.canBeAssignedShifts', 'caregiver.qualificationGroup', 'lastName'],
  ['desc', 'desc', 'asc']
)

export const getDates = (startDate, endDate) => startDate.isSame(endDate)
  ? [startDate.format('YYYY-MM-DD')]
  : endDate.isAfter(startDate)
    ? [...Array(dayjs(endDate).diff(startDate, 'days')).keys()]
      .map(d => startDate.add(d + 1, 'day').format('YYYY-MM-DD'))
    : [...Array(dayjs(startDate).diff(endDate, 'days')).keys()]
      .map(d => startDate.subtract(d + 1, 'day').format('YYYY-MM-DD'))

export const sortedTours = tours => [
  tours => _.orderBy(tours, ['shift.name']),
  tours => _.partition(tours, tour => dayjs(tour.startDatetime).hour() < 13),
  tours => _.flatten(tours),
  tours => _.orderBy(tours, ['shift.caregiver.qualificationGroup'], ['desc'])
].reduce((tours, fn) => fn(tours), tours)

export const getLocationParameter = (location, parameter) =>
  (new URLSearchParams(location.search)).get(parameter) || ''

export const getToursWithDeletedVisits = tourOptimizerDay => (
  //TODO: sorting should be induced by component, sort the resulting list there
  tourOptimizerDay.tourOptimizerTours
    .reduce((toursWithDeletedVisits, tour) => (
      tour.tourOptimizerActivities.filter(a => a.plannedVisit && a.plannedVisit.isDeleted).length > 0
        ? [
          ...toursWithDeletedVisits,
          {
            ...tour,
            deletedVisits: tour.tourOptimizerActivities
              .filter(a => a.plannedVisit && a.plannedVisit.isDeleted)
          },
        ]
        : toursWithDeletedVisits
    ), [])
    .concat([{
      deletedVisits: tourOptimizerDay.unassignedTourOptimizerActivities
        .filter(a => a.plannedVisit && a.plannedVisit.isDeleted)
    }])
    .filter(tour => tour.deletedVisits.length > 0)
)

export const getRedirectPath = (roles, roleToRedirectPath) => {
  for (let role of roles) {
    return (role in roleToRedirectPath) && roleToRedirectPath[role]
  }
  return 'forbidden'
}

export const getVisitOrders = unassignedTourOptimizerVisits =>
  unassignedTourOptimizerVisits.reduce((visitOrders, tourOptimizerVisit) => (
    visitOrders.map(visitOrder => visitOrder.id).includes(tourOptimizerVisit.plannedVisit.visitOrder.id)
      ? visitOrders
      : [...visitOrders, tourOptimizerVisit.plannedVisit.visitOrder]
  ), [])

export const visitOrderIdToUnassignedTourOptimizerVisits = unassignedTourOptimizerVisits =>
  unassignedTourOptimizerVisits.reduce((result, visit) => (
    {
      ...result,
      [visit.plannedVisit.visitOrder.id]: [...(result[visit.plannedVisit.visitOrder.id] || []), visit]
    }), {})

export const getShiftKindBackgroundStyle = shiftKind => shiftKind
  ? { background: (colorCodes => `linear-gradient(${colorCodes[0]} 50%, ${colorCodes[1]} 50%)`)(
    shiftKindAbbreviationToColorCodes[shiftKind.abbreviation] || ['#B8C5D0', '#B8C5D0']
  ) }
  : {}

export const getStringFirstLetterInUppercase = string => string.charAt(0).toUpperCase() + string.slice(1)
