import React from 'react'

import StationRedirect from '../../components/StationRedirect'

import styles from './ServicesRoot.module.css'

const ServicesRoot = props => {
  return <div className={styles.ServicesRoot}>
    <StationRedirect path={stationId => `/services/${stationId}`} />
  </div>
}

export default ServicesRoot
