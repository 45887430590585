import React from 'react'
import { Label } from 'semantic-ui-react'

import ClientLink from './ClientLink'
import { getCategoryLabel } from '../../utils/helpers'

import './TaskDescription.css'
import RelativeTimestamp from "./RelativeTimestamp"

const TaskDescription = ({ message, client, user }) => (
  <div className="TaskDescription">
    <div className="Title">
      <h4>{getCategoryLabel(message.tags)}</h4>
      {message.task && (message.task.status === 'opened') && <Label size='tiny'>Neu</Label>}
      {message.tags.includes('important') && <Label size='tiny' className='wichtig'>Wichtig</Label>}
    </div>
    <div className="Content">
      {client && <ClientLink client={client} />}
      {message.text.length > 130 ? message.text.substring(0, 130) + '...' : message.text}
    </div>
    <div className="Metadata">
      {((user, timestamp) =>
        <p>{`Erstellt von ${user} `}<RelativeTimestamp timestamp={timestamp} /></p>
      )((user => user ? `${user.firstName} ${user.lastName}` : "Unbekannt")(user), message.timestamp)
      }
    </div>
  </div>
)

export default TaskDescription
