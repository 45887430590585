import React from 'react'

import dayjs from 'dayjs'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Mutation, Query } from 'react-apollo'
import { Button, Checkbox, Dropdown, Loader, Popup } from 'semantic-ui-react'

import { editingDisabledStationIds } from '../../../constants'
import { GET_USERS_WITH_CAREGIVER_ATTRIBUTES } from '../../../constants/queries'
import { UPDATE_USER } from '../../../constants/mutations'
import { orderedCaregivers } from '../../../utils/helpers'

import './index.css'

class PreviousMonth extends React.Component {
  state = {
    startDate: dayjs().endOf('month').set('hour', 12).subtract(6, 'day')
  }

  render = () => (
    <Query query={GET_USERS_WITH_CAREGIVER_ATTRIBUTES} variables={{ isActive: true, stationId: this.props.match.params.stationId }}>
      {({ data, loading }) =>
        <div className="PreviousMonth">
          <Loader active={loading} />
          <div className="Headers">
            <div className="Header">Nachname</div>
            <div className="Header">Vorname</div>
            {[...new Array(7).keys()].map((day, index) =>
              <div className="Header Day" key={index}>
                {dayjs(this.props.plannedMonthDate)
                  .startOf('month').set('hour', 12).subtract(7, 'day').add(day, 'day').format('dd DD.MM')
                }
              </div>
            )}
            <Popup
              trigger={
                <div className="Header Weekend">Anz. Wochenenden</div>
              }
              on='hover'
              content={
                <p>Wie viele Wochenenden am Stück hat der Mitarbeiter am Ende des Vormonats gearbeitet?</p>
              }
            />
          </div>
          <div className="CaregiversList">
            {data && data.users &&
              orderedCaregivers(data.users.filter(user => user.caregiver && user.caregiver.canBeAssignedShifts))
                .map((user, index, users) =>
                  <Caregiver
                    key={user.id}
                    className={user === _.findLast(
                      users,
                      user => user.caregiver.qualificationGroup === 'QualificationGroup.QUALIFIED_CAREGIVER'
                    ) ? 'LastQualifiedCaregiver' : null}
                    user={user}
                    startDate={dayjs(this.props.plannedMonthDate)
                      .startOf('month').set('hour', 12).subtract(7, 'day')
                    }
                    isDropdownUpward={index > 5}
                    isEditable={!(editingDisabledStationIds.includes(this.props.match.params.stationId))}
                  />
                )
            }
          </div>
          <Link to={`/shift-planning/${this.props.match.params.stationId}/shift-optimizer`}>
            <Button primary>Weiter</Button>
          </Link>
        </div>
      }
    </Query>
  )
}

class Caregiver extends React.Component {
  state = {
    ...[...new Array(6).keys()].reduce((hasShiftOnDays, day) => ({
      ...hasShiftOnDays,
      [`hasShiftOnDay${day}`]: (this.props.user.caregiver.shiftConditions.filter(s =>
        s.conditionKind === 'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_SHIFT' &&
        s.date === this.props.startDate.add(day, 'day').format('YYYY-MM-DD')).length > 0),
    }), {}),
    shiftOnDay6:
      ((s => s.length > 0 ? s[0].conditionKind.replace('ShiftConditionKind.', '') : null)(
        this.props.user.caregiver.shiftConditions.filter(s =>
          [
            'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_EARLY_SHIFT',
            'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_LATE_SHIFT',
          ].includes(s.conditionKind) &&
          s.date === this.props.startDate.add(6, 'day').format('YYYY-MM-DD')
        ))
      ),
    workingWeekendCount:
      ((s => s.length > 0 ? s[0].previousMonthWorkingWeekendCount : 0)(
        this.props.user.caregiver.shiftConditions.filter(s =>
          s.conditionKind === 'ShiftConditionKind.PREVIOUS_MONTH_WORKING_WEEKENDS'
        ))),
  }

  render = () => (
    <Mutation
      mutation={UPDATE_USER}
      variables={{
        caregiver: {
          canHaveSingleFreeDays: this.props.user.caregiver.canHaveSingleFreeDays,
          canSplitWeekends: this.props.user.caregiver.canSplitWeekends,
          maximumConsecutiveWorkdays: this.props.user.caregiver.maximumConsecutiveWorkdays,
          maximumConsecutiveWorkingWeekends: this.props.user.caregiver.maximumConsecutiveWorkingWeekends,
          qualificationGroup: this.props.user.caregiver.qualificationGroup.replace('QualificationGroup.', ''),
          shiftsPerWeek: this.props.user.caregiver.shiftsPerWeek,
          shiftConditions: this.getShiftConditions()
        },
        id: this.props.user.id,
        isActive: true,
      }}
      refetchQueries={['getUsers']}
    >
      {updateUser =>
        <div className={`Caregiver ${!!this.props.className ? this.props.className : ''}`}>
          <div className="LastName">{this.props.user.lastName}</div>
          <div className="FirstName">{this.props.user.firstName}</div>
          {[...new Array(6).keys()].map((day, index) =>
            <Checkbox
              checked={this.state[`hasShiftOnDay${day}`]}
              className="HasShiftOnDay"
              disabled={!this.props.isEditable}
              onChange={(e, data) => this.setState(
                prevState => ({ [`hasShiftOnDay${day}`]: data.checked }),
                updateUser
              )}
              key={day}
            />)}
          <Dropdown
            className="ShiftOnDay6"
            disabled={!this.props.isEditable}
            onChange={(e, data) => this.setState(
              prevState => ({ shiftOnDay6: data.value }),
              updateUser
            )}
            options={[
              { value: null, text: '' },
              ...['PREVIOUS_MONTH_CAREGIVER_HAD_EARLY_SHIFT', 'PREVIOUS_MONTH_CAREGIVER_HAD_LATE_SHIFT']
                .map(shiftOnDay6 => ({ text: shiftOnDay6ToLabel[shiftOnDay6], value: shiftOnDay6 }))
            ]}
            upward={this.props.isDropdownUpward}
            value={this.state.shiftOnDay6}
            selection
          />
          <Dropdown
            className="WorkingWeekendCount"
            disabled={!this.props.isEditable}
            onChange={(e, data) => this.setState(
              prevState => ({ workingWeekendCount: data.value }),
              updateUser
            )}
            options={[...new Array(5).keys()].map(count => ({ text: count, value: count }))}
            upward={this.props.isDropdownUpward}
            value={this.state.workingWeekendCount}
            selection
          />
        </div>
      }
    </Mutation>
  )

  getShiftConditions = () => ([
    ...this.props.user.caregiver.shiftConditions
      .filter(s => ![
        'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_EARLY_SHIFT',
        'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_LATE_SHIFT',
        'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_SHIFT'].includes(s.conditionKind))
      .map(s => s.shiftKind
        ? ({
          conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''),
          date: s.date,
          shiftKindId: s.shiftKind && s.shiftKind.id,
        })
        : ({ conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''), date: s.date })
      ),
    ...[...new Array(6).keys()].reverse().map(day => this.state[`hasShiftOnDay${day}`] && ({
      conditionKind: "PREVIOUS_MONTH_CAREGIVER_HAD_SHIFT",
      date: this.props.startDate.add(day, 'day').format('YYYY-MM-DD'),
    })),
    this.state.shiftOnDay6 && {
      conditionKind: this.state.shiftOnDay6,
      date: this.props.startDate.add(6, 'day').format('YYYY-MM-DD')
    },
    {
      conditionKind: "PREVIOUS_MONTH_WORKING_WEEKENDS",
      previousMonthWorkingWeekendCount: this.state.workingWeekendCount
    },
  ].filter(Boolean))

  componentDidUpdate = prevProps => {
    if (prevProps.startDate !== this.props.startDate) {
      this.setState({
        ...[...new Array(6).keys()].reduce((hasShiftOnDays, day) => ({
          ...hasShiftOnDays,
          [`hasShiftOnDay${day}`]: (this.props.user.caregiver.shiftConditions.filter(s =>
            s.conditionKind === 'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_SHIFT' &&
            s.date === this.props.startDate.add(day, 'day').format('YYYY-MM-DD')).length > 0),
        }), {}),
        shiftOnDay6:
          ((s => s.length > 0 ? s[0].conditionKind.replace('ShiftConditionKind.', '') : null)(
            this.props.user.caregiver.shiftConditions.filter(s =>
              [
                'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_EARLY_SHIFT',
                'ShiftConditionKind.PREVIOUS_MONTH_CAREGIVER_HAD_LATE_SHIFT',
              ].includes(s.conditionKind) &&
              s.date === this.props.startDate.add(6, 'day').format('YYYY-MM-DD')
            ))
          ),
      })
    }
  }
}

const shiftOnDay6ToLabel = {
  'PREVIOUS_MONTH_CAREGIVER_HAD_EARLY_SHIFT': 'Früh',
  'PREVIOUS_MONTH_CAREGIVER_HAD_LATE_SHIFT': 'Spät',
}

export default PreviousMonth
