import React, { useState } from 'react'

import { LinearProgress, Menu, MenuItem } from '@material-ui/core'

import SearchBar from '../SearchBar'
import { usePopup } from '../../hooks'

import styles from './index.module.css'

const AddressSearchBar = (
  { country = "Germany", onSelect, preferredLocality, ...rest }
) => {
  const [isFetching, setIsFetching] = useState(false)
  const [isMenuOpen, showMenu, closeMenu, menuAnchorElement] = usePopup()
  const [places, setPlaces] = useState([])
  return <>
    <SearchBar
      onKeyPress={e => {if ((e.key === 'Enter') && e.target.value.trim().length) {
        showMenu(e)
        setIsFetching(true)
        setPlaces([])
        fetchPlaces(e.target.value, country, preferredLocality)
          .then(setPlaces)
          .catch(error => {closeMenu(); alert(error)})
          .then(() => setIsFetching(false))
      }}}
      {...rest}
    />
    <Menu
      anchorEl={menuAnchorElement}
      className={styles.Menu}
      keepMounted
      onClose={closeMenu}
      open={isMenuOpen}
    >
      {isFetching && <LinearProgress />}
      {!isFetching && (places.length === 0) &&
        <MenuItem onClick={closeMenu}>Keine passenden Treffer gefunden</MenuItem>
      }
      {places.map((p, i) => (
        <MenuItem key={i} onClick={e => {closeMenu(e); onSelect && onSelect(p)}}>
          {p.text}
        </MenuItem>
      ))}
    </Menu>
  </>
}

const fetchPlaces = (query, country, preferredLocality) => fetch(
  'https://maps.googleapis.com/maps/api/geocode/json' +
  `?address=${query}` +
  '&components=' + [
    country && `country:${country}`, preferredLocality && `locality:${preferredLocality}`
  ].filter(Boolean).join('|') +
  `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
)
  .then(response => response.json())
  .then(data => (data.results)
    .filter(place => place.address_components.length > 1 /* exclude country matches */)
    .sort((lhs, rhs) => {
      if (getLocalityFromPlace(lhs) === preferredLocality) return -1
      if (getLocalityFromPlace(rhs) === preferredLocality) return 1
      return 0
    })
    .map(place => ({
      lat: place.geometry.location.lat,
      lng: place.geometry.location.lng,
      place: place,
      text: place.formatted_address,
    }))
  )

const getLocalityFromPlace = place => {
  const localityComponent = place.address_components.find(component => component.types.includes('locality'))
  return localityComponent ? localityComponent.short_name : null
}

export default AddressSearchBar
