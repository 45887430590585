import React from "react"
import _ from "lodash"
import gql from "graphql-tag"
import { Button, Dropdown, Grid, Icon, Image, Message, Modal, Radio } from "semantic-ui-react"
import moment from "moment"
import DatePicker from "react-datepicker"
import { setHours, setMinutes } from "date-fns"

import Avatar from "./../svgs/nurse.svg"

import apolloClient from "../../utils/apolloClient"
import * as API from "./../../utils/API"
import Auth from "../../utils/auth"

import styles from './TourOrderModal.module.css'

const CREATE_TOUR_ORDER = gql`
  mutation CreateTourOrder(
    $partnerStationId: Int,
    $plannedTourDuration: Int,
    $plannedTourId: String,
    $plannedTourStartDatetime: DateTime
    $requiredCaregiverQualification: CaregiverQualification!
  ) {
    createTourOrder(
      partnerStationId: $partnerStationId,
      plannedTourDuration: $plannedTourDuration,
      plannedTourId: $plannedTourId,
      plannedTourStartDatetime: $plannedTourStartDatetime,
      requiredCaregiverQualification: $requiredCaregiverQualification
    ) { tourOrder { id } }
  }
`

class TourOrderModal extends React.Component {
  auth = new Auth();
  state = {
    error: null,
    stations: [],
    selectedStationId: null,
    selectedStartTime: setHours(setMinutes(new Date(), 0), 6),
    selectedEndTime: setHours(setMinutes(new Date(), 0), 14),
    selectedStartDate: new Date(),
    selectedEndDate: new Date(),
    isQualifiedCaregiver: false,
    plannedTourName: ''
  };

  render() {
    const { isOpened, onClose } = this.props
    return (
      <Modal
        dimmer='inverted'
        open={isOpened === undefined ? true : isOpened}
        onClose={onClose}
        className={styles.OrderPlannedVisitModal}
      >
        <header className={styles.Header}>
          <Image
            className={styles.Nurse}
            size='small'
            src={Avatar}
            avatar
          />
          <h3>Einsatzbuchung</h3>
        </header>
        <section className={styles.Content}>
          {this.state.error &&
            <Message negative>
              <Message.Header>Tour creation failed: { this.state.error.message }</Message.Header>
            </Message>
          }
          <Grid centered columns={2}>
            <Grid.Row>
              <Grid.Column>
                <h4>Wähle einen Partner aus</h4>
                {
                  (stations =>
                    <Dropdown
                      className={styles.Stations}
                      onChange={(e, { value }) => this.setState({ selectedStationId: value })}
                      value={this.state.selectedStationId}
                      selection
                      options={stations}
                    />
                  )(this.state.stations.map(station => ({ key: station.id, value: station.id, text: station.name })))
                }
              </Grid.Column>
              <Grid.Column>
                <h4>Details</h4>
                <div className={styles.Field}>
                  <h5>Fachkraft wird benötigt</h5>
                  <Radio
                    className={styles.Fachkraft}
                    checked={this.state.isQualifiedCaregiver}
                    toggle
                    onChange={e => this.setState({ isQualifiedCaregiver: !this.state.isQualifiedCaregiver })}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className={styles.StartDatePickerColumn}>
                <h4>Einsatzdatum wählen</h4>
                <div className={styles.Fields}>
                  <label className={styles.StartDatePickerLabel}>
                    <Icon name='calendar' className={styles.CalendarIcon} />
                    <DatePicker
                      className={styles.StartDatePicker}
                      onChange={startDate => this.setState({ selectedStartDate: startDate })}
                      minDate={new Date()}
                      selected={this.state.selectedStartDate}
                      selectsStart
                      startDate={this.state.selectedStartDate}
                      endDate={this.state.selectedEndDate}
                      dateFormat="dd.MM.yyyy"
                      shouldCloseOnSelect={false}
                    />
                  </label>
                  <label className={styles.StartDatePickerLabel}>
                    <Icon name='calendar' className={styles.CalendarIcon} />
                    <DatePicker
                      className={styles.StartDatePicker}
                      dateFormat="dd.MM.yyyy"
                      endDate={this.state.selectedEndDate}
                      minDate={this.state.selectedStartDate}
                      selected={this.state.selectedEndDate}
                      selectsEnd
                      startDate={this.state.selectedStartDate}
                      shouldCloseOnSelect={false}
                      onChange={endDate => this.setState({ selectedEndDate: endDate })}
                    />
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column>
                <h4>Einsatzzeitraum</h4>
                <div className={styles.Fields}>
                  <label>
                    <Icon name='clock' className={styles.CalendarIcon} />
                    <DatePicker
                      className={styles.StartTimePicker}
                      dateFormat="HH:mm"
                      minTime={setHours(setMinutes(new Date(), 0), 6)}
                      maxTime={setHours(setMinutes(new Date(), 30), 20)}
                      onChange={startTime => this.setState({ selectedStartTime: startTime })}
                      selected={this.state.selectedStartTime}
                      showTimeSelect
                      showTimeSelectOnly
                      shouldCloseOnSelect={false}
                      timeCaption="Zeit"
                      timeFormat="HH:mm"
                      timeIntervals={10}
                    />
                  </label>
                  <label>
                    <Icon name='clock' className={styles.CalendarIcon} />
                    <DatePicker
                      className={styles.EndTimePicker}
                      dateFormat="HH:mm"
                      minTime={setHours(setMinutes(new Date(), 0), 6)}
                      maxTime={setHours(setMinutes(new Date(), 30), 20)}
                      onChange={endTime => this.setState({ selectedEndTime: endTime })}
                      selected={this.state.selectedEndTime}
                      showTimeSelect
                      showTimeSelectOnly
                      shouldCloseOnSelect={false}
                      timeCaption="Zeit"
                      timeFormat="HH:mm"
                      timeIntervals={10}
                    />
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section>
        {
          //TODO: Create sub-component to have max. 40 - 60 LOCs per method
        }
        <footer className={styles.Actions}>
          <Button
            className={styles.BookingButton}
            disabled={moment(this.state.selectedStartDate) > moment(this.state.selectedEndDate)}
            onClick={this.createTourOrders}>WEITER</Button>
        </footer>
      </Modal>
    )
  }

  //TODO: Use Query component to avoid life cycle method
  componentDidMount = () => {
    API.fetchStations().then(result => {
      this.setState(prevState => ({
        stations: result.stations,
        selectedStationId: _.first(result.stations).id
      }))
    })
  }

  createTourOrders = () => {
    Promise.all(
      this.getDateRange(this.state.selectedStartDate, this.state.selectedEndDate).map(date =>
        apolloClient().mutate({
          mutation: CREATE_TOUR_ORDER,
          variables: {
            partnerStationId: this.state.selectedStationId,
            plannedTourDuration: moment(this.state.selectedEndTime).diff(moment(this.state.selectedStartTime), "seconds"),
            plannedTourStartDatetime: moment(date).hour(moment(this.state.selectedStartTime).hour()).minute(moment(this.state.selectedStartTime).minute()).format(),
            requiredCaregiverQualification: this.state.isQualifiedCaregiver ? 'QUALIFIED' : 'CARE_COMPANION'
          }
        })
      )
    )
      .then(() => { this.props.onTourOrdersCreated(); this.props.onClose() })
      .catch(error => this.setState({ error }))
  }

  getDateRange = (startDate, endDate) =>
    [...Array(1 + moment(endDate).endOf('day').diff(startDate, 'days')).keys()].map(n => moment(startDate).add(n, 'days'))

}

export default TourOrderModal
