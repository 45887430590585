import React from 'react'

import _ from 'lodash'
import gql from 'graphql-tag'
import { Link, withRouter } from 'react-router-dom'
import { List } from 'semantic-ui-react'
import { Query } from 'react-apollo'

import './index.css'

const StationPicker = ({ location, linkTo }) => (
  <div className="StationPicker">
    <h4>Wählen Sie eine Station aus:</h4>
    <Query query={gql`query getStations { stations { id name } }`}>
      {({ data }) =>
        <List>
          {data.stations && _.sortBy(data.stations, 'name').map(station =>
            <Link to={[location.pathname, station.id, linkTo].join('/')} key={station.id}>
              <List.Item>
                {station.name}
              </List.Item>
            </Link>
          )}
        </List>
      }
    </Query>
  </div>
)

export default withRouter(StationPicker)
