import React from 'react'
import { Button, Dropdown, Form, Grid, Header, Segment } from 'semantic-ui-react'

import './index.css'
import { Link } from 'react-router-dom'

const qualificationGroupOptions = [
  { key: 'Pflegefachkraft', value: 'QUALIFIED_CAREGIVER', text: 'Pflegefachkraft' },
  { key: 'Pflegehilfskraft', value: 'CARE_COMPANION', text: 'Pflegehilfskraft' }
]

class ClientMatchingForm extends React.Component{
  state = {
    isFormSubmitted: false,
    street: '',
    zipCode: '',
    pastDaysCount: '',
    qualificationGroup: 'QUALIFIED_CAREGIVER'
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  getQueryParameter = () => `city=${this.state.city}&street=${this.state.street}&zipCode=${this.state.zipCode}&pastDaysCount=${this.state.pastDaysCount}&qualificationGroup=${this.state.qualificationGroup}`

  render() {
    return (
      <div className="ClientMatchingForm">
        <Header as='h2' color='teal' textAlign='center'>Klientendetails</Header>
        <Form>
          <Grid container columns={2} stackable>
            <Grid.Column>
              <Segment>
                <input
                  placeholder='Stadt'
                  name='city'
                  value={this.state.city}
                  onChange={this.onChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <input
                  placeholder='Straße'
                  name='street'
                  value={this.state.street}
                  onChange={this.onChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <input
                  placeholder='PLZ'
                  name='zipCode'
                  value={this.state.zipCode}
                  onChange={this.onChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Dropdown
                  placeholder='QualificationGroup'
                  fluid
                  selection
                  options={qualificationGroupOptions}
                  name='qualificationGroup'
                  value={this.state.qualificationGroup}
                  onChange={(e, { value }) => this.setState({ qualificationGroup: value })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <input
                  placeholder='Historische Tage'
                  name='pastDaysCount'
                  value={this.state.pastDaysCount}
                  onChange={this.onChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Link to={`/client-matching/result?${this.getQueryParameter()}`}>
                <Button primary disabled={!(this.state.city && this.state.zipCode)}>Tour finden</Button>
              </Link>
            </Grid.Column>
          </Grid>
        </Form>
      </div>
    )
  }
}

export default ClientMatchingForm
