import gql from 'graphql-tag'
import {
  TOUR_OPTIMIZER_TOUR_CONSTRAINT_VIOLATIONS_FRAGMENT,
  TOUR_OPTIMIZER_VISIT_CONSTRAINT_VIOLATIONS_FRAGMENT,
} from '../../constraint-violations'

const GET_PLANNED_VISIT_BY_ID = gql`
  query getPlannedVisit(
    $id: String
  ) {
    plannedVisits(
      id: $id
    ) {
      endDatetime
      id
      client {
        caregiverPreferences {
          caregiver {
            id
            user {
              firstName
              id
              lastName
            }
          }
          id
          isPositive
        }
        careUtilities {
          id
          name
          status
        }
        city
        firstName
        id
        lastName
        phoneNumber
        street
        zipCode
      }
      hasSgbVService
      services {
        abbreviation
        name
        id
      }
      shiftTime {
        id
        name
      }
      startDatetime
    }
  }
`

const GET_PLANNED_TOURS = gql`
  query getPlannedTours(
    $date: Date!,
    $stationId: Int!,
  ) {
    plannedTours(
      date: $date,
      stationId: $stationId,
    ) {
      endDatetime
      id
      isTaleaTour
      name
      shift {
        abbreviation
        caregiver {
          qualificationGroup
        }
        id
        name
        user {
          firstName
          id
          lastName
        }
      }
      plannedVisits {
        client {
          firstName
          id
          lastName
        }
        endDatetime
        hasSgbVService
        id
        services {
          abbreviation
          name
          id
        }
        startDatetime
        user {
          firstName
          id
          lastName
        }
      }
      startDatetime
    }
    plannedTourMetrics(
      date: $date,
      stationId: $stationId,
    ) {
        assignedVisitCount
        assignedVisitsDrivingTimeAverage
        averageAssignedCaregiverHistoricalVisitPercentage
        manualAdjustmentsCount
        totalDeviationFromAverageTimesDuration
        totalDrivingTimeDuration
        unassignedVisitCount
        visitsAssignedToTop1CaregiverPercentage
        visitsAssignedToTop3CaregiverPercentage
    }
    actualTourMetrics(
      date: $date,
      stationId: $stationId,
    ) {
        assignedVisitCount
        assignedVisitsDrivingTimeAverage
        averageAssignedCaregiverHistoricalVisitPercentage
        manualAdjustmentsCount
        totalDeviationFromAverageTimesDuration
        totalDrivingTimeDuration
        unassignedVisitCount
        visitsAssignedToTop1CaregiverPercentage
        visitsAssignedToTop3CaregiverPercentage
    }
  }
`

const GET_UNASSIGNED_PLANNED_VISITS = gql`
  query getUnassignedPlannedVisits(
    $endBeforeDatetime: DateTime,
    $id: String,
    $startFromDatetime: DateTime,
    $stationId: Int,
  ) {
    unassignedPlannedVisits(
      endBeforeDatetime: $endBeforeDatetime,
      id: $id,
      startFromDatetime: $startFromDatetime,
      stationId: $stationId,
    ) {
      endDatetime
      hasSgbVService
      id
      client {
        firstName
        id
        lastName
      }
      startDatetime
    }
  }
`

const GET_TOUR_OPTIMIZER_DAY = gql`
  query getTourOptimizerDay($date: Date!, $stationId: Int!) {
    tourOptimizerDay(date: $date, stationId: $stationId) {
      createdDatetime
      date
      id
      hasBeenAppliedSinceLastChange
      tourOptimizerMetric {
        assignedVisitCount
        assignedVisitsDrivingTimeAverage
        averageAssignedCaregiverHistoricalVisitPercentage
        totalDeviationFromAverageTimesDuration
        totalDrivingTimeDuration
        unassignedVisitCount
        visitsAssignedToTop1CaregiverPercentage
        visitsAssignedToTop3CaregiverPercentage
      }
      tourOptimizerTours {
        endDatetime
        id
        name
        shift {
          abbreviation
          caregiver {
            qualificationGroup
          }
          id
          name
          user {
            firstName
            id
            lastName
          }
        }
        startDatetime
        tourOptimizerActivities {
          duration
          id
          startDatetime
          ... on TourOptimizerVisitType {
            earliestStartDatetime
            isEarliestStartDatetimeHard
            isLatestStartDatetimeHard
            latestStartDatetime
            plannedVisit (shouldIncludeDeleted: true) {
              client {
                id
                firstName
                lastName
              }
              hasSgbVService
              id
              isDeleted
              services {
                name
              }
            }
            visitingCaregiverStatistics {
              id
              isPrimaryCaregiver
              user {
                firstName
                lastName
              }
            }
            ${TOUR_OPTIMIZER_VISIT_CONSTRAINT_VIOLATIONS_FRAGMENT}
          }
          __typename
        }
        ${TOUR_OPTIMIZER_TOUR_CONSTRAINT_VIOLATIONS_FRAGMENT}
      }
      unassignedTourOptimizerActivities {
        duration
        id
        startDatetime
        ... on TourOptimizerVisitType {
          earliestStartDatetime
          isEarliestStartDatetimeHard
          isLatestStartDatetimeHard
          latestStartDatetime
          plannedVisit (shouldIncludeDeleted: true) {
            client {
              id
              firstName
              lastName
            }
            hasSgbVService
            isDeleted
            services {
              name
            }
            id
             ... on ReschedulingPlannedVisitType {
            visitOrder {
              id
              station {
                id
                name
              }
            }
           }
          }
          visitingCaregiverStatistics {
            id
            isPrimaryCaregiver
            user {
              firstName
              lastName
            }
          }
        }
        __typename
      }
    }
  }
`

export {
  GET_PLANNED_TOURS,
  GET_PLANNED_VISIT_BY_ID,
  GET_TOUR_OPTIMIZER_DAY,
  GET_UNASSIGNED_PLANNED_VISITS
}
