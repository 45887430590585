import React, { useState } from 'react'

import moment from 'moment'
import { Form, Input } from 'semantic-ui-react'
import { default as ReactDatePicker } from 'react-datepicker'

import styles from './index.module.css'

const TimePicker = ({
  className,
  date,
  isInputOnly,
  isLoading = false,
  onBlur,
  onChange,
  onFocus,
  shouldAutoFocus = false,
  size = 'medium',
}) => {
  const [inputValue, setInputValue] = useState(moment(date).format('HH:mm'))
  // Wrapping the date picker into `Form` applies the semantic-ui style
  return (
    <Form className={[styles.TimePicker, styles[size], className].join(' ')}>
      {isInputOnly
        ? <Input
          autoFocus={shouldAutoFocus}
          className={styles.Input}
          loading={isLoading}
          onBlur={onBlur}
          onChange={e => setInputValue(e.target.value)}
          onFocus={onFocus}
          onKeyPress={e => {if (onChange && (e.key === 'Enter')) {
            const time = moment(e.target.value, 'HH:mm')
            onChange(moment(date).set(
              { hour: time.get('hour'), minute: time.get('minute'), second: 0, millisecond: 0 }
            ).toDate())
          }}}
          type="Text"
          value={inputValue}
        />
        : <ReactDatePicker
          dateFormat={'HH:mm'}
          onChange={onChange}
          selected={date}
          showTimeSelect
          showTimeSelectOnly
          timeFormat={'HH:mm'}
          timeIntervals={15}
        />
      }
    </Form>
  )
}

export default TimePicker
