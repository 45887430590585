import React from 'react'
import { Button, Dropdown, Form, Icon, Modal } from 'semantic-ui-react'
import { setHours, setMinutes } from "date-fns"

import moment from 'moment'
import DatePicker from 'react-datepicker'

import { weekdays } from '../../constants'

import 'react-datepicker/dist/react-datepicker.css'

class DesiredTimeWindowModal extends React.Component {
  state = {
    endTime: setHours(setMinutes(new Date(), 0), 10),
    serviceGroup: null,
    startTime: setHours(setMinutes(new Date(), 0), 8),
    weekdays: [0, 1, 2, 3, 4, 5, 6],
  }

  render() {
    return <Modal
      closeIcon
      closeOnDimmerClick={false}
      onClose={this.props.onClose}
      onOpen={this.props.onOpen}
      open={this.props.open}
      size='small'
    >
      <React.Fragment>
        <Modal.Header>Feste Besuchszeit hinzufügen</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field width={12}>
              <label>Wochentag(e):</label>
              <Dropdown
                multiple
                onChange={(e, { value }) => this.setState({ weekdays: value })}
                options={weekdays}
                placeholder='bitte auswählen'
                search
                selection
                value={this.state.weekdays}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Form.Select
                fluid
                label='V / XI'
                onChange={(e, { value }) => this.setState({ serviceGroup: value })}
                options={[
                  { key: 'v', text: 'V', value: 'SGB_V' },
                  { key: 'xi', text: 'XI', value: 'SGB_XI' },
                ]}
                placeholder='Leistungsgruppe'
                value={this.state.serviceGroup}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>Startzeit:</label>
              <DatePicker
                dateFormat="HH:mm"
                onChange={startTime => this.setState({ startTime })}
                selected={this.state.startTime}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Zeit"
                timeFormat="HH:mm"
                timeIntervals={15}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>Endzeit:</label>
              <DatePicker
                dateFormat="HH:mm"
                onChange={endTime => this.setState({ endTime })}
                selected={this.state.endTime}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Zeit"
                timeFormat="HH:mm"
                timeIntervals={15}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' onClick={this.props.onClose}>
            <Icon name='remove' /> Abbrechen
          </Button>
          <Button
            color='green'
            onClick={() => this.addDesiredTimeWindow()}
            disabled={!this.state.serviceGroup || (this.state.weekdays.length === 0)}
          >
            <Icon name='checkmark' /> Speichern
          </Button>
        </Modal.Actions>
      </React.Fragment>
    </Modal>
  }

  addDesiredTimeWindow = () => this.props.onAddDesiredTimeWindow({
    earliestStartTime: moment(this.state.startTime).format('HH:mm'),
    latestEndTime: moment(this.state.endTime).format('HH:mm'),
    ...[...new Array(7).keys()].reduce((days, day) => ({
      ...days,
      [`isForDay${day}`]: this.state.weekdays.includes(day)
    }), {}),
    serviceGroup: this.state.serviceGroup
  })
}

export default DesiredTimeWindowModal
