import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { Dropdown } from 'semantic-ui-react'
import gql from 'graphql-tag'
import moment from "moment"

import { useEffectAfterRender } from '../../hooks'
import ApolloErrorButton from '../../components/ApolloErrorButton'

import styles from './index.module.css'

const PlannedTourDropdown = forwardRef((
  {
    canSelectNull,
    className,
    date,
    extraPlannedTour,
    inlineStyles,
    isUpward,
    nullText,
    onError,
    onSelect,
    shouldRefetchOnOpen,
    stationId,
    value,
  },
  ref
) => {
  const _nullText = nullText || '(Keine)'
  const _canSelectNull = canSelectNull === undefined ? true : canSelectNull
  const { error, loading, data, refetch } = useQuery(
    GET_PLANNED_TOURS,
    {
      onError,
      variables: { stationId: stationId, isAssignedToShift: false, date: moment(date).format('YYYY-MM-DD') },
    }
  )
  const plannedTours = data && data.plannedTours
  const [sortedPlannedTours, setSortedPlannedTours] = useState([])
  const [selectedValue, setSelectedValue] = useState(value)
  useImperativeHandle(ref, () => ({ refetch }))
  useEffect(() => { setSelectedValue(value) }, [value])
  useEffect(
    () => {
      const _sortedPlannedTours = [
        ...(plannedTours || []),
        ...((
          extraPlannedTour && !(plannedTours && plannedTours.map(t => t.id).includes(extraPlannedTour.id))
        ) ? [extraPlannedTour] : [])
      ]
        .sort((a, b) => a.name && a.name.toLowerCase().localeCompare(b.name && b.name.toLowerCase()))
      setSortedPlannedTours(_sortedPlannedTours)
      if (!value && !_canSelectNull && _sortedPlannedTours.length > 0) {
        setSelectedValue(_sortedPlannedTours[0].id)
      }
    },
    [plannedTours, extraPlannedTour],
  )
  useEffectAfterRender(() => onSelect && onSelect(selectedValue), [selectedValue])
  if (error) return <ApolloErrorButton error={error} title="Touren konnten nicht geladen werden" />
  return (
    <Dropdown
      className={[styles.PlannedTourDropdown, className].join(' ')}
      loading={loading}
      onChange={(e, { value }) => setSelectedValue((value === '_null' ? null : value))}
      onOpen={() => shouldRefetchOnOpen && refetch()}
      options={loading
        ? []
        : [
          ...(!_canSelectNull
            ? [] : [{ key: '_null', value: '_null', text: _nullText }]
          ),
          ...(loading ? [] : sortedPlannedTours.map(
            s => ({ key: s.id, value: s.id, text: s.name || '(Ohne Namen)' })
          ))
        ].sort((a, b) => a.text && a.text.toLowerCase().localeCompare(b.text && b.text.toLowerCase()))
      }
      placeholder={_nullText}
      selection
      style={inlineStyles}
      upward={isUpward}
      value={selectedValue}
    />
  )
})

const GET_PLANNED_TOURS = gql`
  query getPlannedTours(
    $date: Date,
    $isAssignedToShift: Boolean,
    $stationId: Int!,
  ) {
    plannedTours(
      date: $date,
      isAssignedToShift: $isAssignedToShift,
      stationId: $stationId,
    ) {
      id
      name
    }
  }
`

export default PlannedTourDropdown
