const tourConstraintViolationTypeToLabel = {
  'TourOptimizerFixedTourTimeWindowConstraintViolationType':
    'Die Tour überschreitet die reguläre Arbeitszeit',
  'TourOptimizerPreferredTourTimeWindowConstraintViolationType':
    'Die Tour ist länger als die vom Mitarbeiter gewünschte Tourdauer',
  'TourOptimizerTourForbiddenCaregiverForClientConstraintViolationType':
    'unerwünschte Mitarbeiter',
  'TourOptimizerTourForbiddenCaregiverForServiceConstraintViolationType':
    'unerwünschte Leistungen bei Mitbarbeitern',
  'TourOptimizerTourPrimaryCaregiverForVisitConstraintViolationType':
    'Bezugspfleger',
  'TourOptimizerTourRequiredQualificationForVisitConstraintViolationType':
    'passende Qualifikation',
}

const visitConstraintViolationTypeToLabel = {
  'TourOptimizerFixedVisitTimeWindowConstraintViolationType':
    'Fixzeiten wird nicht eingehalten',
  'TourOptimizerPreferredVisitTimeWindowConstraintViolationType':
    'Abweichung zur normalen Wunschzeit',
  'TourOptimizerVisitForbiddenCaregiverForClientConstraintViolationType':
    'Mitarbeiter und Klienten vertragen sich nicht',
  'TourOptimizerVisitForbiddenCaregiverForServiceConstraintViolationType':
    'Mitarbeiter kann Leistung nicht erbringen',
  'TourOptimizerVisitPrimaryCaregiverForVisitConstraintViolationType':
    'Mitarbeiter ist nicht Hauptbezugspfleger',
  'TourOptimizerVisitRequiredQualificationForVisitConstraintViolationType':
    'TourOptimizerVisitRequiredQualificationForVisitConstraintViolation',
  'TourOptimizerVisitSpecialTourForSpecialServiceConstraintViolationType':
    'Ein anderer Dienst erledigt normalerweise diesen Einsatz',
  'TourOptimizerVisitTwoVisitsInSameTourConstraintViolationType':
    'Einsätze vom gleichen Klienten sind in unterschiedlichen Touren',
}

const TOUR_OPTIMIZER_TOUR_CONSTRAINT_VIOLATIONS_FRAGMENT = `
  constraintViolations {
    id
    __typename
    ... on TourOptimizerTourForbiddenCaregiverForServiceConstraintViolationType {
      id
      service {
        id
        abbreviation
        name
      }
      __typename
    }
  }
`

const TOUR_OPTIMIZER_VISIT_CONSTRAINT_VIOLATIONS_FRAGMENT = `
  constraintViolations {
    id
    __typename
    ... on TourOptimizerVisitForbiddenCaregiverForServiceConstraintViolationType {
      id
      service {
        id
        abbreviation
        name
      }
      __typename
    }
    ... on TourOptimizerVisitSpecialTourForSpecialServiceConstraintViolationType {
      id
      __typename
    }
  }
`

export {
  TOUR_OPTIMIZER_TOUR_CONSTRAINT_VIOLATIONS_FRAGMENT,
  TOUR_OPTIMIZER_VISIT_CONSTRAINT_VIOLATIONS_FRAGMENT,
  tourConstraintViolationTypeToLabel,
  visitConstraintViolationTypeToLabel,
}
