import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './TextLink.css'

const TextLink = ({ to, text }) => (
  <Link to={to} className="TextLink">
    {text}
  </Link>
)

TextLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
export default TextLink
