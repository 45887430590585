import React from "react"
import { Button, Icon, Image } from "semantic-ui-react"
import { getStringFirstLetterInUppercase } from './../../../utils/helpers'

import styles from './FileUploadStatus.module.css'

const FileUploadStatus = props =>
  <section className={[styles.FormContent, styles[getStringFirstLetterInUppercase(props.status)]].join(' ')}>
    <div className={styles.FileUploadStatus}>
      <Image
        className={styles.UploadStatusCloud}
        size='small'
        src={props.svg}
        avatar
      />
      <h4 className={styles.FileUploadTitle}>{props.title}</h4>
      <span>{props.text}</span>
      {
        props.errors && props.errors.length > 0 &&
        <ul className={styles.ErrorMessages}>{props.errors.map((e, i) => <li key={i}><Icon name='info circle' /> {e.message}</li>)}</ul>
      }
    </div>
    <Button
      onClick={() => props.onModalClose()}
      className={styles.FileUploadStatusButton}
      loading={props.status === 'inProgress'}
    >
      {props.buttonLabel}
    </Button>
  </section>


export default FileUploadStatus
