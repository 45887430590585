import React from 'react'

import StationPicker from '../../components/StationPicker'

import './ShiftPlanningHome.css'

const ShiftPlanningHome = () => (
  <div className="ShiftPlanningHome">
    <h1>Dienstplanung</h1>
    <StationPicker linkTo={'shifts'} />
  </div>
)

export default ShiftPlanningHome
