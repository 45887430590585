import React from 'react'
import { connect } from 'react-redux'
import { Divider } from 'semantic-ui-react'

import TaskDescription from './TaskDescription'
import { setCurrentMessageId } from './../../actions/TwilioActions'

import './FeedItem.css'

class FeedItem extends React.Component {
  render() {
    const { message } = this.props
    return (
      <React.Fragment>
        <div
          className={`FeedItem ${message.tag}`}
          onClick={() => {this.props.setCurrentMessageId(message.id); this.props.onClickFeedItem()}}
        >
          <TaskDescription
            message={message}
            client={this.props.clientIdToClient && this.props.clientIdToClient[message.clientId]}
            user={this.props.userIdToUser && this.props.userIdToUser[message.userId]}
          />
        </div>
        <Divider />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentMessageId: id => dispatch(setCurrentMessageId(id))
})

export default connect(null, mapDispatchToProps)(FeedItem)
