import React, { Component } from 'react'

import debounce from 'lodash/debounce'

import './search-bar.css'

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      isUpdatingResults: false,
      results: [],
    }
    this.updateResults = debounce(this.updateResults, 300)
  }

  render = () => (
    <div className={[this.props.className, "SearchBar"].filter(Boolean).join(' ')}>
      <input
        type="text"
        value={this.state.query}
        placeholder="Adresse suchen"
        onChange={e => this.onChangeQuery(e.target.value)}
        onKeyPress={e => e.key === 'Enter' ? this.onPressEnter() : null}
      />
      {
        this.state.query ? this.renderResultList() : null
      }
    </div>
  )

  renderResultList = () => (
    <div className="Results">
      {
        this.state.results.length > 0 || this.state.isUpdatingResults
          ? this.renderResultItems()
          : <div className="NoMatch">Keine passenden Suchergebnisse gefunden</div>
      }
    </div>
  )

  renderResultItems = () => this.state.results.map((result, index) => (
    <ResultItem
      key={index}
      result={result}
      onClick={result => this.onClickResult(result)}
    />
  ))

  onChangeQuery(query) {
    this.setState({
      query: query,
      isUpdatingResults: true,
    })
    this.updateResults(query)
  }

  updateResults(query) {
    fetchResults(query, this.props.preferredCountry, this.props.preferredLocality)
      .then(results => this.setState({ results: results }))
      .catch(error => console.error(error) /*TODO: Present to user*/)
      .then(() => this.setState({ isUpdatingResults: false }))
  }

  onPressEnter() {
    // We re-fetch results since fetching them in input's onChange
    // is debounced thus we might not have results yet
    fetchResults(this.state.query, this.props.preferredCountry, this.props.preferredLocality)
      .then(results => results[0])
      .then(result => this.onClickResult(result))
  }

  onClickResult(result) {
    //TODO: Animate entered address to fly into the marker position
    //      as a clue, because the address is deleted from the input
    this.setState({
      query: '',
      results: [],
    })
    this.props.onSelectResult(result)
  }
}


//TODO: Currently we limit search to `preferredCountry` instead of preferring it
const fetchResults = (query, preferredCountry, preferredLocality) => fetch(
  'https://maps.googleapis.com/maps/api/geocode/json' +
  `?address=${query}` +
  `&components=country:${preferredCountry}` +
  `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
)
  .then(response => response.json())
  .then(data => (data.results)
    .filter(place => place.address_components.length > 1 /* exclude country matches */)
    .sort((lhs, rhs) => {
      if (getLocalityFromPlace(lhs) === preferredLocality) return -1
      if (getLocalityFromPlace(rhs) === preferredLocality) return 1
      return 0
    })
    .map(place => ({
      text: place.formatted_address,
      lat: place.geometry.location.lat,
      lng: place.geometry.location.lng,
      place: place,
    }))
  )


const getLocalityFromPlace = place => {
  const localityComponent = place.address_components
    .find(component => component.types.includes('locality'))
  return localityComponent ? localityComponent.short_name : null
}

const ResultItem = ({ result, onClick }) => (
  <div
    className="Item"
    onClick={() => onClick(result)}
  >
    {result.text}
  </div>
)

export default SearchBar
