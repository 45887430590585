import React from 'react'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import _ from 'lodash'
import { graphql, Mutation, Query } from 'react-apollo'
import { DragDropContext, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { Link } from 'react-router-dom'
import { Grid } from 'react-virtualized'
import { Button, Form, Label, Loader, Popup } from 'semantic-ui-react'

import { GET_USERS_AND_SHIFTS } from '../../../constants/queries'
import { UPDATE_USER } from '../../../constants/mutations'
import { weekendDayIndexes } from '../../../constants'
import { getDates, orderedCaregivers, orderedShiftKinds } from '../../../utils/helpers'

import 'react-virtualized/styles.css'
import './index.css'

dayjs.extend(isBetween)

class Planning extends React.Component {
  render = () => (
    <Query
      query={GET_USERS_AND_SHIFTS}
      variables={{
        isActive: true,
        stationId: this.props.match.params.stationId,
      }}
    >
      {({ data, loading }) =>
        <div className="Planning">
          <Loader active={loading} />
          {data.users &&
            <Table
              users={orderedCaregivers(data.users.filter(user => user.caregiver &&
                user.caregiver.canBeAssignedShifts))}
              startDate={this.props.plannedMonthDate}
              shiftKinds={orderedShiftKinds(data.shiftKinds)
                .map(s => ({ text: s.name, value: s.id, key: s.id }))
              }
            />
          }
          <Link to={`/shift-planning/${this.props.match.params.stationId}/previous-month`}>
            <Button primary>Weiter</Button>
          </Link>
        </div>
      }
    </Query>
  )
}

class Table extends React.Component {
  state = {
    hoveredShiftConditionDate: '',
    hoveredShiftConditionRowIndex: '',
  }

  render = () => (
    <div className='Table'>
      <header>
        <div className="Name">Name</div>
        {[...Array(this.props.startDate.daysInMonth()).keys()]
          .map(i => this.props.startDate.add(i, 'day').format('YYYY-MM-DD'))
          .map((date, index) =>
            <div className="Day" key={index}>
              <span>{dayjs(date).format('dd.')}</span>
              <span>{dayjs(date).format('DD')}</span>
            </div>
          )
        }
      </header>
      <Grid
        cellRenderer={this.renderCell}
        className="Grid"
        columnCount={this.props.startDate.daysInMonth() + 1}
        columnWidth={({ index }) => index === 0 ? 150 : 40}
        height={window.innerHeight - 210}
        rowCount={this.props.users.length}
        rowHeight={40}
        width={window.innerWidth - 20}
      />
    </div>
  )

  renderCell = ({ columnIndex, key, rowIndex, style }) => {
    const date = this.props.startDate.add(columnIndex - 1, 'day').format('YYYY-MM-DD')

    return (
      <div className={this.classNames(rowIndex, date)} style={style} key={key}>
        {columnIndex === 0
          ? <div className="Name">
            {`${this.props.users[rowIndex].lastName} ${this.props.users[rowIndex].firstName}`}
          </div>
          : <ShiftCondition
            date={date}
            dayShiftConditions={this.props.users[rowIndex].caregiver.shiftConditions
              .filter(s => s.date === date)}
            hoveredShiftConditionDate={this.state.hoveredShiftConditionDate}
            hoveredShiftConditionRowIndex={this.state.hoveredShiftConditionRowIndex}
            onEndDrag={() => this.setState({
              hoveredShiftConditionDate: '',
              hoveredShiftConditionRowIndex: '',
            })}
            onOver={(hoveredShiftConditionDate, rowIndex) => this.setState({
              hoveredShiftConditionDate: hoveredShiftConditionDate,
              hoveredShiftConditionRowIndex: rowIndex
            })}
            rowIndex={rowIndex}
            shiftConditions={this.props.users[rowIndex].caregiver.shiftConditions}
            shiftKinds={this.props.shiftKinds}
            user={this.props.users[rowIndex]}
          />
        }
      </div>
    )
  }

  classNames = (rowIndex, date) => ([
    'Cell',
    rowIndex % 2 && 'isEven',
    weekendDayIndexes.includes(dayjs(date).day()) && 'isWeekend',
    (this.props.users[rowIndex] === _.findLast(
      this.props.users,
      user => user.caregiver.qualificationGroup === 'QualificationGroup.QUALIFIED_CAREGIVER'
    )) && 'isLastQualifiedCaregiver',
  ].filter(Boolean).join(' '))
}

class ShiftCondition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      conditionKind: (conditionKind => conditionKind ? conditionKind : null)(
        this.props.dayShiftConditions
          .map(shiftCondition => shiftCondition.conditionKind)
          .filter(conditionKind => dayTypes.includes(conditionKind))[0]
      ),
      isPopupOpened: false,
      possibleShiftIds: this.getShiftIdsFromCondition('POSSIBLE_SHIFT'),
      preferredShiftIds: this.getShiftIdsFromCondition('PREFERRED_SHIFT'),
    }
  }

  render = () => this.props.connectDropTarget(this.props.connectDragSource(this.renderShiftCondition()))

  renderShiftCondition = () => (
    <div className={this.classNames()}>
      <Mutation
        mutation={UPDATE_USER}
        variables={{
          caregiver: {
            canHaveSingleFreeDays: this.props.user.caregiver.canHaveSingleFreeDays,
            canSplitWeekends: this.props.user.caregiver.canSplitWeekends,
            maximumConsecutiveWorkdays: this.props.user.caregiver.maximumConsecutiveWorkdays,
            maximumConsecutiveWorkingWeekends: this.props.user.caregiver.maximumConsecutiveWorkingWeekends,
            qualificationGroup: this.props.user.caregiver.qualificationGroup.replace('QualificationGroup.', ''),
            shiftsPerWeek: this.props.user.caregiver.shiftsPerWeek,
            shiftConditions: this.getShiftConditions()
          },
          id: this.props.user.id,
          isActive: true,
        }}
        refetchQueries={['getUsersAndShifts']}
      >
        {updateUser =>
          <Popup
            className="ShiftConditionPopup"
            trigger={this.renderConditionKind(updateUser)}
            open={this.state.isPopupOpened}
            onOpen={() => this.setState({ isPopupOpened: true })}
            onClose={() => this.setState({ isPopupOpened: false })}
            on='click'
            position='bottom center'
            content={this.renderPopupContent(updateUser)}
          />
        }
      </Mutation>
    </div>
  )

  classNames = () => ([
    'ShiftCondition',
    ((this.props.user.id === this.props.draggedUserId)
      ? (this.props.hoveredShiftConditionRowIndex === this.props.rowIndex) && (
        dayjs(this.props.date).isBetween(
          dayjs(this.props.startDate),
          dayjs(this.props.hoveredShiftConditionDate).add(1, 'day')
        ) ||
          dayjs(this.props.date).isBetween(
            dayjs(this.props.hoveredShiftConditionDate).subtract(1, 'day'),
            dayjs(this.props.startDate)
          )
      )
      : this.props.isOver) && 'isHighlighted',
    this.props.isDragging && 'isDragging',
    this.state.isPopupOpened && 'isPopupOpened',
  ].filter(Boolean).join(' '))

  isHighlighted = () => (this.props.user.id === this.props.draggedUserId)
    ? (this.props.hoveredShiftConditionRowIndex === this.props.rowIndex) && (
      dayjs(this.props.date).isBetween(
        dayjs(this.props.startDate),
        dayjs(this.props.hoveredShiftConditionDate).add(1, 'day')
      ) ||
        dayjs(this.props.date).isBetween(
          dayjs(this.props.hoveredShiftConditionDate).subtract(1, 'day'),
          dayjs(this.props.startDate)
        )
    )
    : this.props.isOver

  renderConditionKind = updateUser => (
    <div className="ConditionKind">
      {this.props.dayShiftConditions
        .map(s => s.conditionKind)
        .filter((conditionKind, i, conditionKinds) => conditionKinds.indexOf(conditionKind) === i)
        .map((conditionKind, i) =>
          (conditionKind => conditionKind &&
            <Label circular color={conditionKind.color} key={i}>
              {conditionKind.label}
            </Label>
          )(conditionKindToLabel[conditionKind.replace('ShiftConditionKind.', '')])
        )}
      {this.props.dayShiftConditions.length > 0 &&
        <Button className="Remove" icon='trash'
          onClick={e => {this.setState(prevState => ({
            conditionKind: null,
            possibleShiftIds: [],
            preferredShiftIds: []
          }), updateUser); e.stopPropagation()}}
          circular
        />
      }
    </div>
  )

  renderPopupContent = updateUser => (
    <Form>
      <div className="DayTypes">
        {dayTypes.map((conditionKind, index) =>
          <Popup
            trigger={
              <Label
                onClick={() => this.setState(
                  prevState => ({ conditionKind }),
                  () => updateUser().then(() => this.setState({ isPopupOpened: false }))
                )}
                className={`
                  ${(!this.state.conditionKind || this.state.conditionKind === conditionKind)
                  ? 'isActive'
                  : ''}
                `}
                color={conditionKindToLabel[conditionKind.replace('ShiftConditionKind.', '')]['color']}
                circular
              >
                {conditionKindToLabel[conditionKind.replace('ShiftConditionKind.', '')]['label']}
              </Label>
            }
            content={shiftConditionToLabel[conditionKind.replace('ShiftConditionKind.', '')]}
            position='bottom center'
            key={index}
          />
        )}
      </div>
      <Form.Dropdown
        className="PossibleShifts"
        label={shiftConditionToLabel['POSSIBLE_SHIFT']}
        multiple
        onChange={(e, data) => this.setState(
          prevState => ({ possibleShiftIds: data.value }),
          () => updateUser()
        )}
        options={this.props.shiftKinds}
        selection
        value={this.state.possibleShiftIds}
      />
      <Form.Dropdown
        className="PreferredShifts"
        label={shiftConditionToLabel['PREFERRED_SHIFT']}
        multiple
        onChange={(e, data) => this.setState(
          prevState => ({ preferredShiftIds: data.value }),
          () => updateUser()
        )}
        options={this.props.shiftKinds}
        selection
        value={this.state.preferredShiftIds}
      />
    </Form>
  )

  componentDidUpdate = prevProps => {
    if (prevProps.dayShiftConditions !== this.props.dayShiftConditions) {
      this.setState({
        conditionKind: this.props.dayShiftConditions
          .map(shiftCondition => shiftCondition.conditionKind)
          .filter(conditionKind => dayTypes.includes(conditionKind))[0] || null,
        possibleShiftIds: this.getShiftIdsFromCondition('POSSIBLE_SHIFT'),
        preferredShiftIds: this.getShiftIdsFromCondition('PREFERRED_SHIFT'),
      })
    }

    if (!prevProps.isOver && this.props.isOver) {
      this.props.onOver(this.props.date, this.props.rowIndex)
    }
  }

  getShiftConditions = () => ([
    ...this.props.shiftConditions
      .filter(s => s.date !== this.props.date)
      .map(s => s.shiftKind
        ? ({
          conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''),
          date: s.date,
          shiftKindId: s.shiftKind && s.shiftKind.id,
        })
        : ({ conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''), date: s.date })
      ),
    this.state.conditionKind &&
      { conditionKind: this.state.conditionKind.replace('ShiftConditionKind.', ''), date: this.props.date },
    ...this.state.possibleShiftIds.map(shiftKindId => ({
      conditionKind: "POSSIBLE_SHIFT",
      date: this.props.date,
      shiftKindId: shiftKindId,
    })),
    ...this.state.preferredShiftIds.map(shiftKindId => ({
      conditionKind: "PREFERRED_SHIFT",
      date: this.props.date,
      shiftKindId: shiftKindId,
    })),
  ].filter(Boolean))

  getShiftIdsFromCondition = conditionKind => this.props.dayShiftConditions
    .filter(c => c.conditionKind === `ShiftConditionKind.${conditionKind}`)
    .map(c => c.shiftKind.id)
}

ShiftCondition = DragSource(
  'SHIFT_CONDITION',
  {
    beginDrag: props => {
      return ({
        dayShiftConditions: props.dayShiftConditions,
        startDate: props.date,
        userId: props.user.id
      })
    },
    endDrag: props => props.onEndDrag()
  },
  (connect, monitor) => ({ connectDragSource: connect.dragSource(), isDragging: monitor.isDragging() })
)(ShiftCondition)

ShiftCondition = DropTarget(
  'SHIFT_CONDITION',
  {
    drop: (props, monitor) => {
      props.mutate({
        variables: {
          caregiver: {
            canHaveSingleFreeDays: props.user.caregiver.canHaveSingleFreeDays,
            canSplitWeekends: props.user.caregiver.canSplitWeekends,
            maximumConsecutiveWorkingWeekends: props.user.caregiver.maximumConsecutiveWorkingWeekends,
            maximumConsecutiveWorkdays: props.user.caregiver.maximumConsecutiveWorkdays,
            qualificationGroup: props.user.caregiver.qualificationGroup.replace('QualificationGroup.', ''),
            shiftsPerWeek: props.user.caregiver.shiftsPerWeek,
            shiftConditions:
              (mapShiftCondition => [
                ...props.shiftConditions.filter(s => s.date !== props.date).map(s => mapShiftCondition(s, s.date)),
                ..._.flatten(
                  [
                    ...getDates(
                      (monitor.getItem().userId === props.user.id)
                        ? dayjs(monitor.getItem().startDate)
                        : dayjs(props.date),
                      dayjs(props.date)
                    )
                  ].map(date => monitor.getItem().dayShiftConditions.map(s => mapShiftCondition(s, date)))
                ),
              ])(
                (s, date) => s.shiftKind
                  ? ({
                    conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''),
                    date,
                    shiftKindId: s.shiftKind && s.shiftKind.id,
                  })
                  : ({ conditionKind: s.conditionKind.replace('ShiftConditionKind.', ''), date })
              )
          },
          id: props.user.id,
          isActive: true,
        },
        refetchQueries: ['getUsersAndShifts'],
      })
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    startDate: monitor.getItem() && monitor.getItem().startDate,
    draggedUserId: monitor.getItem() && monitor.getItem().userId,
  })
)(ShiftCondition)

ShiftCondition = graphql(UPDATE_USER)(ShiftCondition)

const shiftConditionToLabel = {
  'CONDITION_KIND': 'Besonderheit',
  'FIXED_DAY_OFF': 'Frei (fix)',
  'OTHER_PAID_LEAVE': 'bezahlte Abwesenheit',
  'POSSIBLE_SHIFT': 'Mögliche Dienste',
  'PREFERRED_DAY_OFF': 'Wunschfrei',
  'PREFERRED_SHIFT': 'Bevorzugte Dienste',
  'PREFERRED_WORK_DAY': 'Dienst gewünscht',
  'SICK_DAY': 'Krank',
  'VACATION_DAY': 'Urlaub',
}

const dayTypes = [
  'ShiftConditionKind.FIXED_DAY_OFF',
  'ShiftConditionKind.OTHER_PAID_LEAVE',
  'ShiftConditionKind.SICK_DAY',
  'ShiftConditionKind.PREFERRED_DAY_OFF',
  'ShiftConditionKind.PREFERRED_WORK_DAY',
  'ShiftConditionKind.VACATION_DAY',
]

const conditionKindToLabel = {
  'FIXED_DAY_OFF': {
    label: 'FF',
    color: 'yellow',
  },
  'OTHER_PAID_LEAVE': {
    label: 'Abw',
    color: 'grey',
  },
  'PREFERRED_DAY_OFF': {
    label: 'WF',
    color: 'brown',
  },
  'PREFERRED_WORK_DAY': {
    label: 'Dw',
    color: 'olive',
  },
  'SICK_DAY': {
    label: 'K',
    color: 'red',
  },
  'VACATION_DAY': {
    label: 'U',
    color: 'orange',
  },
  'POSSIBLE_SHIFT': {
    label: 'Dm',
    color: 'yellow',
  },
  'PREFERRED_SHIFT': {
    label: 'Db',
    color: 'green',
  },
}

export default DragDropContext(HTML5Backend)(Planning)
