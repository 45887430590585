import gql from 'graphql-tag'

const CLIENTS_FRAGMENT = `
  clients(isActive: $isActive) {
    firstName
    id
    isActive
    lastName
    station {
      code
    }
  }
`

const CLIENT_DETAILS_FRAGMENT = `clients(id: $id) {
  addressSuffix
  allergies
  birthDate
  canAcceptLeasing
  caregiverPreferences {
    caregiver {
      id
      user {
        firstName
        id
        lastName
      }
    }
    id
    isPositive
  }
  careLevel
  careLevelAcceptanceDate
  careLevelInfo
  careLevelStatus
  careUtilities {
    id
    name
    status
  }
  careSituation
  clientDesiredTimeWindows {
    earliestStartTime
    isForDay0
    isForDay1
    isForDay2
    isForDay3
    isForDay4
    isForDay5
    isForDay6
    latestEndTime
    serviceGroup
    id
  }
  city
  contactPersons {
    city
    faxNumber
    firstName
    houseNumber
    id
    lastName
    phoneNumber
    relationshipType
    salutation
    street
    title
    zipCode
  }
  diagnosis
  familyStatus
  firstName
  firstVisitDate
  hasPrescription
  height
  houseNumber
  id
  insurances {
    benefitAmount
    id
    insuranceType
    isEntitledToBenefit
    isFreeOfCopayment
    name
    number
  }
  isActive
  isLivingAlone
  lastName
  nationality
  phoneNumber
  preferredCaregiverGender
  salutation
  serviceModule
  serviceModuleStatus
  station {
    id
    name
  }
  street
  title
  weight
  zipCode
}`

const CLIENT_VISITS_FRAGMENT = `clients(id: $id) {
  actualVisits(startFromDatetime: $startFromDatetime, startBeforeDatetime: $startBeforeDatetime) {
    actualTour {
      shift {
        abbreviation
      }
    }
    endDatetime
    id
    services {
      abbreviation
      name
      id
    }
    startDatetime
    user {
      id
      firstName
      lastName
    }
  }
  firstName
  id
  lastName
  plannedVisits(startFromDatetime: $startFromDatetime, startBeforeDatetime: $startBeforeDatetime) {
    endDatetime
    id
    plannedTour {
      shift {
        abbreviation
      }
    }
    services {
      abbreviation
      name
      id
      hint
    }
    startDatetime
    user {
      id
      firstName
      lastName
    }
  }
}`

const USER_WITH_CAREGIVER_FRAGMENT = `
  caregiver {
    canBeAssignedShifts
    canHaveSingleFreeDays
    canSplitWeekends
    clientPreferences {
      client {
        id
        firstName
        lastName
      }
      id
      isPositive
    }
    desiredWorkingTimes {
      endTime
      id
      isForDay0
      isForDay1
      isForDay2
      isForDay3
      isForDay4
      isForDay5
      isForDay6
      isForEarlyShift
      startTime
    }
    id
    maximumConsecutiveWorkdays
    maximumConsecutiveWorkingWeekends
    qualificationGroup
    shiftsPerWeek
    shiftConditions {
      conditionKind
      date
      id
      shiftKind {
        id
      }
      previousMonthWorkingWeekendCount
    }
    unwantedServices {
      abbreviation
      id
      name
    }
  }
  firstName
  id
  isActive
  lastName
`

const GET_CLIENTS_BY_NAME = gql`
  query getClient($nameQuery: String, $isActive: Boolean) {
    clients (nameQuery: $nameQuery, isActive: $isActive) {
      city
      firstName
      id
      lastName
      lat
      lng
      station {
        code
        id
      }
      street
      zipCode
    }
}
`

const GET_CLIENTS_AND_USERS = gql`
  query getClientsAndUsers($isActive: Boolean, $stationId: Int) {
    ${CLIENTS_FRAGMENT}
    users(stationId: $stationId, isActive: $isActive) {
      firstName
      id
      isActive
      lastName
      ${''/* TODO: Old shift planning expects USER_WITH_CAREGIVER_FRAGMENT fragment to be included here */}
    }
  }
`

const GET_CLIENT_DETAILS = gql`
  query getClient($id: String) {
    ${CLIENT_DETAILS_FRAGMENT}
  }
`

const GET_CLIENT_VISITS = gql`
  query getClient($id: String, $startFromDatetime: DateTime, $startBeforeDatetime: DateTime) {
    ${CLIENT_VISITS_FRAGMENT}
  }
`

const GET_MESSAGES = gql`
  query getMessages {
    messages {
      id
      clientId
      timestamp
      lastUpdatedTimestamp
      text
      userId
      tag
      tags
      stationId
      twilioSid
      submessages {
        id
        clientId
        timestamp
        lastUpdatedTimestamp
        text
        userId
        tag
        tags
        stationId
        twilioSid
        task {
          status
          dueDatetime
          assigneeUser {
            id
            firstName
            lastName
          }
        }
      }
      task {
        status
        dueDatetime
        assigneeUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`
const CREATE_MESSAGE = gql`
  mutation createMessage (
    $clientId: String,
    $parentId: String,
    $stationId: Int,
    $tag: String,
    $tags: [String],
    $taskAssigneeUserId: String,
    $taskDueDatetime: DateTime,
    $taskStatus: String,
    $text: String!
    $timestamp: DateTime!
  ) {
    createMessage (
      clientId: $clientId,
      parentId: $parentId,
      stationId: $stationId,
      tag: $tag,
      tags: $tags,
      taskAssigneeUserId: $taskAssigneeUserId,
      taskDueDatetime: $taskDueDatetime,
      taskStatus: $taskStatus,
      text: $text,
      timestamp: $timestamp
    ) {
      message {
        id
        clientId
        parentMessageId
        timestamp
        lastUpdatedTimestamp
        text
        userId
        tag
        tags
        stationId
        twilioSid
        submessages {
          id
          clientId
          timestamp
          lastUpdatedTimestamp
          text
          userId
          tag
          stationId
          twilioSid
          task {
            status
            dueDatetime
            assigneeUser {
              id
              firstName
              lastName
            }
          }
        }
        task {
          status
          dueDatetime
          assigneeUser {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

const UPDATE_MESSAGE = gql`
  mutation updateMessage (
    $id: String!,
    $lastUpdatedTimestamp: DateTime!,
    $taskAssigneeUserId: String,
    $taskDueDatetime: DateTime,
    $taskStatus: String,
    $text: String,
    $tags: [String]
  ) {
    updateMessage (
      id: $id,
      lastUpdatedTimestamp: $lastUpdatedTimestamp,
      taskAssigneeUserId: $taskAssigneeUserId
      taskDueDatetime: $taskDueDatetime,
      taskStatus: $taskStatus,
      text: $text
      tags: $tags
    ) {
      message {
        id
        clientId
        parentMessageId
        timestamp
        lastUpdatedTimestamp
        text
        userId
        tag
        tags
        stationId
        twilioSid
        submessages {
          id
          clientId
          timestamp
          lastUpdatedTimestamp
          text
          userId
          tag
          stationId
          twilioSid
          task {
            status
            dueDatetime
            assigneeUser {
              id
              firstName
              lastName
            }
          }
        }
        task {
          status
          dueDatetime
          assigneeUser {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

const GET_SHIFT_KINDS = gql`
  query getShiftKinds ($id: String, $stationId: Int) {
    shiftKinds (id: $id, stationId: $stationId) {
      abbreviation
      id
      isActive
      isForDay0
      isForDay1
      isForDay2
      isForDay3
      isForDay4
      isForDay5
      isForDay6
      name
      qualificationGroup
      shiftTime {
        id
        name
      }
      station {
        id
      }
    }
  }
`

const GET_SHIFT_TIMES = gql`
  query getShiftTimes {
    shiftTimes {
      id
      name
    }
  }
`

const GET_STATIONS = gql`
  query getStations {
    stations {
      id
      name
    }
  }
`

const GET_USERS_AND_SHIFTS = gql`
  query getUsersAndShifts($id: String, $isActive: Boolean, $stationId: Int) {
    users(id: $id, stationId: $stationId, isActive: $isActive) {
      ${USER_WITH_CAREGIVER_FRAGMENT}
    }
    shiftKinds(stationId: $stationId) {
      id
      name
      qualificationGroup
    }
  }
`

const GET_USERS = gql`
  query getUsers($isActive: Boolean, $stationId: Int) {
    users(stationId: $stationId, isActive: $isActive) {
      firstName
      id
      isActive
      lastName
      caregiver {
        id
      }
    }
  }
`

const GET_USERS_WITH_CAREGIVER_ATTRIBUTES = gql`
  query getUsers($isActive: Boolean, $stationId: Int) {
    users(stationId: $stationId, isActive: $isActive) {
      ${USER_WITH_CAREGIVER_FRAGMENT}
    }
  }
`

const GET_USER_BY_ID = gql`
  query getUserById($id: String, $isActive: Boolean, $stationId: Int) {
    users(id: $id, stationId: $stationId, isActive: $isActive) {
      ${USER_WITH_CAREGIVER_FRAGMENT}
    }
  }
`

const SHIFT_OPTIMIZER_PARAMETERS_FRAGMENT = `
  shiftOptimizerParameters(stationId: $stationId) {
    consecutiveTourBlocksWeight
    dummyEmployeeAssignmentsPenalty
    dummyEmployeeAssignmentsPenaltyWeekend
    dummyShiftForPartTimersPenalty
    dummyShiftPenalty
    flexibleEmployeesOnWeekendsWeight
    freeWeekendsEveryoneWeight
    freeWeekendsMinMaxWeight
    maxFreeWeekendsCount
    minFreeWeekendsCount
    preferredFreeDaysWeight
    preferredToursWeight
    shouldUseDummyEmployee
    shouldUseDummyShift
    slackConsecutiveDaysPenalty
    slackFreeDaysPenalty
    slackWorkingDaysPenalty
    station {
      id
    }
    transitionsWeight
  }
`

const SHIFT_OPTIMIZER_RESULTS_FRAGMENT = `
  shiftOptimizerResults(plannedMonthDate: $plannedMonthDate, stationId: $stationId) {
    assignments {
      date
      id
      isDummyShift
      isDummyUser
      isFreeShift
      shiftKind {
        id
        name
      }
      station {
        id
      }
      user {
        id
        isActive
        firstName
        lastName
        caregiver {
          canBeAssignedShifts
          canHaveSingleFreeDays
          canSplitWeekends
          id
          maximumConsecutiveWorkdays
          maximumConsecutiveWorkingWeekends
          qualificationGroup
          shiftsPerWeek
        }
      }
    }
    constraintViolations {
      constraintKind
      date
      description
      id
      shiftKind {
        id
        name
      }
      station {
        id
      }
      user {
        id
        firstName
        lastName
      }
      value
    }
  }
`

const GET_SHIFT_OPTIMIZER_RESULTS = gql`
  query getShiftOptimizerResults($isActive: Boolean, $plannedMonthDate: Date, $stationId: Int) {
    ${SHIFT_OPTIMIZER_PARAMETERS_FRAGMENT}
    ${SHIFT_OPTIMIZER_RESULTS_FRAGMENT}
    users(stationId: $stationId, isActive: $isActive) {
      ${USER_WITH_CAREGIVER_FRAGMENT}
    }
  }
`

const GET_MATCHING_TOUR = gql`
  query getMatchingTour(
    $newClient: ClientInputType!, $qualificationGroup: QualificationGroup!, $pastDaysCount: Int
  ) {
    matchingTours(
      newClient: $newClient, qualificationGroup: $qualificationGroup, pastDaysCount: $pastDaysCount
    ) {
      capacity
      distance
      station { name }
      tourName
    }
  }
`

const GET_SERVICES = gql`
  query getServices($id: String, $stationId: String) {
    services(id: $id, stationId: $stationId) {
      abbreviation
      id
      name
      canBeProvidedByCareCompanion
    }
  }
`

const GET_SPECIAL_SERVICES = gql`
  query getSpecialServices($stationId: Int) {
    specialServices(stationId: $stationId) {
      client {
        firstName
        id
        lastName
      }
      id
      service {
        id
        name
        abbreviation
      }
      shiftKind {
        abbreviation
        id
        name
      }
    }
  }
`

const GET_CLIENTS = gql`
  query getClients($stationIds: [Int], $isActive: Boolean) {
    clients(isActive: $isActive, stationIds: $stationIds) {
      firstName
      id
      isActive
      lastName
      station {
        code
      }
    }
  }
`

const GET_TWILIO_TOKEN = gql`
  query getTwilioSession {
      twilioSession {
        accessToken
        encryptionKey
      }
  }
`

const GET_VISITS_OF_TODAY = gql`
  query getVisitsOfToday($stationName: String!) {
    legacyDbActualTours(stationName: $stationName){
      actualStartTime
      name
      visits {
          actualEndTime
          actualStartTime
          caregiverName
          clientName
          plannedEndTime
          plannedStartTime
      }
    }
  }
`

export {
  CREATE_MESSAGE,
  GET_CLIENT_DETAILS,
  GET_CLIENT_VISITS,
  GET_CLIENTS_AND_USERS,
  GET_CLIENTS_BY_NAME,
  GET_CLIENTS,
  GET_MATCHING_TOUR,
  GET_MESSAGES,
  GET_SERVICES,
  GET_SHIFT_KINDS,
  GET_SHIFT_OPTIMIZER_RESULTS,
  GET_SHIFT_TIMES,
  GET_SPECIAL_SERVICES,
  GET_STATIONS,
  GET_TWILIO_TOKEN,
  GET_USER_BY_ID,
  GET_USERS_AND_SHIFTS,
  GET_USERS_WITH_CAREGIVER_ATTRIBUTES,
  GET_USERS,
  GET_VISITS_OF_TODAY,
  UPDATE_MESSAGE,
}
