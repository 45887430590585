import React, { useEffect, useState } from 'react'

import { Droppable } from 'react-beautiful-dnd'
import { Loader } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import ApolloErrorButton from '../ApolloErrorButton'
import { DraggablePlannedVisit } from './DraggablePlannedActivity'

import styles from './UnscheduledPlannedVisits.module.css'

const UnscheduledPlannedVisits = ({
  beforeStartDate,
  className,
  clientStationIds,
  date,
  fromStartDate,
  isClientStationCodeVisible,
  isDraggingEnabled,
  isOrderedOrReschedulingOrdered,
  nearbyPlannedVisitId,
  onLoaded,
}) => {
  const { loading, error, data, refetch } = useQuery(
    UNSCHEDULED_PLANNED_VISITS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        beforeStartDatetime: beforeStartDate.toISOString(),
        fromStartDatetime: fromStartDate.toISOString(),
        isOrderedOrReschedulingOrdered,
        nearbyPlannedVisitId,
        schedulableForStationIds: clientStationIds.length > 0 ? clientStationIds : null,
      }
    }
  )
  const plannedVisits = data && data.plannedVisits
  const [isRefetching, setIsRefetching] = useState(false)
  const isLoading = loading || isRefetching
  const [expandedPlannedVisitIds, setExpandedPlannedVisitIds] = useState([])
  const animatedRefetch = async () => await Promise.resolve()
    .then(() => setIsRefetching(true))
    .then(refetch)
    .catch(e => console.log(e))
    .then(() => setIsRefetching(false))
  // Reset expanded visits when they have been reloaded due to a query filter change
  useEffect(() => setExpandedPlannedVisitIds([]), [date, clientStationIds])
  useEffect(() => onLoaded(error ? [] : (plannedVisits || []), animatedRefetch), [plannedVisits, error])
  return <div className={[styles.UnscheduledPlannedVisits, className].join(' ')}>
    {isLoading && <Loader active />}
    {error && <ApolloErrorButton error={error} />}
    <Droppable droppableId="unscheduledVisits" isDropDisabled={!isDraggingEnabled}>
      {provided =>
        <div
          className={[styles.Droppable, isLoading && styles.Loading].join(' ')}
          ref={provided.innerRef}
          style={provided.droppableProps.style}
        >
          {plannedVisits && plannedVisits.length === 0 &&
            `Keine unverplanten Einsätze ${clientStationIds.length > 0
              ? "für die ausgewählte Station(en) "
              : ""} vorhanden`
          }
          {plannedVisits && plannedVisits.map((v, index) =>
            <DraggablePlannedVisit
              className={styles.PlannedVisit}
              index={index}
              isClientStationCodeVisible={isClientStationCodeVisible}
              isDraggingEnabled={isDraggingEnabled}
              isExpanded={expandedPlannedVisitIds.includes(v.id)}
              isGhosting={false}
              isIndexVisible={true}
              isSelected={false}
              key={v.id}
              onToggleExpansion={() => setExpandedPlannedVisitIds(
                expandedPlannedVisitIds.includes(v.id)
                  ? expandedPlannedVisitIds.filter(id => id !== v.id)
                  : [...expandedPlannedVisitIds, v.id]
              )}
              onToggleSelection={() => {}}
              plannedActivity={v}
              selectionCount={0}
            />
          )}
        </div>
      }
    </Droppable>
  </div>
}

const UNSCHEDULED_PLANNED_VISITS_QUERY = gql`
  query unscheduledPlannedVisits(
    $beforeStartDatetime: DateTime
    $fromStartDatetime: DateTime
    $isOrderedOrReschedulingOrdered: Boolean
    $nearbyPlannedVisitId: String
    $schedulableForStationIds: [Int]
  ) {
    plannedVisits(
      beforeStartDatetime: $beforeStartDatetime
      fromStartDatetime: $fromStartDatetime
      isOrderedOrReschedulingOrdered: $isOrderedOrReschedulingOrdered
      hasNoPlannedTourOrIsActivelyOrderedAndUnrescheduled: true
      nearbyPlannedVisitId: $nearbyPlannedVisitId
      schedulableForStationIds: $schedulableForStationIds
    ) {
      additionalDrivingDuration
      client {
        city
        firstName
        houseNumber
        id
        lastName
        lat
        lng
        station { code id }
        street
        zipCode
      }
      duration
      endDatetime
      historicallyEarliestStartDatetime
      historicallyLatestEndDatetime
      id
      isRescheduled
      lat
      lng
      requiredCaregiverQualification
      serviceGroups
      services { abbreviation id name }
      startDatetime
      tourOrder { code id status }
      ...on ReschedulingPlannedVisitType {
        isRescheduledPlannedVisitDeleted
      }
    }
  }
`

export default UnscheduledPlannedVisits
