import React, { Fragment } from 'react'
import { Breadcrumb, Loader, Message } from 'semantic-ui-react'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { Query } from "react-apollo"
import moment from 'moment'
import _ from 'lodash'

import { caregiverQualificationToLabel, orderStatusToStatusCode } from '../../constants'
import Auth from '../../utils/auth'
import OrderSteps from '../../components/tour-orders/OrderSteps'
import TourOrderActionButtons from "../../components/tour-orders/TourOrderActionButtons"

import FileUploadModal from '../../components/tour-orders/FileUploadModal'
import PlannedVisits from '../../components/tour-orders/PlannedVisits'

import PlannedTourAssignment from '../../components/tour-orders/PlannedTourAssignment'

import styles from './TourOrder.module.css'

class TourOrder extends React.Component {
  auth = new Auth()
  isTaleaUser = this.auth.getUserRole().includes('talea')
  state = {
    isFileUploadModalOpen: false,
    isTourCreationModalOpen: false,
    selectedPlannedTourId: null,
  }

  render() {
    return (
      <Query query={TOUR_ORDER_QUERY} variables={{ tourOrderId: this.props.match.params.id }}>
        {({ loading, error, data, refetch }) => {
          if (error) return `Error! ${error.message}`
          return (
            <div className={styles.Order}>
              <div className={styles.Header}>
                <div className={styles.HeaderContainer}>
                  <Breadcrumb className={styles.Breadcrumb}>
                    <Breadcrumb.Section><Link to={`/tour-orders/`}>Bestellungen</Link></Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section>
                      {(data.tourOrders && data.tourOrders.length > 0 && data.tourOrders[0].code) || "..."}
                    </Breadcrumb.Section>
                  </Breadcrumb>
                </div>
              </div>
              {
                loading
                  ? <Loader active={loading} />
                  : data.tourOrders && data.tourOrders.length > 0
                    ? <div className={styles.TourOrder}>
                      {(tourOrder => tourOrder &&
                        <Fragment>
                          <div className={styles.OrderStepsContainer}>
                            <OrderSteps currentStep={orderStatusToStatusCode[`${tourOrder.status.replace('TourOrderStatus.', '')}`]} />
                          </div>
                          <div className={styles.OrderContainer}>
                            <div>Einsatzdatum - {moment(tourOrder.plannedTour.startDatetime).format('YYYY-MM-DD')}</div>
                            <div>Einsatzzeitraum - {moment(tourOrder.plannedTour.startDatetime).format('HH:mm')} - {moment(tourOrder.plannedTour.endDatetime).format('HH:mm')}</div>
                            <div>{caregiverQualificationToLabel[tourOrder.requiredCaregiverQualification]}</div>
                          </div>
                          {this.isTaleaUser && <PlannedTourAssignment sourcePlannedTourId={tourOrder.plannedTour.id} />}
                          {
                            tourOrder.plannedTour.plannedVisits && tourOrder.plannedTour.plannedVisits.length > 0 &&
                              <PlannedVisits
                                key={tourOrder.plannedTour.id}
                                onUpdatePlannedVisit={refetch}
                                visits={tourOrder.plannedTour.plannedVisits}
                              />
                          }
                          <TourOrderActionButtons tourOrder={_.first(data.tourOrders)} handleFileUploadModal={this.handleFileUploadModal} />
                        </Fragment>
                      )(_.first(data.tourOrders))}
                    </div>
                    : <Message floating>Bestellung {this.props.match.params.id} existiert nicht</Message>
              }
              <FileUploadModal
                isOpened={this.state.isFileUploadModalOpen}
                onClose={this.handleFileUploadModal}
                tourOrder={_.first(data.tourOrders)}
                onUploaded={refetch}
              />
            </div>
          )
        }}
      </Query>
    )
  }

  handleFileUploadModal = () => this.setState({ isFileUploadModalOpen: !this.state.isFileUploadModalOpen });
}

const TOUR_ORDER_QUERY = gql`
  query tourOrders($tourOrderId: String) {
    tourOrders(tourOrderId: $tourOrderId) {
      code
      createdAtDatetime
      id
      partnerStation { id }
      plannedTour {
        endDatetime
        id
        name
        startDatetime
        plannedVisits {
          id
          startDatetime
          endDatetime
          client {
            addressSuffix
            city
            firstName
            id
            lastName
            phoneNumber
            street
            zipCode
          }
          hint
          services {
            abbreviation
            id
            name
            serviceGroup
          }
        }
      }
      requiredCaregiverQualification
      status
    }
  }
`

export default TourOrder
