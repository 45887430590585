import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'
import gql from 'graphql-tag'
import SplitterLayout from 'react-splitter-layout'
import { DragDropContext } from 'react-beautiful-dnd'
import { useMutation } from '@apollo/react-hooks'

import Map from '../../components/tour-planner2/Map'
import PlannedTours from '../../components/tour-planner2/PlannedTours'
import Toolbar from './Toolbar'
import UnscheduledPlannedVisits from '../../components/tour-planner2/UnscheduledPlannedVisits'
import { endOfDay, format, parse, startOfDay } from 'date-fns'
import { useLocalStorage } from '../../hooks'

import 'react-splitter-layout/lib/index.css'
import styles from './index.module.css'

const TourPlanner2 = ({ history, match }) => {
  console.log("Rerendering tour planner screen. TODO: Avoid unnecesary rerenderings")
  // TODO: Do not push to back stack, only rewrite URL
  const updatePath = (stationId, date) => history.push({ pathname: match.path
    .replace(':stationId', stationId)
    .replace(':date', format(date, 'yyyy-MM-dd'))
  })
  // ----------------------------------------------
  // Filter states (controlled via toolbar)
  // ----------------------------------------------
  const date = useMemo(() => parse(match.params.date, 'yyyy-MM-dd', new Date()), [match.params.date])
  const primaryStationId = match.params.stationId
  const setDate = date => updatePath(primaryStationId, date)
  const setPrincipalStationId = id => updatePath(id, date)
  // XXX: Always show primary station tour + additional stations tours
  const [secondaryStationIds, setSecondaryStationIds] = useState([])
  const [unscheduledStationIds, setUnscheduledStationIds] = useState([])
  const [unscheduledTimeRange, setUnscheduledTimeRange] = useState([
    startOfDay(new Date()), endOfDay(new Date())
  ])
  const [isUnscheduledOrderedOrReschedulingOrdered, setIsUnscheduledOrderedOrReschedulingOrdered] =
    useState(null)
  // ----------------------------------------------
  // Loaded planned tours and unscheduled planned visits
  // ----------------------------------------------
  const [plannedTours, setPlannedTours] = useState([])
  const [plannedTourIdToRefetchHash, setPlannedTourIdToRefetchHash] = useState({})
  useEffect(
    () => {
      // Only reset the refetch hashes if tours are already loaded
      if ((Object.keys(plannedTourIdToRefetchHash).length > 0) || (plannedTours.length > 0))
        setPlannedTourIdToRefetchHash({})
    },
    [plannedTours]
  )
  const [unscheduledNearbyPlannedVisits, setUnscheduledNearbyPlannedVisits] = useState([])
  // ----------------------------------------------
  // UI states
  // ----------------------------------------------
  const [secondaryPaneSize, setSecondaryPaneSize] = useLocalStorage(
    'tour-planner.secondaryPaneSize', null, { shouldDebounce: true }
  )
  const [visiblePlannedTourIds, setVisiblePlannedTourIds] = useState([])
  const [selectedPlannedActivityIds, setSelectedPlannedActivityIds] = useState([])
  const [firstSelectedPlannedVisitId, setFirstSelectedPlannedVisitId] = useState(null)
  // ID of the activity which is dragging
  const [multiDraggingPlannedActivityId, setMultiDraggingPlannedActivityId] = useState(null)
  useEffect(
    () => {
      // FIXME: plannedTours.plannedActivities will be outdated after user performed drag&drop operation.
      //        To fix this, the tours should report selected (rescheduling) visits separately
      const plannedVisitIds = plannedTours
        .flatMap(t => t.plannedActivities)
        .filter(a => ['PlannedVisitType', 'ReschedulingPlannedVisitType'].includes(a.__typename))
        .map(a => a.id)
      setFirstSelectedPlannedVisitId(_.find(selectedPlannedActivityIds, id => plannedVisitIds.includes(id)))
    },
    [selectedPlannedActivityIds.join(',')]
  )
  // ----------------------------------------------
  // Drag&drop mutation
  // ----------------------------------------------
  const [movePlannedActivities, { error: movePlannedActivitiesError }] = useMutation(
    MOVE_PLANNED_VISITS,
    { onCompleted: ({ movePlannedActivities: { affectedPlannedTours, sourcePlannedTours } }) => {
      const reloadablePlannedTourIds = _.uniq(
        [...affectedPlannedTours, ...sourcePlannedTours].map(t => t && t.id)
      )
      setPlannedTourIdToRefetchHash(prevState => reloadablePlannedTourIds
        .filter(Boolean)
        .reduce((r, id) => ({ ...r, [id]: Math.random() }), { ...prevState })
      )
      // `null` encodes that at least one visit did not have a planned tour before/after moving it
      if (reloadablePlannedTourIds.includes(null))
        refetchUnscheduledPlannedVisits()
    } }
  )
  useEffect(
    // TODO: Show notification popups instead of using alert
    () => movePlannedActivitiesError &&
      alert("Verschieben fehlgeschlagen: " + movePlannedActivitiesError.message),
    [movePlannedActivitiesError]
  )
  return (
    <div className={styles.TourPlanner}>
      <Toolbar
        className={styles.Toolbar}
        date={date}
        isUnscheduledOrderedOrReschedulingOrdered={isUnscheduledOrderedOrReschedulingOrdered}
        onChangeDate={setDate}
        onChangeIsUnscheduledOrderedOrReschedulingOrdered={setIsUnscheduledOrderedOrReschedulingOrdered}
        onChangeStationId={setPrincipalStationId}
        onChangeStationIds={setSecondaryStationIds}
        onChangeUnscheduledStationIds={setUnscheduledStationIds}
        onChangeUnscheduledTimeRange={setUnscheduledTimeRange}
        stationId={primaryStationId}
        stationIds={secondaryStationIds}
        unscheduledStationIds={unscheduledStationIds}
        unscheduledTimeRange={unscheduledTimeRange}
      />
      <SplitterLayout
        customClassName={styles.ToursAndVisitsAndMap}
        onSecondaryPaneSizeChange={setSecondaryPaneSize}
        primaryIndex={1}
        secondaryInitialSize={secondaryPaneSize || (3 * 220 + 250)} /*3x tours and 1x unscheduled visits*/
        secondaryMinSize={440}
      >
        <div className={styles.ToursAndVisits}>
          <DragDropContext
            onDragEnd={dragEnd => handleDragEnd(
              dragEnd,
              selectedPlannedActivityIds,
              plannedTours,
              movePlannedActivities,
              setMultiDraggingPlannedActivityId,
            )}
            onDragStart={dragStart => handleDragStart(
              dragStart,
              selectedPlannedActivityIds,
              setSelectedPlannedActivityIds,
              setMultiDraggingPlannedActivityId,
            )}
          >
            <PlannedTours
              className={styles.PlannedTours}
              colors={TOUR_COLORS}
              date={date}
              isDraggingEnabled={true}
              multiDraggingPlannedActivityId={multiDraggingPlannedActivityId}
              onChangeVisiblePlannedTourIds={setVisiblePlannedTourIds}
              onLoaded={setPlannedTours}
              onToggleSelection={(plannedActivityId, isMultiSelect) => setSelectedPlannedActivityIds(
                toggleSelection(plannedActivityId, isMultiSelect, selectedPlannedActivityIds)
              )}
              onUnassignedPlannedVisitsChange={refetchUnscheduledPlannedVisits}
              plannedTourIdToRefetchHash={plannedTourIdToRefetchHash}
              primaryStationId={primaryStationId}
              secondaryStationIds={secondaryStationIds}
              selectedPlannedActivityIds={selectedPlannedActivityIds}
            />
            <UnscheduledPlannedVisits
              beforeStartDate={
                dateWithTime(date, unscheduledTimeRange[1].getHours(), unscheduledTimeRange[1].getMinutes())
              }
              className={styles.UnscheduledPlannedVisits}
              clientStationIds={unscheduledStationIds}
              date={date}
              fromStartDate={
                dateWithTime(date, unscheduledTimeRange[0].getHours(), unscheduledTimeRange[0].getMinutes())
              }
              isClientStationCodeVisible={
                [primaryStationId, ...secondaryStationIds].includes('6') /* TODO: Don't hard-code */
              }
              isDraggingEnabled={true}
              isOrderedOrReschedulingOrdered={isUnscheduledOrderedOrReschedulingOrdered}
              nearbyPlannedVisitId={firstSelectedPlannedVisitId}
              onLoaded={(plannedVisits, refetch) => {
                setUnscheduledNearbyPlannedVisits(plannedVisits)
                refetchUnscheduledPlannedVisits = refetch
              }}
            />
          </DragDropContext>
        </div>
        {/*TODO: Map should load planned tours from cache instead of passing the tours as props*/}
        <Map
          className={styles.Map}
          nearbyPlannedVisits={unscheduledNearbyPlannedVisits}
          plannedTourIds={plannedTours.map(t => t.id).filter(id => visiblePlannedTourIds.includes(id))}
          plannedTourIdToColor={plannedTours.reduce(
            (r, t, index) => ({ ...r, [t.id]: TOUR_COLORS[index % TOUR_COLORS.length] }), {}
          )}
          plannedTourIdToRefetchHash={plannedTourIdToRefetchHash}
          selectedPlannedActivityIds={selectedPlannedActivityIds}
        />
      </SplitterLayout>
    </div>
  )
}

const TOUR_COLORS = [
  '#FFAF0D',
  '#00B0E7',
  '#00D98B',
  '#3d5a80',
  '#B8C5D0',
  '#FF8C76',
  '#fff05a',
  '#fac9b8',
  '#bcaf9c',
  '#95bf74',
  '#e8d2ae',
  '#98c1d9',
  '#d7b8f3',
  '#a0ddff',
  '#7dcfb6',
  '#fbd1a2',
]

// TODO: Use `useMutation(..., { refetchQueries })` attribute to let Apollo refetch these queries instead of
//       calling these refetch-methods
let refetchUnscheduledPlannedVisits = () => {}

const toggleSelection = (plannedActivityId, isMultiSelect, selectedPlannedActivityIds) =>
  isMultiSelect
    ? (selectedPlannedActivityIds.includes(plannedActivityId)
      ? selectedPlannedActivityIds.filter(i => i !== plannedActivityId)
      : [...selectedPlannedActivityIds, plannedActivityId])
    : (selectedPlannedActivityIds.includes(plannedActivityId)
      ? (selectedPlannedActivityIds.length > 1 ? [plannedActivityId] : [])
      : [plannedActivityId])

const handleDragStart = (
  { draggableId },
  selectedPlannedActivityIds,
  setSelectedPlannedActivityIds,
  setMultiDraggingPlannedActivityId,
) => {
  if (!_.includes(selectedPlannedActivityIds, draggableId) && selectedPlannedActivityIds.length > 0) {
    setSelectedPlannedActivityIds([])
  }
  else if (selectedPlannedActivityIds.length > 1) {
    setMultiDraggingPlannedActivityId(draggableId)
  }
}

const handleDragEnd = (
  { destination, draggableId },
  selectedPlannedActivityIds,
  plannedTours,
  movePlannedActivities,
  setMultiDraggingPlannedActivityId,
) => {
  if (destination) {
    let plannedActivityIds = selectedPlannedActivityIds.length >= 1
      ? selectedPlannedActivityIds
      : [draggableId]
    const destinationPlannedTour = destination.droppableId === 'unscheduledVisits'
      ? null
      : plannedTours.filter(t => t.id === destination.droppableId)[0]
    movePlannedActivities({
      variables: {
        atPosition: destination.index,
        draggingPlannedActivityId: draggableId,
        plannedActivityIds,
        toPlannedTourId: destinationPlannedTour ? destinationPlannedTour.id : '',
      }
    })
  }
  setMultiDraggingPlannedActivityId(null)
}

const dateWithTime = (date, ...items) => new Date(new Date(date).setHours(...items))

// TODO: Rename mutation to `movePlannedActivities`, also rename parameters
const MOVE_PLANNED_VISITS = gql`
  mutation movePlannedActivities(
    $atPosition: Int!,
    $draggingPlannedActivityId: String!,
    $plannedActivityIds: [String]!,
    $toPlannedTourId: String!,
  ) {
    movePlannedActivities(
      atPosition: $atPosition
      draggingPlannedActivityId: $draggingPlannedActivityId
      plannedActivityIds: $plannedActivityIds
      toPlannedTourId: $toPlannedTourId
    ) { affectedPlannedTours { id } sourcePlannedTours { id } }
  }
`

export default withRouter(TourPlanner2)
