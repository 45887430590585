import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '@material-ui/core/Avatar'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PersonIcon from '@material-ui/icons/Person'

/*
  Talea Styles
*/
import './LoggedInUser.css'

function LoggedInUser({ loggedInUser, logout }) {
  const [anchorElement, setAnchorElement] = React.useState(null)

  const handleMenu = event => setAnchorElement(event.currentTarget)
  const handleClose = () => setAnchorElement(null)

  return (
    <div>
      <Button
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="account of current user"
        color="inherit"
        onClick={handleMenu}
      >
        <Avatar className="avatar" src={loggedInUser.picture} />
        <ExpandMoreIcon />
      </Button>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id="menu-appbar"
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleClose}>
          <PersonIcon /> {loggedInUser.name}
        </MenuItem>
        <MenuItem onClick={logout}>
          <ExitToAppIcon /> Ausloggen
        </MenuItem>
      </Menu>
    </div>
  )
}

LoggedInUser.propTypes = {
  loggedInUser: PropTypes.PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
}

export default LoggedInUser
