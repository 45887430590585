import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from 'prop-types'

import PersonAvatar from './avatars/PersonAvatar'
import { taskStatusToLabel } from '../../constants'

import './TaskMetadata.css'

const TaskMetadata = ({ message, userIdToUser }) => (
  <div className="TaskMetadata">
    {
      ((!message.task || message.task.status === 'new') && !message.assigneeUser)
        ? <div className="Status">Nicht zugewiesen</div>
        : <div className="Status">{taskStatusToLabel[message.task.status]}</div>
    }
    <div className="Assignment">
      {
        message.task && (
          <div className="DueDate">
            {moment(message.task.dueDatetime).isValid()
              ? moment(message.task.dueDatetime).format('DD/MM')
              : null}
          </div>
        )
      }
      {_.values(message.submessageIdToSubmessage)
        .map((submessage, index) => submessage.task && submessage.task.status !== 'closed' &&
          userIdToUser && submessage.task.assigneeUser && submessage.task.assigneeUser.id &&
            <div key={index}>
              <PersonAvatar person={userIdToUser[submessage.task.assigneeUser.id]} />
            </div>)
      }
      <div className="SmallAvatarParent">
        <PersonAvatar person={userIdToUser[message.task && message.task.assigneeUser && message.task.assigneeUser.id]} />
      </div>
    </div>
  </div>
)

TaskMetadata.propTypes = {
  message: PropTypes.object.isRequired,
  userIdToUser: PropTypes.object.isRequired,
}

export default TaskMetadata
