import React from 'react'
import { Checkbox, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './ClientInfo.css'

const ClientInfo = ({ client }) => {
  return (
    <div className="ClientInfo">
      <div className="ProfileAndStatus">
        <div className="AvatarAndStatus">
          <Icon disabled name='user' size='massive' />
          {
            <span className={client.isActive ? 'active' : 'inactive'}></span>
          }
        </div>
        <h3>{`${client.firstName} ${client.lastName}`}</h3>
        <Link className="tourplanning" to={`/clients/${client.id}/visits`}>Einsätze dieser Woche</Link>
      </div>
      <div className="PatientGeneralInfo">
        <ul className="general">
          <li>
            <label>Dienst</label>
            <span>{client.station.name}</span>
          </li>
          <li>
            <label>Zeitspanne</label>
            <span>
              {client.firstVisitDate &&
          `${moment(client.firstVisitDate).format('DD.MM.YYYY')}
              (${moment(client.firstVisitDate).fromNow()})`
              }
            </span>
          </li>
          <li>
            <label>Pflegegrad</label>
            <span>{client.careLevel}</span>
          </li>
        </ul>
        <ul className="address">
          <li>
            <label>Adresse</label>
            <span>
              <dl>
                <dt>{client.street}</dt>
                <dd>{client.zipCode}, {client.city}</dd>
              </dl>
            </span>
          </li>
          <li>
            <label>Telefonnummer</label>
            <span>{client.phoneNumber}</span>
          </li>
        </ul>
        <ul className="clientMetaData">
          <li className="familyStatus">
            <label>Familienstand</label>
            <span>
              <dl>
                <dt>{client.familyStatus}</dt>
                <dd>
                  <Checkbox label='Alleinlebend' checked={client.isLivingAlone} />
                </dd>
              </dl>
            </span>
          </li>
          <li className="insurances">
            <label>Versicherungen</label>
            <ul>
              {client.insurances && client.insurances.map((insurance, index) => (
                <li key={index}>
                  <dl>
                    <dt>{insurance.insuranceType}</dt>
                    {insurance.isEntitledToBenefit &&
                      <dd><Icon disabled name='check' /> Beihilfeberechtigt</dd>
                    }
                  </dl>
                  <dl>
                    {insurance.isFreeOfCopayment &&
                      <dd><Icon disabled name='check' /> Zuzahlungsbefreit </dd>
                    }
                  </dl>
                </li>
              ))}
            </ul>
          </li>
          <li className="doctors">
            <label>Arzt/Ärztin</label>
            <ul>
              {(client.contactPersons || [])
                .filter(contactPerson => contactPerson.relationshipType === 'Arzt')
                .map((doctor, index) => (
                  <li key={index}>
                    <dl>
                      <dt>{`${doctor.lastName}, ${doctor.firstName}`}
                      </dt>
                      <dd>{`${doctor.street}, ${doctor.zipCode} ${doctor.city}`}</dd>
                      {doctor.phoneNumber && <dd>{`Tel: ${doctor.phoneNumber}`}</dd>}
                    </dl>
                  </li>
                ))
              }
            </ul>
          </li>
          <li className="Diagnosis">
            <label>Diagnose</label>
            <span>{client.diagnosis}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

ClientInfo.propTypes = {
  client: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    careLevel: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    isLivingAlone: PropTypes.bool,
    diagnosis: PropTypes.string,
  }).isRequired,
}

export default ClientInfo
