import React from 'react'

import { Mutation } from 'react-apollo'
import { Popup } from 'semantic-ui-react'
import gql from 'graphql-tag'
import { Loader } from 'semantic-ui-react'

import { getShiftKindBackgroundStyle } from "../../../utils/helpers"

import styles from './DayCell.module.css'

class DayCell extends React.Component {
  state = {
    isPopupOpened: false,
    selectedShiftKindId: '',
    shift: null,
  }

  render = () => (
    <Popup
      className={styles.DayCellPopup}
      trigger={this.renderPopupTrigger()}
      open={this.state.isPopupOpened}
      onOpen={() => this.setState({ isPopupOpened: true })}
      onClose={() => this.setState({ isPopupOpened: false })}
      on='click'
      position='bottom center'
      content={this.renderPopupContent()}
    />
  )

  renderPopupTrigger = () => {
    const shiftKind = this.props.shiftKinds.find(s => s.id === this.state.selectedShiftKindId)
    return (
      <div
        className={[
          styles.DayCell,
          this.props.isWeekend ? styles.isWeekend : '',
          this.props.rowIndex % 2 ? styles.isEvenRow : '',
        ].join(' ')}
        style={{ ...this.props.style, ...getShiftKindBackgroundStyle(shiftKind) }}
      >
        {shiftKind && shiftKind.abbreviation}
      </div>
    )
  }

  renderPopupContent = () => (
    <div className={styles.PopupContent}>
      {this.props.shiftKinds.map(shiftKind => this.renderShiftKind(shiftKind))}
    </div>
  )

  renderShiftKind = shiftKind =>
    <Mutation
      mutation={CREATE_OR_UPDATE_SHIFT}
      variables={{
        caregiverId: this.props.caregiverId,
        date: this.props.date,
        id: this.state.shift && this.state.shift.id,
        shiftKindId: shiftKind.id,
        stationId: 6,
      }}
      refetchQueries={['getShifts', 'getShiftKinds']}
      onCompleted={({ createOrUpdateShift: { shift } }) => this.setState({
        shift,
        selectedShiftKindId: shift.shiftKind.id,
        isPopupOpened: false,
      })}
      onError={() => alert("Dienstzuweisung fehlgeschlagen. Probiere bitte erneut oder lade die Seite neu.")}
      key={shiftKind.id}
    >
      {(createOrUpdateShift, { loading }) =>
        <div
          className={styles.ShiftKind}
          style={getShiftKindBackgroundStyle(shiftKind)}
          onClick={createOrUpdateShift}
        >
          {loading && <Loader active />}
          {shiftKind.abbreviation}
        </div>
      }
    </Mutation>

  componentDidMount = () => this.setState({
    shift: this.props.shift,
    selectedShiftKindId: this.props.shift && this.props.shift.shiftKind && this.props.shift.shiftKind.id
  })

  componentDidUpdate = prevProps => {
    if (prevProps.shift !== this.props.shift) {
      this.setState({
        shift: this.props.shift,
        selectedShiftKindId: this.props.shift && this.props.shift.shiftKind && this.props.shift.shiftKind.id
      })
    }
  }
}

const CREATE_OR_UPDATE_SHIFT = gql`
  mutation createOrUpdateShift(
    $caregiverId: String!,
    $date: Date!,
    $id:String,
    $shiftKindId: String!,
    $stationId: Int!
  ) {
    createOrUpdateShift(
      caregiverId: $caregiverId,
      date: $date,
      id: $id,
      shiftKindId: $shiftKindId,
      stationId: $stationId
    ) {
      shift {
        abbreviation
        caregiver {
          id
        }
        endDatetime
        id
        name
        shiftKind {
          abbreviation
          id
          name
        }
        startDatetime
      }
    }
  }
`

export default DayCell
