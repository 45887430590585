import React, { useEffect, useState } from "react"

import { Dropdown } from "semantic-ui-react"
import gql from "graphql-tag"

import ApolloErrorButton from '../ApolloErrorButton'
import { useMergeQuery } from '../../hooks'

import styles from './index.module.css'

const StationDropdown = ({
  canSelectNull, className, isMultiSelect, nullText, onError, onSelect, value
}) => {
  const _canSelectNull = canSelectNull === undefined ? true : canSelectNull
  const { loading, error, data } = useMergeQuery(STATIONS_QUERY, { onError })
  const stations = data && data.stations
  const [sortedStations, setSortedStations] = useState([])
  const [selectedValue, setSelectedValue] = useState(value)
  useEffect(
    () => {
      const _sortedStations = stations &&
        [...stations].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      if (_sortedStations) {
        setSortedStations(_sortedStations)
        if (!isMultiSelect && !value && !_canSelectNull && _sortedStations.length > 0) {
          setSelectedValue(_sortedStations[0].id)
        }
      }
    },
    [stations]
  )
  useEffect(() => onSelect && onSelect(selectedValue), [selectedValue])
  if (error) return <ApolloErrorButton error={error} />
  return (
    <Dropdown
      className={[styles.StationDropdown, className].join(' ')}
      loading={loading}
      multiple={isMultiSelect || false}
      onChange={(e, { value }) => setSelectedValue(
        isMultiSelect ? value : (value === '_null' ? null : value)
      )}
      options={(loading || error)
        ? []
        : [
          ...(isMultiSelect || !_canSelectNull
            ? [] : [{ key: '_null', value: '_null', text: nullText || "(Keine Auswahl)" }]
          ),
          ...((loading || error) ? [] : sortedStations.map(s => ({ key: s.id, value: s.id, text: s.name })))
        ].sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
      }
      placeholder={nullText || "(Keine Auswahl)"}
      selection
      value={selectedValue}
    />
  )
}

const STATIONS_QUERY = gql`query stations { stations { id name } }`

export default StationDropdown
