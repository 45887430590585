import React from 'react'

const TourPath = ({ color, latLngs, mapView }) =>
  <svg width={mapView.size.width + 'px'} height={mapView.size.height + 'px'}>
    <polyline
      points={pathPolylinePoints(latLngs, mapView)}
      fill="none"
      stroke={color}
      strokeDasharray="6 6"
      strokeWidth="3px"
    />
  </svg>

const pathPolylinePoints = (latLngs, mapView) => {
  const lngMultiplier = mapView.size.width / (mapView.bounds.se.lng - mapView.bounds.nw.lng)
  const latMultiplier = mapView.size.height / (mapView.bounds.se.lat - mapView.bounds.nw.lat)
  return latLngs.map(latLng =>
    Math.round((latLng.lng - mapView.bounds.nw.lng) * lngMultiplier) + ',' +
      Math.round((latLng.lat - mapView.bounds.nw.lat) * latMultiplier)
  )
    .join(' ')
}

export default TourPath
