import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { PlannedBreak, PlannedGenericActivity, PlannedVisit } from './PlannedActivity'

import styles from './DraggablePlannedActivity.module.css'

const DraggablePlannedActivity = ({
  PlannedActivity, className, index, isDraggingEnabled, isGhosting, plannedActivity, selectionCount, ...rest
}) =>
  <Draggable
    draggableId={plannedActivity.id}
    index={index}
    isDragDisabled={!isDraggingEnabled}
    key={plannedActivity.id}
  >
    {(provided, snapshot) =>
      <PlannedActivity
        className={[
          className, snapshot.isDragging && styles.IsDragging, isGhosting && styles.IsGhosting
        ].filter(Boolean).join(' ')}
        index={index}
        plannedActivity={plannedActivity}
        ref={provided.innerRef}
        selectionCount={snapshot.isDragging ? selectionCount : null}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        {...rest}
      />
    }
  </Draggable>

const DraggablePlannedBreak = props => (
  <DraggablePlannedActivity PlannedActivity={PlannedBreak} {...props} />
)

const DraggablePlannedGenericActivity = props => (
  <DraggablePlannedActivity PlannedActivity={PlannedGenericActivity} {...props} />
)

const DraggablePlannedVisit = props => (
  <DraggablePlannedActivity PlannedActivity={PlannedVisit} {...props} />
)

export { DraggablePlannedGenericActivity, DraggablePlannedBreak, DraggablePlannedVisit }

