import React from 'react'

import { graphql, Mutation } from 'react-apollo'
import { Checkbox, Loader } from 'semantic-ui-react'
import _ from 'lodash'

import { GET_SERVICES } from '../../constants/queries'
import { UPDATE_SERVICE_SETTINGS } from '../../constants/mutations'

import styles from './Services.module.css'

class Services extends React.Component {
  render = () => (
    <div className={styles.Services}>
      <div className={styles.Header}>
        <h3>Leistungen</h3>
      </div>
      <div className={styles.ListHeader}>
        <div>Abkürzung</div>
        <div>Leistung</div>
        <div>Von Hilfskraft durchführbar</div>
      </div>
      <ul className={styles.ServicesList}>
        {this.props.data.services
          ? _.orderBy(this.props.data.services, ['abbreviation']).map(service =>
            <li className={styles.Service} key={service.id}>
              <div>{service.abbreviation}</div>
              <div>{service.name}</div>
              <CareCompanionProvidableService
                serviceId={service.id}
                canBeProvidedByCareCompanion={service.canBeProvidedByCareCompanion}
              />
            </li>
          )
          : <Loader active size='medium' />
        }
      </ul>
    </div>
  )
}

class CareCompanionProvidableService extends React.Component {
  state = {
    canBeProvidedByCareCompanion: this.props.canBeProvidedByCareCompanion || false,
  }

  render = () => (
    <Mutation
      mutation={UPDATE_SERVICE_SETTINGS}
      variables={{
        id: this.props.serviceId,
        canBeProvidedByCareCompanion: this.state.canBeProvidedByCareCompanion,
      }}
      refetchQueries={['getServices']}
    >
      {updateServiceSettings => (
        <Checkbox
          checked={this.state.canBeProvidedByCareCompanion}
          onChange={(e, { checked }) => this.setState(
            prevState => ({ canBeProvidedByCareCompanion: checked }),
            updateServiceSettings
          )}
        />
      )}
    </Mutation>
  )

  componentDidUpdate = prevProps => {
    if (prevProps.canBeProvidedByCareCompanion !== this.props.canBeProvidedByCareCompanion) {
      this.setState({ canBeProvidedByCareCompanion: this.props.canBeProvidedByCareCompanion })
    }
  }
}

export default graphql(
  GET_SERVICES,
  { options: props => ({ variables: { stationId: props.stationId } }) }
)(Services)
