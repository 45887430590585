import React from 'react'

import { Button, Table } from 'semantic-ui-react'

import styles from './UnwantedServicesTable.module.css'

const UnwantedServicesTable = ({ unwantedServices, onRemoveUnwantedService }) => (
  <Table className={styles.UnwantedServicesTable} celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell>Abkürzung</Table.HeaderCell>
        <Table.HeaderCell>Leistung</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {unwantedServices.map(service =>
        <Table.Row key={service.id}>
          <Table.Cell>
            <Button icon='delete' onClick={() => onRemoveUnwantedService(service.id)} />
          </Table.Cell>
          <Table.Cell>{service.abbreviation}</Table.Cell>
          <Table.Cell>{service.name}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
)

export default UnwantedServicesTable
