import React from 'react'

import { Mutation } from 'react-apollo'
import { Button } from 'semantic-ui-react'
import _ from 'lodash'

import { UPDATE_CAREGIVER } from '../../../constants/mutations'
import ErrorDialog from '../../../components/dialogs/ErrorDialog'
import WorkingTimeModal from './WorkingTimeModal'
import WorkingTimesTable from './WorkingTimesTable'

import styles from './index.module.css'

class DesiredWorkingTimes extends React.Component {
  state = {
    desiredWorkingTimes: this.props.caregiverUser.caregiver.desiredWorkingTimes || [],
    isWorkingTimeModalOpened: false,
    error: null,
  }

  render = () => (
    <Mutation
      mutation={UPDATE_CAREGIVER}
      variables={{
        id: this.props.caregiverUser.caregiver.id,
        desiredWorkingTimes: this.state.desiredWorkingTimes.map(w => _.omit(w, ['id', '__typename']))
      }}
      onCompleted={() => this.setState({ isWorkingTimeModalOpened: false })}
      onError={error => this.setState({
        error: error,
        isWorkingTimeModalOpened: false,
        desiredWorkingTimes: this.props.caregiverUser.caregiver.desiredWorkingTimes
      })}
      refetchQueries={['getUserById']}
    >
      {updateCaregiver =>
        <div className={styles.DesiredWorkingTimes}>
          <div className={styles.Header}>
            <h4>Hat folgende Arbeitszeiten:</h4>
            <Button icon='add' onClick={() => this.setState({ isWorkingTimeModalOpened: true })} />
          </div>
          <WorkingTimesTable
            desiredWorkingTimes={this.state.desiredWorkingTimes}
            onRemoveWorkingTime={workingTimeId => this.setState(
              prevState => ({ desiredWorkingTimes: prevState.desiredWorkingTimes
                .filter(desiredWorkingTime => desiredWorkingTime.id !== workingTimeId)
              }),
              updateCaregiver
            )}
          />
          <WorkingTimeModal
            caregiverUser={this.props.caregiverUser}
            isOpened={this.state.isWorkingTimeModalOpened}
            onOpen={() => this.setState({ isWorkingTimeModalOpened: true })}
            onClose={() => this.setState({ isWorkingTimeModalOpened: false })}
            onAddWorkingTime={workingTime => this.setState(
              prevState => ({ desiredWorkingTimes: [...prevState.desiredWorkingTimes, workingTime] }),
              updateCaregiver
            )}
          />
          {this.state.error &&
            <ErrorDialog
              error={this.state.error}
              isOpened={!!this.state.error}
              onClose={() => this.setState({ error: null })}
            />
          }
        </div>
      }
    </Mutation>
  )

  componentDidUpdate = prevProps => {
    if (
      prevProps.caregiverUser.caregiver.desiredWorkingTimes !==
      this.props.caregiverUser.caregiver.desiredWorkingTimes
    ) {
      this.setState({ desiredWorkingTimes: this.props.caregiverUser.caregiver.desiredWorkingTimes })
    }
  }
}

export default DesiredWorkingTimes
