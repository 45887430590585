import React from 'react'
import { Popup } from "semantic-ui-react"
import moment from "moment"

const RelativeTimestamp = ({ timestamp }) => <Popup
  inverted
  trigger={<span>{moment(timestamp).fromNow()}</span>}
  content={moment(timestamp).format('MMMM Do YYYY, H:mm:ss')}
  size='mini'
/>

export default RelativeTimestamp