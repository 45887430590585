import React, { Fragment } from "react"
import axios from 'axios'

import Auth, { getAccessToken } from "../../utils/auth"

import FileUploadForm from './file-upload/FileUploadForm'
import FileUploadStatus from "./file-upload/FileUploadStatus"
import Modal from '../Modal'

import ExcitedCloud from "./../svgs/excited_cloud.svg"
import SuccessCloud from "./../svgs/success_cloud.svg"
import AngryCloud from "./../svgs/angry_cloud.svg"
import SadCloud from "./../svgs/sad_cloud.svg"

import styles from './FileUploadModal.module.css'


const api = process.env.REACT_APP_API_URL

class FileUploadModal extends React.Component {
  auth = new Auth();
  state = {
    isFormVisible: true,
    isUploaded: false,
    isFileInWrongFormat: false,
    fileUploadError: null,
    stationId: null,
    uploadProgress: 0,
  };

  render = () =>
    <Modal
      isOpened={this.props.isOpened === undefined ? true : this.props.isOpened}
      onMount={() => this.setState({ isFormVisible: true })}
      onClose={() => this.handleModalClose(this.props.onClose)}
      className={styles.FileUploadModal}
    >
      <Fragment>
        {this.state.isFormVisible &&
          <FileUploadForm
            onAcceptFile={this.handleUploadFile}
            onSelectStationId={stationId =>  this.setState({ stationId })}
            stationId={this.state.stationId}
          />
        }
        {!!this.state.uploadProgress &&
          <FileUploadStatus
            title='Dokument wird hochgeladen'
            text='Dies kann einen Moment dauern. Bitte warten Sie'
            status='inProgress'
            svg={ExcitedCloud}
            buttonLabel='Lädt'
          />
        }
        {this.state.isFileInWrongFormat &&
          <FileUploadStatus
            title='Ups! Falsches Format hochgeladen.'
            text='Bitte laden sie Ihren Tourenplan im PDF Format hoch.'
            status='error'
            svg={AngryCloud}
            buttonLabel='Neu hochladen'
            onModalClose={this.handleModalClose}
          />
        }
        {this.state.fileUploadError &&
          <FileUploadStatus
            title='Ups! Es ist ein Fehler aufgetreten.'
            text='Bitte versuchen Sie es erneut, oder kontaktieren Sie uns unter springer@talea.de bzw. 030 610 821 181.'
            errors={this.state.fileUploadError}
            svg={SadCloud}
            status='error'
            buttonLabel='Tourenplan hochladen'
            onModalClose={this.handleModalClose}
          />
        }
        {this.state.isUploaded &&
          <FileUploadStatus
            title='Tourenplan erfolgreich hochgeladen.'
            text='Bitte überprüfen Sie die Daten. Sollte der importierte Tourenplan fehlerhaft sein,
              versuchen Sie ihn erneut hochzuladen oder kontaktieren Sie uns unter
              springer@talea.de bzw. (030) 610 821 181.'
            status='success'
            svg={SuccessCloud}
            buttonLabel='Jetzt Daten überprüfen'
            onModalClose={() => this.handleModalClose(this.props.onClose, 'success')}
          />
        }
      </Fragment>
    </Modal>

  handleModalClose = (onClose, status) => {
    this.setState(
      prevState => ({
        isFormVisible: (status !== 'success'),
        isUploaded: false,
        isFileInWrongFormat: false,
        fileUploadError: null,
        uploadProgress: 0
      }),
      onClose
    )
  }

  handleUploadFile = pdfFile => {
    if (!this.checkMimeType(pdfFile)) return
    if (!this.state.stationId) return // TODO: Instead deactivate upload button if stationId not set

    this.setState({
      isFormVisible: false,
      uploadProgress: 1
    })

    const formData = new FormData()
    formData.append('pdf_file', pdfFile)

    let uploadUrl = this.props.tourOrder
      ? `${api}/stations/${this.props.tourOrder.partnerStation.id}/tour-orders/${this.props.tourOrder.id}/planned-tours`
      : `${api}/stations/${this.state.stationId}/tour-orders`

    axios.post(uploadUrl, formData, {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
      onUploadProgress: progressEvent => {
        this.setState({ uploadProgress: (progressEvent.loaded / progressEvent.total) * 100 })
      },
    })
      .then(res => {
        res.data.errors.length > 0
          ? this.setState({ uploadProgress: 0, fileUploadError: res.data.errors })
          : this.setState({ uploadProgress: 0, isUploaded: true }, this.props.onUploaded)
      })
      .catch(error => this.setState({ uploadProgress: 0, fileUploadError: error }))
  }

  checkMimeType = file => {
    if (file.type !== 'application/pdf'){
      this.setState({ isFileInWrongFormat: true, uploadProgress: 0, isFormVisible: false })
      return false
    }
    return true
  }
}

export default FileUploadModal
