import React from 'react'
import { getCategoryLabel } from '../../../utils/helpers'

import supplyManagementWhite from '../../svgs/supplyManagementWhite.svg'
import './FloatingMenuItem.css'

const FloatingMenuItem = ({ showDialog }) => (
  <div className='FloatingMenuItem'>
    <div className='InputMenuItem Tourenplanung' onClick={() => showDialog('visit-planning')}>
      <span className='label'>{getCategoryLabel('visit-planning')}</span>
      <button className="circular ui icon button">
        <i className="icon calendar"></i>
      </button>
    </div>
    <div className='InputMenuItem orderMed' onClick={() => showDialog('drug-order')}>
      <span className='label'>{getCategoryLabel('drug-order')}</span>
      <img src={supplyManagementWhite} className="orderMed" alt="orderMed" />
    </div>
    <div className='InputMenuItem Gesundheitszustand' onClick={() => showDialog('health')}>
      <span className='label'>{getCategoryLabel('health')}</span>
      <button className="circular ui icon button">
        <i className="icon heart"></i>
      </button>
    </div>
    <div className='InputMenuItem General' onClick={() => showDialog('')}>
      <span className='label'>{getCategoryLabel('general')}</span>
      <button className="circular ui icon button">
        <i className="icon comment"></i>
      </button>
    </div>
  </div>
)

export default FloatingMenuItem
