import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import App from './App'
import Auth from '../utils/auth'
import Caregivers from './caregivers/Caregivers'
import CaregiversHome from './caregivers'
import ClientHome from './clients'
import ClientMapHome from './clients-map'
import ClientMatchingForm from '../components/client-matching'
import ClientPage from './clients/ClientPage'
import ClientVisits from './clients/ClientVisits'
import Error from '../components/Error'
import Forbidden from "../components/Forbidden"
import LiveVisitsApp from "./live-visits"
import LoginScreen from '../components/LoginScreen'
import LogoutScreen from '../components/LogoutScreen'
import ShiftPlanner2 from './shift-planner2'
import OAuthCallback from '../components/OAuthCallback'
import TourOrder from './tour-orders/TourOrder'
import PrivateRoute from '../utils/PrivateRoute'
import RecoEngine from '../components/reco-engine'
import Result from '../components/client-matching/Result'
import ServicesPage from './services/ServicesPage'
import ServicesRoot from './services/ServicesRoot'
import ServicesLegacyHome from './services-legacy'
import ServicesLegacyPage from './services-legacy/ServicesPage'
import ShiftPlanning from './shift-planning'
import ShiftPlanningHome from './shift-planning/ShiftPlanningHome'
import Stations from './stations'
import TourOrders from './tour-orders'
import TaskManager from './taskmanager'
import TourPlanner2 from './tour-planner2'
import TourPlanner2Home from './tour-planner2/TourPlanner2Home'

import { notFoundErrorMessage } from '../constants'


const MainRoutes = () => (
  <Router>
    <Switch>
      <Route exact path='/login' component={() => <LoginScreen auth={new Auth()} />} />
      <Route exact path='/logout' component={() => <LogoutScreen />} />
      <Route path='/oauth/callback' component={OAuthCallback} />
      <App>
        <Switch>
          <Route exact path='/forbidden' component={props => <Forbidden {...props} />} />
          {privateRoutes.map((r, i) => <PrivateRoute
            component={r[1]} exact key={i} path={r[0]} requiredPermission={r[2]}
          />)}
          <Route component={() => <Error text={notFoundErrorMessage} code='404' />} />
        </Switch>
      </App>
    </Switch>
  </Router>
)

const privateRoutes = [
  ['/', TaskManager, "visit:tasks"],
  ['/caregivers', CaregiversHome, "visit:caregivers"],
  ['/caregivers/:stationId', Caregivers, "visit:caregivers"],
  ['/caregivers/:stationId/:caregiverUserId', Caregivers, "visit:caregivers"],
  ['/client-matching', ClientMatchingForm, "visit:clientMatching"],
  ['/client-matching/result', Result, "visit:clientMatching"],
  ['/clients', ClientHome, "visit:clients"],
  ['/clients-map', ClientMapHome, "visit:clientsMap"],
  ['/clients/:clientId', ClientPage, "visit:clients"],
  ['/clients/:clientId/visits', ClientVisits, "visit:clients"],
  ['/live-visits', LiveVisitsApp, "visit:liveVisits"],
  ['/reco-engine', RecoEngine, "visit:recoEngine"],
  ['/services', ServicesRoot, "visit:services"],
  ['/services/:stationId', ServicesPage, "visit:services"],
  ['/services-legacy', ServicesLegacyHome, "visit:services"],
  ['/services-legacy/:stationId', ServicesLegacyPage, "visit:services"],
  ['/shift-planner2/:stationId', ShiftPlanner2, "visit:shiftPlanner"],
  ['/shift-planning', ShiftPlanningHome, "visit:shiftPlanning"],
  ['/shift-planning/:stationId', ShiftPlanning, "visit:shiftPlanning"],
  ['/stations', Stations, "visit:stations"],
  ['/tasks', TaskManager, "visit:tasks"],
  ['/tour-orders', TourOrders, "visit:careShop"],
  ['/tour-orders/:id', TourOrder, "visit:careShop"],
  ['/tour-planner2', TourPlanner2Home, "visit:tourPlanning"],
  ['/tour-planner2/:stationId/:date', TourPlanner2, "visit:tourPlanning"],
]

export default MainRoutes
