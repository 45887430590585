import React, { Component } from 'react'

import GoogleMap from 'google-map-react'

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import ClientMapMarker from '../../components/maps/ClientMapMarker'
import config from '../../constants/config'
import MapMarker from '../../components/MapMarker'
import SearchBar from './search-bar'

import styles from './index.module.css'

class ClientMapHome extends Component {

  constructor(props) {
    super(props)
    this.state = {
      searchResult: null,
      center: { lat: 52.5192, lng: 13.4061 },
    }
  }

  render() {
    return (
      <div className={styles.ClientMapHome}>
        <GoogleMap
          bootstrapURLKeys={{ key: config.googleMapsApiKey }}
          center={this.state.center}
          zoom={11}
        >
          {[
            ...((!this.props.data.loading && this.props.data.stations.map((station, index) => {
              const color = STATION_COLORS[index % STATION_COLORS.length]
              return station.clients
                .filter(client => client.isActive)
                .map(client =>
                  <ClientMapMarker
                    className={styles.ClientMapMarker}
                    client={client}
                    color={['blue', 'red', 'green', 'orange', 'purple'][index % 5]}
                    key={client.id}
                    label={client.firstName.charAt(0) + client.lastName.charAt(0)}
                    lat={client.lat}
                    lng={client.lng}
                    style={{ '--background-color': 'white', '--border-color': color, '--color': color }}
                  />
                )
            })) || []),
            this.state.searchResult
              ? <MapMarker
                key="searchResult"
                lat={this.state.searchResult.lat}
                lng={this.state.searchResult.lng}
                onDelete={() => this.setState({ searchResult: null })}
                place={this.state.searchResult}
              />
              : null
          ]}
        </GoogleMap>
        <SearchBar
          className={styles.SearchBar}
          preferredCountry={'Germany'}
          preferredLocality={'Berlin'}
          onSelectResult={result => this.onSelectSearchResult(result)}
        />
      </div>
    )
  }

  onSelectSearchResult(result) {
    this.setState({ searchResult: result, center: { lat: result.lat, lng: result.lng } })
  }
}

const StationsQuery =
  gql`{stations{clients(isActive: true){id isActive firstName lastName city zipCode street lat lng}}}`

const STATION_COLORS = [
  '#FFAF0D',
  '#00B0E7',
  '#00D98B',
  '#3d5a80',
  '#B8C5D0',
  '#FF8C76',
  '#fff05a',
  '#fac9b8',
  '#bcaf9c',
  '#95bf74',
  '#e8d2ae',
  '#98c1d9',
  '#d7b8f3',
  '#a0ddff',
  '#7dcfb6',
  '#fbd1a2',
]

export default graphql(StationsQuery)(ClientMapHome)
