import React from 'react'

import dayjs from 'dayjs'
import { Route, Switch } from 'react-router-dom'

import Caregivers from '../../components/shift-planning/caregivers'
import MonthSelector from '../../components/MonthSelector'
import Planning from '../../components/shift-planning/planning'
import PreviousMonth from '../../components/shift-planning/previous-month'
import ShiftOptimizer from '../../components/shift-planning/shift-optimizer'
import Shifts from '../../components/shift-planning/shifts'
import Steps from '../../components/shift-planning/Steps'

import './index.css'

class ShiftPlanning extends React.Component {
  state = {
    plannedMonthDate: dayjs().add(1, 'month').startOf('month').endOf('day'),
  }

  render = () => {
    return (
      <div className="ShiftPlanning">
        <Steps />
        <MonthSelector
          onClickPreviousMonth={() => this.setState(prevState => ({
            plannedMonthDate: dayjs(prevState.plannedMonthDate)
              .subtract(1, 'month').startOf('month').endOf('day')
          }))}
          onClickNextMonth={() => this.setState(prevState => ({
            plannedMonthDate: dayjs(prevState.plannedMonthDate)
              .add(1, 'month').startOf('month').endOf('day')
          }))}
          plannedMonthDate={this.state.plannedMonthDate}
        />
        <Switch>
          <Route path='/shift-planning/:stationId/shifts' component={Shifts} />
          <Route path='/shift-planning/:stationId/caregivers' component={Caregivers} />
          <Route
            path='/shift-planning/:stationId/planning'
            render={props => <Planning plannedMonthDate={this.state.plannedMonthDate} {...props} />}
          />
          <Route
            path='/shift-planning/:stationId/previous-month'
            render={props => <PreviousMonth plannedMonthDate={this.state.plannedMonthDate} {...props} />}
          />
          <Route
            path='/shift-planning/:stationId/shift-optimizer'
            render={props => <ShiftOptimizer plannedMonthDate={this.state.plannedMonthDate} {...props} />}
          />
          <Route path='/shift-planning/:stationId' component={Shifts} />
        </Switch>
      </div>
    )
  }
}

export default ShiftPlanning
