import { useEffect, useState } from 'react'

export const useDebounce = (value, delayMillis) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      const handler = setTimeout(() => setDebouncedValue(value), delayMillis)
      return () => clearTimeout(handler)
    },
    [value, delayMillis]
  )
  return debouncedValue
}
