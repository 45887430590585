import React, { Fragment } from "react"
import gql from "graphql-tag"
import { Button, Grid, Image, Input, Loader, Modal, TextArea } from "semantic-ui-react"
import { Mutation } from "react-apollo"
import { ApolloProvider } from '@apollo/react-hooks'

import ClientColumn from './ClientColumn'
import ClientHealthInsuranceGroup from './ClientHealthInsuranceGroup'
import apolloClient from '../../utils/apolloClient'
import Avatar from "./../svgs/nurse.svg"
import { GET_CLIENTS } from './../../screens/clients'

import styles from './ClientModal.module.css'


const UPDATE_CLIENT = gql`
  mutation updateClient(
    $addressSuffix: String
    $apartmentKey: String
    $careLevel: Int
    $diagnosis: String
    $healthInsuranceName: String
    $id: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $streetHouseNumber: String
    $zipCodeCity: String
  ) {
    updateClient(
      addressSuffix: $addressSuffix
      apartmentKey: $apartmentKey
      careLevel: $careLevel
      diagnosis: $diagnosis
      healthInsuranceName: $healthInsuranceName
      id: $id
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      streetHouseNumber: $streetHouseNumber
      zipCodeCity: $zipCodeCity
    ) {
      client {
        canAcceptLeasing
        isActive
        id
      }
    }
  }
`

class ClientModal extends React.Component {
  state = { client: this.props.client }
  _apolloClient = apolloClient()

  render() {
    const { isOpened, onClose } = this.props
    return (
      <div className={styles.Client}>
        <Modal
          dimmer='inverted'
          open={isOpened}
          onClose={onClose}
          className={styles.ClientModal}
        >
          <Mutation
            mutation={UPDATE_CLIENT}
            variables={{
              addressSuffix: this.state.client.addressSuffix,
              apartmentKey: this.state.client.apartmentKey,
              careLevel: this.state.client.careLevel,
              diagnosis: this.state.client.diagnosis,
              healthInsuranceName: this.state.client.healthInsuranceName,
              id: this.state.client.id,
              firstName: this.state.client.firstName,
              lastName: this.state.client.lastName,
              streetHouseNumber: this.state.client.streetHouseNumber,
              phoneNumber: this.state.client.phoneNumber,
              zipCodeCity: this.state.client.zipCodeCity,
            }}
            refetchQueries={[{ query: GET_CLIENTS, variables: { isActive: true } }]}
            onCompleted={onClose}
            onError={error => this.props.onError({ error })}
            notifyOnNetworkStatusChange
          >
            {(updateClient, { loading, data }) => (
              <Fragment>
                <header className={styles.Header}>
                  <Image
                    className={styles.Nurse}
                    size='small'
                    src={Avatar}
                    avatar
                  />
                  <h3>Patientendaten</h3>
                </header>
                {loading && <Loader />}
                <section className={styles.Content}>
                  <Grid columns={2}>
                    {[
                      [['firstName', 'Vorname'], ['lastName', 'Nachname']],
                      [['phoneNumber', 'Telefonnummer'], ['streetHouseNumber', 'Straße und Hausnummer']],
                      [['healthInsuranceName', 'Krankenkasse'], ['zipCodeCity', 'PLZ und Ort']],
                      [['careLevel', 'Pflegegrad'], ['apartmentKey', 'Schlüssel Nr.']],
                      [['diagnosis', 'Diagnose', TextArea], ['addressSuffix', 'Adresszusatz', TextArea]],
                    ].map((row, rowIndex) => (
                      <Grid.Row key={rowIndex}>
                        {row.map((column, columnIndex) => <Grid.Column key={columnIndex} className={styles.Column}>
                          <ClientColumn
                            component={column.length > 2 ? column[2] : Input}
                            onChange={value => this.setState({ client: { ...this.state.client, [column[0]]: value } })}
                            placeholder={`${column[1]}...`}
                            title={column[1]}
                            value={this.state.client[column[0]]}
                          />
                        </Grid.Column>)}
                      </Grid.Row>
                    ))}
                  </Grid>
                  <div>
                    <b>Krankenkassengruppe</b><br />
                    <ApolloProvider client={this._apolloClient}>
                      <ClientHealthInsuranceGroup clientId={this.props.client.id} />
                    </ApolloProvider>
                  </div>
                </section>
                <footer className={styles.Actions}>
                  <Button
                    className={styles.UpdateButton}
                    disabled={!this.state.client}
                    onClick={updateClient}>Weiter</Button>
                </footer>
              </Fragment>
            )}
          </Mutation>
        </Modal>
      </div>
    )
  }
}

export default ClientModal
