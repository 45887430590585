import React from 'react'
import PlannedVisit from '../../components/tour-orders/PlannedVisit'

import styles from "../../components/tour-orders/PlannedVisits.module.css"

class PlannedVisits extends React.Component {
  render() {
    return (
      <div className={styles.PlannedVisits}>
        <div className={styles.PlannedVisitsHeader}>
          <div>Nr.</div>
          <div>Patientenname</div>
          <div>Leistungsgruppen</div>
          <div>KK</div>
          <div>Leistungen</div>
          <div>Geplante Zeit</div>
          <div>Aktionen</div>
        </div>
        <div className={styles.PlannedVisitsList}>
          {this.props.visits.filter(Boolean).map(
            (visit, index) => <PlannedVisit
              index={index}
              key={visit.id}
              onInsuranceGroupUpdate={this.props.onUpdatePlannedVisit}
              visit={visit}
            />
          )}
        </div>
      </div>
    )
  }
}
export default PlannedVisits
