import React from 'react'

import styles from './index.module.css'
import image from './image.png'

import { Card, CardHeader, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

const MapMarker = ({ onDelete, place }) => (
  <div className={styles.MapMarker}>
    <img src={image} alt="Map marker" />
    <Card className={styles.Hint} variant='outlined'>
      <CardHeader
        action={onDelete && <IconButton onClick={onDelete}><DeleteIcon /></IconButton>}
        title={place.text}
      />
    </Card>
  </div>
)

export default MapMarker
