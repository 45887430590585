import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'

import { getPersonColor } from '../../../utils/helpers'

import './PersonAvatar.css'

const PersonAvatar = ({ person }) => (
  <div className="PersonAvatar">
    {person
      ? <div style={{ backgroundColor: `${getPersonColor(person)}` }}>
        {`${person.firstName.charAt(0)}${person.lastName.charAt(0)}`}
      </div>
      : <div>
        <Icon name='user' size='big' />
      </div>
    }
  </div>
)

PersonAvatar.propTypes = {
  user: PropTypes.object,
}

export default PersonAvatar
