import React from 'react'

import _ from 'lodash'
import { ArrowDropDown } from '@material-ui/icons'
import { Button, LinearProgress, Menu, MenuItem, Select } from '@material-ui/core'

import { usePopup } from '../../hooks'
import styles from './index.module.css'

const SelectButton = ({
  buttonVariant = 'text',
  isLoading = false,
  isMultiSelect = false,
  errorMessage,
  inputVariant = 'standard',
  label,
  noValueLabel,
  onChange,
  options,
  value,
  variant = 'button', // 'button' or 'input'
  ...rest
}) => {
  const [isMenuOpen, showMenu, closeMenu, menuAnchorElement] = usePopup()
  const toggleOption = newValue => onChange && onChange(isMultiSelect
    ? (value.includes(newValue) ? value.filter(v => v !== newValue) : [...value, newValue]) : newValue
  )
  const menuItems = [
    isLoading && <MenuItem disabled={true} key="_loading">
      <LinearProgress className={styles.LinearProgress} />
    </MenuItem>,
    errorMessage && <MenuItem disabled={true} key="_error">{errorMessage}</MenuItem>,
    ...options.map(option => (
      <MenuItem
        key={option.value}
        onClick={() => {
          toggleOption(option.value)
          !isMultiSelect && closeMenu()
        }}
        selected={isMultiSelect ? (value.indexOf(option.value) > -1) : (option.value === value)}
        value={option.value}
      >
        {option.label}
      </MenuItem>
    ))
  ].filter(Boolean)
  return (variant === 'button')
    ? <>
      <Button onClick={showMenu} variant={buttonVariant} {...rest}>
        {
          label ||
          (!!options && (isMultiSelect
            ? options.filter(o => !!value && value.includes(o.value)).map(o => o.label).join(', ')
            : value ? (_.find(options, o => o.value === value) || {}).label : noValueLabel
          ))
        }
        <ArrowDropDown />
      </Button>
      <Menu anchorEl={menuAnchorElement} onClose={closeMenu} open={isMenuOpen}>{menuItems}</Menu>
    </>
    : <Select
      multiple={isMultiSelect}
      onChange={e => onChange(e.target.value)}
      value={options.length > 0 ? value : ''}
      variant={inputVariant}
      {...rest}
    >
      {menuItems}
    </Select>
}

export default SelectButton
