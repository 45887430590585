import React from 'react'

import moment from 'moment'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import { Button, Dimmer, Dropdown, Icon, Loader, Popup, TextArea } from 'semantic-ui-react'

import PersonAvatar from '../avatars/PersonAvatar'

class CommentingSection extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isUserDropdownOpen: false,
      isDatepickerOpen: false,
      messageBody: '',
      taskDueDatetime: null,
      taskAssigneeUserId: null,
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="CommentArea">
          {this.props.isFetching && <Dimmer active inverted><Loader size='small' /></Dimmer>}
          <div className="SmallAvatarParent">
            {this.props.userIdToUser && <PersonAvatar person={this.props.userIdToUser[this.props.loggedInUserId]} />}
          </div>
          <TextArea
            placeholder="Kommentar"
            value={this.state.messageBody}
            onChange={(_, data) => this.setState({ messageBody: data.value })}
          />
          <div className="Actions">
            <Popup
              trigger={
                <div className="Duedate">
                  {moment(this.state.taskDueDatetime).isValid()
                    ? <p>{moment(this.state.taskDueDatetime).format('DD/MM')}</p>
                    : <Icon name='clock' size='big' />}
                </div>
              }
              content={
                <DatePicker
                  selected={this.state.taskDueDatetime}
                  onChange={date => this.setState({ taskDueDatetime: date })}
                  openToDate={new Date()}
                  inline
                />
              }
              on='click'
              open={this.state.isDatepickerOpen}
              onOpen={() => this.setState({ isDatepickerOpen: true })}
              onClose={() => this.setState({ isDatepickerOpen: false })}
              position='top right'
              style={{ height: 'auto' }}
            />
            <Popup
              trigger={
                <div className="SmallAvatarParent">
                  {this.props.userIdToUser &&
                    <PersonAvatar person={this.props.userIdToUser[this.state.taskAssigneeUserId]} />}
                </div>
              }
              content={
                <Dropdown
                  options={_.sortBy(
                    Object.keys(this.props.userIdToUser)
                      .filter(userId => this.props.userIdToUser[userId].isActive)
                      .map(userId => ({
                        value: userId,
                        text: (user => `${user.firstName} ${user.lastName}`)(this.props.userIdToUser[userId]),
                      })),
                    ['text']
                  )}
                  value={this.state.taskAssigneeUserId}
                  onChange={(e, { value }) => this.setState({ taskAssigneeUserId: value })}
                  className="SubTaskUserDropdown"
                  placeholder="Bearbeiter"
                  icon={'search'}
                  search
                  selection
                />
              }
              on='click'
              open={this.state.isUserDropdownOpen}
              onOpen={() => this.setState({ isUserDropdownOpen: true })}
              onClose={() => this.setState({ isUserDropdownOpen: false })}
              position='top right'
              style={{ height: 'auto' }}
            />
          </div>
        </div>
        <Button onClick={() => this.closeTask()} disabled={!(this.state.messageBody)} secondary>Aufgabe beenden</Button>
        <Button onClick={() => this.createTask()} disabled={!this.state.messageBody} primary>Kommentar hinzufügen</Button>
      </React.Fragment>
    )
  }

  createTask = () => {
    this.props.createMessage({
      parentId: this.props.message.id,
      tag: this.props.message.tag,
      taskStatus: 'opened',
      text: this.state.messageBody,
      taskDueDatetime: this.state.taskDueDatetime ? moment(this.state.taskDueDatetime).format() : null,
      taskAssigneeUserId: this.state.taskAssigneeUserId,
    })

    this.setState({
      messageBody: '',
      taskDueDatetime: null,
      taskAssigneeUserId: null,
    })

    if (!this.props.message.task || ['in-progress', 'new'].includes(this.props.message.task.status)) return

    this.props.updateMessage({
      id: this.props.message.id,
      taskStatus: (['opened', 'read'].includes(this.props.message.task.status))
        ? 'in-progress'
        : this.props.message.task.assigneeUser ? 'opened' : 'new'
    })
  }

  closeTask = () => {
    this.props.createMessage({
      parentId: this.props.message.id,
      tag: this.props.message.tag,
      text: this.state.messageBody,
      taskStatus: 'closed',
    })

    this.props.updateMessage({
      id: this.props.message.id,
      taskStatus: 'closed',
      taskAssigneeUserId: (this.props.message.task && this.props.message.task.assigneeUser &&
        this.props.message.task.assigneeUser.id)
        ? this.props.message.task.assigneeUser.id
        : this.props.loggedInUserId,
    })
  }
}

export default CommentingSection
