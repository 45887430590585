import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/de'
import 'dayjs/locale/de'

import Auth from '../utils/auth'
import apolloClient from '../utils/apolloClient'
import Header from '../components/general/Header'
import initPluralize from '../utils/language'
import Error from '../components/Error'
import { requestAuth0Success } from '../actions/Auth0Actions'

import styles from './App.module.css'
import colorStyles from './App.colors.module.css'

dayjs.locale('de')
moment.locale('de')
initPluralize()

class App extends Component {
  constructor(props) {
    super(props)
    this.auth = new Auth()
    this.apolloClient = apolloClient()
    if (this.auth.isAuthenticated()) {
      this.auth.getUser()
        .then(authData => this.props.handleAuthentication(authData))
        .catch(() => this.handleLogout())
    }
  }

  render = () => {
    // TODO: Remove dependencies to Semantic such as Dimmer, etc.
    if (!this.auth.isAuthenticated()) return <Redirect to={{ pathname: "/login" }} />
    return <ApolloProvider client={this.apolloClient}>
      <ApolloHooksProvider client={this.apolloClient}>
        <Dimmer.Dimmable as={Segment} dimmed={this.props.isInitializing}>
          <Dimmer active={this.props.isInitializing} inverted>
            <Loader />
          </Dimmer>
          <div className={[styles.App, colorStyles.Colors].join(' ')}>
            <Header loggedInUser={this.props.loggedInUser} logout={this.handleLogout} />
            {this.props.error && <Error text={this.props.error} />}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {!this.props.error && this.props.children}
            </MuiPickersUtilsProvider>
          </div>
        </Dimmer.Dimmable>
      </ApolloHooksProvider>
    </ApolloProvider>
  }

  handleLogout = () => {
    this.auth.logout()
    window.location.reload()
  }
}

App.defaultProps = {
  error: null,
}

App.propTypes = {
  isInitializing: PropTypes.bool.isRequired,
}

const mapStateToProps = globalState => ({
  isInitializing: globalState.auth.isInitializing,
  loggedInUser: globalState.auth.loggedInUser.data,
  error: globalState.auth.error || globalState.chat.error,
})

const mapDispatchToProps = dispatch => ({
  handleAuthentication: authData => {
    dispatch(requestAuth0Success(authData))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
