import React from 'react'
import { connect } from 'react-redux'
import { Divider } from 'semantic-ui-react'

import TaskDescription from './TaskDescription'
import TaskMetadata from './TaskMetadata'
import TaskAvatar from './TaskAvatar'
import { setCurrentMessageId } from './../../actions/TwilioActions'

import './Task.css'

class Task extends React.Component {

  render = () => (
    <React.Fragment>
      <div
        className={`Task ${this.props.message.tags.join(' ')} ${this.props.message.task.status}`}
        onClick={() => {this.props.setCurrentMessageId(this.props.message.id); this.props.onClickTask()}}
      >
        <TaskAvatar tags={this.props.message.tags} />
        <TaskDescription
          message={this.props.message}
          client={this.props.clientIdToClient && this.props.clientIdToClient[this.props.message.clientId]}
          user={this.props.userIdToUser && this.props.userIdToUser[this.props.message.userId]}
        />
        <TaskMetadata
          message={this.props.message}
          userIdToUser={this.props.userIdToUser}
        />
      </div>
      <Divider />
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  setCurrentMessageId: id => dispatch(setCurrentMessageId(id))
})

export default connect(null, mapDispatchToProps)(Task)
